.MyProfile-main {
  /* background-color: #3a5686; */
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
}

.MyProfile-main-fixed {
  background-color: #3a5686;
  height: auto;
}
.MyPfroile_Upper_Container {
  background-color: #3a5686;
  /* box-shadow: 5px 5px 11px #00000026; */
  height: auto;
  margin-top: 80px;
}

.MyProfile-Container {
  background-color: #3a5686;
}

.My-Profile-details1 {
  max-width: 1024px;
}

.Myprofile-detail-button {
  width: 100px;
  height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #373f4b;
  border-radius: 30px;
  cursor: pointer;
  color: white;
}

.Myprofile-detail-button.active1 {
  width: 100px;
  height: 30px;
  color: #373f4b;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #f9f9f9;
  border-radius: 30px;
}

.profile_roles_main {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  justify-content: flex-start;
  height: fit-content;
  overflow-y: auto;
}

.profile_roles_child {
  width: 243px;
  height: auto;
  padding: 15px;
  gap: 4px;
  border-radius: 20px;
  background-color: #314d7e;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}

.profile_roles_child > h4 {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.07px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  width: 211px;
  /* height: 19px; */
  color: #f9f9f9;
  /* overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap; */
}
.profile_roles_child > h4::first-letter {
  text-transform: capitalize !important;
}
.profile_roles_des {
  margin-top: 5px;
}

.profile_roles_des > p {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.34px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #f9f9f9;
  /* width: 213px; */
}

.profile_roles_des > h6 {
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 13.62px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  width: 213px;
  color: #dadada;
  margin-top: 5px;
}

.roles_not_available {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.07px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  width: 211px;
  height: 19px;
  color: #f9f9f9;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}

.MyProfile_cin_address_slide_right {
  height: 325px;
  width: 288px;
  margin-top: 20px;
}
.profile-detail-main-responsive {
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.individual-profile-detail-responsive {
  display: flex;
  flex-direction: column;
  background-color: #314d7e;
  margin-top: 41px;
}
.individual_profile_acc_responsive {
  display: flex;
  flex-direction: column;
  width: auto;
  padding: 15px 20px;
  border-bottom: 1px solid #102c5d;
}
.profile-email-div-main-Resposive {
  width: auto;
  border-bottom: 1px solid #102c5d;
  display: flex;
  justify-content: space-between;
}
.profile-grade-details-responsive {
  display: flex;
  width: auto;
  gap: 35%;
  padding-left: 20px;
  border-bottom: 1px solid #102c5d;
}
.recipient-profile-box2 {
  background: #506994 0% 0% no-repeat padding-box;
  opacity: 1;
  border-right: 1px solid #1d3a6d;
}
.profile-detail-border-div {
  border-right: 1px solid #1d3a6d;
}
.profile-details-div-section-responsive {
  width: 100%;
  background-color: #1d3a6d;
  margin-top: 20px !important;
  margin-bottom: 20px;
}
.profile-details-section-responsive {
  height: 39px;
  background-color: #314d7e;
  max-width: 1024px;
  margin: auto;
}
.Porfilebuttonsforspecificdetails-responsive {
  display: flex;
  height: 39px;
  align-items: center;
  background-color: #314d7e;

  overflow-x: auto;
  white-space: nowrap;
}
.CINButton-responsive {
  height: 40px;
  display: flex;
  height: 39px;
  min-width: 132px;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #dadada;
}
.Details_profile_button_responsive {
  min-width: 130px;
  display: flex;
  align-items: center;
  height: 39px;
  justify-content: center;
  border-right: 1px solid #dadada;
}
.entity-detail-card-responsive {
  width: auto;
  height: auto;
  margin-bottom: 20px;
  background-color: #314d7e;
  border-radius: 10px;
  padding: 20px;
  margin-left: 20px;
  margin-right: 20px;
}
/* @media screen and (max-width: 400px) {
  .leaflet-container {
    height: auto !important;
  }
} */
.assigned_tag_div {
  flex-direction: row;
  padding: 15px 20px 20px 15px;
  gap: 10px;
  margin: 15px;
  background-color: #314d7e;
  display: flex;
  flex-wrap: wrap;
  border-radius: 10px;
}
.noassignedtags-card-responsive {
  width: auto;
  height: auto;
}
@media screen and (min-width: 800px) and (max-width: 1024px) {
  .individual-profile-detail-responsive {
    margin-top: 88px !important;
  }
}
@media screen and (min-width: 928px) and (max-width: 1024px) {
  .CINButton-responsive {
    min-width: 180px !important;
  }
  .Details_profile_button_responsive {
    min-width: 180px !important;
  }
}
.myprofile-ifsc-response {
  font: normal normal normal 16px / 22px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.service_section {
  margin-bottom: 20px;
}

.service_header {
  width: 1024px;
  height: 49px;
  padding: 15px 20px;
  background-color: #506994;
  font: normal normal normal 14px Open Sans;
  font-weight: 600;
  margin-bottom: 10px;
  color: #dadada;
  border-radius: 10px;
}

.service_container {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}
.service_container_responsive {
  width: 100%;
}
.profile_roles_child_resp {
  width: auto;
  height: 105px;
  padding: 15px;
  gap: 4px;
  border-radius: 20px;
  background-color: #314d7e;
  color: #f9f9f9;
  display: flex;
  flex-direction: column;
  margin: 0px 10px 10px 10px;
}
.service_header_resp {
  width: 1024px;
  height: 49px;
  padding: 15px 20px;
  background-color: #506994;
  font: normal normal normal 14px Open Sans;
  font-weight: 600;
  margin-bottom: 10px;
  color: #dadada;
}
.profile_roles_child_resp > h4::first-letter {
  text-transform: capitalize !important;
}
.profile-card-row-responsive {
  flex-direction: row;
  margin: 20px 0px 0px 0px;
  gap: 20px;
  padding-bottom: 20px !important;
}

.Inter_dividecontact {
  width: 472px;
  max-height: 400vh;
}

.Inter_select_owner_butt {
  width: 432px !important;
  height: 43px !important;
  background: #2a487e 0% 0% no-repeat padding-box !important;
  border-radius: 10px !important;
  margin: 20px !important;
  text-transform: none !important;
}

.Inter_select_owner_butt > span {
  text-align: left;
  letter-spacing: 0px;
  color: #ff7f33;
  opacity: 1;
}
.Inter_settleType_main_div_res {
  width: 100%;
  height: 287px;
  background: #314d7e 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  /* margin-left: 40px; */
  margin-top: 20px;
  padding: 20px 0px;
}

.Inter_settleType_main_div_res > p {
  width: auto;
  height: 22px;
  text-align: left;
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
  margin-left: 20px;
}

@media screen and (max-width: 800px) {
  .beneContact_Main_res {
    flex-direction: column !important;
    justify-content: flex-start !important;
    gap: 25px;
    /* padding-bottom: 73px; */
  }
}

.associatebutton {
  margin-left: 30px;
  margin-bottom: 10px;
}

.associatebuttonPan {
  width: auto !important;
  height: 24px !important;
  border-radius: 18px !important;
  text-align: center !important;
  font: normal normal 600 10px/14px Open Sans !important;
  letter-spacing: 0px !important;
  text-transform: none !important;
}

.associatebuttonBank {
  width: auto !important;
  height: 24px !important;
  margin-left: 10px !important;
  border-radius: 18px !important;
  text-align: center !important;
  font: normal normal 600 10px/14px Open Sans !important;
  letter-spacing: 0px !important;
  text-transform: none !important;
}

.custom-scroll-container::-webkit-scrollbar {
  width: 8px;
}

.custom-scroll-container::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 8px;
}

.custom-scroll-container::-webkit-scrollbar-thumb {
  background-color: #dadada;
  border-radius: 8px;
}

.custom-scroll-container::-webkit-scrollbar-thumb:hover {
  background-color: #dadada;
  border-radius: 8px;
}

.label-container {
  color: #dadada;
}

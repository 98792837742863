.internal-stepper-main {
  width: 1024px;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.internal-main_Line {
  height: 7px;
  border-radius: 4px;
  background-color: #ffffff;
}

@keyframes growHeightInt {
  from {
    width: 100px;
  }

  to {
    width: 360px;
  }
}

.internal-growing-element {
  animation: growHeightInt 0.7s backwards;
}

.internal-stepperline_Div_biller {
  width: 772px;
  height: 59.85px;
  padding: 0px 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

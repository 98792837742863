/*_utils/SelectCategoryTranscation/index.css*/
.txn_sort_by_category_big {
  width: 231px;
  height: 257px;
  border: 1px solid #e25869;
  border-radius: 10px;
  opacity: 1;
  z-index: 10;
  padding: 15px 0px;
  background-color: #667ca3;
  position: absolute;
}

.select-category-transaction>p {
  width: fit-content;
  height: 17px;
  text-align: left;
  font: normal normal normal 12px / 17px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
  margin-bottom: 5px;
}

.input_div_element {
  height: 30px;
  background: #3a5686 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  display: flex;
  padding: 8px 10px 10px 10.5px;
  justify-content: space-around;
}

.arrowdown_txn {
  width: 14px;
  height: 12px;
  opacity: 1;
}

.input_div_element>input {
  width: 70%;
  outline: none;
  background-color: transparent;
  text-align: left;
  font: normal normal normal 14px/18px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  margin-left: 10.16px;
  height: 20px;
  margin-top: -3px;
}

.input_div_element>input::placeholder {
  width: 44px;
  height: 19px;
  /* UI Properties */
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.search_owner_element {
  width: 14px;
  height: 14px;
  opacity: 1;
  cursor: pointer;
}

.txn_sort_by_category_scroll {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding-top: 2px;
}

.category_name {
  width: 211px !important;
  height: 39px !important;
  border-radius: 5px !important;
  margin-left: 5px !important;
  text-align: left !important;
  font: normal normal normal 14px/19px Open Sans !important;
  letter-spacing: 0px !important;
  color: #ffffff !important;
  display: flex !important;
  justify-content: flex-start !important;
  text-transform: none !important;
}

.txn_sort_by_category_scroll::-webkit-scrollbar {
  width: 5px;
  /* Width of the scrollbar */
}

/* Handle */
.txn_sort_by_category_scroll::-webkit-scrollbar-thumb {
  background: #fafafa 0% 0% no-repeat padding-box;
  /* Color of the scrollbar handle */
  border-radius: 5px;
  /* Rounded corners of the scrollbar handle */
  width: 5px;
  /* Width of the scrollbar thumb */
  height: 4px;
  /* Height of the scrollbar thumb */
}

/* Track */
.txn_sort_by_category_scroll::-webkit-scrollbar-track {
  background-color: transparent;
  /* Color of the scrollbar track */
}

.category_btn_select {
  padding: 0 !important;
  margin: 0 !important;
  height: 39px !important;
  background: #667ca3 0% 0% no-repeat padding-box !important;
  text-transform: none !important;
  text-align: left !important;
  font: normal normal normal 14px/19px Open Sans !important;
  letter-spacing: 0px !important;
  color: #fafafa !important;
  display: flex !important;
  justify-content: space-between !important;
  border-radius: 10px !important;
  padding: 0px 15px !important;
  position: absolute !important;
}

.txn_category_select {
  padding: 0 !important;
  /* margin: 0 !important; */
  text-transform: none !important;
  z-index: 99 !important;
  background-color: #667ca3 !important;
  border-radius: 10px !important;
  display: flex !important;
  flex-direction: column !important;
  overflow: hidden !important;
  transition: all 0.2s ease !important;
  cursor: pointer;
  margin-top: 4px !important;
}

.txn_category_select_select {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 36px;
  padding-right: 10px;
  align-items: center;
  position: absolute;
  top: 0;
}

.txn_category_select_select>span {
  width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  transition: all 0.2s ease;
  font: normal normal normal 14px/16px Open Sans;
}

.txn_category_select_scroll_com {
  width: 180px !important;
  height: 27px !important;
  border-radius: 5px !important;
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  text-align: left !important;
  font: normal normal normal 12px/17px Open Sans !important;
  letter-spacing: 0px !important;
  color: #ffffff !important;
  text-transform: none !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  font-size: 12px !important;
  transition: background-color 0.2s ease !important;
  outline: none !important;
  box-shadow: none !important;
  margin-left: 4px !important;
  margin-bottom: 2px;
  padding-left: 5px;
}

.txn_category_select_select>img {
  width: 11px;
  height: 11px;
  opacity: 1;
  transition: all 0.2s ease;
}

.txn_category_select_scroll_line {
  height: 1px;
  position: absolute;
  background-color: #3a5686;
  top: 39px;
  width: calc(-24px + 100%);
  left: 12px;
}

.txn_category_select_scroll {
  position: absolute;
  top: 37.5px;
  padding-top: 9px;
}

_select {
  padding: 0 !important;
  margin: 0 !important;
  width: 189px !important;
  text-transform: none !important;
  position: absolute !important;
  z-index: 99 !important;
  background-color: #667ca3 !important;
  border-radius: 10px !important;
  display: flex !important;
  flex-direction: column !important;
  overflow: hidden !important;
  transition: all 0.2s ease !important;
}

.txn_category_select_scroll_com:hover {
  background-color: #506994 !important;
  border-radius: 5px !important;
}

.txn_category_select_scroll_com:focus {
  outline: none !important;
  /* Adjust thickness and color as needed */
  box-shadow: none !important;
  /* Optional: Use a box-shadow for focus */
}

.select-category-transaction .focus-span:focus {
  outline: none;
  /* Remove default outline */
}


.bene_txn_category_select_scroll_comMulti {
  position: relative;
  width: 196px !important;
  height: 30px !important;
  border-radius: 5px !important;
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  text-align: left !important;
  font: normal normal normal 12px/17px Open Sans !important;
  letter-spacing: 0px !important;
  color: #ffffff !important;
  text-transform: none !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  margin-bottom: 3px !important;
  padding: 10px;
}


.bene_txn_category_select_scroll_comMulti:hover {
  background-color: #506994 !important;
  border-radius: 5px !important;
}

.bene_txn_MultiApp_filter_div_left .bene_txn_MultiApp_close_btn {
  display: flex;
  margin-bottom: 3px;
  justify-content: center;
  align-items: center;
  height: 15px;
  width: 15px;
  background-color: #667ca3;
  border-radius: 50%;
  opacity: 1;
}
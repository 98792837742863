.css-2y464i-MuiInputBase-root-MuiFilledInput-root {
  background: #3a5686 0% 0% no-repeat padding-box !important;
  color: white !important;
  border: none !important;
  border-radius: 10px !important;
  flex-shrink: 1;
  flex-grow: 1;
}

.css-wb57ya-MuiFormControl-root-MuiTextField-root {
  height: 65px !important;
  width: 446px !important;
}

#free-solo-2-demo-label {
  font-size: larger !important;
  color: #c9c9c9;
}

.css-2y464i-MuiInputBase-root-MuiFilledInput-root:after {
  border-bottom: none !important;
}

.autoCompleteListBox {
  color: white;
  background: #667ca3;
  scrollbar-width: 1px;
  width: 446px !important;
  flex-shrink: 1;
}

.settledUsingBox2{
  margin-top:5% !important;; 
}

.autoCompleteListBox > li:hover {
  background: #3a5686 !important;
}


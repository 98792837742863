.bank-logo-style {
  width: 83px !important;
  height: 29px !important;
  padding: 3px;
  background: #fafafa 0% 0% no-repeat padding-box;
  border-radius: 26px;
  opacity: 1;
}

.bank-details-crt {
  z-index: 2;
}

.user-crt-bank-name {
  z-index: 1;
  position: relative;
  background: var(---4cb537-verified-green) 0% 0% no-repeat;
  background: #4cb537 0% 0% no-repeat;
  padding-top: 13px;
  bottom: 26px;
  height: auto;
  width: 472px;
}

.user-crt-bank-address {
  position: relative;
  padding-top: 42px;
  bottom: 54px;
}

.user-box-rounded {
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

.bank-crt-main-header-new {
  width: 440px;
  height: 22px;
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.79px;
  color: #f9f9f9;
  opacity: 1;
  margin-bottom: 15px;
}

.bank-crt-main-header-address {
  width: 440px;
  height: 22px;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600)
    var(--unnamed-font-size-16) / var(--unnamed-line-spacing-22)
    var(--unnamed-font-family-open-sans);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
  margin-bottom: 15px;
}

.bank-crt-sub-header-new {
  width: 40px;
  height: 17px;
  text-align: left;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  color: #dadada;
  opacity: 1;
}

.acct_holder_name_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 13px 0 0 -13px;
  padding: 0 30px;
}

.acct_holder_span {
  margin-top: 0;
  margin-left: -13px;
  font-size: 12px;
  color: #fefefe;
}

.acct_holder_span_div {
  margin: -5px 63px;
  padding-bottom: 13px;
  padding-top: 2px;
}

.bank-img {
  position: relative;
  left: 277px;
  bottom: -8px;
  width: 132px;
  height: 47px;
}

.acct_holder_name {
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  margin: 0 0 0 -10px;
  text-wrap: wrap;
  word-wrap: break-word;
  flex: 1;
}

@media (max-width: 700px) {
  .acct_holder_name {
    width: 350px;
  }
}

@media (max-width: 410px) {
  .acct_holder_name {
    width: 260px;
  }
}

.merge_name-new {
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.79px;
  color: #f9f9f9;
}

.bank-details-crt .MuiFormControl-root .MuiInputLabel-root {
  font: normal normal 400 16px/16.34px "Open Sans";
}

.beneContact_Main .MuiFormControl-root .MuiInputLabel-root {
  font: normal normal 400 16px/16.34px "Open Sans";
}

.merge_name-new > img {
  width: 20px;
  height: 20px;
  margin-right: 22.86px;
}

@media screen and (max-width: 1022px) {
  .user-crt-bank-name {
    z-index: 1;
    position: relative;
    background: var(---4cb537-verified-green) 0% 0% no-repeat;
    background: #4cb537 0% 0% no-repeat;
    padding-top: 13px;
    bottom: 26px;
    height: auto;
    width: auto;
  }
  .acct_holder_span_div {
    display: flex;
    width: 100vw;
    justify-content: space-between;
    margin: -5px 63px;
    padding-bottom: 13px;
    padding-top: 2px;
  }
  .bank-img {
    transform: translateX(-136px);
    position: relative;
    left: inherit;
    bottom: -16px;
    width: 132px;
    height: 47px;
  }

  @media screen and (max-width: 500px) {
    .bank-img {
      transform: translateX(-136px);
      position: relative;
      left: inherit;
      bottom: -33px;
      width: 132px;
      height: 30px;
    }
  }
  .acct_holder_span {
    padding-top: 8px;
  }
  .bank-crt-main-header-new {
    width: auto;
  }
  .user-crt-bank-address {
    position: relative;
    margin-top: -25px;
    bottom: 16px;
  }
}

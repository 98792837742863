.nextButton {
  width: 136px;
  height: 50px;
  text-align: center;
  font: normal normal normal 22px/13px Open Sans !important;
  letter-spacing: 0px !important;
  color: #000000 !important;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 28px !important;
  cursor: pointer;
  text-transform: none !important;
}

.createVendorTxnContainer {
  margin: 160px auto 0 auto;
}

.backButton {
  text-decoration: underline !important;
  font: normal normal normal 22px/13px Open Sans !important;
  letter-spacing: 0px;
  color: #ffffff !important;
}

.step {
  font-size: 20px !important;
}

.MuiStepIcon-text {
  font-size: 20px !important;
}

.mobileStepperContainer .MobileStepper {
  background: #2d4e86 0% 0% no-repeat padding-box !important;
  box-shadow: 5px 5px 11px #00000033 !important;
  position: fixed !important;
  width: 100% !important;
  bottom: 0 !important;
  display: flex !important;
  border-radius: 40px 40px 0px 0px !important;
  justify-content: space-around !important;
}

.mobileStepperContainer .MuiMobileStepper-dot {
  background-color: white !important;
  opacity: 0.65;
  height: 10px !important;
  width: 10px !important;
  padding: 0 5% !important;
}

.mobileStepperContainer .MuiMobileStepper-dotActive {
  background-color: white !important;
  opacity: 1;
  width: 40px !important;
  height: 10px !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 30px !important;
}

#step3 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 2%;
  margin-top: 2%;
  height: 70vh;

}

#step2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  margin-top: 2%;
  height: 70vh;
}


#selectTxnsHeadingBoxMobileID {
  background-color: #1d3a6d;
  top: 120px;
}

@media only screen and (min-width: 480px) {
  .mobileStepperContainer .MobileStepper {
    display: none !important;
  }

  .mobileStepperContainer .mobileStepperHeading {
    display: none;
  }

  .mobileStepperHeading {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .mobileStepperHeading {
    text-align: center;
    font: normal normal 600 22px Open Sans;
    letter-spacing: 0px;
    color: #fcfcfc;
    margin: 5% auto;
  }

  .mobileStepperContainer .stepperLabel {
    display: none !important;
  }

  .stepperContainer {
    display: none;
  }

  .desktopStepperHeading {
    display: none;
  }


  .enterDetails {
    margin: auto;
    width: 90%;
  }

  #step2 {
    margin: 0 auto;
  }

  #step3 {
    display: block;
    margin-bottom: 100px;
  }
}

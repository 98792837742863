.proceedBTMobile {
  width: 70%;
  height: 13%;
  font: normal normal normal 24px/33px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  background: transparent linear-gradient(105deg, #0279ff 0%, #ff5860 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 5px 5px 11px #00000033;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10% 7%;
  flex-shrink: 1;
  margin-right: 25%;
}

.selectTxnsHeading {
  text-align: left;
  font: normal normal 600 24px/33px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  padding: 2%;
}

.selectTxnsRight {
  width: 30%;
  background: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.selectTxnSearchImg {
  width: 25px;
  height: 25px;
}

.selectTxnSearch {
  width: 45px;
  height: 45px;
  background: inherit 0% 0% no-repeat padding-box !important;
  box-shadow: 5px 5px 11px #00000033 !important;
  border-radius: 10px !important;
}

.selectTxnsHeadingBox {
  padding: 2% 2% 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 64%;
  position: fixed;
  background: inherit 0% 0% no-repeat padding-box;
  z-index: 500;
}

.allSelectVendorCards {
  display: flex;
  flex-wrap: wrap;
  margin-top: 9%;
  padding: 2%;
  column-gap: 1em;
  row-gap: 1em;
}

.selectTxnsLeft::-webkit-scrollbar {
  /* display: none; */
  width: 5px;
  height: 25px;
}

.selectTxnsLeft::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

.selectTxnsLeft::-webkit-scrollbar-thumb {
  background-color: #fafafa;
  /* outline: 1px solid #314D7E; */
  border-radius: 25px;
  /* height: 75px; */
}

.selectTxnsHeadingBoxMobile {
  padding: 20px 5%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  left: 0;
  right: 0;
  position: fixed;
  background: #3e5555 0% 0% no-repeat padding-box;
  z-index: 500;
}

@media only screen and (max-width: 480px) {
  .selectTxnsHeadingBox {
    display: none;
  }

  .selectTxnsRight {
    display: none;
  }

  .selectTxnsLeft {
    width: 100%;
  }

  .proceedBTMobile {
    margin: auto;
    margin-top: 150px;
    margin-bottom: -50px;
  }
  .proceedBTMobile {
    width: 70%;
    height: 13%;
    font: normal normal normal 24px/33px Open Sans;
    letter-spacing: 0px;
    color: #ffffff;
    background: transparent linear-gradient(105deg, #0279ff 0%, #ff5860 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 5px 5px 11px #00000033;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10% 7%;
    flex-shrink: 1;
    margin-right: 25%;
  }

  .dataCon {
    height: fit-content !important;
    background-color: #3e5555;
  }

  .selectTxnsHeading {
    text-align: left;
    font: normal normal 600 24px/33px Open Sans;
    letter-spacing: 0px;
    color: #ffffff;
    padding: 2%;
  }

  .selectTxnsRight {
    width: 30%;
    background: inherit;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .selectTxnSearchImg {
    width: 25px;
    height: 25px;
  }

  .selectTxnSearch {
    width: 45px;
    height: 45px;
    background: inherit 0% 0% no-repeat padding-box !important;
    box-shadow: 5px 5px 11px #00000033 !important;
    border-radius: 10px !important;
  }

  .selectTxnsHeadingBox {
    padding: 2% 2% 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 64%;
    position: fixed;
    background: inherit 0% 0% no-repeat padding-box;
    z-index: 500;
  }

  .allSelectVendorCards {
    display: flex;
    flex-wrap: wrap;
    margin-top: 9%;
    padding: 2%;
    column-gap: 1em;
    row-gap: 1em;
  }

  .selectTxnsLeft::-webkit-scrollbar {
    /* display: none; */
    width: 5px;
    height: 25px;
  }

  .selectTxnsLeft::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  }

  .selectTxnsLeft::-webkit-scrollbar-thumb {
    background-color: #fafafa;
    /* outline: 1px solid #314D7E; */
    border-radius: 25px;
    /* height: 75px; */
  }

  .selectTxnsHeadingBoxMobile {
    padding: 20px 5%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    left: 0;
    right: 0;
    position: fixed;
    background: #3e5555 0% 0% no-repeat padding-box;
    z-index: 500;
  }

  @media only screen and (max-width: 480px) {
    .selectTxnsHeadingBox {
      display: none;
    }

    .selectTxnsRight {
      display: none;
    }

    .selectTxnsLeft {
      width: 100%;
    }

    .proceedBTMobile {
      margin: auto;
      margin-top: 150px;
      margin-bottom: -50px;
    }

    .approveDetailsBTsMobile {
      position: fixed;
      bottom: 0;
      background: #3e5555 0% 0% no-repeat padding-box;
      box-shadow: 0px -3px 6px #00000029;
      border-radius: 40px 40px 0px 0px;
      margin: 0;
      padding: 20px 0;
      z-index: 500;
    }

    .selectTxnsLeft {
      padding-left: 0;
    }
  }

  @media only screen and (min-width: 480px) {
    .selectTxnsHeadingBoxMobile {
      display: none;
    }

    .proceedBTMobile {
      display: none;
    }

    .approveDetailsBTsMobile {
      display: none;
    }
  }

  .showMultipleBTMobile {
    width: 45px;
    height: 45px;
    background: inherit 0% 0% no-repeat padding-box !important;
    box-shadow: 5px 5px 11px #00000033 !important;
    border-radius: 10px !important;
    margin-right: 10% !important;
    position: absolute !important;
    margin: 0 !important;
  }

  .approveDetailsBTsMobile {
    position: fixed;
    bottom: 0;
    background: #3e5555 0% 0% no-repeat padding-box;
    box-shadow: 0px -3px 6px #00000029;
    border-radius: 40px 40px 0px 0px;
    margin: 0;
    padding: 20px 0;
    z-index: 500;
  }

  .selectTxnsLeft {
    padding-left: 0;
  }
}

@media only screen and (min-width: 480px) {
  .selectTxnsHeadingBoxMobile {
    display: none;
  }

  .proceedBTMobile {
    display: none;
  }

  .approveDetailsBTsMobile {
    display: none;
  }
}

.showMultipleBTMobile {
  width: 45px;
  height: 45px;
  background: inherit 0% 0% no-repeat padding-box !important;
  box-shadow: 5px 5px 11px #00000033 !important;
  border-radius: 10px !important;
  margin-right: 10% !important;
  position: absolute !important;
  margin: 0 !important;
}

.device-mainPopup {
  position: fixed;
  left: 0%;

  word-break: break-all;

  color: #ffffff;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  overflow-y: hidden;
}

.token-popup {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 246px;
  left: 403px;
  width: 561px;
  height: 220px;
  background-color: #102c5d;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  padding: 20px;
  z-index: 99;
}
.token-popup h5 {
  font: normal normal 600 20px/27px Open Sans;
  font-size: 16px;
  letter-spacing: 0px;
  color: #ff7f33;
  opacity: 1;
}

.token-popup p {
  font-size: 13px;
}

.token-detail-text {
  display: flex;
  justify-content: space-between;
}

.api-box {
  border: 1px dashed #f9f9f9;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  position: relative;
}

.api-box > p {
  font-size: 12px;
  margin: 10px;
}

.api-box .clipboard-img img {
  position: absolute;
  height: 21px;
  right: 2px;
  bottom: 6px;

  cursor: pointer;
}

.api-box .clipboard-img .copied-notification {
  position: absolute;
  padding: 4px;
  font-size: 10px;
  border-radius: 2px;
  right: 2px;
  bottom: -20px;
  background-color: #000000;
  color: #ffffff;
}


.settled_bene_txn_popover_btn_accm {
  color: #4ddd37 !important;
  /* width: 112px;
  height: 26px; */
  text-transform: none !important;
  cursor: pointer;
}

.awaiting_verification_bene_txn_popover_btn_accm,
.approved_bene_txn_popover_btn_accm {
  color: #4ddd37 !important;
  cursor: pointer;
  text-transform: capitalize !important;
}

.denied_bene_txn_popover_btn_accm {
  color: #ff5860 !important;
  /* margin-right: 20px; */
  cursor: pointer;
}

.failed_bene_txn_popover_btn_accm {
  color: #ff5860 !important;
  cursor: pointer;
}

.blocked_bene_txn_popover_btn_accm,
.cancelled_bene_txn_popover_btn_accm,
.rejected_bene_txn_popover_btn_accm {
  color: #ff5860 !important;
  /* margin-right: 20px; */
  cursor: pointer;
}

.hold_bene_txn_popover_btn_accm {
  color: #fbc02d !important;
  /* margin-right: 20px; */
  cursor: pointer;
}

.queued_bene_txn_popover_btn_accm {
  color: #fbc02d !important;
  /* margin-right: 20px; */
  cursor: pointer;
}

.pending_bene_txn_popover_btn_accm {
  color: #31e5e5 !important;
  /* margin-right: 20px; */
  cursor: pointer;
}

.abandoned_bene_txn_popover_btn_accm {
  color: #b4b4b4 !important;
  /* margin-right: 20px; */
  cursor: pointer;
}

.all_bene_txn_popover_btn_accm::first-letter {
  text-transform: capitalize !important;
}

.all_bene_txn_popover_btn_accm {
  /* color: #446aff !important; */
  text-transform: none !important;
  /* margin-right: 20px; */
  cursor: pointer;
}

#bene_txn_popover_section_accm {
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: flex-start !important;
  flex-direction: column;
  gap: 5px;
  width: auto !important;
  height: auto !important;
  align-items: flex-start;
  /* padding: 20px !important; */
}

@media (min-width: 801px) {
  .popover-sectionm {
    width: 490px !important;
    height: 113px !important;
  }
}

.main-popover-sectionm {
  border-radius: 0px !important;
  margin-top: 10px !important;
}

.bene_txn_popover_section_accm a {
  height: 26px !important;
  text-align: center !important;
  display: inline-flex;
  align-items: center !important;
  justify-content: center !important;
  text-decoration: none !important;
}

.popover-link_accm::first-letter {
  text-transform: capitalize !important;
}

.bene_txn_selected_btn_approved_acc
  .bene_txn_selected_btn_denied_acc
  .bene_txn_selected_btn.pending_acc
  .bene_txn_selected_btn.blocked_acc
  .bene_txn_selected_btn.abandoned_acc
  ::first-letter {
  text-transform: capitalize !important;
}

.bene_txn_selected_btn_denied_accm {
  background-color: #ff5860 !important;
  color: #ff5860 !important;
  width: 110px;
  height: 28px;
  text-transform: none !important;
}

/* .all-txn-status-icon {
  width: 15px !important;
  height: 15px !important;
  margin-right: 5px !important;
} */
.status_icon_accm {
  width: 18px !important;
  margin-left: 5px !important;
  height: 16px !important;
}

.view-all_bene_txn_popover-main_accm {
  position: relative;
  display: inline-block;
}

.all-txn-status-icon_accm,
.all-txn-status-icon_accm special-checkmark {
  margin-left: 5px;
  width: 18px;
  height: 18px;
}

.popover-link_accm {
  background-color: #4ddd37;
  border-radius: 20px;
  color: white;
  padding: 5px 10px;
  cursor: pointer;
  text-align: center;
  text-transform: none !important;
}

.popover-link_accm:hover {
  box-shadow: 1px soild red;
}

.pop_overbtn.settled_accm {
  /* background-color: #4ddd37 !important; */
  color: #4ddd37 !important;
  width: 114px;
  height: 29px;
  text-transform: none !important;
  border: none !important;
}

.pop_overbtn.awaiting_verification_accm,
.pop_overbtn.approved_accm {
  /* background-color: #b196fa !important; */
  color: #4ddd37 !important;
  width: 175px;
  height: 30px;
  font-size: 13px !important;
  text-transform: capitalize !important;
  border: none !important;
}

.pop_overbtn.pending_accm,
.pop_overbtn.abandoned_accm,
.pop_overbtn.all_accm ::first-letter {
  text-transform: capitalize !important;
}

.pop_overbtn.denied_accm,
.pop_overbtn.blocked_accm,
.pop_overbtn.failed_accm,
.pop_overbtn.cancelled_accm,
.pop_overbtn.rejected_accm {
  /* background-color: #ff5860 !important; */
  color: #ff5860 !important;
  width: 110px;
  height: 28px;
  text-transform: capitalize !important;
  border: none !important;
}

.pop_overbtn.pending_accm {
  /* background-color: #31e5e5 !important; */
  color: #31e5e5 !important;
  width: 110px;
  height: 28px;
  text-transform: none !important;
  border: none !important;
}

.pop_overbtn.queued_accm,
.pop_overbtn.hold_accm {
  /* background-color: #fbc02d !important; */
  color: #fbc02d !important;
  width: 110px;
  height: 28px;
  text-transform: none !important;
  border: none !important;
}

.pop_overbtn.abandoned_accm {
  /* background-color: #b4b4b4 !important; */
  color: #b4b4b4 !important;
  width: 118px;
  height: 28px;
  text-transform: none !important;
  border: none !important;
}

.beneTxnViewAllPopover_accm {
  height: 77px;
  background-color: #506994;
  cursor: pointer;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
}

@media screen and (min-width: 800px) {
  .beneTxnViewAllPopover_acc {
    -webkit-tap-highlight-color: transparent;
  }
}
.benTxnViewall_inner_div_accm {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  /* padding: 0px 20px 0px 0px; */
  justify-content: space-between;
}

.bene-txn-viewall-popover_accm {
  margin: 0 -2rem;
  height: fit-content;
  background-color: #506994;
  /* width: 232px; */
  padding: 10px 12px;
  /* margin-left: -1rem; */
}

.bene-txn-viewall-popover_accm.open {
  visibility: visible;
  height: 240px;
  transition: height 0.2s ease-in;
}

.bene-txn-viewall-popover_accm.closed {
  transition: height 0.2s ease-in, visibility 0s linear 0.2s;
  height: 0;
  visibility: hidden;
}

.bene-txn-viewall-popover_accm::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /* 60% of the div's width */
  height: 100%;
  border-bottom: 1px solid #1d3a6d;
}

.beneTxnViewAllchild2_accm {
  height: 17px;
  text-align: center;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
  margin-top: 1px;
}

.bene_viewall_popover_icon_accm {
  width: 20px;
  height: 20px;
  background: #1d3a6d;
  border-radius: 3px;
  margin-bottom: -30px;
  padding: 3px 3px 0px 3px;
}

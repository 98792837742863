.QuickAccessMenu-container {
  box-shadow: -5px -5px 11px #71717133, 5px 5px 11px #00000033;
  border-radius: 40px;
  opacity: 1;
  justify-content: space-around;
  align-items: center;
  position: flex;
  width: 100%;
}

.QuickAccessMenu-Top-Menu {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.QuickAccessMenu-bottom-Menu {
  display: flex;
  flex-direction: column;
  padding-top: 1.875em;
}

.QuickAccessMenu-bottom-Menu-Item {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  height: 40%;
}

.bottom {
  text-align: left;
  font: normal normal normal 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.QuickAccessMenu-Top-Menu-Item-Text {
  color: white;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
}


.QuickAccessMenu-Top-Menu-Item-Icon>img {
  width: 50px;
  height: 50px;
}
.hoverEffect:hover {
  background-color: #fafafa1a;
  border-radius: 20px;
  /* opacity: 0.7; */
  /* padding-right: 2px; */
}

@media screen and (max-width: 480px) {
  .QuickAccessMenu-container>div:nth-child(2) {
    display: none;
  }
}

.beneBank_Main_edit {
  width: 100%;
  scroll-snap-align: start;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
}

.dividebank_edit {
  width: 472px;
  margin: 0px 20px;
}

.bankright_edit {
  width: 100%;
}
.bankContainer {
  width: 100%;
  height: 588px;
  border-radius: 30px;
  background-color: #304b7d;
  box-shadow: 0px 3px 6px #00000029;
}

.ifscCode {
  padding-top: 19px;
}
.ifscCode span {
  color: #fafafa !important;
}
.ifscCode p {
  color: #d5d5d5 !important;
}

.bankImg {
  border-radius: 26px;
  width: 85px;
  height: 29px;
  margin-left: -24px;
}

.bankImg img {
  width: 67px;
  height: 20px;
}

.bankholderName {
  display: flex;
  padding-top: 10px;
  justify-content: space-between;
}
.bankholderName span {
  color: #fafafa !important;
}
.bankholderName p {
  padding-right: 30px;
}

.bankLeft_edit {
  width: 100%;
}

.bankContainerRight {
  width: 100%;
  height: 588px;
  border-radius: 30px;
  background-color: #304b7d;
}

.Scroll-bar {
  height: 480px;
  overflow-y: scroll;
}

.archieveAccount {
  padding-left: 30px;
  padding-top: 20px;
  color: #f9f9f9;
}

.contactdetail {
  padding-top: 8px;
}

.contactdetail span {
  padding-top: 5px !important;
}
.contactdetail p {
  color: #c9c9c9 !important;
  padding-bottom: 8px;
}
.archievemessage {
  padding-top: 15px;
  color: #c9c9c9 !important;
}
.email-bank {
  padding-top: 0px !important;
}

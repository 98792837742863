.vendor-register-pan-verfication-input {
  border: 4px solid #4CB537;
  flex: 2.4;
  border-radius: 0 20px 20px 0;
}

.vendor-register-pan-verfication-banner {
  flex: 2.2;
  background: #4CB537 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  color: #FFFFFF;
  border-radius: 40px 0 0 40px;
}

.vendor-register-bank-verfication-input {
  border: 5px solid #4CB537;
  flex: 2.12;
  border-radius: 0 30px 30px 0;
  margin-right: 4.5%;
  padding: 1% 0% 0% 0%;
}

.vendor-register-bank-not-verified-input {
  border: 5px solid #CE362A;
  flex: 2.12;
  border-radius: 0 30px 30px 0;
  margin-right: 4.5%;
  padding: 1% 0% 1% 0%;
}

.vendor-register-bank-verfication-banner {
  flex: 1.97;
  background: #4CB537 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: #FFFFFF;
  border-radius: 40px 0 0 40px;
}

.vendor-register-bank-verfication-banner-text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 5%;
  margin-top: 4%;
}

.vendor-register-bank-verfication-banner-logo {
  width: 158px;
  height: 55px;
  background: #FFFFFF;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 0px 40px;
  margin-top: 4%;
}

.leagal-name-div {
  text-align: left;
  width: 100%;
  font: normal normal 600 18px/24px Open Sans;
  letter-spacing: 0px;
  color: #FFFFFF;
  margin-left: 20%;
}

.vendor-leagal-name-label {
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #DADADA;
  opacity: 1;
}

.vendor-register-bank-not-verified-banner {
  flex: 1.96;
  background: #CE362A 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 40px 0 0 40px;
  max-height: 8.2em;
}

.vendor-register-bank-not-verified-banner-text {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  margin-top: 5%;
  margin-left: 5%;
}

.vendor-register-bank-not-verified-banner-text ul {
  margin-top: 2%;
  margin-left: -5%;
}

.vendor-register-bank-not-verified-banner-text li {
  line-height: 180%;
  font: normal normal 600 1em/2em Open Sans;
  color: #FAFAFA;
}

.bank-remark-vendor {
  background-color: #DE892E;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 104%;
  text-align: left;
  border-radius: 0 0px 40px 40px;
  margin-left: -5%;
}

.bank-remark-vendor p {
  font: normal normal 0.8em/1.5em Open Sans;
  color: #FAFAFA;
  width: 80%;
}


@media screen and (max-width: 480px) {
  .create-vendor-txn-form {
    background: #3a5686 0% 0% no-repeat padding-box;
  }
}

.TxnCreatedContainer {
  background: #29487e 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 40px;
  padding: 1% 0% 1% 0%;
  margin: auto;
  text-align: center;
  width: 80%;
  flex-shrink: 1;
  height: 4/5;
  flex-grow: 1;
}

.detailsText {
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  margin: 5% 10%;
  padding: 0 0 2% 0;
}

.detailsText b {
  font: normal normal 600 18px/24px Open Sans;
  letter-spacing: 0px;
  color: #000000;
}

.detailsText p {
  margin: 0 0 15px 0;
  font: normal normal normal 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #828282;
}

.txnStatusCard {
  align-self: right;
  background-color: white;
  width: 100%;
  padding: 5px 0% 0% 0%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 40px;
}

.txnCreatedText {
  display: flex;
  align-items: center;
  justify-content: center;
  font: normal normal normal 21px/28px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  gap: 20px;
}

.amountTextTxnCreated {
  color: #6ba673;
  font: normal normal 600 34px/46px Open Sans;
  letter-spacing: 0px;
}

.estimatedDate2 > span {
  font: normal normal normal 11px/15px Open Sans;
  letter-spacing: 0px;
  color: #828282;
}

.estimatedDate2 > b {
  margin-top: 15px;
  font: normal normal 600 15px/20px Open Sans;
  letter-spacing: 0px;
  color: #69b76f;
}

.invoiceNumber {
  text-align: left;
  font: normal normal normal 20px/27px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  padding: 8px 45px;
}

.invoiceNumber > p {
  font: normal normal normal 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  line-height: 0px;
}

.createTxnBt {
  width: 286px;
  height: 54px;
  margin-bottom: -40px !important;
  font: normal normal normal 20px/14px Open Sans !important;
  letter-spacing: 0px !important;
  color: #ffffff !important;
  background: #e37718 0% 0% no-repeat padding-box !important;
  border-radius: 10px !important;
  text-transform: none !important;
}

.paraNImg {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.txnCreatedIntName {
  font: normal normal 600 21px/28px Open Sans;
  letter-spacing: 0px;
  color: #000000;
  padding: 20px 0;
}

.dashedLine {
  height: 3px;
  background: repeating-linear-gradient(
    to left,
    transparent,
    transparent 15px,
    #000000 15px,
    #000000 30px
  );
  opacity: 0.2;
}

.simpleLine {
  margin: 7px 0px;
  height: 1px;
  background: #cbcbcb;
}

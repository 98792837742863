.InstaAccSumContainer {
  width: 400px;
  height: 120px;
  background: #3a5686 0% 0% no-repeat padding-box;
  box-shadow: 5px 5px 11px #00000033;
  border-radius: 30px;
  opacity: 1;
  display: flex;
  justify-content: space-between;
  padding-top: 5%;
  padding-left: 5%;
  flex-shrink: 1;
}

.Instaflex1 {
  background: white;
  border-radius: 30px 0px;
  height: 59px;
  width: 96px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 1;
}

.Insta-avail-balance {
  width: 288px;
  height: 46px;
  text-align: left;
  font: normal normal 600 33px/45px Open Sans;
  letter-spacing: 0px;
  color: #4de659;
  opacity: 1;
}

.Insta-avail-balance-subtitle {
  text-align: center;
  font: normal normal normal 17px/23px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
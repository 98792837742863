.txnDetailsContainerLeft {
  width: 55%;
  padding: 150px 5% 0% 11%;
  overflow: auto;
  box-shadow: -5px -5px 11px #71717133;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.txnDetailsContainerRight {
  padding: 150px 3% 0% 2%;
  width: 25%;
  overflow: auto;
}

.payNVendorDetails {
  display: flex;
  justify-content: space-between;
  gap: 10px 2%;
}

.quickAccessMenuMobile {
  background: inherit 0% 0% no-repeat padding-box;
  box-shadow: -5px -5px 11px #71717133;
  border-radius: 40px;
}

.quickAccessMenuMobile>div {
  font: normal normal 600 20px Open Sans;
  text-align: left;
  letter-spacing: 0px;
  color: #c9c9c9;
  padding: 10px 20px;
}

@media screen and (max-width: 480px) {
  .txnDetailsContainerRight {
    display: none;
  }

  .txnDetailsContainerLeft {
    width: 90%;
    padding-left: 5%;
  }
}

@media screen and (min-width: 480px) {
  .quickAccessMenuMobile {
    display: none;
  }

  .quickAccessMenuMobile {
    display: none;
  }
}

.DataCount_div {
  width: auto;
  height: 42px;
  border-radius: 5px;
  padding: 10px;
  background-color: #031f4fcc;
  opacity: 0.8;
}

.DataCount_text {
  width: auto;
  height: 22px;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  color: #f9f9f9;
}

.mobile-view-edit-modal-nav {
    display: none;
}

@media screen and (max-width: 800px){
    .mobile-view-edit-modal-hide {
        padding-left: 10px;
    }
    /* .mobile-view-edit-modal-bgblack {
        align-items: end !important;
    }
    .mobile-view-edit-modal-container {
        padding: 0 !important;
    } */
    .mobile-view-edit-modal-card {
        width: 100vw !important;
        max-width: none !important;
        border-radius: 20px !important;
        padding: 20px 10px 0 10px !important;
    }
    /* .mobile-view-edit-modal-card-div{
        padding-right: 30px;
        padding-left: 30px;
    }
    .mobile-view-edit-modal-nav {
        display: flex;
        flex-direction: column;
    }
    .mobile-view-edit-modal-error{
        width: 100vw;
        height: 60px;
        background-color: #E74C3C;
        color: #F9F9F9;
        font-size: 14px;
        font-weight: 400;
        position: relative;
        bottom: -15px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
    }
    .mobile-view-edit-modal-alert {
        width: 100vw;
        height: 60px;
        background-color: #CE720F;
        color: #F9F9F9;
        font-size: 14px;
        font-weight: 400;
        position: relative;
        bottom: -15px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        padding: 10px 0 0 10px;
    }
    .mobile-view-edit-modal-btn {
        width: 100vw;
        background-color: #3A5686;
        border-radius: 20px 20px 0 0;
        padding: 15px 20px 15px 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        position: relative;
    } */
}

@media screen and (max-width: 480px) {
    /* .mobile-view-edit-modal-alert {
        height: 75px !important;
    }
    .mobile-view-edit-alert-img {
        margin-top: 10px !important;
    } */
}

@media screen and (max-width: 440px) {
    /* .mobile-view-edit-modal-card-div {
        padding-right: 20px;
        padding-left: 20px;
    } */
    .mobile-view-edit-modal-options {
        font-size: 14px !important;        
    }
}

@media screen and (max-width: 370px) {
    .mobile-view-edit-modal-options {
        font-size: 13px !important;
    }
}
.refundContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #6b476b 0% 0% no-repeat padding-box;
  box-shadow: -5px -5px 11px #71717133;
  border-radius: 40px;
  display: flex;
  padding: 20px;
  text-align: left;
  color: white;
  align-items: center;
}
.txn-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 50px;
}
.refund-right {
  display: flex;
  flex-direction: column;
  font: normal normal normal 20px/27px Open Sans;
  letter-spacing: 0px;
  color: #fcfcfc;
  gap: 50px;
}
.date {
  font: normal normal normal 16px/22px Open Sans;
  color: #c9c9c9;
  gap: 0px;
}
.amount-status {
  font: normal normal normal 20px/27px Open Sans;
  letter-spacing: 0px;
  color: #fcfcfc;
  margin: 0px;
  padding: 0px;
}
.heading {
  font: normal normal normal 20px/27px Open Sans;
  letter-spacing: 0px;
  color: #fcfcfc;
  margin: 0px;
  padding: 0px;
}
.subheading-text {
  font: normal normal normal 16px/22px Open Sans;
  color: #c9c9c9;
  gap: 0px;
}

.status-icon > img {
  width: 42px;
  align-self: center;
}

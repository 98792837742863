.beneficiaryMultiApprovalTxnDetails_top {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 300px;
  background: #3a5686 0% 0% no-repeat padding-box;
  box-shadow: 5px 5px 11px #00000026;
  padding-top: 105px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* position: sticky; */
  /* z-index: 9998; */
}

.beneficiaryMultiApprovalTxnDetails_main {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.beneficiaryMultiApprovalTxnDetails_bottom {
  height: 100%;
  overflow-y: scroll;
  position: relative;
  bottom: 0;
  margin-top: 5px;
}

.beneficiaryMultiApprovalTxnDetails_top_1 {
  top: 120px;
  left: 171px;
  width: 1023px;
  height: 90px;
  opacity: 1;
  border-bottom: 1px solid #1d3a6d;
  display: flex;
}

.beneficiaryMultiApprovalTxnDetails_top_2 {
  width: 1024px;
  height: 300px;
  display: flex;
  justify-content: space-between;
}

.beneficiaryMultiApprovalTxnDetails_top_2_p2 {
  height: 32px;
  text-align: left;
  font: normal normal normal 12px / 16px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
}

.beneficiaryMultiApprovalTxnDetails_top_2_p1 {
  width: 257px;
  height: 19px;
  /* UI Properties */
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
  white-space: nowrap;
  margin-top: 26px;
  margin-bottom: 4px;
}

.beneficiaryMultiApprovalTxnDetails_top_1_div1 {
  width: 102px !important;
  height: 90px !important;
  background: #506994 0% 0% no-repeat padding-box !important;

  border-radius: 0 !important;
  text-transform: none !important;
}

.beneficiaryMultiApprovalTxnDetails_top_1_div1:hover {
  background: #63799f !important;
}

.beneficiaryMultiApprovalTxnDetails_top_1_com {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.beneficiaryMultiApprovalTxnDetails_top_1_com > img {
  width: 40px;
  height: 40px;
  opacity: 1;
  margin-bottom: 4px;
}

.beneficiaryMultiApprovalTxnDetails_top_1_com > p {
  width: 40px;
  height: 14px;
  text-align: center;
  font: normal normal normal 10px/14px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.beneficiaryMultiApprovalTxnDetails_top_1_div2 {
  height: 90px;
  width: 717px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 20px;
}

.beneficiaryMultiApprovalTxnDetails_top_1_div3 {
  width: 102px !important;
  height: 90px !important;
  background: #f05a6a 0% 0% no-repeat padding-box !important;
  text-transform: none !important;
  border-radius: 0 !important;
}

.beneficiaryMultiApprovalTxnDetails_top_1_div3_NotHover:hover {
  background: #cc4a51 0% 0% no-repeat padding-box !important;
}

.beneficiaryMultiApprovalTxnDetails_top_1_div4 {
  width: 102px !important;
  height: 90px !important;
  background: #69b76f 0% 0% no-repeat padding-box !important;
  text-transform: none !important;
  border-radius: 0 !important;
}

.beneficiaryMultiApprovalTxnDetails_top_1_div4_NotHover:hover {
  background: #59a25f 0% 0% no-repeat padding-box !important;
}

.beneficiaryMultiApprovalTxnDetails_top_1_div2_p1 {
  width: 108px;
  height: 22px;
  text-align: left;
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #4de659;
  opacity: 1;
  margin-bottom: 4px;
  white-space: nowrap;
}
.beneficiaryMultiApprovalTxnDetails_top_1_div2_p2 {
  width: 114px;
  height: 17px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
}

.beneficiaryMultiApprovalTxnDetails_bottom > h4 {
  width: 90%;
  height: 22px;
  text-align: left;
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin: 20px auto;
  white-space: nowrap;
}

.beneficiaryMultiApprovalTxnDetails_bottom_data_main {
  width: 90%;
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 auto;
}

.beneficiaryMultiApprovalTxnDetails_bottom_data {
  width: 254px;
  height: 195px;
  background: #506994 0% 0% no-repeat padding-box;
  box-shadow: 5px 5px 11px #00000033;
  border-radius: 10px;
  opacity: 1;
  margin-right: 20px;
  margin-bottom: 20px;
  position: relative;
}

.beneficiaryMultiApprovalTxnDetails_bottom_data_top {
  /* width: 238px; */
  height: 89px;
  /* UI Properties */
  background: #3a5686 0% 0% no-repeat padding-box;
  box-shadow: -5px -5px 11px #71717133;
  border-radius: 10px 10px 0 0;
  opacity: 1;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 10px;
}

.beneficiaryMultiApprovalTxnDetails_bottom_data_bottom {
  padding-top: 10px;
  padding-left: 10px;
}

.beneficiaryMultiApprovalTxnDetails_bottom_data_top > div > h4 {
  width: 99px;
  /* UI Properties */
  text-align: left;
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #4ddd37;
  opacity: 1;
}

.beneficiaryMultiApprovalTxnDetails_bottom_data_top > h5 {
  width: 220px;
  text-align: left;
  font: normal normal normal 14px/26px Open Sans;
  letter-spacing: 0px;
  color: #fafafa;
  text-transform: lowercase;
  opacity: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.beneficiaryMultiApprovalTxnDetails_bottom_data_top > h5::first-line {
  text-transform: capitalize;
}

.beneficiaryMultiApprovalTxnDetails_bottom_data_top_date {
  display: flex;
  justify-content: space-between;
}

.beneficiaryMultiApprovalTxnDetails_bottom_data_top_date > p {
  width: 76px;
  height: 14px;
  text-align: right;
  font: normal normal 600 10px/14px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
  white-space: nowrap;
}

.multiLowerBottomBene {
  padding: 10px 0px 10px 0px;
}

.beneficiaryMultiApprovalTxnDetails_bottom_data_top_details {
  width: 94px !important;
  height: 24px !important;
  background: #7286a7 0% 0% no-repeat padding-box !important;
  border-radius: 5px 0px 0px 0px !important;
  opacity: 1;
  display: flex !important;
  justify-content: space-around !important;
  align-items: center !important;
  cursor: pointer !important;
}

.beneficiaryMultiApprovalTxnDetails_bottom_data_top_details > h6 {
  width: 58px !important;
  height: 14px !important;
  text-align: left !important;
  font: normal normal normal 10px/14px Open Sans !important;
  letter-spacing: 0px !important;
  color: #f9f9f9 !important;
  text-transform: none !important;
}

.beneficiaryMultiApprovalTxnDetails_bottom_data_bottom_1_img_div {
  width: 67px;
  height: 24px;
  background: #f9f9f9 0% 0% no-repeat padding-box;
  border-radius: 12px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.beneficiaryMultiApprovalTxnDetails_bottom_data_bottom_1_img_div > img {
  width: 50px;
  height: 13px;
  opacity: 1;
}

.beneficiaryMultiApprovalTxnDetails_bottom_data_top_details > img {
  width: 10px;
  height: 10px;
  opacity: 1;
}

.beneficiaryMultiApprovalTxnDetails_bottom_data_bottom_1 {
  display: flex;
  padding-right: 10px;
  align-items: center;
  justify-content: space-between;
}

.beneficiaryMultiApprovalTxnDetails_bottom_data_bottom_1_left > h4 {
  width: 79px;
  height: 17px;
  text-align: left;
  font: normal normal 600 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
  white-space: nowrap;
  margin-bottom: 4px;
}

.beneficiaryMultiApprovalTxnDetails_bottom_data_bottom_1_left > h5 {
  width: 72px;
  height: 14px;
  text-align: left;
  font: normal normal normal 10px/14px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
  white-space: nowrap;
}

.beneficiaryMultiApprovalTxnDetails_bottom_data_bottom_2_left {
  margin-top: 10px;
}

.Owner_Balance {
  /* width: 165px; */
  height: 26px;

  display: flex;
  overflow: hidden;
}

.Owner_Balance p {
}

.name1 {
  /* border: 2px solid red; */
}

.name2 {
  /* border: 2px solid rgb(0, 255, 51); */
  width: 20px;

  position: relative;
  z-index: 9;
}

.name2 p {
  /* background: #000; */
  height: 18px;
}

.beneficiaryMultiApprovalTxnDetails_bottom_data_bottom_2_left
  > .Owner_Balance
  > .name1
  > h4 {
  width: auto;
  height: 17px;
  /* UI Properties */
  text-align: left;
  font: normal normal 600 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
  margin-bottom: 4px;
}

.beneficiaryMultiApprovalTxnDetails_bottom_data_bottom_2_left > h5 {
  /* width: 102px; */
  height: 14px;
  /* UI Properties */
  text-align: left;
  font: normal normal normal 10px/14px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
}

.beneficiaryMultiApprovalTxnDetails_bottom_data_bottom_2_button {
  width: 90px !important;
  height: 34px !important;
  /* UI Properties */
  background: #7286a7 0% 0% no-repeat padding-box !important;
  border-radius: 20px 0px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  position: absolute !important;
  bottom: 0 !important;
  right: 0 !important;
}

.beneficiaryMultiApprovalTxnDetails_bottom_data_bottom_2_button > img {
  width: 12px;
  height: 12px;
  /* UI Properties */
  opacity: 1;
  margin-right: 4px;
}

.beneficiaryMultiApprovalTxnDetails_bottom_data_bottom_2_button > h6 {
  width: 37px !important;
  text-align: left !important;
  font: normal normal 600 10px/26px Open Sans !important;
  letter-spacing: 0px !important;
  color: #ffffff !important;
  text-transform: none !important;
}

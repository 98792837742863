.card-section {
  position: relative;
  /* z-index: -1; */
  background: transparent
    linear-gradient(270deg, var(--Blue_1) 0%, #224074 50%, var(--Blue_1) 100%, #234176 100%)
    0% 0% no-repeat padding-box;
}

.card-div {
  padding: 61px 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
}

.amcContainer {
  display: flex;
  flex-direction: column;
}

.card {
  /* background: transparent; */
  /* border: 1px solid #31e5e5; */
  /* border-radius: 15px; */
  text-align: center;
  /* width: 240px;
  height: 230px; */
  color: var(--Primary_white);
  position: relative;
}

.card-semicircle {
  width: 80px;
  height: 40px;
  border-bottom-left-radius: 110px;
  border-bottom-right-radius: 110px;
  border: 1px solid var(--Pending);
  border-top: 0;
  margin: -1px auto 0;
  position: relative;
  background-color: #122f61;
}

.card-content {
  display: flex;
  flex-direction: column;
}

.card-content h2 {
  margin-top: 15px;
  font-size: 14px;
  color: var(--Primary_white);
  font-weight: 600;
}

.card-content p {
  font-size: 12px;
  line-height: 1.5;
  padding: 10px;
}

.header-content h2 {
  margin-top: 15px;
  font-size: 28px;
  color: var(--Primary_white);
  font-weight: 600;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: minmax(112px, auto);
  gap: 20px;
  padding: 20px 0;
}

.AMC_box {
  color: var(--Primary_white);
  padding: 20px;
}

.AMC_box .title-sec {
  font-size: 14px;
  font-weight: 600;
}

.AMC_box p {
  font-size: 12px;
}

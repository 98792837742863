.refreshloader {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.refreshtext {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 27px;
  margin: 10px 0;
  padding-left: 25px;
}

.refreshtext > p {
  text-align: left;
  font: normal normal 600 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
}

.progressdiv {
  display: inline-block;
  position: relative;
  top: -3px;
  width: 38px;
  height: 20px;
  background-color: #fff;
  border-radius: 12px;
  padding-right: 13px;
}

.progresstext {
  display: inline-block;
  position: absolute;
  top: 5px;
  width: 17px;
  left: 7px;
  font: normal normal 600 10px/14px Open Sans;
  color: #343536;
  opacity: 1;
}

.widthtrans {
  transition: all 0.3s ease-in-out;
}

.expentra-Loader-class {
  position: relative;
  background-color: #ff5860 !important;
  width: 100px;
  height: 38px;
  margin-right: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 3px 6px #00000029;
  /* border: 2px solid #cccccc; */
  border-radius: 20px;
  opacity: 1;
}

.expentra-Loader-class img {
  width: 40px;
  height: 40px;
}

.vendorTxnApprovalReq {
  max-width: 1440px;
  min-width: 780px;
  margin-left:auto;
  margin-right:auto;
  margin-top: -90px;
}

.vendorTxnCarousel {
  max-width: 1440px;
  min-width: 780px;
  margin-left:auto;
  margin-right:auto;
  margin-top: 105px;
}

.vendorTxnCarouselHeader {
    height: 165px;
}
#instantpayTxnDetailsBanner {
  background: white;
}

#instantpayTxnDetailsBannerLeft {
  border-radius: 30px;
  width: 60%;
  padding: 20px;
  gap: 80px;
}

#instantpayTxnDetailsBannerRight {
  text-align: right;
  border-radius: 30px;
  width: 50%;
  padding: 20px;
  background-color: inherit;
}

#instantpayTxnDetailsBannerRight > div {
  text-align: right;
}

.txnDetailsBannerRightMiddle > span {
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #707070;
}

.txnDetailsBannerRightMiddle > span > text {
  text-align: right;
  font: normal normal normal 22px/30px Open Sans;
  letter-spacing: 0px;
  color: green;
}

.txnDetailsBannerRightMiddle > hr {
  margin: 0;
  padding: 0%;
  width: 100%;
}

.txnDetailsBannerMobile > div > div {
  padding: 10px;
}

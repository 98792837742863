.associatebutton-crt-flow {
  margin-left: 30px;
  margin-bottom: 10px;
}

.associatebuttonPan {
  width: auto !important;
  height: 24px !important;
  border-radius: 18px !important;
  text-align: center !important;
  font: normal normal 600 10px/14px Open Sans !important;
  letter-spacing: 0px !important;
  text-transform: none !important;
}

.associatebuttonBank {
  width: auto !important;
  height: 24px !important;
  margin-left: 10px !important;
  border-radius: 18px !important;
  text-align: center !important;
  font: normal normal 600 10px/14px Open Sans !important;
  letter-spacing: 0px !important;
  text-transform: none !important;
  margin-left: 5px !important;
}

.custom-scroll-container::-webkit-scrollbar {
  width: 5px;
}

.custom-scroll-container::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 8px;
}

.custom-scroll-container::-webkit-scrollbar-thumb {
  background-color: #dadada;
  border-radius: 8px;
}

.custom-scroll-container::-webkit-scrollbar-thumb:hover {
  background-color: #dadada;
  border-radius: 8px;
}

.label-container {
  color: #dadada;
}

.mobile-crt-confirm-page-pop {
  width: 50vw;
  max-width: 600px;
}

.mobile-view-title-bar {
  display: none;
}

.associatebutton-crt-flow-mobile {
  display: none;
}

.create-bene-account-btn-mobile {
  display: none;
}

@media screen and (max-width: 800px) {
.mobile-crt-confirm-page-pop {
    width: 100vw;
    max-width: auto;
    height: 100vh;
    border-radius: 0px !important;
    padding-bottom: 0px !important;
  }
.mobile-view-title-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #3A5686;
  color: #F9F9F9;
  height: 56px;
}
.mobile-view-title-bar-icon {
  flex: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mobile-view-title-bar-label {
  flex: 90%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.79px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.mobile-view-title-message {
  font-size: 14px;
  font-weight: 600;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  line-height: 19.07px;
  color: #FF7F33;
}
.associatebutton-crt-flow {
  display: none;
}
.associatebutton-crt-flow-mobile {
  display: flex;
  flex-direction: row;
}
.associatebutton-crt-flow-PAN {
  flex: 50%;
    text-transform: none !important;
      border-radius: 0px !important;
      font-size: 14px;
      font-weight: 400;
      line-height: 19.07px;
      font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.associatebutton-crt-flow-BANK {
  flex: 50%;
    text-transform: none !important;
      border-radius: 0px !important;
      font-size: 14px;
        font-weight: 400;
        line-height: 19.07px;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.associate-pan-bank-mobile-container {
  padding: 0px !important;
  height: 100%;
  margin-bottom: 50px;
}
.create-bene-account-btn {
  display: none !important;
}
.adjust-padding-bottom {
  padding-bottom: 0px !important;
}
.create-bene-account-btn-mobile{
  display: block;
  width: 100vw;
  height: 49px;
  background-color: #69B76F;
  color: #FFFFFF !important;
  max-width: 600px;
  position: fixed;
  bottom: 0;
}
.create-bene-account-btn-mobile-btn {
  text-transform: none !important;
  width: 100vw;
  height: 49px;
}
}


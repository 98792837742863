.smallInputIcon {
  width: 34px !important;
  height: 34px !important;
  background: #314d7e 0% 0% no-repeat padding-box !important;
  border-radius: 8px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.smallInputIcon > img {
  width: 14px;
  height: 14px;
}

.smallInputIcon:hover{
  background: #506994 0% 0% no-repeat padding-box !important;
}

.input_div {
  width: 300px;
  height: 34px;
  background: #314d7e 0% 0% no-repeat padding-box;
  border: 1px solid #f05a6a;
  border-radius: 5px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0px 5px;
}

.input_div > input {
  width: 80%;
  outline: none;
  background-color: transparent;
  text-align: left;
  font: normal normal normal 14px/18px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
}

.input_div > input::placeholder {
  width: 44px;
  height: 19px;
  text-align: left;
  font: normal normal normal 14px/18px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
}

.search_owner_acc_bar {
  width: 14px;
  height: 14px;
  opacity: 1;
  cursor: pointer;
}

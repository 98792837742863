.recentTransactionsCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 15px;
  opacity: "1";
  box-shadow: 5px 5px 11px #00000033;
  background: #3a5686;
  width: 450px;
  height: 60px;
  border-radius: 40px;
  flex-shrink: 1;
}

.recentTransactionsCard:hover {
  background-color: #5676ad;
}

.logo-div {
  background: white;
  height: 42px;
  width: 120px;
  border-radius: 30px 5px 30px 0px;
  align-self: flex-end;
}

.logo {
  margin-top: 8px;
}

.recentTxnAmountDiv {
  font: normal normal 600 1em/1.25em Open Sans;
  width: 9em;
  font-size: 20px;
  text-align: right;
}

.account-div {
  padding-left: 5%;
  width: 5.5em;
  color: #fafafa;
  font-family: Open Sans, Regular;
  font-size: 20px;
  text-align: left;
  text-transform: capitalize;
}

.amountWithLogo {
  display: flex;
  align-self: flex-end;
  gap: 5%;
}

@media screen and (max-width: 1300px) {
  .recentTransactionsCard {
    flex-grow: 1;
    flex-shrink: 1;
  }
}

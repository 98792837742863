.QuickAccessMenu-bottom-Menu {
  background: inherit 0% 0% no-repeat padding-box;
  box-shadow: 5px 5px 11px black;
  border-radius: 40px;
  opacity: 1;
  width: 60%;
  width: 90%;
}

.more-actions {
  text-align: left;
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  margin-left: 20px;
}

.hoverEffect:hover {
  background-color: #fafafa1a;
  border-radius: 30px;
}
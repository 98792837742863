.OwnerTransactionCard {
  display: flex;
  flex-wrap: wrap;
  width: 100% !important;
  margin: 0 auto;
  /* cursor: pointer; */
  /* padding-bottom: 20px; */
  padding-left: 2rem;
  position: absolute;
  top: 0;
  bottom: 0;
  height: 50%;

  /* padding-top: 0px; */
  /* justify-content: space-around; */
}

.multiSelectOwnerTransactionCard {
  width: 232px;
  height: 99px !important;
  border-radius: 20px !important;
  box-shadow: -5px -5px 11px #71717133 !important;
  grid-column: span 1;
  background-color: #3a5686 !important;
  box-sizing: border-box;
  padding: 10px 15px !important;
  margin: 4px;
  padding-right: 1px !important;
  cursor: pointer;
}

@media screen and (min-width: 2160px) {
  .multiSelectOwnerTransactionCard {
    width: 227px;
  }
}

.ownerTransaction_acc_title {
  color: #b0bbce;
  font-size: 10px;
  margin-bottom: 20px;
  font-weight: 600;
}

.ownerTransaction_bank_acc_div {
  color: #fafafa;
  font-size: 14px;
  /* font-weight: 600; */
  margin-bottom: 8px;
  width: 14rem;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: lowercase;
  height: 20.2px;
}

.ownerTransaction_name_div {
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #b196fa;
  /* margin-bottom: 10px; */
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ownerTransaction_bank_acc_div:first-line {
  text-transform: capitalize !important;
}

.ownerTransaction_user {
  white-space: nowrap;
  width: 14rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ownerTransaction_box_status {
  width: 5px;
  height: 15px;
  background-color: #4ddd37;
  border-radius: 0px 5px 0px 0px;
}

.multiSelectOwnerTransactionCard:hover .ownerTransaction_assigned {
  display: block;
}

.ownerTransaction_assigned {
  display: none;
  width: 60px;
  background-color: transparent;
  /* opacity: 0.6; */
  height: 15px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  text-transform: none;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

.ownerTransaction_label_div {
  display: flex;
  height: 15px;
  right: 0px;
  position: absolute;
}

.css-wdslgk {
  padding-right: 0 !important;
}

@keyframes slideOutRightToLeft {
  0% {
    visibility: visible;
    transform: translateX(0);
  }

  100% {
    visibility: hidden;
    transform: translateX(-10%);
  }
}

.multiSelectOwnerTransactionCard:hover .ownerTransaction_name_div {
  visibility: hidden;
  animation: slideOutRightToLeft 0.2s ease-in-out;
}

/* .viewall-amount-div {
  visibility: visible;
} */

.ownerTransaction_card_container {
  margin-right: 0px !important;
  width: 98px !important;
  height: 40px !important;
}

.ownerTransaction_assigned_pending_box,
.ownerTransaction_assigned_approved_box,
.ownerTransaction_assigned_blocked_box,
.ownerTransaction_assigned_abandoned_box,
.ownerTransaction_assigned_denied_box ::first-letter {
  text-transform: capitalize;
}

.ownerTransaction_assigned_pending_box {
  background-color: rgba(49, 229, 229, 0.2);
  color: rgba(49, 229, 229, 1);
  font-size: 9px;
  border-radius: 0px 3px 0px 0px;
}

.ownerTransaction_assigned_approved_box {
  background-color: rgba(177, 150, 250, 0.2);
  color: rgba(177, 150, 250, 1);
  font-size: 9px;
  width: 96px !important;
  border-radius: 0px 3px 0px 0px;
}

.ownerTransaction_assigned_settled_box {
  background-color: rgba(77, 221, 55, 0.2);
  color: rgba(77, 221, 55, 1);
  font-size: 9px;
  border-radius: 0px 3px 0px 0px;
}

.ownerTransaction_assigned_hold_box,
.ownerTransaction_assigned_queued_box {
  color: rgba(251, 192, 45, 1);
  background: rgba(251, 192, 45, 0.2);
  font-size: 9px;
  border-radius: 0px 3px 0px 0px;
}

.ownerTransaction_assigned_denied_box,
.ownerTransaction_assigned_blocked_box,
.ownerTransaction_assigned_rejected_box {
  background-color: rgba(255, 88, 96, 0.2);
  color: rgba(255, 88, 96, 1);
  text-transform: capitalize;
  border-radius: 0px 3px 0px 0px;
  font-size: 9px;
}

.ownerTransaction_assigned_abandoned_box {
  background-color: rgba(207, 207, 207, 0.2);
  color: rgba(180, 180, 180, 1);
  border-radius: 0px 3px 0px 0px;
  font-size: 9px;
}
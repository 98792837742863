.bene_txn_viewall_calendar_icon {
  height: 14px;
  align-items: center;
  justify-content: center;
  width: 14px !important;
}
.date-picker-container {
  z-index: 10 !important;
  width: 100%;
  background-color: #667ca3 !important;
}
.react-datepicker__day--disabled {
  cursor: default !important;
  opacity: 0.4 !important;
}

/* .react-datepicker__year-dropdown::before {
  content: "\25BC" !important;
  position: absolute;
  top: 5%;
  left: 45%;
  margin: auto;
} */

.react-datepicker__year-dropdown::after {
  /* content: "\25BC" !important;
  display: block !important;
  position: relative !important;
  bottom: 15% !important;
  padding: 0px !important;
  margin: 0px !important; */
  display: none !important;
}
.react-datepicker__navigation--years-previous {
  display: none !important;
}
.react-datepicker__year-select {
  background-color: #667ca3 !important;
  margin-bottom: 7px !important;
  margin-left: 4px !important;
  /* max-height: 20px !important; */
}

.react-datepicker__year-dropdown-container--select {
  color: #f9f9f9 !important;
  /* max-height: 20px !important; */
}

.react-datepicker__year-read-view--selected-year {
  color: #f9f9f9;
  font-size: 13px;
  /* max-height: 20px !important; */
}
/* .react-datepicker__year-dropdown {
  max-height: 50px !important;
} */
.react-datepicker__header {
  background-color: #667ca3 !important;
  padding: 0px !important;
  border-top-left-radius: 0px !important;
  border-top: 1px solid #b9b9b9 !important;
  border-bottom: none !important;
  border-top-right-radius: 0px !important;
}
.react-datepicker__day--selected-end {
  background-color: #f05a6a !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: transparent !important;
}
.calendar_buttons {
  display: flex;
  justify-content: center;
  margin-top: 3px !important;
  margin-bottom: 8px;
}
.calendar_buttons_single {
  display: flex;
  justify-content: center;
  margin-top: 15px !important;
  margin-bottom: 15px;
}
.calendar_cancel_button {
  border: 1px solid #dadada;
  border-radius: 20px;
  width: 125px;
  height: 24px;
  color: #f9f9f9;
  text-align: center;
  font-size: 12px;
  /* background-color: #667ca3 !important;   */
}
.calendar_confirm_button {
    width: 125px;
      height: 25px;
      /* background: #f05a6a; */
      /* box-shadow: 0px 3px 6px #00000029; */
      border-radius: 20px;
      opacity: 1;
      color: #f9f9f9;
      text-align: center;
      font-size: 12px;
      margin-left: 10px;
}

.react-datepicker {
  background-color: #667ca3 !important;
  opacity: 1;
  display: flex !important;
  justify-content: center !important;
  border: none !important;
}
.react-datepicker__current-month {
  color: #f9f9f9 !important;
  font-size: 13px !important;
  padding: 10px 0px 5px 0px !important;
  font-weight: normal !important;
}
.react-datepicker__month {
  background-color: #667ca3 !important;
  margin: 0px !important;
  color: #f9f9f9 !important;
}
/* .react-datepicker__navigation {
  margin: 15px 0px 15px 0px !important;
  width: 135px !important;
  height: 15px !important;
} */
.react-datepicker__day {
  color: #f9f9f9 !important;
  font-size: 12px !important;
  width: 2.3rem !important;
  margin: 0px !important;
  margin-right: 2px !important;
}

/* .react-datepicker__day--keyboard-selected {
  background-color: #f05a6a !important;
} */
.react-datepicker__day--selected,
.react-datepicker__day--selected-start,
.react-datepicker__day--selected-end,
.react-datepicker__day--range-end {
  background-color: #f05a6a !important;
}
.react-datepicker__day--in-range {
  background-color: #BB5353 !important;
  border-radius: 5px !important;
}
.react-datepicker__day-names {
  border-top: 1px solid #b9b9b9 !important;
  padding-top: 10px !important;
  margin-bottom: 0px !important;
  line-height: 1.3rem !important;
}
.react-datepicker__day-name {
  color: #f9f9f9 !important;
  opacity: 1 !important;
  /* padding-bottom: 10px !important; */
  width: 2.4rem !important;
  margin: 0px !important;
}
.react-datepicker__day:hover {
  background-color: #f05a6a !important;
}
.start-date {
  background-color: #f05a6a !important;
}
.end-date {
  background-color: #f05a6a !important;
}
.calendar_cancel_button:hover {
  /* background-color: #506994; */
}
.react-datepicker__header__dropdown {
    background-color: #3A5686;
    margin: 0 auto 5px auto;
    width: 72px;
    padding-top: 3px;
    /* height: 23px;
    border-radius: 5px; */
}
.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  display: flex;
  flex-direction: column;
  gap:2px;
  background-color: #3A5686;
  color: #F9F9F9;
  position: absolute;
  width: 75px;
  margin-left: 30px;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 0.3rem;
  border: 1px solid #FF5860;
  scrollbar-width: none;
  padding: 5px;
}
.react-datepicker__week {
  white-space: nowrap;
  margin-bottom: 2px;
}
@media only screen and (max-width:401px) {
  .react-datepicker__year-dropdown,
    .react-datepicker__month-dropdown,
    .react-datepicker__month-year-dropdown {      
      left: 20%;      
    }
}

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  background-color: #61789E;
  border-radius: 5px;
}

.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected_year {
  position: absolute;
  left: 75%;
}

.year-dropdown-visible .react-datepicker__year-dropdown-container--scroll {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  display: inline-block !important;
}

.react-datepicker__header__dropdown{background-color: #3A5686;margin: 0 auto 5px auto;width: 72px;border-radius: 5px;}

.react-datepicker__year-read-view--selected-year {
  margin-left: -12px;
  color: #f9f9f9;
  font-size: 13px;
  /* max-height: 20px !important; */
}

.react-datepicker__year-option--selected_year{background-color: #244277;border-radius: 5px;}

.react-datepicker__year-option--selected_year:hover {
  background-color: #1D355F;
  border-radius: 5px;
}

/* .calendar_cancel_button {
  opacity: 0.5;
  cursor: default;
}

.calendar_cancel_button.active {
  opacity: 1;
  cursor: pointer;
  text-align: center;
  align-items: center;
  justify-content: center;
}
  


.calendar_cancel_button.active:hover {
  background-color: #506994;
} */
.react-datepicker__year-dropdown .react-datepicker__year-dropdown--scrollable {
  margin-top: 50px !important;
}
.react-datepicker__day--outside-month {
  opacity: 0 !important;
}
.react-datepicker__navigation--next {
  top: -2px;
  right: 70px !important;
}
.react-datepicker__navigation--previous {
  top: -2px;
  left: 70px !important;
}
.react-datepicker__navigation-icon {
  font-size: 12px !important;
}
.year-dropdown-visible .react-datepicker__year-dropdown-container--scroll {
  margin-bottom: 5px !important;
  display: inline-block !important;
}

.hide-year-dropdown .react-datepicker__year-dropdown-container--scroll {
  display: none !important;
}

@media screen and (max-width: 400px) {
  .react-datepicker__day-name {
    width: 1.8rem !important;
  }
  .react-datepicker__day {
    width: 1.8rem !important;
  }
  .react-datepicker__navigation--previous {
    left: 35px !important;
  }
  .react-datepicker__navigation--next {
    right: 35px !important;
  }
}

.container_page_search {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.page_search_mix {
  display: flex;
}

.bank_amt_div {
  position: relative;
}

.bank_amt_div_refresh_show {
  position: absolute;
  color: #ffffff;
  font-size: 10px;
  padding: 2px 8px;
  border-radius: 4px;
  top: 43px;
  left: 300px;
  white-space: nowrap;
  font-weight: 400;
  background-color: black;
}

.allrefreshOwner {
  width: 39px;
  height: 39px;
  background: #314d7e 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: pointer;
}

.allrefreshOwner > img {
  width: 16px;
  height: 16px;
}

.allrefreshOwner:hover {
  background: #4d6ca2 0% 0% no-repeat padding-box;
}

/* .viewAllvendor-txn {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5em;
  padding: 1% 5%;
  overflow: hidden;
  overflow-y: scroll;
  height: 90%;
} */

.viewAllvendor-txn::-webkit-scrollbar {
  width: 10px;
}

.viewAllvendor-txn::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

.viewAllvendor-txn::-webkit-scrollbar-thumb {
  background-color: #FAFAFA;
  outline: 1px solid #314D7E;
  border-radius: 25px;
  height: 75px;
}

.settleTxnStatusCard {
  align-self: right;
  background-color: white;
  width: 80%;
  height: fit-content;
  padding: 2% 0% 0% 0%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 40px;
}

.settleTxnStatusCard > p {
  font: normal normal 600 24px/33px Open Sans;
  letter-spacing: 0px;
  color: #000000;
}

.settleAmountText {
  font: normal normal 600 32px/43px Open Sans;
  letter-spacing: 0px;
  color: #27b882;
  margin-top: 8%;
}

.options {
  margin-top: 16%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  background: #7fb085 0% 0% no-repeat padding-box;
  box-shadow: -5px -5px 11px #71717133;
  border-radius: 40px;
  height: 20%;
}

.simpleLine {
  margin: 7px 0px;
  height: 1px;
  background: #000000;
}

.options > div {
  cursor: pointer;
}

.logoWithText > img {
  width: 60px;
  height: 60px;
}

.detailsText {
  text-align: left;
  font-style: Open Sans;
  letter-spacing: 0px;
  color: #000000;
  padding-top: 2%;
  width: 85%;
  margin: auto;
}

.paraNImg {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.paraNImg div {
  margin-top: -40px;
}

@media only screen and (max-width: 480px) {
  .options {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: fit-content;
    width: 90%;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 40px 40px 0px 0px;
  }

  .logoWithText {
    position: a;
    display: flex;
    flex-direction: column;
    color: white;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    gap: 0;
  }

  .settleTxnStatusCard {
    flex-grow: 1;
    box-shadow: none;
    width: 100%;
  }
}

.header {
  display: flex;
  width: 425px;
  height: 30px;
  background: #506994 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  opacity: 1;
  margin-bottom: 15px;
}

.onhover {
  display: none;
  height: auto;
  position: absolute;
  left: 0;
  width: 422px;
  background-color: "#314D7E";
  /* border: 1px solid #ff5860;
  border-top: none; */
  border-radius: 0px 0px 10px 10px;
}

.list-section {
  margin: 5px 0px;
  display: flex;
  width: 425px;
  height: 49px;
  background: #506994 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  opacity: 1;
}

.list-section:hover {
  transition: all 0.1s ease !important;
  position: absolute;
  height: 130px !important;
  border: 1px solid #ff5860;
  z-index: 999;
}

.list-section:hover .onhover {
  transition: all 0.1s ease-in;
  display: block;
}
.list-section:hover .bank-div-img {
  border-radius: 10px 0px 0px 0px;
}

.list-section:hover .balance-data-div {
  border-radius: 0px 10px 0px 0px;
}

.arrow_animate {
  position: relative;
}

.list-section:hover .arrow_animate {
  transition: all 0.2s ease-in;
  animation: moves 0.5s forwards ease-in;
  position: absolute;
}

@keyframes moves {
  0% {
    top: 0px;
    left: 0;
  }
  100% {
    top: 55px;
    left: -220px;
    bottom: 0px;
  }
}

.list-section:hover,
.list-section:hover .balance-data-div,
.list-section:hover .balance-data-div .refresh-balance,
.list-section:hover .balance-data-div .info-div {
  cursor: pointer;
}

.bank-div {
  padding: 7px 20px;
  width: 70px;
  height: 49px;
}

.bank-div-img {
  height: 49px;
  border-radius: 10px 0px 0px 10px;
  padding: 5px;
  background-color: #fff;
  width: 70px;
}

.bank-div-img > img {
  height: 40px;
  width: 60px;
}

.bank-div > p {
  width: 30px;
  height: 17px;
  text-align: center;
  font: normal normal 600 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
}

.accounts-div {
  height: 49px;
  padding: 8px 0px;
  width: 179px;
  border-right: 2px solid #1d3a6d;
  border-left: 2px solid #1d3a6d;
}

.marquee {
  /* width: 200px;
  max-width: 200px; */
  color: white;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
}
.marquee p {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 7s linear infinite;
}
@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

.accounts-div > p {
  width: auto;
  height: 17px;
  text-align: left;
  font: normal normal 600 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
}
.accounts_header-div {
  padding: 7px 15px;
  width: 203px;
  border-right: 2px solid #1d3a6d;
  border-left: 2px solid #1d3a6d;
}

.accounts_header-div > p {
  width: 100px;
  height: 17px;
  text-align: left;
  font: normal normal 600 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
}

.balance-div {
  padding: 7px 20px;
  width: 190px;
}

.balance-div > p {
  width: 136px;
  height: 17px;
  text-align: left;
  font: normal normal 600 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
}

.balance-data-div {
  border-radius: 0px 10px 10px 0px;
  background-color: #314d7e;
  display: flex;
  width: 176px;
  height: 49px;
  position: relative;
}

.balance-data-div > p {
  position: relative;
  padding: 5px 8px;
  width: 150px;
  height: 17px;
  text-align: left;
  font: normal normal 600 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
}

.refresh-balance {
  width: 22px;
  height: 18px;
}

.refresh-balance > img {
  width: 22px;
  height: 18px;
  object-fit: cover;
  border-radius: 5px;
}

.info-div {
  width: 22px;
  height: 18px;
}

.info-div > img {
  width: 22px;
  height: 18px;
  object-fit: cover;
  border-radius: 5px;
}

.balance_last_div {
  display: flex;
  flex-direction: column;
  height: 49px;
  gap: 4px;
  padding: 5px 0px;
  width: 22px;
}

@media screen and (max-width: 960px) and (min-width: 700px) {
  .ownerListViewDiv {
    width: 330px !important;
  }

  .list-section {
    width: 330px !important;
  }

  .bank-div-img {
    width: 60px !important;
  }

  .accounts-div {
    width: 140px !important;
  }

  .balance-data-div {
    width: 140px !important;
  }
}

@media screen and (max-width: 500px) {
  .list-section {
    width: 320px !important;
  }
  .accounts-div {
    width: 140px !important;
  }
  .balance-data-div {
    width: 120px !important;
  }
}

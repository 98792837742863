.pop_overbtn {
  width: 68px;
  height: 27px;
  text-align: center !important;
  letter-spacing: 0px !important;
  color: rgba(255, 255, 255, 1) !important;
  background-color: #446aff !important;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
  border-radius: 2rem !important;
  opacity: 1 !important;
  margin-right: 1rem !important;
  border: 1px solid #446aff !important; /* Add border */
}

.view-all-pop-over {
  background-color: white;
  padding: 1rem;
}

.button-row {
  display: flex;
  flex-wrap: wrap;
}

.button-row > button {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

/* Specific styles for each button */
/* .denied {
  background-color: red;
  color: white;
}

.pending {
  background-color: yellow;
}

.blocked {
  background-color: orange;
}

.archived {
  background-color: purple;
  color: white;
}

.approved {
  background-color: green;
  color: white;
} */

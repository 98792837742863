.border-mid-div {
  border-right: 1px solid #1d3a6d;
}
.start-date {
  background-color: #f05a6a !important;
}
.end-date {
  background-color: #f05a6a !important;
}
.statement_div-content {
  display: flex;
  justify-content: space-between;
  max-width: 1024px;
  height: 73px;
  width: 100%;
  /* margin-bottom: 10px; */
  background-color: #314c7f;
  z-index: 1;
  position: relative;

  align-items: center;
}

.statement_div_container {
  background-color: #314c7f;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  margin-top: 18px;
  /* z-index: 998; */
}

.statement_div-content {
  display: flex;
  flex-direction: row;
  background-color: #314c7f;

  position: relative;
  top: 0;
  left: 0;
  width: 100%;
}

.statement-gradient {
  justify-content: end;
  height: 73px;
  /* position: absolute; */

  width: 281px;
  background-color: #506994;
}

.statement-mid-gradient {
  display: flex;
  height: 60px;
  border-radius: 10px;
  width: 432px;
  background-color: #506994;
}

.statement-containers {
  display: flex;
  margin-left: 28px;
  margin-bottom: 10px;
}

.account-statement-div {
  color: white;
}

.acc-statement {
  font: normal normal normal 14px/19px Open Sans;
  color: #f9f9f9;
  margin-right: 28px;
  margin-top: 8px;
}

.date-statement {
  font: normal normal normal 10px/14px Open Sans;
  margin-top: 4px;
  /* margin-bottom: 10px; */
  letter-spacing: 0px;
  color: #dadada;
}

.update-statement {
  font: normal normal normal 10px/14px Open Sans;
  margin-top: 4px;
  /* margin-bottom: 10px; */
  letter-spacing: 0px;
  color: #dadada;
}

.border-calender-div {
  border: 1px solid #1d3a6d;
  height: 40px;
  margin-top: 10px;
}

.statement_calendar_main_div {
  width: 300px;
  height: 60px;
  /* margin-right: 45px; */
  /* border-radius: 10px;  */
  /* margin-left: 15px;  */
}

.statement_calendar_date_heading_div {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 298px;
}

.statement_pagination_containers {
  margin-left: 15px;
  height: 36px !important;
  width: 107px !important;
  cursor: pointer;
  display: flex;
  background: #3a5686;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  opacity: 1;
}

.statement_calendar_date_heading_left {
  display: flex;
  text-align: left;
  font: normal normal normal 12px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
  padding-left: 20px !important;
}

.statement_noacc_found {
  width: 300px;
  height: 250px;
  opacity: 0.9;
}

.statement_calendar_filter_search-bar {
  background-color: #3a5686 !important;
  width: 240px !important;
  color: #ffffff !important;
}

.statement_calendar_date_heading_right {
  display: flex;
  font: normal normal normal 12px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
  padding-left: 22px !important;
}

.statement_calendar_date_select_div {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 298px;
}

.statement_calendar_date_select_leftmain {
  height: 27px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  padding-left: 20px;
}

.statement_calendar_date_select_right {
  height: 27px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  text-align: center;
  color: #dadada;
  padding-left: 20px;
}

.react-datepicker__month--march .date-picker-container,
.react-datepicker__month--june .date-picker-container {
  width: 298px;
  /* Keep the same width */
  height: 390px;
  /* Keep the same height */
}

/* Hide overflow for March and June */
.react-datepicker__month--march .react-datepicker__month-container,
.react-datepicker__month--june .react-datepicker__month-container {
  overflow: hidden;
}

.closing-balance-div {
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin-left: 10px;
}

.total_entry {
  font: normal normal 600 14px/25px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
}

.closing-balance {
  text-align: left;
  font: normal normal normal 12px/25px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
}

.closing-balance-amt {
  font: normal normal 600 14px/19px Open Sans;
  letter-spacing: 0px;
  padding-left: 7px;
  color: #4de658;
}

.table-div-container {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  justify-content: space-between;
  background-color: #506994;
  width: 100%;
  box-shadow: 5px 5px 11px #00000026;
  height: 51px;
  overflow-y: hidden;
  max-height: 51px;
}

.table-data-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 1024px;
  min-height: 51px;
  height: auto;
  overflow-x: auto;
}

.table-label-div-cl-amt {
  font: normal normal 400 12px/19px Open Sans;
  color: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 164px;
  min-height: 51px;
  height: auto;
  padding: 0px 10px;
}

.table-label-div-amt {
  font: normal normal 400 12px/19px Open Sans;
  color: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 149px;
  min-height: 51px;
  height: auto;
  padding: 0px 10px;
}

.table-label-div-transaction {
  font: normal normal 400 12px/19px Open Sans;
  color: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 167px;
  min-height: 51px;
  height: auto;
  padding: 0px 10px;
}

.table-label-div-value {
  font: normal normal 400 12px/19px Open Sans;
  color: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 103px;
  min-height: 51px;
  height: auto;
  padding: 0px 10px;
}

.table-label-div-ref {
  font: normal normal 400 12px/19px Open Sans;
  color: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 148px;
  min-height: 51px;
  height: auto;
  padding: 0px 10px;
  word-break: break-all;
  text-wrap: wrap;
}

.table-label-div-narr {
  font: normal normal 400 12px/19px Open Sans;
  color: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 280px;
  word-break: break-all;
  text-wrap: wrap;
  min-height: 51px;
  height: auto;
  padding: 0px 10px;
}

.table-label-div-type {
  font: normal normal 400 12px/19px Open Sans;
  color: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  min-height: 51px;
  height: auto;
  padding: 0px 10px;
}

.download_statement {
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
}

.statement_text {
  text-align: left;
  font: normal normal normal 10px/14px Open Sans;
  color: #c9c9c9;
  padding: 2px 0px 0px 0px;
}

.statement_header {
  font: normal normal 600 16px/22px Open Sans;
  color: #f9f9f9;
  text-align: left;
  padding-bottom: 2px;
}

.label-div-clamt {
  font: normal normal normal 12px/16px Open Sans;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #dadada;
  width: 165px;
  height: 50px;
  padding: 5px 10px;
  text-align: center;
}

.label-div-trans {
  font: normal normal normal 12px/16px Open Sans;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #dadada;
  width: 167px;
  height: 50px;
  padding: 5px 20px;
  text-align: center;
}

.label-div-val {
  font: normal normal normal 12px/16px Open Sans;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #dadada;
  width: 103px;
  height: 50px;
  padding: 5px 10px;
  text-align: center;
}

.label-div-narr {
  font: normal normal normal 12px/16px Open Sans;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #dadada;
  width: 280px;
  height: 50px;
  padding: 5px 10px;
  text-align: center;
}

.label-div-ref {
  font: normal normal normal 12px/16px Open Sans;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #dadada;
  width: 148px;
  height: 50px;
  padding: 5px 10px;
  text-align: center;
}

.label-div-type {
  font: normal normal normal 12px/16px Open Sans;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #dadada;
  width: 80px;
  height: 50px;
  padding: 5px 10px;
  text-align: center;
}

.label-div-amt {
  font: normal normal normal 12px/16px Open Sans;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #dadada;
  width: 169px;
  height: 50px;
  padding: 5px 10px;
  text-align: center;
}

.amount-inputs-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: #dadada;
  padding-top: 55px;
  padding-left: 8px;
}

.input-container label {
  display: inline-block;
  font-size: 10px;
  margin-bottom: 3px;
  text-align: left;
}

.amount-inputs-div p {
  font-size: 10px;
  margin-bottom: 10px;
  text-align: left;
  line-height: 1.1;
}

.amount-inputs-div input {
  background-color: #244277;
  width: 129px;
  height: 24px;
  top: 78px;
  left: 10px;
  gap: 0px;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 5px;
}

.input-container {
  display: inline-block;
}

.input-container input {
  padding-left: 20px;
}

/* .input-container input::before {
  content: '₹';
  color: #F9F9F9; 
  position: absolute;
} */

.inputs-div-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 8px 0px;
}

.inputs-div-btns button:nth-child(1) {
  width: 62px;
  height: 24px;
  top: 218px;
  left: 10px;
  padding: 5px 10px 5px 10px;
  gap: 10px;
  border-radius: 8px;
  border: 1px 0px 0px 0px;
  border: 1px solid var(--F9F9F9---Primary-white, #f9f9f9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 10px;
}

.inputs-div-btns button:nth-child(2) {
  width: 62px;
  height: 24px;
  top: 218px;
  left: 77px;
  padding: 5px 10px 5px 10px;
  gap: 10px;
  border-radius: 8px;
  background: var(--FF5860---Failed, #ff5860);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 10px;
}

.clear-button {
  cursor: pointer;
}

.clear-button:hover {
  background-color: #8093b3ee;
}

.clear-button.disabled {
  pointer-events: none;
  cursor: not-allowed;
  background-color: #506994;
  opacity: 0.6;
}

.clear-button.disabled:hover {
  background-color: inherit;
}

.apply-button {
  background-color: #ff5860;
}

.apply-button:hover {
  opacity: 0.9;
}

.apply-button.disabled {
  background-color: rgb(112, 112, 112) !important;
  pointer-events: none;
  cursor: not-allowed;
}

.apply-button.disabled:hover {
  background-color: inherit;
}

.border-sec {
  border: 1px solid #506994;
  opacity: 1;
}

.statement-type {
  display: block;
  margin-left: 1px;
  width: 85px;
  height: 36px;
}

.balance_type_statement {
  display: block;
  width: 84px;
  height: 50px;
  overflow-y: hidden;
}

.amtDiv_type {
  display: block;
  width: 149px;
  height: 50px;
}

.statement_select_scroll_com {
  padding: 6px 4px !important;
  height: 27px !important;
  border-radius: 5px !important;
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  text-align: left !important;
  font: normal normal normal 12px / 15px Open Sans !important;
  letter-spacing: 0px !important;
  margin: 8px 0px !important;
  color: #ffffff !important;
  text-transform: none !important;
}

.pdf-select-type {
  display: flex;
  justify-content: space-between;

  /* background-color: #F05A6A; */
  height: 39px;
  padding-right: 10px;
  align-items: center;
  flex-wrap: wrap;
  position: absolute;
  top: 0;
}

.inner-text {
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  padding: 10px;
}

.statement_text_value {
  height: 17px;
  font: normal normal normal 12px/17px Open Sans;
  color: #f9f9f9;
}

.statement_placeholder {
  padding-top: 2px;
  font: normal normal normal 10px/14px Open Sans;
  color: #c9c9c9;
}

.apply-btn {
  width: 62px;
  height: 27px;
  background: #707070 0% 0% no-repeat padding-box;
  border-radius: 16px;
}

.pdf-main-btn {
  padding: 5px 10px !important;
  margin: 0 !important;
  width: 84px !important;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: none !important;
  position: absolute !important;
  z-index: 99 !important;
  background-color: #f05a6a !important;
  display: flex !important;
  justify-content: space-between;
  overflow: hidden !important;
  transition: all 0.2s ease !important;
}

.type-main-btn {
  margin: 0;
  width: 77px;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: none;
  position: absolute;
  z-index: 1;
  background-color: #506994;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  transition: all 0.2s ease;
}

.amount-main-btn {
  margin: 0;
  width: 149px;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: none;
  position: absolute;
  z-index: 1;
  background-color: #506994;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  transition: all 0.2s ease;
}

.statement_select_scroll_com:hover {
  background-color: rgba(256, 256, 256, 0.2) !important;
}

.downloadStatement-icon {
  height: 20px;
  width: 35px;
  margin: auto;
  /* margin-left: 2px; */
}
.react-datepicker__day--selected,
.react-datepicker__day--selected-start,
.react-datepicker__day--selected-end,
.react-datepicker__day--range-end {
  background-color: #f05a6a !important;
}

.edit_contactDetails_main {
  max-width: 1024px;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
}

.main_edit_stepper_div {
  background-color: #314c7f;
  height: 164px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  box-shadow: 2px 2px 10px 0px rgba(26, 26, 26, 0.733);
  position: fixed;
  top: 0;
  z-index: 10;
  left: 0;
  width: 472px;
}

.edit_contactDetails {
  max-width: 1024px;
  padding-top: 186px;
  height: 100%;
  overflow: hidden;
  scroll-behavior: smooth;
  display: flex;
  scroll-snap-type: x mandatory;
}
.dividecontactDetails {
  width: 472px;
  max-height: 400vh;
  padding-top: 186px;
  height: 100%;
}

@media screen and (max-width: 800px) {
  .edit_contactDetails_main {
    display: block;
    width: 100vw !important;
    height: 100vh !important;
    padding-top: 100px;
  }
  .dividecontactDetails {
    width: 100% !important;
    padding: 0 !important;
  }
  .edit_contactDetails_main-radio-btn-container {
    height: 100px !important;
    flex-direction: column;
    gap: 10px !important;
  }
  .mobile-account-name-btn {
    width: auto !important;
  }
  /* .mobile-account-name-span-responsive {
    width: 80% !important;
  } */
  .mobile-view-radio-btn {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .mobile-view-radio-btn-dis {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .mobile-view-main_edit_stepper_div {
    position: fixed;
    bottom: 0;
    z-index: 100;
  }
}
.owner_category_div {
    width: "50%";
    display: flex;
    align-items: center;
    border-right: 2px solid #b4b4b4;
}

.owner_payment_mode_div {
    /* width: "50%"; */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 98px !important;
    height: 30px !important;
}

.owner_paymentmode_image {
    object-fit: contain;
    width: 38px;
    height: 30px;
}
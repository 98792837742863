.filterDrawer {
  padding-top: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: 100%;
  background: #1d3a6d 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  color: #ffffff;
  z-index: -1;
}

.filterLabel {
  padding: 120px 5% 5% 5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 141px;
  background: #314b79 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
}

.crossIcon {
  width: 23px;
  height: 23px;
  display: flex;
  align-items: center;
}

.crossIcon2 {
  width: 43px;
  height: 43px;
}

.filterText {
  height: 36px;
  text-align: left;
  font: normal normal normal 26px/36px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  padding: 70%;
}

.filterButtons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 5%;
  background-color: #1d3a6d;
}

.filterButton1 {
  text-transform: capitalize !important;
  color: white !important;
  width: 213px;
  height: 55px;
  border-radius: 34px !important;
  border: 1px solid #fafafa !important;
  text-align: center;
  font: normal normal normal 20px/27px Open Sans !important;
  letter-spacing: 0px;
  color: #fafafa;
}

.filterButton2 {
  text-transform: capitalize !important;
  width: 213px;
  height: 55px;
  background: #f05a6a 0% 0% no-repeat padding-box !important;
  border-radius: 34px !important;
  text-align: center;
  font: normal normal normal 20px/27px Open Sans !important;
  letter-spacing: 0px;
  color: #fafafa !important;
}

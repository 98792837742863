.multiApprovalViewallCard {
  display: flex;
  flex-wrap: wrap;
  width: 91%;
  margin: 0 auto;
  padding-bottom: 30px;
}

.multiSelectApprovalViewAllCard {
  border-radius: 10px !important;
  box-sizing: border-box;
  grid-column: span 1;
  height: 96px !important;
  margin: 10px;
  padding: 10px !important;
  width: 257px;
}

@media screen and (max-width: 3840px) and (min-width: 2560px) {
  .multiApprovalViewallCard {
    width: 91%;
  }

  .multiSelectApprovalViewAllCard {
    width: 268px;
  }
}

@media screen and (max-width: 1518px) and (min-width: 853px) {
  /* .beneTxnViewallCard {
    width: 90%;
  } */
  /* .multiSelectApprovalViewAllCard {
   width: 254px;
 } */
}

@media screen and (max-width: 2400px) and (min-width: 1800px) {
  .multiSelectApprovalViewAllCard {
    width: 268px;
  }
}

.multiApproval_viewall_acc_title {
  color: #dadada;
  font-size: 10px;
  font-weight: 600;
}

.multiApproval_viewall_bank_acc_div {
  color: #f9f9f9;
  font-size: 14px;
  margin-bottom: 12px;
  width: 14rem;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: lowercase;
  height: 20.2px;
}

.multiApproval_viewall_name_divMob {
  width: 100%;
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #4ddd37;
  margin-bottom: 2px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.multiApproval_viewall_name_div {
  display: flex;
  flex-direction: row;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
  flex-direction: row;
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #4ddd37;
  margin-bottom: 2px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.multiApproval_viewall_bank_acc_div:first-line {
  text-transform: capitalize !important;
}

.multiApproval_view_all_user {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.multiApproval_viewall_box_status {
  width: 5px;
  height: 15px;
  background-color: #4ddd37;
  border-radius: 0px 5px 0px 0px;
  top: 0px;
  position: absolute;
  right: 0px;
}

.multiApproval_viewall_details:hover .multiApproval_viewall_assigned {
  display: block;
}

.multiApproval_viewall_details:hover .multiApproval_viewall_box_status {
  transform: translateX(calc(100% -100%));
  right: auto;
  left: 0px;
}

.multiApproval_viewall_assigned {
  display: none;
  height: 15px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  text-transform: none;
  padding-left: 10px;
  padding-right: 10px;
}

.multiApproval_viewall_label_div {
  display: flex;
  height: 20px;
  right: 0px;
  position: absolute;
  bottom: 65px;
}

.css-wdslgk {
  padding-right: 0 !important;
}

@keyframes slideOutRightToLeft {
  0% {
    visibility: visible;
    transform: translateX(0);
  }

  100% {
    visibility: hidden;
    transform: translateX(-10%);
  }
}

.multiApproval_viewall_details:hover .multiApproval_viewall_name_div {
  visibility: hidden;
  animation: slideOutRightToLeft 0.2s ease-in-out forwards;
}

.multiApproval_viewall_card_container {
  background: #f9f9f9 0% 0% no-repeat padding-box;
  border-radius: 5px 0px 10px 0px;
  height: 24px;
}

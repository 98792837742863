@media screen and (max-width: 800px) {
  .bene_createAccountFailureMainContainer{
    height: 100vh;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .bene_createAccountFailureContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .bene_createAcc_FailureImg_container{
    padding-top: 0px;
    background-color: #754545;
  }

  .bene_createAccountFailureImageDiv{
    padding: 20px;
  }

  .bene_createAccFailureText{
    padding-top: 20px;
  }

  .bene_createAccFailureText p{
    font-size: 14px;
    font-weight: 600;
    font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    text-align: center;
    line-height: 19.07px;
  }

  .bene_createAccFailureDetails{
    padding-top: 0px;
    padding-bottom: 0px;
    width: 100%;
  }

  .bene_accFailureDetailsCard {
    height: auto !important;
    margin: 0;
    border-radius: 0;
  }

  .bene_createAccFailure_BottomDiv{
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    position: fixed;
    bottom: 0;
  }
}

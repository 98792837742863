/* .view-all-logo-div {
  width: "50%";
  display: flex;
  align-items: center;
} */
.view-all-bank-div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 98px !important;
  height: 30px !important;
  margin-right: 5px;
}
.view-all-bank-image {
  object-fit: contain;
  height: 22px;
  width: 58px;
}

.dividepan {
  width: 472px;
  margin: 0px 20px;
}

.pan_Enter {
  height: 180px;
  border-radius: 30px;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  transition: all 0.1s ease;
}

::-webkit-scrollbar {
  width: 9px;
  height: 10px !important;
}

::-webkit-scrollbar-thumb {
  width: 9px;
  height: 20px;
  background: #eeeeee 0% 0% no-repeat padding-box;
  border-radius: 25px;
  opacity: 1;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-corner {
  background-color: #f1f1f1;
}

.top_acc {
  width: 200px !important;
  height: 22px;
  text-align: left;
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #fafafa;
  opacity: 1;
  margin-left: 30px;
  margin-bottom: 4px;
}

.scroll_All_approval {
  height: 225px;
  overflow-y: scroll;
}

.ass_top {
  width: 472px;
  height: 329px;
}

.top_con {
  width: 390px;
  height: 17px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #e0e0e0;
  opacity: 1;
  margin-left: 30px;
}

.pan_Details {
  width: 472px;
  border-radius: 30px;
  min-height: 350px;
  background: #3a5686 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 30px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.beneSettle_Main {
  width: 100%;
  scroll-snap-align: start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  height: fit-content;
  max-width: 1024px;
}

.scroll_All_approval_settleType {
  height: 440px;
  overflow-y: scroll;
}

.dividepan_settleType {
  width: 472px;
  margin: 0px 20px;
}

.ass_top_settleType {
  width: 472px;
  height: 329px;
}

.top_con_settleType {
  width: 383px;
  height: 17px;
  text-align: left;
  font: normal normal 600 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
  margin-left: 30px;
}

.non_active_accounts_container {
  margin-top: 20px;
  margin-left: 30px;
}

.non_active_accounts {
  width: 412px;
  height: 68px;
  background: #1d3a6d 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 0.5;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.non_active_accounts > img {
  width: 73px;
  height: 68px;
  background: #f9f9f9 0% 0% no-repeat padding-box;
  border-radius: 20px;
  object-fit: contain;
}

.non_active_accounts > p {
  width: 59px;
  height: 22px;
  text-align: left;
  font: normal normal 600 16px/27px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  margin-left: 20px;
}

.top_acc_settleType {
  width: 226px;
  height: 22px;
  text-align: left;
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  margin-left: 30px;
  margin-bottom: 10px;
  position: relative;
}

.pan_Details_settleType {
  width: 472px;
  border-radius: 30px;
  height: 520px;
  background: #3a5686 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 30px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.achived_main_settleType > p {
  width: 138px;
  height: 17px;
  text-align: left;
  font: normal normal 600 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
  margin-top: 20px;
  margin-bottom: 11px;
  margin-left: 30px;
}

.achived_main_settleType {
  width: 100%;
  margin-top: 20px;
  height: fit-content;
}

.achived_scroll_settleType {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 28px;
}

.child_scroll_settleType {
  width: 412px !important;
  display: flex !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  flex-direction: column !important;
  padding: 0px !important;
  margin: 0px !important;
  position: relative !important;
  margin-bottom: 10px !important;
  text-transform: none !important;
  border-radius: 20px !important;
  overflow: hidden !important;
  transition: all 0.2s ease !important;
}
.top_Drop_settleType {
  min-height: 95px;
}
.bottom_Drop_settleType {
  height: 80px;
  background: #314d7e 0% 0% no-repeat padding-box;
  width: inherit;
  display: flex;
}

.align_settleType {
  width: 137px !important;
  height: 80px !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: flex-start !important;
  padding-top: 10px !important;
  border-radius: 0px 10px 10px 20px !important;
}

.settleType_1 {
  width: 40px;
  height: 22px;
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
}

.settleType_2 {
  width: fit-content;
  height: 17px;
  font: normal normal normal 10px/17px Open Sans;
  color: #dadada;
  margin-top: 4px;
}

.bank_name_settleType {
  width: 80px;
  height: 95px;
  background: #f9f9f9 0% 0% no-repeat padding-box;
  border-radius: 18px 0px 0px 18px;
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bank_name_settleType > img {
  width: 58px;
  height: 15px;
  opacity: 1;
}
.neft_settleType {
  border-bottom-left-radius: 20px !important;
}

.imps_settleType {
  border-bottom-right-radius: 20px !important;
}

.neft_settleType::after {
  content: "";
  position: absolute;
  top: 15px;
  right: 0;
  width: 1.5px;
  height: 63%;
  background-color: #dadada5d;
}
.rtgs_settleType::after {
  content: "";
  position: absolute;
  top: 15px;
  right: 0;
  width: 1.5px;
  height: 63%;
  background-color: #dadada5d;
}

.client_name_settleType {
  height: fit-content;
  padding-left: 100px !important;
}

.settle_main_div_container {
  /* width: 472px; */
  /* height: 282px; */
  /* box-shadow: 0px 3px 6px #00000029; */
  /* border-radius: 30px; */
  position: relative;
  margin: 0px 20px;
}

.settle_main_div_container_img {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 110px;
  height: 37px;
  background: #f9f9f9 0% 0% no-repeat padding-box;
  border-radius: 10px 0px;
  opacity: 1;
  object-fit: contain;
  display: flex;
  justify-content: center;
  align-items: center;
}

.settle_main_div_container_img > img {
  width: 72px;
  height: 18px;
}

.card_no_settleType {
  height: 22px;
  text-align: left;
  font: normal normal 600 16px/38px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
  margin-bottom: 12px;
  margin-top: 5px;
}

.data_settleType {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 6px;
  width: fit-content;
}

.refresh_settleType {
  width: 14px !important;
  height: 16px !important;
  text-align: left !important;
  font: normal normal normal 12px/11px Open Sans !important;
  color: #dadada !important;
  text-transform: capitalize !important;
  margin-top: 5px !important;
  padding-left: 10px !important;
}

.refresh_settleType > img {
  width: 10px;
  height: 10px;
  opacity: 1;
  margin: 0px 4px;
}

.balance_updated_settleType {
  height: 17px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
}

.amount_settleType {
  width: 150px;
  height: 22px;
  text-align: left;
  font: normal normal 600 16px/27px Open Sans;
  letter-spacing: 0px;
  color: #4de659;
  opacity: 1;
  position: relative;
}

.amount_settleType::after {
  content: "";
  position: absolute;
  top: 7px;
  right: 0;
  width: 1.5px;
  height: 59%;
  background-color: #dadada;
}

.settle_main_div {
  width: 472px;
  height: fit-content;
}

.settle_main_div_inner_con {
  margin: 20px;
  border-bottom: 1px solid #172e57;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  align-items: flex-start;
  margin-bottom: 5px;
  position: relative;
}

.settle_main_div_ifsc {
  padding: 0 0 15px 20px;
}

.settle_main_div_ifsc > h6 {
  width: 10px;
  height: 22px;
  text-align: left;
  font: normal normal normal 16px/27px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
  margin-bottom: 4px;
}

.settle_main_div_ifsc > p {
  width: 54px;
  height: 17px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
}

.bank_amt_div_refresh_settle {
  width: 24px;
  height: 24px;
  background: #506994 0% 0% no-repeat padding-box;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  margin-top: 9px;
}

.bank_amt_div_refresh_settle > img {
  width: 12px;
  height: 12px;
}

.settle_main_div_inner_con_amt_1 {
  width: 140px;
  height: 22px;
  text-align: left;
  font: normal normal 600 16px/27px Open Sans;
  letter-spacing: 0px;
  color: #4de659;
  margin-bottom: 4px;
}

.settle_main_div_inner_con_amt_2 {
  width: 159px;
  height: 17px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  white-space: nowrap;
}

.select_tag_name {
  /* width: fit-content; */
  height: fit-content;
  padding: 20px 20px;
}

.source_select_p1 {
  width: auto;
  height: 22px;
  text-align: left;
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
}

.source_select_p2 {
  width: 390px;
  height: 17px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
  margin-top: 4px;
}

.select_owner_box {
  width: 472px;
  height: 155px;
  background: #3a5686 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
}

.right_side_select_ownerBene_div_top_res {
  display: flex;
  flex-direction: column;
  position: relative;
  background: #3a5686;
  padding: 20px;
  border-radius: 10px;
  align-items: center;
}

.select_owner_butt {
  width: 412px !important;
  height: 52px !important;
  background: #2a487e 0% 0% no-repeat padding-box !important;
  border-radius: 10px !important;
  margin-left: 30px !important;
  text-transform: none !important;
}

.select_owner_butt > span {
  text-align: left;
  letter-spacing: 0px;
  color: #ff7f33;
  opacity: 1;
}

.settleType_main_div {
  width: 472px;
  height: 287px;
  background: #314d7e 0% 0% no-repeat padding-box;
  border-radius: 30px;
  opacity: 1;
  margin: 0px 20px;
  /* padding: 20px 0px; */
}

.settleType_main_div > p {
  width: auto;
  height: 22px;
  text-align: left;
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
  margin-left: 30px;
  margin-top: 20px;
}

.settleType_neft_imps_rfgs_container {
  width: 471px;
  height: 92px;
  background: #506994 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  margin-top: 10px;
  position: relative;
  display: flex;
}

.settleType_neft_imps_rfgs_container_inner {
  width: 236px !important;
  height: 92px !important;
  border-radius: 20px !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  text-transform: none !important;
}

.settleType_rfgs_inner::before {
  content: "";
  position: absolute;
  top: 21%;
  left: 0%;
  transform: translateX(-50%);
  width: 1px;
  height: 54px;
  background-color: #314d7e;
}

/* .settleType_rfgs_inner::after {
  content: "";
  position: absolute;
  top: 21%;
  right: 33%;
  transform: translateX(-50%);
  width: 2px;
  height: 54px;
  background-color: #314d7e;
} */

.settleType_neft_imps_rfgs_container_inner > h6 {
  width: 40px;
  height: 22px;
  text-align: center;
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
  z-index: 3;
}

.settleType_neft_imps_rfgs_container_inner > p {
  width: 142px;
  height: 34px;
  text-align: center;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  opacity: 1;
  z-index: 3;
}

.settleType_neft_imps_rfgs_container_back {
  width: 236px;
  height: 92px;
  background: #69b76f 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  position: absolute;
  transition: all 0.4s ease;
  top: 0;
  left: 0;
  cursor: pointer;
}

.settleType_neft_imps_rfgs_container_info {
  margin-top: 20px;
  margin-left: 20px;
  margin-bottom: 26px;
}

.neft_container_info > h6 {
  width: 39px;
  height: 22px;
  text-align: left;
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
  margin-bottom: 10px;
}

.neft_container_info {
  transition: all 2s ease;
}

.neft_container_info_bullet > p {
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
  width: fit-content;
  margin-bottom: 10px;
}

.right_side_select_owner_div_top {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: auto;
  background: #3a5686;
  border-radius: 20px;
  margin-bottom: 20px;
  padding: 20px;
}

.right_side_select_owner_div_top_img {
  width: 25px;
  height: 25px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-right: 10px;
  margin-bottom: 45px;
}

.right_side_select_owner_div_top_para {
  position: relative;
  padding-right: 20px;
}

.right_side_select_owner_div_top_para::after {
  content: "";
  position: absolute;
  right: 0;
  width: 1px;
  height: 54px;
  background-color: #031f4f;
  top: 15%;
}

.right_side_select_owner_div_top_para > h3 {
  width: 100%;
  height: 100%;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #dadada;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin-bottom: 5px;
}

.right_side_select_owner_div_top_para > p {
  width: 100%;
  height: 100%;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #dadada;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.right_side_select_owner_div_top_button {
  position: relative;
  min-width: 40px;
  height: 22.5px;
  border-radius: 20px;
  cursor: pointer;
  margin-left: 20px;
}

.right_side_select_owner_div_top_button_inner {
  position: absolute;
  left: 13.48%;
  right: 57.23%;
  top: 24.07%;
  bottom: 23.62%;
  background: #f9f9f9;
  border-radius: 50%;
  transition: transform 0.2s ease-in-out;
}

@keyframes slideFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.settleTypeAnimation {
  animation: slideFromRight 0.4s ease forwards;
}

@media screen and (max-width: 1024px) {
  .settle_main_div_container {
    padding: 0 20px;
    margin: 0 !important;
  }
}

@media screen and (max-width: 800px) {
  .right_side_select_owner_div_top_para::after {
    content: "";
    right: 0;
    width: 0px;
    height: 0px;
    background-color: #031f4f;
    top: 0%;
  }
  .beneSettle_Main {
    flex-direction: column !important;
    justify-content: flex-start !important;
    gap: 25px;
    padding-top: 20px !important;
  }
  .select_tag_name {
    width: 100% !important;
  }
  .select_owner_butt {
    width: 90% !important;
    margin: 0 auto !important;
  }
  .source_select_p2 {
    width: auto !important;
    height: auto !important;
  }
  .select_owner_box {
    display: flex;
    flex-direction: column;
    height: 160px !important;
  }
  .settleType_main_div {
    padding: 0 20px !important;
    margin: 0 !important;
  }
  .settleType_neft_imps_rfgs_container {
    width: inherit !important;
  }
  .settleType_neft_imps_rfgs_container_back {
    width: 50% !important;
  }
  .settleType_neft_imps_rfgs_container_inner {
    width: 50% !important;
  }
}

@media screen and (max-width: 1024px) {
  .right_side_select_ownerBene_div_top_res {
    margin: 0 20px !important;
  }
}

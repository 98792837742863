.ClientTxnDetailsBannerRightTop {
  display: flex;
  gap: 50px;
  padding: 5px 0;
}

.ClientTxnDetailsBannerRightOne,
.ClientTxnDetailsBannerRightTwo,
.ClientTxnDetailsBannerRightThree {
  display: flex;
  gap: 20px;
  padding: 5px 0;
}

.ClientTxnDetailsBannerLeft {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
}

.ClientTxnDetailsBannerRight {
  padding: 20px;
  background: #6b476b 0% 0% no-repeat padding-box;
  box-shadow: 0px 20px 20px #00000029;
  border-radius: 40px;
  font: normal normal 500 18px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  width: 354px;
  margin-right: -6%;
}

.ClientTxnDetailsBannerLeftTop {
  font: normal normal normal 32px/43px Open Sans;
  letter-spacing: 0px;
  color: #000000;
}

.ClientTxnDetailsBannerLeftMiddle {
  font: normal normal bold 20px/27px Open Sans;
  letter-spacing: 0px;
}

#txnDetailsBannerClient {
  background: white !important;
}

@media screen and (max-width: 480px) {
  .ClientTxnDetailsBannerRight {
    margin-right: 0;
    width: 80%;
    margin: auto;
    margin-bottom: -6%;
  }
  #txnDetailsBannerClientMb {
    margin-bottom: 50px;
  }
}

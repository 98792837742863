.txnheadertextmv-mob {
    display: table-cell;
    vertical-align: middle;
    padding: 0px 10px;
}

.txnheaderarrowvcv-mob {
    display: table-cell;
    width: 10%;
    vertical-align: middle;
    cursor: pointer;
}

.txnheadertextv-mob {
    width: 100%;
    padding: 20px 10px;
    display: table;
}

.beneacc_approval_heder_container-mob {
    margin-top: 0px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    z-index: 5;
}
.calendarOuter {
  width: 100%;
  padding: 15px 20px;
  height: 80px;
  display: flex;
  align-items: center;
}

.calendarInner {
  display: flex;
  padding: 5px 0px 5px 0px;
  height: 55px;
  width: 48px;
  background-color: var(--Blue_8);
  text-align: center;
  border-radius: 5px;
  font-weight: 600;
  justify-content: center;
  align-items: center;
}

.occasionName {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 82%;
  padding: 10px 0 10px 10px;
  overflow: hidden;
}

.occasionName span {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

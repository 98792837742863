.edit_category_main {
  width: 100%;
  height: 100%;
  max-width: 1024px;
}

.edit_1024_con {
  display: flex;
  justify-content: center;
}

.main_edit_stepper_div {
  width: 100%;
  background-color: #314c7f;
  height: 164px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  box-shadow: 2px 2px 10px 0px rgba(26, 26, 26, 0.733);
  position: fixed;
  top: 0;
  z-index: 10;
  left: 0;
}

.edit_category_details_all {
  width: 1024px;
  max-width: 1024px;
  padding-top: 186px;
  height: 100%;
  overflow: hidden;
  scroll-behavior: smooth;
  display: flex;
  scroll-snap-type: x mandatory;
}
.dividecategory {
  width: 472px;
  max-height: 400vh;
  margin: 0px 20px;
  padding-top: 186px;
  height: 100%;
}
.categoryName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 290px;
  color: #f9f9f9;
  font: normal normal 600 14px/19.07px "Open Sans";
}

.settled_bene_txn_popover_btn_acc {
  color: var(--Settled) !important;
  /* width: 112px;
  height: 26px; */
  text-transform: none !important;
  cursor: pointer;
}

.awaiting_verification_bene_txn_popover_btn_acc,
.approved_bene_txn_popover_btn_acc {
  color: var(--Settled) !important;
  cursor: pointer;
  text-transform: capitalize !important;
}

.denied_bene_txn_popover_btn_acc {
  color: var(--Failed) !important;
  /* margin-right: 20px; */
  cursor: pointer;
}

.failed_bene_txn_popover_btn_acc {
  color: var(--Failed) !important;
  cursor: pointer;
}

.blocked_bene_txn_popover_btn_acc,
.cancelled_bene_txn_popover_btn_acc,
.rejected_bene_txn_popover_btn_acc {
  color: var(--Failed) !important;
  /* margin-right: 20px; */
  cursor: pointer;
}

.hold_bene_txn_popover_btn_acc {
  color: var(--Queued) !important;
  /* margin-right: 20px; */
  cursor: pointer;
}

.queued_bene_txn_popover_btn_acc {
  color: var(--Queued) !important;
  /* margin-right: 20px; */
  cursor: pointer;
}

.pending_bene_txn_popover_btn_acc {
  color: var(--Pending) !important;
  /* margin-right: 20px; */
  cursor: pointer;
}

.abandoned_bene_txn_popover_btn_acc {
  color: var(--Abandoned) !important;
  /* margin-right: 20px; */
  cursor: pointer;
}

.all_bene_txn_popover_btn_acc::first-letter {
  text-transform: capitalize !important;
}

.all_bene_txn_popover_btn_acc {
  text-transform: none !important;
  /* margin-right: 20px; */
  cursor: pointer;
}

#bene_txn_popover_section_acc {
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: flex-start !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.bene_txn_popover_section_acc a {
  height: 26px !important;
  text-align: center !important;
  display: inline-flex;
  align-items: center !important;
  justify-content: center !important;
  text-decoration: none !important;
}

.popover-link_acc::first-letter {
  text-transform: capitalize !important;
}

.bene_txn_selected_btn_approved_acc
  .bene_txn_selected_btn_denied_acc
  .bene_txn_selected_btn.pending_acc
  .bene_txn_selected_btn.blocked_acc
  .bene_txn_selected_btn.abandoned_acc
  ::first-letter {
  text-transform: capitalize !important;
}

.bene_txn_selected_btn_denied_acc {
  background-color: var(--Failed) !important;
  color: var(--Failed) !important;
  width: 110px;
  height: 28px;
  text-transform: none !important;
}

/* .all-txn-status-icon {
  width: 15px !important;
  height: 15px !important;
  margin-right: 5px !important;
} */
.status_icon_acc {
  width: 18px !important;
  margin-left: 5px !important;
  height: 16px !important;
}

.view-all_bene_txn_popover-main_acc {
  position: relative;
  display: inline-block;
}

.all-txn-status-icon_acc,
.all-txn-status-icon_acc special-checkmark {
  margin-left: 5px;
  width: 18px;
  height: 18px;
}

.popover-link_acc {
  background-color: var(--Settled);
  border-radius: 20px;
  color: white;
  padding: 5px 10px;
  cursor: pointer;
  text-align: center;
  text-transform: none !important;
}

.popover-link_acc:hover {
  box-shadow: 1px soild red;
}

.pop_overbtn.settled_acc {
  color: var(--Settled) !important;
  width: 114px;
  height: 29px;
  text-transform: none !important;
  border: none !important;
}

.pop_overbtn.awaiting_verification_acc,
.pop_overbtn.approved_acc {
  color: var(--Settled) !important;
  width: 175px;
  height: 30px;
  font-size: 13px !important;
  text-transform: capitalize !important;
  border: none !important;
}

.pop_overbtn.pending_acc,
.pop_overbtn.abandoned_acc,
.pop_overbtn.all_acc ::first-letter {
  text-transform: capitalize !important;
}

.pop_overbtn.denied_acc,
.pop_overbtn.blocked_acc,
.pop_overbtn.failed_acc,
.pop_overbtn.cancelled_acc,
.pop_overbtn.rejected_acc {
  color: var(--Failed) !important;
  width: 110px;
  height: 28px;
  text-transform: capitalize !important;
  border: none !important;
}

.pop_overbtn.pending_acc {
  color: var(--Pending) !important;
  width: 110px;
  height: 28px;
  text-transform: none !important;
  border: none !important;
}

.pop_overbtn.queued_acc,
.pop_overbtn.hold_acc {
  color: var(--Queued) !important;
  width: 110px;
  height: 28px;
  text-transform: none !important;
  border: none !important;
}

.pop_overbtn.abandoned_acc {
  color: var(--Abandoned) !important;
  width: 118px;
  height: 28px;
  text-transform: none !important;
  border: none !important;
}

.beneTxnViewAllPopover_acc {
  height: 71px;
  background-color: var(--Blue_7);
  cursor: pointer;
  width: 232px;
}

.benTxnViewall_inner_div_acc {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0px 20px;
  justify-content: space-between;
}

.bene-txn-viewall-popover_acc {
  height: fit-content;
  background-color: var(--Blue_7);
  width: 232px;
  padding: 10px 0px;
}

.bene-txn-viewall-popover_acc.open {
  visibility: visible;
  height: 240px;
  transition: height 0.2s ease-in;
}

.bene-txn-viewall-popover_acc.closed {
  transition: height 0.2s ease-in, visibility 0s linear 0.2s;
  height: 0;
  visibility: hidden;
}

.bene-txn-viewall-popover_acc.open::before {
  content: "";
  position: absolute;
  top: 8px;
  left: 2%;
  width: 192px;
  /* 60% of the div's width */
  height: 100%;
  border-bottom: 1px solid var(--Blue_3);
}

.beneTxnViewAllchild2_acc {
  height: 17px;
  text-align: center;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_grey);
  opacity: 1;
  margin-top: 5px;
}

.bene_viewall_popover_icon_acc {
  width: 20px;
  height: 20px;
  background: var(--Blue_3);
  border-radius: 3px;
  margin-top: 20px;
}


.internal-account-viewall-popover_acc {
  height: fit-content;
  background-color: var(--Blue_7);
  width: 232px;
  padding: 10px 0px;
}

.internal-account-viewall-popover_acc.open {
  visibility: visible;
  height: 200px;
  transition: height 0.2s ease-in;
}

.internal-account-viewall-popover_acc.closed {
  transition: height 0.2s ease-in, visibility 0s linear 0.2s;
  height: 0;
  visibility: hidden;
}

.internal-account-viewall-popover_acc.open::before {
  content: "";
  position: absolute;
  top: 8px;
  left: 2%;
  width: 192px;
  height: 100%;
  border-bottom: 1px solid var(--Blue_3);
}
.IrecentTransactionsCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 15px;
  opacity: "1";
  box-shadow: 5px 5px 11px #00000033;
  background: #3a5686;
  width: 450px;
  height: 60px;
  border-radius: 40px;
  flex-shrink: 1;
}

.IrecentTransactionsCard:hover{
  background-color: #5676ad;
}

.Iaccounts {
  display: flex;
  justify-content: space-between;
  padding: 0 0 0 4%;
}

.Ilogo-div {
  background: white;
  height: 42px;
  width: 120px;
  border-radius: 30px 5px 30px 0px;
  align-self: flex-end;
}

.Ilogo {
  margin-top: 8px;
}

.Iamount-internal-div {
  padding-right: 4%;
  height: 28px;
  font-family: Open Sans, Semibold;
  font-size: 20px;
  text-align: right;
}

.Isource-account-div {
  height: 27px;
  width: 91px;
  color: #fafafa;
  font-family: Open Sans, Regular;
  font-size: 20px;
  text-align: center;
}
.Idest-account-div {
  height: 27px;
  width: 91px;
  color: #fafafa;
  font-family: Open Sans, Regular;
  font-size: 20px;
  text-align: center;
}


@media screen and (max-width: 768px) {
  .IrecentTransactionsCard{
    flex-grow: 1;
    flex-shrink: 1;
    /* justify-content: center;
    align-items: center; */
    /* gap: 10px; */
    /* padding: 2% 0; */
  }

}

@media screen and (max-width: 440px) {
  .IrecentTransactionsCard{
    height: 100%;
    flex-grow: 1;
    flex-shrink: 1;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 2% 0;
  }

}



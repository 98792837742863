.challenge-div {
  background: transparent
    linear-gradient(270deg, #031f4f 0%, #224074 50%, #031f4f 100%, #234176 100%)
    0% 0% no-repeat padding-box;
  padding: 0 171px;
}

.header-content h2 {
  margin-top: 15px;
  font-size: 28px;
  color: #f9f9f9;
  font-weight: 600;
}

/* grid */

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: minmax(112px, auto);
  gap: 20px;
  padding: 20px 0;
}

.box {
  color: white;
  padding: 20px;
}

.box .title-sec {
  font-size: 14px;
  font-weight: 600;
}

.box p {
  font-size: 12px;
}

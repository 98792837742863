.timeLineCard {
  display: flex;
  gap: 50px;
  justify-content: left;
  align-items: center;
}

.timeLineCardMiddle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.timeLineCardMiddleBottom {
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
}

.timeLineHeading {
  text-align: left;
  font: normal normal 600 18px/24px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  margin-bottom: 50px;
}

.timeLineAccordion {
  background-color: inherit !important;
  box-shadow: none !important;
  color: white;
}

.timeLineAccordion:hover {
  background-color: #fafafa33 !important;
  cursor: pointer;
  }


.timeLineGridItem:nth-child(n) {
  border-bottom: 1px solid #c9c9c9 !important;
}

.timeLineGridItem:nth-child(2n) {
  border-left: 1px solid #c9c9c9 !important;
}

.MuiAccordionSummary-expandIconWrapper {
  color: white !important;
}

.MuiAccordionSummary-content.Mui-expanded {
  margin: 0px !important;
}

.MuiAccordionDetails-root {
  padding: 0px 16px 8px !important;
}

.popover-main-mobile {
  background: #506994;
  margin: "auto";
  animation: grow 300ms ease-in-out forwards;
  transform-origin: bottom center;
  transform: translateY(100%);
  transition: transform 0.2s ease-in-out;
  z-index: 1;
  width: 100%;
  position: fixed;
  bottom: 0px;
  border-radius: 20px 20px 0px 0px;
  color: #ffffff;
  z-index: 9999999;
}

.menudiv {
  -webkit-tap-highlight-color: transparent;
}

.overlay {
  background-color: #000000;
  opacity: 0.9;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 999999;
  top: 0px;
}

.borderbottom {
  border-bottom: 1px solid #1c396c;
}

.css-9emuhu-MuiPaper-root-MuiDrawer-paper {
  background-color: #506994 !important;
}

.css-9emuhu-MuiPaper-root-MuiDrawer-paper {
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
}

.css-9emuhu-MuiPaper-root-MuiDrawer-paper::-webkit-scrollbar {
  display: none;
}

.close {
  animation: collapseUp 500ms ease-in-out reverse;
}

/* Opening Animation */
.popover-main-mobile.open {
  transform: translateY(0);
  animation: grow 200ms ease-in-out forwards;
}

/* Closing Animation */
.popover-main-mobile.closing {
  transform: translateY(100%);
  animation: collapseUp 200ms ease-in-out forwards;
}

@keyframes grow {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes collapseUp {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(100%);
  }
}

@media only screen and (max-width: 400px) {
  .closeIcon {
    left: 45vw;
  }
}

@media only screen and (min-width: 401px) and (max-width: 530px) {
  .closeIcon {
    left: 46vw;
  }
}
@media only screen and (min-width: 530px) {
  .closeIcon {
    left: 47vw;
  }
}

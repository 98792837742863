.header {
  display: flex;
  width: 425px;
  height: 30px;
  background: #506994 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  opacity: 1;
  margin-bottom: 15px;
}

.bene_txn_list_section {
  margin: 5px 0px;
  display: flex;
  width: 425px;
  height: 49px;
  background: #506994 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;

  border-radius: 10px;
  opacity: 1;
}

.bene_txn_list_section:hover {
  /* position: absolute; */
  border: 1px solid #ff5860;
  background: #031f4f 0% 0% no-repeat padding-box;
  height: 98px !important;
  transition: height 0.2s;
  z-index: 999;
}
.bene_txn_list_section:hover .bene_txn_viewallList_hover_content {
  display: block !important;
  transition: all 0.2s ease 0.2s;
  position: absolute;
  top: 53%;
  left: 70px;
}

.bene_txn_list_distribution_div {
  position: absolute;
  top: 0;
  left: 100%;
  height: 44px;
  padding: 5px 10px;
  /* width: 145px; */
  background-color: #031f4f;
}

.bene_txn_viewallList_hover_content {
  display: none;
}

.bene_txn_list_status_div {
  height: 44px;
  padding: 5px 10px;
  width: 200px;
  background-color: #031f4f;
  border-right: 2px solid var(--Blue_7) ;
  border-left: 2px solid var(--Blue_7);
}

.bene-txn-list-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 30px;
  padding-bottom: 110px !important;
}
@media screen and (max-width: 2400px) and (min-width: 1800px) {
  .bene-txn-list-container {
    grid-template-columns: repeat(3, 1fr);
  }
  .bene_txn_list_section {
    width: 500px;
  }
  .bene_txn_list_accounts_div {
    width: 300px !important;
  }

  .bene_txn_list_status_div{
    width: 252px !important;
  }

  .bene_txn_list_amount_div {
    width: 150px !important;
  }
}
@media (min-width: 2560px) {
  .bene-txn-list-container {
    grid-template-columns: repeat(3, 1fr) !important;
  }
  .bene_txn_list_section {
    width: 600px;
  }
  .bene_txn_list_accounts_div {
    width: 350px !important;
  }

  .bene_txn_list_status_div {
    width: 313px !important;
  }

  .bene_txn_list_amount_div {
    width: 180px !important;
  }
}

@media screen and (max-width: 3840px) and (min-width: 2700px) {
  .bene-txn-list-container {
    grid-template-columns: repeat(4, 1fr) !important;
  }
  .bene_txn_list_section {
    width: 720px;
  }
  .bene_txn_list_accounts_div {
    width: 450px !important;
  }

  .bene_txn_list_status_div {
    width: 450px !important;
  }

  .bene_txn_list_amount_div {
    width: 150px !important;
  }
}

.bank-div {
  padding: 7px 20px;
  width: 70px;
  height: 49px;
}

.bene_txn_listview_bank_div_img,
.bene_txn_list_last_div {
  height: 49px;
  border-radius: 10px 0px 0px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  width: 70px;
  flex-shrink: 0;
}

.bene_txn_list_section:hover .bene_txn_listview_bank_div_img {
  border-radius: 10px 0px 0px 0px;
}
.bene_txn_list_section:hover .bene_txn_list_last_div {
  border-radius: 0px 10px 0px 0px;
}

.bene_txn_listview_bank_div_img > img {
  height: 40px;
  width: 60px;
  padding: 0px 5px;
}
.bene_txn_list_name {
  text-transform: lowercase;
}

.bene_txn_list_name::first-letter {
  text-transform: capitalize;
}
.bank-div > p {
  width: 30px;
  height: 17px;
  text-align: center;
  font: normal normal 600 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
}

.bene_txn_list_accounts_div {
  height: 49px;
  padding: 5px 10px;
  width: 225px;
  background-color: #506994;
  border-right: 2px solid #1d3a6d;
  border-left: 2px solid #1d3a6d;
}
.bene_txn_list_amount_div {
  height: 49px;
  padding: 5px 10px;
  width: 115px;
  background-color: #314d7e;
  border-right: 2px solid #1d3a6d;
  /* border-left: 2px solid #1d3a6d; */
}
.bene_txn_list_last_div {
  width: 50px;
  height: 49px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 0px 10px 10px 0px;
  opacity: 1;
}

@media screen and (min-width: 800px) and (max-width: 1350px) {
  .bene-txn-list-container {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}

@media screen and (max-width: 500px) {
  .bene_txn_list_section {
    width: 350px;
  }
  .bene_txn_list_status_div {
    width: 150px;
  }
  .bene_txn_list_distribution_div {
    width: 140px;
  }
}
/* .marquee {
  width: 200px;
  max-width: 200px;
  color: white;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
}
.marquee p {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 7s linear infinite;
} */
/* @keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
} */

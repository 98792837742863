.view-all-txn-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 50%;
}

.view-all-txn-div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 50%;
}

.view-all-txn-image {
    height: 20px;
    width: 18px;
}

.view-all-txn-image-bank {
    width: 30px;
}

.txn-split {
  width: 0.5px;
  height: fit-content;
  background-color: #506994;
}
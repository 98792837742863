.dataCards {
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 1%;
  flex-direction: row;
}
.leftBody{
   color:#244277 !important;
}

@media only screen and (max-width: 480px) {
  body {
    width: 100%;
  }
  .createTransaction {
    margin: 5% auto;
    background-color: #244277;
    display: flex;
    width: 95%;
    justify-content: center;
    
    z-index: 1;
    padding: 2% 0 2% 0;
  }
  .leftBody {
    width: 95%;
    margin: auto;
    margin-bottom: 20%;
  }
  .recentTransactions {
    width: 95%;
    margin: auto;
    margin: 5%;
    display: flex;
    justify-content: center;
  }
}

@media only screen and (min-width: 480px) {
  .createTransaction {
    display: none;
    width: 90%;
    margin: auto;
    margin-bottom: 5%;
  }
  .leftBody {
    width: 70%;
    padding: 3% 3% 0 13%;
  }
  .recentTransactions {
    margin-top: 10%;
  }
}

@media only screen and (max-width: 480px)  {
  .sideMenu {
    display: none;
  }
}

.recentTransactions {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1%;
}

.viewAllLink:hover{
  text-decoration: none !important;
}


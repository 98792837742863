.hand-pointer {
  cursor: pointer;
}

.block-pointer {
  cursor: not-allowed;
  opacity: 0.5;
}

.ifsc-first-div {
  height: 195px;
  background: #3a5686 0% 0% no-repeat padding-box;
  opacity: 1;
  display: flex;
  align-items: flex-start;
  /* justify-content: space-between; */
  /* gap: 10px; */
}

.ifsc-bank-dropdown {
  width: 216px;
  height: 41px;
  background: #667ca3 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
}

.ifsc-bank-dropdown-expand {
  transition: height 0.1s ease;
  width: 216px;
  height: auto;
  background: #667ca3 0% 0% no-repeat padding-box;
  border: 1px solid #e25869;
  border-radius: 10px;
  opacity: 1;
  z-index: 1;
}

.ifsc-bar-container {
  margin-top: 105px;
  padding: 25px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ifsc-bar-container-others {
  margin-top: 105px;
  padding: 25px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-between; */
  /* gap: 10px; */
  width: 100%;
}

.ifsc-BlueStripMob {
  display: none;
  background-color: var(--Blue_3);
  height: 19px;
}

@media only screen and (max-width: 639px) {
  .ifsc-first-div {
    /* padding-top: 58px !important; */
    gap: 0px;
  }
  .ifsc-BlueStripMob {
    display: block;
    margin-top: 54px !important;
  }
}

@media only screen and (min-width: 640px) and (max-width: 800px) {
  .ifsc-first-div {
    margin-top: 64px !important;
  }
}

@media only screen and (max-width: 800px) {
  .ifsc-first-div {
    padding: 0px;
    /* margin-top: 56px; */
    flex-direction: column;
    justify-content: flex-start;
    height: fit-content;
    gap: 0px;
  }

  .ifsc-bar-container {
    position: absolute;
    margin-top: 0px;
    padding: 0px;
    width: 100%;
  }

  .ifsc-bar-container-others {
    margin-top: 40px;
    padding: 0px;
    flex-direction: column;
    gap: 0px;
    width: 100%;
  }

  .ifsc-bank-dropdown-expand {
    width: 100%;
  }
  /* 
  .bank-name-search-dd {
    width: 94% !important;
  } */

  .ifsc-bank-dropdown {
    border-radius: 0px;
    width: 100%;
  }

  .ifsc-code {
    width: 100% !important;
    border-radius: 0px !important;
    margin-right: 0px !important;
  }

  .ifsc-fetch-css-dis {
    width: 100% !important;
    border-radius: 0px !important;
  }
  .ifsc-fetch-css {
    width: 100% !important;
    border-radius: 0px !important;
  }

  .ifsc-option {
    margin: 10px 0px !important;
  }

  .ifsc-partition-two {
    display: flex;
    justify-content: end;
    margin-right: 5px;
  }

  .ifsc-partition-search {
    display: flex;
    justify-content: end;
  }

  .ifsc-down-search {
    width: 16px !important;
    height: 16px !important;
  }
}

@media only screen and (min-width: 800px) and (max-width: 1024px) {
  .ifsc-first-div {
    padding-right: 20px;
    padding-left: 20px;
    /* gap: 10px; */
  }
  .ifsc-code {
    width: 100% !important;
  }
}

.ifsc-code {
  width: 621px;
  height: 41px;
  background: #244277 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  margin-right: 12px;
}

.ifsc-option {
  width: 23px;
  height: 22px;
  margin: 0 15px;
  font: normal normal 400 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
}

.ifsc-partition {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
}

/* .ifsc-partition-css {
  margin-top: 15px;
  margin-left: 10px;
} */

/* .ifsc-partition > div {
  margin: 0 10px;
} */

.ifsc-partition-one {
  flex: 55%;
}

.ifsc-partition-two {
  flex: 10%;
}
.ifsc-partition-input {
  flex: 130%;
}
.ifsc-partition-search {
  display: flex;
  justify-content: end;
  flex: 10%;
}
.ifsc-dropDown-input {
  flex: 88%;
}
.ifsc-dropdown-search {
  display: flex;
  justify-content: end;
  flex: 10%;
}

.ifsc-partition-one-css {
  width: 182px;
  height: 22px;
  text-align: left;
  font: normal normal normal 12px/22px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
}
.ifsc-input-inner {
  width: 182px;
  height: 22px;
  text-align: left;
  font: normal normal normal 12px/22px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
  display: flex;
}

.ifsc-down-img {
  width: 11px;
  height: 15px;
}

.ifsc-up-img {
  transform: matrix(-1, 0, 0, -1, 0, 0);
}

.ifsc-down-search {
  width: 16px;
  height: 17px;
}

.ifsc-fetch-css {
  width: 128px;
  height: 41px !important;
  background: #f9f9f9 0% 0% no-repeat padding-box !important;
  border-radius: 10px;
  text-align: center !important;
  font: normal normal 600 14px/19px Open Sans !important;
  letter-spacing: 0px !important;
  color: #1d3a6d !important;
  text-transform: none !important;
}

.ifsc-fetch-css-dis {
  width: 128px;
  height: 41px !important;
  background: #707070 0% 0% no-repeat padding-box !important;
  border-radius: 10px;
  text-align: center !important;
  font: normal normal 600 14px/19px Open Sans !important;
  letter-spacing: 0px !important;
  color: #f9f9f9 !important;
  text-transform: none !important;
}

.splitline-dd {
  /* margin-top: 8px; */
  margin-bottom: 10px;
  /* margin-left: 11px; */
  /* width: 100%; */
  /* height: 1px; */
  border-top: 1px solid #314d7e;
  opacity: 1;
}

.bank-name-search-dd {
  /* width: 195px; */
  height: 40px;
  background: #314d7e 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  margin-left: 5px;
  margin-right: 5px;
  flex-wrap: wrap;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.label-left {
  text-align: left !important;
}

.bank-name-dd-options {
  padding-bottom: 5px;
  margin-bottom: 5px;
  height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}

.bank-name-dd-options .bank-name-search-dd {
  margin-top: 5px;
  opacity: 0;
  animation: fadeIn 0.2s ease forwards;
  animation-delay: 0.2s;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.bank-name-dd-options::-webkit-scrollbar {
  width: 5px;
}
/* 
.bank-name-dd-options::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
} */

.bank-name-dd-options::-webkit-scrollbar-thumb {
  background-color: #f9f9f9;
  outline: 1px solid #314d7e;
  border-radius: 25px;
  height: 40px;
}

.bank-name-dd-options::-webkit-scrollbar-thumb:hover {
  background-color: #f9f9f9;
}

.bank-name-search-dd-color {
  background: #667ca3 0% 0% no-repeat padding-box !important;
}

.bank-name-search-dd-text {
  text-align: left;
  align-items: center;
  font: normal normal normal 12px/22px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
  padding-top: 10px;
  padding-left: 5px;
  padding-right: 5px;
}

.bank-name-search-dd-text:hover {
  background: #506994 0% 0% no-repeat padding-box !important;
}

.input-custom {
  background: #314d7e 0% 0% no-repeat padding-box;
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  color: #ffffff;
  font: normal normal normal 14px/19px "Open Sans", sans-serif;
  outline: none;
}

.input-custom-ifsc {
  background: #244277 0% 0% no-repeat padding-box;
  width: 100%;
  height: 22px;
  box-sizing: border-box;
  color: #dadada;
  font: normal normal normal 12px/22px Open Sans;
  letter-spacing: 0px;
  opacity: 1;
  text-align: left;
  outline: none;
}

.input-custom-ifsc::placeholder {
  font: normal normal normal 12px/22px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
}

.input-custom::placeholder {
  font: normal normal normal 14px/19px "Open Sans", sans-serif;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
}

.ifsc-enter-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
}

.ifsc-no-enter-image {
  max-width: 70%;
  height: auto;
}

@media (max-width: 480px) {
  .ifsc-enter-div {
    height: 55vh;
    padding: 12px;
  }
  .ifsc-no-enter-image {
    margin-top: 30px;
    width: 45%;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .ifsc-enter-div {
    padding: 20px;
  }
  .ifsc-no-enter-image {
    margin-top: 25px;
    width: 30%;
  }
}

@media (min-width: 768px) {
  .ifsc-enter-div {
    padding: 22px;
  }
  .ifsc-no-enter-image {
    margin-top: 25px;
    width: 25%;
  }
}

@media (min-width: 1024px) {
  .ifsc-enter-div {
    padding: 0px;
  }
  .ifsc-no-enter-image {
    margin-top: 72px;
    width: 30%;
  }
}

.small-screen-text {
  white-space: normal;
  max-width: 100%;
  text-align: center;
}

@media (max-width: 480px) {
  .small-screen-text {
    font-size: 14px;
    line-height: 1.4;
    max-width: 80%;
    word-break: break-word;
  }
}

.ifsc-enter-label {
  width: 522px;
  height: 54px;
  text-align: center;
  font: normal normal normal 16px/27px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
}

.ifsc-card-viewer {
  max-height: 528px;
  margin-left: 100px;
}

.ifsc-details-card {
  width: 255px;
  height: 98px;
  background-color: #3a5686;
  border-radius: 10px !important;
  box-sizing: border-box;
  grid-column: span 1;
  margin: 10px;
  padding: 10px !important;
  cursor: pointer;
  position: relative;
}

.ifsc-details-card:hover {
  background-color: #506994 !important;
}

.skeletonCard {
  width: 255px;
  border-radius: 10px;
}

@media screen and (max-width: 800px) {
  .ifsc-details-card {
    width: 100% !important;
    height: 93px !important;
  }
  .ifsc-logo-card-container {
    margin-top: -25px !important;
  }

  .skeletonCard {
    width: 100% !important;
    border-radius: 10px;
  }
}
.ifsc-code-card {
  width: 180px;
  text-align: left;
  font: normal normal 400 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
}

.ifsc-code-card-label {
  width: 140px;
  text-align: left;
  font: normal normal normal 10px/14px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
}

.ifsc-logo-card-container {
  position: absolute;
  right: 0;
  margin-right: 0px !important;
  width: 90px !important;
  height: 26px !important;
  background: #f9f9f9 0% 0% no-repeat padding-box;
  margin-top: -22px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  margin-left: 155px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ifsc-bank-image {
  width: 65px;
  height: 17px;
  background: transparent 0% 0% no-repeat padding-box;
  opacity: 1;
}

.ext-padding {
  padding-top: 8px;
}

.loader-height {
  margin-top: 220px;
}

#infinityScrollContainerIfscView {
  display: flex !important;
  flex-direction: column !important;
  overflow: auto;
  position: relative;
  height: 100%;
}

.ifscCard {
  left: 5rem;
  margin-top: 10px;
}

@media only screen and (max-width: 460px) {
  .ifscCard {
    margin-bottom: 60px !important;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .ifscCard {
    display: flex;
    flex-wrap: wrap;
    width: 80% !important;
    /* margin: 0 auto; */
    padding-bottom: 60px;
  }
}
@media screen and (min-width: 1920px) and (min-height: 1080px) {
  .ifscCard {
    left: 6rem;
  }
}

@media screen and (min-width: 2560px) and (min-height: 1440px) {
  .ifscCard {
    left: 8rem;
  }
}

@media screen and (min-width: 3840px) and (min-height: 1920px) {
  .ifscCard {
    left: 12rem;
  }
}

.ifscCard {
  display: flex;
  flex-wrap: wrap;
  width: 94%;
  margin: 0 auto;
  padding-bottom: 60px;
}

.ifsc-close-img {
  width: 11px;
  height: 16px;
}

.ifsc-cancle-img {
  width: 15px;
  height: 16px;
}

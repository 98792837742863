.beneConfirm_Main {
  width: 100%;
  scroll-snap-align: start;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 20px;
}

.dividecon {
  width: 472px;
  margin: 0px 20px;
}

.bencategory {
  box-shadow: 5px 5px 11px #00000033;
}

.overflow-auto::-webkit-scrollbar {
  width: 11px;
}

.overflow-auto::-webkit-scrollbar-thumb {
  background-color: #fafafa;
  border-radius: 5px;
}

.overflow-auto::-webkit-scrollbar-track {
  background-color: transparent;
  border: none;
}
.confirmCategoryName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 350px;
}

.bank-logo-img-crt {
  width: 82px;
  height: 29px;
  background: #FAFAFA 0% 0% no-repeat padding-box;
  border-radius: 26px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 40px;
}

.bank-logo-img-size {
  width: 66px;
  height: 17px;
  background: transparent;
  opacity: 1;
}

.categories-label-iocn {
  width: 52px;
  height: 44px;
  background: var(---f9f9f9) 0% 0% no-repeat padding-box;
  background: #F9F9F9 0% 0% no-repeat padding-box;
  border-radius: 10px 0px 0px 10px;
  opacity: 1;
}

#crt-bene-acc-email-id-firstLetter::first-letter {
  text-transform: lowercase !important;
}

@media screen and (max-width: 800px) {
  .beneConfirm_Main {
    flex-direction: column;
    margin-bottom: 50px;
    padding-top: 18px;
  }
  .mobilewidthconfirmpage {
    padding-top: 20px;
  }
  .dividecon {
    width: -webkit-fill-available;
  }
  .mobile-crt-confirm-page-legal-name {
    width: 100%;
  }
  .confirmCategoryName {
    max-width: 200px;
  }
}

@media screen and (max-width: 390px) {
  .dividecon {
    width: 345px !important;
  }
    .mobile-crt-confirm-page-legal-name {
      width: 200px !important;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
}
@media screen and (max-width: 373px) {
  .dividecon {
    width: 335px !important;
  }
}
@media screen and (max-width: 363px) {
  .dividecon {
    width: 325px !important;
  }
}

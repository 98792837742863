.selectDrp {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  position: relative;
  width: 100%;
}

.selectDrp.open .selectDrp_select {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: rgb(226, 88, 105) solid 1px;
  border-bottom: none;
}

.selectDrp_select {
  background-color: #667ca3;
  border-radius: 10px;
  position: relative;
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 42px;
  justify-content: space-between;
  padding: 0 10px;
  width: 100%;
}

.selectDrp_scroll_line {
  background-color: #3a5686;
  height: 1px;
  position: absolute;
  top: 42px;
  width: 95%;
  z-index: 999;
  margin: 0 2.5%;
}

.selectDrp_select > span {
  font: normal normal normal 16px Open Sans;
  height: 21px;
  font-size: 16px;
  opacity: 1;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 150px;
  text-transform: capitalize;
}

.selectDrp_select > img {
  height: 16px;
  opacity: 1;
  width: 16px;
}

.scroll_content {
  display: flex;
  flex-direction: column;
  max-height: 0;
  padding: 6px 10px;
  position: relative;
  background-color: #667ca3;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 100%;
  border: rgb(226, 88, 105) solid 1px;
  border-top: none;
  z-index: 9;
  overflow: auto;
  transition: max-height 0.5s ease-in-out;
  animation: expand 0.5s forwards;
}

@keyframes expand {
  from {
    max-height: 0;
  }
  to {
    max-height: 150px;
  }
}

.scroll_content .category-wrapper {
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: capitalize;
  margin-bottom: 5px;
}

.scroll_content .category-wrapper:hover {
  background-color: #506994;
}
.category-wrapper.selected {
  background-color: #3a5686;
}
.scroll_content ul li.checkbox input[type="checkbox"] {
  display: none;
}

.scroll_content ul li.checkbox label::after {
  content: "";
  position: absolute;
  right: 15px;
  width: 20px;
  height: 20px;
  background-image: url("../../../../src/assets/TransactionDashboardIcons/select_all_with_white_filled_circle.png"); /* Unchecked image */
  background-size: cover;
}

.scroll_content ul li.checkbox input[type="checkbox"]:checked + label::after {
  background-image: url("../../../../src/assets/TransactionDashboardIcons/selected_with_green_circle.png") !important; /* Checked image */
}

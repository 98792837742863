.approvalStatusImg {
  padding: 10%;
}

.approvalStatusImg > h1 {
  font: normal normal 600 36px/49px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
}





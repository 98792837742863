.txninfo-container {
  margin: auto;
  display: flex;
  flex-direction: column;
  /* width: 20vw; */
  justify-content: center;
  align-items: center;
  box-shadow: 0px 6px 6px #00000029;
  border-radius: inherit !important;
  opacity: 1;
  padding: 30px 0;
  overflow: hidden;
  gap: 10px;
}

.heading {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 375px;
  padding: 0 15%;
  gap: 20px;
}

.heading-text {
  text-align: center;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  width: 50%;
  opacity: 1;
}

.value-text {
  font: normal normal normal 17px/23px Open Sans;
  letter-spacing: 0px;
  color: #fafafa;
  opacity: 1;
  text-align: center;
  width: 50%;
}

.owner_popup_box {
  width: 824px;
  height: 635px;
  background: #102c5d 0% 0% no-repeat padding-box;
  border-radius: 30px;
  opacity: 1;
  padding: 20px 30px 20px 30px;
}

.owner_popup_search > span {
  width: 217px;
  height: 27px;
  text-align: left;
  font: normal normal 600 20px/27px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  white-space: nowrap;
}

.bank_high_to_low_select_2_butt {
  width: 171px !important;
  height: 27px !important;
  margin: 0 !important;
  padding: 0 !important;
  border-radius: 5px !important;
  text-align: left !important;
  font: normal normal normal 12px/17px Open Sans !important;
  letter-spacing: 0px !important;
  color: #ffffff !important;
  text-transform: none !important;
  display: flex !important;
  justify-content: flex-start !important;
}

.bank_high_to_low_select_2 {
  width: fit-content;
  height: 59px;
  margin-top: 6px;
  border-top: 1px solid #3a5686;
  padding-top: 6px;
  margin-left: 9px;
}

.owner_popup_search {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.active_noavtive_owner {
  width: fit-content !important;
  margin-bottom: 21px;
}

.active_noavtive_owner_butt {
  text-align: center !important;
  font: normal normal 600 10px/14px Open Sans !important;
  letter-spacing: 0px !important;
  width: 154px !important;
  height: 24px !important;
  border-radius: 18px !important;
  white-space: nowrap !important;
  margin-right: 10px !important;
  text-transform: none !important;
}

.bank_amt_div_refresh {
  width: 24px;
  height: 24px;
  background: #506994 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  margin-top: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
}

.bank_amt_div_refresh > img {
  width: 12px;
  height: 12px;
  opacity: 1;
}

.bank_display_div_no {
  width: 106px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loading_owner {
  width: 100%;
  margin-top: 103px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading_owner > img {
  width: 90px;
  height: 90px;
}
.bank_display_div_no_1 {
  width: fit-content;
  height: 17px;
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) 12px/17px
    var(--unnamed-font-family-open-sans);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-bottom: 2px;
}
.bank_display_div_no_2 {
  width: fit-content;
  height: 14px;
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) var(--unnamed-font-size-10) /
    var(--unnamed-line-spacing-14) var(--unnamed-font-family-open-sans);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(---dadada);
  text-align: left;
  font: normal normal normal 10px/14px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
}
.bank_display_div_white {
  width: 60px;
  height: 43px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px 0px 0px 10px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.active_noavtive_owner_butt_2 {
  text-align: center !important;
  text-transform: none !important;
  font: normal normal 600 10px/14px Open Sans !important;
  letter-spacing: 0px !important;
  width: 157px !important;
  height: 24px !important;
  border-radius: 18px !important;
  white-space: nowrap !important;
  margin-right: 10px !important;
}

.pagination_owner_acc {
  width: 90px;
  height: 34px;
  background: #314d7e 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}

.owner_popup_cancel {
  width: 34px !important;
  height: 34px !important;
  background: #314d7e 0% 0% no-repeat padding-box !important;
  border-radius: 8px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.owner_popup_cancel:hover {
  cursor: pointer !important;
  background-color: rgba(59, 130, 246, 0.5) !important;
}

.owner_popup_main {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 99999;
  top: 0;
  left: 0;
  background-color: #000000cc;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.owner_popup_cancel > img {
  width: 14px;
  height: 14px;
  opacity: 1;
}

.owner_popup_sort {
  display: flex;
  padding-bottom: 39px;
  position: relative;
  width: 450px;
}

.data_bank_acc_no {
  width: 100%;
  height: fit-content;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.bank_display_div {
  width: 166px;
  height: 100%;
  background: #506994 0% 0% no-repeat padding-box;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  display: flex;
}

.bank_amt_div {
  width: 191px;
  height: 100%;
  display: flex;
}

.owner_sort_by_bank {
  margin-right: 20px;
  position: relative;
}

.search_cancel {
  width: 211px !important;
  height: 39px !important;
  background: #3a5686 0% 0% no-repeat padding-box !important;
  border-radius: 5px !important;
  text-transform: none !important;
}

.owner_sort_by_bank > p {
  width: fit-content;
  height: 17px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
  margin-bottom: 5px;
}

.owner_bank_scroll {
  margin-top: 10px;
  height: 180px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow-y: scroll;
  padding-top: 10px;
}

.data_bank_acc_no_inner {
  width: 367px !important;
  height: 43px !important;
  background: #314d7e 0% 0% no-repeat padding-box !important;
  border-radius: 10px !important;
  display: flex !important;
  padding: 0 !important;
  margin: 0 !important;
  text-transform: none !important;
  margin-bottom: 10px !important;
  justify-content: space-between !important;
}

.bank_amt_div_realamt_updated {
  padding: 5px 9px 10px 0px;
  height: 100%;
}

.bank_amt_div_realamt_updated_1 {
  width: 97px;
  height: 17px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  opacity: 1;
  white-space: nowrap;
  margin-bottom: 2px;
}

.bank_amt_div_realamt_updated_2 {
  width: 133px;
  height: 14px;
  text-align: left;
  font: normal normal normal 10px/14px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.vendorDeniedReasonComponent {
  width: 100%;
  height: 10vh;
  border-radius: 30px;
  box-shadow: -5px -5px 11px #71717133, 5px 5px 11px #00000033;
  padding: 30px;
  z-index: 100;
}

.detail-status-left{
  color: white;
  display: flex;
  text-align: left;
  width: 50%;
  flex-direction: column;
  justify-content: space-between;
}
.detail-right {
  width: "50%";
  display: flex;
  flex-direction: column;
  color: white;
  align-items: center;
  justify-content: space-between;
}
.bankLogoVendorDetailsCard{
  background: white;
  height: 52px;
  width: 145px;
  display: flex;
  margin-right: -30px;
  margin-bottom: -30px;
  align-items: center;
  justify-content: center;
  border-radius: 30px 5px 30px 0px;
  align-self: flex-end;
}
.content {
  text-align: left;
  width: 230px;
  font: "normal normal normal 20px/27px Open Sans";
}

.create-vendor-txn-top {
  /* width: 100%; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font: normal normal 600 20px/33px Open Sans;
}
.card-box {
  display: flex;
  flex-direction: column;
  width: fit-content;
}

.create-vendor-txn-top span {
  width: 250px;
}

#or-span {
  color: #ffffff;
  font: normal normal normal 18px/21px Arial;
}

#account-span {
  color: #ff7f33;
  font: normal normal medium 28px/37px Roboto;
}

#account-no-span {
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #d5d5d5;
}
#ifsc-span {
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #d5d5d5;
}
.account-no {
  font: normal normal medium 28px/37px Roboto;
  letter-spacing: 0px;
  color: #ff7f33;
}
.account-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 500px;
}

.bankLogo-div-createTxn {
  width: 113px;
  height: 40px;
  background: #fafafa 0% 0% no-repeat padding-box;
  border-radius: 24px;
  opacity: 1;
}

.selected-vendor-lower-div {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 480px) {
  .create-vendor-txn-top > span {
    display: none;
  }
  .create-vendor-txn-top {
    display: block;
    width: 100%;
  }
  .select-vendor-logo {
    display: none;
  }
  .create-vendor-txn {
    width: 90% !important;
    margin: auto !important;
    background: none !important;
    box-shadow: none !important;
  }
  .selectVendorTextBox {
    text-align: center;
    font: normal normal 600 22px Open Sans;
    text-decoration: none;
    letter-spacing: 0px;
    color: #ff7f33;
    background: #3a5686 0% 0% no-repeat padding-box;
    box-shadow: 5px 5px 11px #00000033;
    border-radius: 20px;
    margin: 0;
    padding: 5% 0;
    width: 100%;
  }
  .account-details {
    width: 90%;
    background: #3a5686 0% 0% no-repeat padding-box;
    border-radius: 40px;
    box-shadow: -5px -5px 11px #71717133;
    padding: 5%;
  }
}

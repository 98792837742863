.time-picker {
  background-color: #667ca3;
  border-radius: 8px;
  width: 100%;
}

.time-display {
  background-color: #667ca3;
  border-radius: 10px;
  position: relative;
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 42px;
  text-transform: capitalize;
  justify-content: space-between;
  padding: 0 10px;
  width: 100%;
}

.time-picker.open .time-display {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: rgb(226, 88, 105) solid 1px;
  border-bottom: none;
}

.time-scroll-container {
  display: flex;
  justify-content: left;
  gap: 25px;
}

.time-scroll {
  height: 100px;
  overflow-y: scroll;
  /* border-radius: 5px; */
  text-align: center;
  padding: 0px 0;
  scrollbar-width: none;
}

.time-option {
  padding: 3px;
  cursor: pointer;
  font-size: 14px;
}

.time-option.selected {
  background-color: #2b3d6e;
  font-weight: bold;
}

.time-picker-buttons button {
  padding: 5px 15px;
  border-radius: 20px;
}

.time-picker-buttons button:first-child {
  background-color: transparent;
  border: 1px solid #fff;
}

.dropdown-display {
  display: flex;
  flex-direction: column;
  max-height: 0;
  padding: 6px 15px;
  /* position: absolute; */
  background-color: #667ca3;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  /* margin-top: 42px; */
  width: 100%;
  border: rgb(226, 88, 105) solid 1px;
  border-top: none;
  z-index: 9;
  overflow: auto;
  transition: max-height 0.5s ease-in-out;
  animation: expand 0.5s forwards;
}

.horizontal_line {
  background-color: #3a5686;
  height: 1px;
  position: absolute;
  top: 42px;
  width: 95%;
  z-index: 999;
  margin: 0 2.5%;
}

.vendor-txn-settelment::-webkit-scrollbar {
    width: 5px;
    height: 100px;
}

.vendor-txn-settelment::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    background-color: #3A3649;
}

.vendor-txn-settelment::-webkit-scrollbar-thumb {
    background-color: #fafafa;
    /* outline: 1px solid #314D7E; */
    border-radius: 25px;
    height: 25%;
}
.header {
  display: flex;
  width: 425px;
  height: 30px;
  background: #506994 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  opacity: 1;
  margin-bottom: 15px;
}

.bene_txn_list_section_acc {
  margin: 5px 0px;
  display: flex;
  width: 425px;
  height: 49px;
  background: #506994 0% 0% no-repeat padding-box;
  /* box-shadow: 0px 3px 6px #00000029; */
  border-radius: 10px;
  opacity: 1;
}

.bene_txn_list_section_accm {
  margin: 5px 0px;
  display: flex;
  width: 90%;
  height: 49px;
  background: #506994 0% 0% no-repeat padding-box;
  /* box-shadow: 0px 3px 6px #00000029; */
  border-radius: 10px;
  opacity: 1;
  margin: 0px auto;
  overflow-x: hidden;
}

.bene-txn-list-container_accm {
  padding-bottom: 20px;
}

.bene-txn-list-container_acc {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 30px;
  padding-bottom: 20px;
  margin-top: 10px;
}
@media screen and (max-width: 2400px) and (min-width: 1800px) {
  .bene-txn-list-container {
    grid-template-columns: repeat(3, 1fr);
  }
  .bene_txn_list_section_acc {
    width: 500px;
  }
  .bene_acc_list_accounts_div_acc {
    width: 300px !important;
  }
  .bene_txn_list_amount_div_acc {
    width: 150px !important;
  }
}
@media (min-width: 2560px) {
  .bene-txn-list-container_acc {
    grid-template-columns: repeat(4, 1fr);
  }
  .bene_txn_list_section_acc {
    width: 600px;
  }
  .bene_acc_list_accounts_div_acc {
    width: 350px !important;
  }
  .bene_txn_list_amount_div_acc {
    width: 180px !important;
  }
}

@media screen and (max-width: 3840px) and (min-width: 2700px) {
  .bene-txn-list-container {
    grid-template-columns: repeat(5, 1fr);
  }
  .bene_txn_list_section_acc {
    width: 720px;
  }
  .bene_acc_list_accounts_div_acc {
    width: 500px !important;
  }
  .bene_txn_list_amount_div_acc {
    width: 150px !important;
  }
}

.bank-div {
  padding: 7px 20px;
  width: 70px;
  height: 49px;
}

.bene_txn_listview_bank_div_img_acc,
.bene_txn_list_last_div_acc {
  height: 49px;
  border-radius: 10px 0px 0px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  width: 70px;
}

.bene_txn_listview_bank_div_img_acc > img {
  height: 40px;
  width: 60px;
  padding: 0px 5px;
}
.bene_txn_list_name_acc {
  margin: 5px 10px 0px 10px;
}
.bene_txn_list_name_acc::first-line {
  text-transform: capitalize;
}
.bank-div > p {
  width: 30px;
  height: 17px;
  text-align: center;
  font: normal normal 600 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
}

.bene_acc_list_accounts_div_acc {
  height: 49px;
  width: 209px;
  background-color: #506994;
  border-right: 2px solid #1d3a6d;
  /* padding: 5px 10px 0px 10px; */
}
.bene_txn_list_amount_div_acc {
  height: 49px;
  width: 146px;
  background-color: #314d7e;
  border-right: 2px solid #1d3a6d;
  border-radius: 0px 10px 10px 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 10px;
  justify-content: center;
}
.bene_txn_list_last_div_acc {
  width: 50px;
  height: 49px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 0px 10px 10px 0px;
  opacity: 1;
}
@media screen and (max-width: 500px) and (min-width: 300px) {
  .bene_txn_list_section_accm {
    width: 74% !important;
    margin-bottom: 85px !important;
  }
}

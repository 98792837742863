.device-content-page {
  width: 100%;
  background-color: var(--Blue_6);
  height: 480px;
  margin-top: 72px;
}

.device-browserLogo-div,
.device-deviceLogo-div,
.device-token-div,
.device-deleteDevice-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--Primary_white);
  cursor: pointer;
  text-align: center;
  gap: 0.25rem;
  padding: 0.5rem;
  width: 100px;
}

.device-browserLogo-div {
  box-shadow: inset 0 10px 15px -5px rgba(0, 0, 0, 0.3);
  cursor: default;
}

.device-token-div,
.device-deleteDevice-div {
  background-color: var(--Blue_7);
  width: 100px;
}

.device-token-div button,
.device-browserLogo-div button,
.device-deviceLogo-div button,
.device-deleteDevice-div button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.device-token-div .device-token-img,
.device-browserLogo-div .device-browser-logo,
.device-deviceLogo-div .device-logo,
.device-deleteDevice-div .device-deleteDevice-img {
  height: 25px;
  margin-bottom: 5px;
}

.device-container {
  width: 100%;
  background-color: var(--Blue_6);
  height: 71vh;
  height: 30%;
  /* min-height: 15%; */
  margin: auto;
}

.device-section {
  height: 30%;
  background-color: var(--Blue_6);
  max-width: 1024px;
  margin: auto;
  display: flex;
}

.device-major-div {
  width: 900px;
  margin-top: 29px;
}

.device-individual-device-detail-first,
.device-individual-device-detail-second,
.device-individual-device-detail-third {
  height: 90px;
  display: flex;
  border-bottom: 1px solid var(--Blue_3);
}

.device-createdBy-div,
.device-lastUsed-div {
  padding: 20px;
  padding-top: 25px;
  width: 50%;
}

.device-number-balance {
  color: var(--Primary_grey);
  opacity: 1;
  font-size: 0.75rem;
  margin-left: 22px;
  margin-top: -22px;
}

.device-refresh-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 15px;
  margin-left: 6px;
  margin-left: 6px;
  margin-top: 2px;

  border-left: 2px solid var(--Primary_white);
}

.device-model-div,
.device-platform-div,
.device-loggedIn-div {
  padding-top: 29px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-left: 1px;
}

.device-text-label {
  color: var(--Primary_grey);
  opacity: 1;
  font-size: 0.75rem;
  font: normal normal normal 18px/25px Open Sans;
  letter-spacing: 0px;
  /* color: #C9C9C9; */
  opacity: 1;
}

.device-entity {
  font: normal normal normal 17px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_white);
  opacity: 1;
}
.device-details-heading {
  width: 80px;
  height: 28px;
  background-color: var(--Primary_white);
  border-radius: 18px;
  opacity: 1;
  color: #3a3649;
  font-size: 12px;
  margin-top: 20px;
}

.device-content {
  color: var(--Primary_white);
  opacity: 1;
  font-size: 1.2rem;
  border-left: 1px solid var(--Blue_3);
  height: 47px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-left: 20px;
}

.device-token-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.device-logo-bank-container {
  background-color: "" !important;
  height: 88.5px;
  width: 102px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.device-detailed-bank-logo {
  width: 80px;
}

.device-minor-div {
  margin-top: 29px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 270px;
}

.device-content-detail {
  justify-content: center;
  height: 48px;
  color: var(--Primary_white);
  opacity: 1;
  font-size: 1.2rem;
  border-left: 1px solid var(--Blue_3);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 20px;
}

.device-flex-device-div {
  display: flex;
  justify-content: flex-end;
  height: 90px;
  width: 100px;
  border-bottom: 1px solid var(--Blue_3);
}

.device-os-div {
  padding: 20px;
  width: 50%;
}

.device-acc-status-div {
  padding-top: 26px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-left: 1px;
}

.device-pending-status {
  color: var(--Pending);
}

.device-approved-status {
  color: var(--Settled);
}

.device-denied-status,
.device-blocked-status {
  color: #ff5860;
}

.device-archived-status {
  color: #a3a3a3;
}

.device-active-status {
  color: var(--Settled);
}

.device-not-active-status {
  color: #ff5860;
}

.device-recipient {
  height: 90px;
  border-bottom: 1px solid var(--Blue_3);
  display: flex;
}

.device-recipient-box2 {
  background: var(--Blue_7) 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 33.3%;
  border-right: 1px solid var(--Blue_3);
}

.device-recipient-box3 {
  background: var(--Blue_7) 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 34%;
}

.device-recipient-box4 {
  background: var(--Blue_7) 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 34%;
}

.device-recipient-img {
  width: 35px;
  height: 35px;
}

.device-image-label {
  font: normal normal normal 10px/14px Open Sans;
  letter-spacing: 0px;
  /* color: var(--Blue_3); */
  opacity: 1;
}

.device-image-label {
  font-size: 10px;
  /* color: var(--Primary_white) !important; */
}

.device-statement-img {
  background: var(--Blue_7);
  opacity: 1;
}

.device-details-div-section {
  width: 100%;
  background-color: var(--Blue_3);
  margin-top: 255px !important;
  margin-bottom: 20px;
}

.device-details-section {
  height: auto;
  background-color: var(--Blue_3);
  max-width: 1024px;
  margin: auto;
}

.device-details-btn {
  width: 80px;
  height: 30px;
  background-color: var(--Primary_white);
  border-radius: 18px;
  margin-right: 20px;
  color: var(--Detail_chip);
  font-size: 0.75rem;
  text-align: center;
  font-style: Open, Sans;
  font-weight: 600;
  text-align: center;
}

.device-detail-card {
  width: 328px;
  height: 250px;
  margin-top: 20px;
  background-color: var(--Blue_5);
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 20px;
}

.device-detail-label {
  letter-spacing: 0px;
  color: var(--Primary_grey);
  opacity: 1;
}

.device-title1 {
  font-size: 0.8rem;
  font-weight: 600;
  color: var(--Primary_grey);
  margin-bottom: 10px;
}

.device-data-div {
  font-size: 0.9rem;
  color: var(--Primary_white);

  padding-bottom: 4px;
}

.device-basic-div {
  padding-bottom: 10px;
  font-size: 0.8rem;
  color: var(--Primary_grey);
  padding-bottom: 10px;
  border-bottom: 1px solid var(--Blue_3);
  width: 100%;
}

.device-acc-type-section {
  margin-top: 10px;
}

.device-ifsc-code-div {
  font-size: 0.8rem;
  color: var(--Primary_grey);
  padding-bottom: 10px;
  width: 100%;
}

.device-status-div {
  padding-bottom: 10px;
  font-size: 0.8rem;
  color: var(--Primary_grey);
  width: 100%;
}

.device-owner-div {
  padding-bottom: 10px;
  font-size: 0.8rem;
  color: var(--Primary_grey);

  width: 100%;
}

.device-statementNotFound {
  width: 224px;
  height: 90px;
  background: #bb5353 0% 0% no-repeat padding-box;
  opacity: 1;
}

.device-support-detail-card {
  width: 328px;
  height: 180px;
  background-color: var(--Blue_5);
  border-radius: 20px;
  padding: 20px;
}

.device-addDetails-div {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

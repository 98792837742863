.viewTransactionsContainer {
  width: 398px;
  background: transparent linear-gradient(108deg, #3173e2 0%, #df5c74 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 0.5px solid #446aff33;
  border-radius: 20px;
  opacity: 1;
  margin-left: -20%;
}


@media screen and (max-width: 768px) {
  .createTxnContainer {
    width: 398px;
    height: 100%;
  }
}

.owner_div {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  z-index: 1;
  height: 105px;
  position: relative;
  align-items: center;
}

.owner_div-tags {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  z-index: 1;
  height: 105px;
  position: relative;
  align-items: center;
  border-bottom: 1px solid #031f4f;
}

.owner_div-tags {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  z-index: 1;
  height: 105px;
  position: relative;
  align-items: center;
  border-bottom: 1px solid #031f4f;
}

.owner_filterdropdown {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1024px !important;
  height: 323px;
  border-top: 1px solid var(--Blue_1);
  margin: 0 auto;
  z-index: 1;
}

.buttoncontainer {
  margin: 0 auto;
  display: flex;
  padding-left: 20px;
  gap: 30px;
}

.clear {
  width: 87px;
  height: 25px;
  border: 0.5px solid var(--Primary_white);
  border-radius: 8px;
  padding: 3px 27px;
  opacity: 1;
  text-align: center;
}

.apply {
  width: 87px;
  height: 25px;
  border-radius: 8px;
  padding: 3px 27px;
  opacity: 1;
  text-align: center;
}

.buttoncontainer-text {
  text-align: center;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_white);
  opacity: 1;
}

.owner_div_container {
  background-color: var(--Blue_6);
  /* height: 105px; */
  width: 100%;
  /* margin-top: 60px; */
  transition: height 0.3s ease-in-out;
  z-index: 998;
}

.owner_div_height {
  transition: height 0.2s ease-in-out;
  height: 165px;
}

.owner_search_div {
  width: 50px;
  height: 30px;
  background-color: var(--Blue_4);
  border-radius: 5px;
  padding: 7px 9px;
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
}

.filter-transition-close {
  transition: all 0.2s ease-in;
  height: 0;
  visibility: hidden;
}

.filter-transition-open {
  visibility: visible;
  animation: growDown 300ms ease-in-out forwards;
  transform-origin: top center;
  top: 90;
  position: absolute;
  background-color: var(--Blue_6);
  height: auto;
  border-radius: 0 !important;
  width: 100%;
  z-index: 998;
}

@keyframes growDown {
  0% {
    transform: scaleY(0);
  }

  100% {
    transform: scaleY(1);
  }
}

.refresh-transition-close {
  transition: all 0.2s ease-in;
  height: 0;
  visibility: hidden;
}

.refresh-transition-open {
  padding-top: 55px;
  visibility: visible;
  animation: growDown 300ms ease-in-out forwards;
  transform-origin: top center;
  top: 50px;
  position: absolute;
  background-color: #000;
  opacity: 0.9;
  height: 135px;
  width: 100%;
  z-index: 999;
}

.filter_applied_div {
  height: 60px;
  background-color: var(--Blue_6);
}

@media screen and (min-width: 1920px) {
  .filter_applied_div {
    height: 60px;
  }
}

.filter_badge_div {
  display: flex;
  gap: 10px;
  width: auto;
}

.filter_badge_sort_div {
  padding: 0px 20px;
  width: 250px;
  display: flex;
}

.filterby_text {
  width: 76px;
  height: 19px;
  font: normal normal normal 12px/16px Open Sans;
  padding-top: 10px;
  color: #fafafa;
}

.owner_middle_section_container {
  display: flex !important;
  justify-content: space-between !important;
  margin: 0 auto;
  height: 80px;
  background-color: var(--Blue_3);
  z-index: 888;
}

.filter_badge {
  width: max-content;
  max-width: 170px;
  height: 36px;
  background: var(--Blue_6) 0% 0% no-repeat padding-box;
  border: 2px solid var(--Blue_8);
  border-radius: 48px;
  display: flex;
  gap: 10px;
  padding: 7px 10px;
}

.filter_badge_text {
  width: auto;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 92px;
  height: 18px;
  text-align: center;
  font: normal normal 400 12px/19px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_white);
}

.filter_badge_img {
  margin-top: 1px;
  cursor: pointer;
  width: 16px;
  height: 16px;
}

.split_view {
  position: relative;
  width: 90px;
  height: 30px;
  background-color: var(--Blue_2);
  padding: 5px;
  border-radius: 4px;
  cursor: pointer;
}

.owner-viewall-page-select-div {
  height: 36px !important;
  width: 131px !important;
  cursor: pointer;
  display: flex;
  background: var(--Blue_4);
  border-radius: 5px;
  opacity: 1;
}

.split_view_img_def {
  position: absolute;
  left: 5px;
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.owner-main-btn {
  margin: 0 !important;
  width: 56px !important;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_white);
  text-transform: none !important;
  position: absolute !important;
  z-index: 1 !important;
  background-color: var(--Blue_8) !important;
  display: flex !important;
  justify-content: space-evenly;
  overflow: hidden !important;
  transition: all 0.2s ease !important;
}

.owner_select_scroll_com {
  height: 24px !important;
  border-radius: 5px !important;
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  text-align: left !important;
  font: normal normal normal 12px / 15px Open Sans !important;
  letter-spacing: 0px !important;
  margin: 4px 0px !important;
  color: var(--Primary_white) !important;
  text-transform: none !important;
  padding: 2px 4px;
}

.owner_select_scroll_com:hover {
  background-color: var(--Blue_7) !important;
}

.split_view_img {
  position: absolute;
  left: 5px;
  width: 20px;
  height: 20px;
  object-fit: contain;
  animation: anim-img 0.2s forwards ease-in;
}

@keyframes anim-img {
  0% {
    transform: translateX(280%);
  }

  100% {
    transform: translateX(0%);
  }
}

.animm-img {
  position: absolute;
  width: 20px;
  height: 20px;
  transition: all 0.2s ease-in;
  animation: img-anim 0.2s forwards ease-in;
}

@keyframes img-anim {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(280%);
  }
}

.split_view_text_def {
  position: absolute;
  font: normal normal 400 10px/20px Open Sans;
  color: var(--Primary_white);
  width: 51px;
  height: 26px;
  right: 5px;
}

.split_view_text {
  position: absolute;
  font: normal normal 400 10px/20px Open Sans;
  color: var(--Primary_white);
  width: 51px;
  height: 26px;
  right: 5px;
  animation: anim-txt 0.2s forwards ease-in;
}

@keyframes anim-txt {
  0% {
    transform: translateX(-70%);
  }

  100% {
    transform: translateX(0%);
  }
}

.animm-text {
  font: normal normal 400 10px/20px Open Sans;
  color: var(--Primary_white);
  width: 51px;
  position: absolute;
  transition: all 0.2s ease-in;
  animation: text-anim 0.2s forwards ease-in;
}

@keyframes text-anim {
  0% {
    transform: translateX(70%);
  }

  100% {
    transform: translateX(0%);
  }
}

.select_balance_btn {
  width: 61px;
  border-left: 1px solid var(--Blue_2);
  padding: 0 5px;
  /* height: 26px; */
  z-index: 2;
  cursor: pointer;
}

.select_balance_type {
  display: flex;
  position: relative;
}

.balance_type_container {
  width: 100%;
  max-width: 1024px;
  margin: 20px auto;
  height: 40px;
  background-color: var(--Blue_4);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
}

.select_balance_text {
  font: normal normal 400 12px/20px Open Sans;
  color: var(--Primary_white);
  width: 51px;
  padding: 5px;
  border-radius: 4px;
  height: 26px;
  transition: all 0.2s ease-in;
}

.listview-container {
  overflow-x: hidden;
}

.views-counts {
  width: 170px;
  font: normal normal normal 12px/17px Open Sans;
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
  letter-spacing: 0px;
  color: var(--Primary_grey);
}

.Nos-of-balancess {
  font: normal normal 600 16px/22px Open Sans;
  margin-top: 8px;
  width: 153px;
  display: flex;
  justify-content: space-between;
}

.all-containers {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 209px;
  background-color: var(--Blue_7);
}

.border-div {
  height: 50px;
  border-right: 2px solid var(--Blue_4);
  margin: 0 20px;
  margin-top: 5px;
}

.totals-count-symbols {
  transform: matrix(0, -1, 1, 0, 0, 0);
  font-style: italic;
  color: var(--Primary_white);
  margin-right: 4px;
  font-size: 1.125rem;
}

.rupee-count-symbol {
  font-size: 1.125rem;
  font-weight: 400;
}

.filter-containers {
  height: 30px;
  width: 50px;
  padding: 9px 6px;
  display: flex;
  justify-content: space-around;
  cursor: pointer;
  background: var(--Blue_4);
  border-radius: 5px;
  opacity: 1;
}

/* .filter-containers:hover {
  background-color: var(--Blue_7);
} */

.filter-images {
  width: 12px !important;
  height: 12px;
}

.arrow-images {
  width: 14px !important;
  height: 14px;
  object-fit: cover;
}

.toggle-containers {
  height: 30px;
  width: 68px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  background: var(--Blue_4);
  border-radius: 5px;
  opacity: 1;
}

.pagination-containers {
  height: 36px !important;
  width: 136px !important;
  cursor: pointer;
  display: flex;
  background: var(--Blue_4);
  border-radius: 5px;
  opacity: 1;
}

.toggle-box {
  border-radius: 5px;
  padding: 8px;
  height: 30px !important;
  width: 34px !important;
}

.toggle-images {
  width: 16px !important;
  height: 16px;
}

.owner-border-div {
  height: 220px;
  border-left: 2px solid var(--Blue_3);
  margin-top: 40px;
  margin-right: 15px;
  margin-left: 20px !important;
}

.owner_account_number_text_input {
  outline: none;
  background: var(--Blue_8);
  border-radius: 10px;
  opacity: 1;
  width: 216px;
  height: 37px;
  padding: 15px;
  font: normal normal normal 12px/17px Open Sans;
  color: var(--Primary_grey);
}

.owner_account_number_text_input::placeholder {
  color: var(--Primary_grey);
}

.owner_account_number_text {
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
  padding-bottom: 5px;
}

.owner-refresh-div {
  width: 34px !important;
  height: 30px !important;
  background: var(--Blue_4) 0% 0% no-repeat padding-box;
  border-radius: 5px !important;
  opacity: 1;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}

.no-acc-image {
  width: 320px;
  height: 226px;
  opacity: 1;
  margin-bottom: 20px;
}

.no-acc-image-opacity {
  width: 360px;
  height: 300px;
  opacity: 0.2;
  margin-bottom: 20px;
}

.no-owneraccount-text,
.no-owneraccount-text2 {
  color: var(--Primary_white);
  opacity: 1;
  font-size: 14px;
  margin-bottom: 20px;
  text-align: center;
}

.no-owneraccount-text-opacity {
  color: var(--Primary_white);
  font-size: 14px;
  text-align: center;
  opacity: 0.2;
  margin-bottom: 20px;
}

.no-owneraccount-text2 {
  display: inline-flex;
  padding-bottom: 18px;
}

.owner_loader-container > img {
  width: 50px !important;
  height: 50px !important;
}

.ownerviewallscroll::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.ownerviewallscroll::-webkit-scrollbar-track {
  height: 10px;
}

/* Handle */
.ownerviewallscroll::-webkit-scrollbar-thumb {
  height: 10px !important;
  background-color: #fafafa;
  outline: 1px solid var(--Blue_5);
  border-radius: 25px;
}

.owner-total-div {
  position: absolute;
  padding-left: 10px;
  width: 30px;
  border-radius: 0px 10px 10px 0px;
  background-color: var(--Blue_7);
}

.owner-total-div:hover,
.owner-total-div > img:hover {
  cursor: pointer;
}

.owner-total-div > img {
  width: 15px;
  height: 15px;
}

@media screen and (max-width: 800px) {
  .filter-transition-open {
    height: 92%;
    position: fixed !important;
    /* margin-top: 50px; */
  }

  .main_pop_div {
    width: 100% !important;
    height: 100% !important;
    left: 0 !important;
    border-radius: 0 !important;
  }

  .owner_div_height {
    height: 60px !important;
  }

  .owner_account_number_text_input {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 500px) {
  .clear,
  .apply {
    width: 50%;
    height: auto;
    border-radius: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.assign-tags-btn {
  /* Frame 11337 */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 5px;

  width: 195px;
  height: 30px;

  background: #667ca3;
  border-radius: 5px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  cursor: pointer;
}

.assign-tags-btn img {
  /* Subtraction 469 */

  height: 18px;
  width: 18px;
}

.assign-tags-btn p {
  /* Assign tags to users */

  width: 152px;
  height: 19px;

  /* Web Font/14 Regular */
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  color: #f9f9f9;
}

.assign-tags-div-mob {
  /* Frame 11474 */

  /* Auto layout */
  display: flex;
  flex-direction: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0px;
  gap: 4px;

  width: 100%;
  height: 50px;
  margin-top: 60px;
  background: #667ca3;
  cursor: pointer;
}

.assign-tags-div-mob img {
  width: 18px;
  height: 18px;
}

.assign-tags-div-mob p {
  /* Assign tags to accounts */

  width: 131px;
  height: 16px;

  /* Web Font/12 Regular */
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;

  color: #f9f9f9;

  /* Inside auto layout */
}

.assign-tags-text-mob {
  /* Frame 11465 */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px 25px;
  gap: 10px;

  height: 78px;
  border-top: 1px solid #1d3a6d;
  margin-top: 60px;
  background: #3a5686;
}

.assign-tags-text-mob p {
  /* Select accounts to apply tags. Tagged users will gain access to view only owner accounts that share the same tags. */

  width: 318px;
  height: 48px;

  /* Web Font/12 Regular */
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  color: #f9f9f9;
}

.assign-tags-select-mob {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 15px 0px;

  width: 100%;
  height: 60px;

  background: #667ca3;
  cursor: pointer;
}

.assign-tags-select-mob button {
  /* CTA */

  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  gap: 10px;

  width: 145px;
  height: 39px;

  border: 1px solid #dadada;
  border-radius: 43px;

  font-size: 14px;
  color: #f9f9f9;
}

.assign-tags-select-mob button:nth-child(2) {
  /* Assign tags */
  background-color: #707070;
}

.assignTags-btn {
  /* Stepper button */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  gap: 10px;

  width: 125px;
  height: 59px;

  background: #707070;

  /* Inside auto layout */
  flex: none;
  order: 2;
  flex-grow: 0;
}

.assignTags-btn p {
  /* Next */

  width: 93px;
  height: 22px;

  /* Web Font/16 Regular */
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */
  text-align: center;
  color: #f9f9f9;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.assign-select-text {
  /* Select accounts to apply tags. Tagged accounts will gain access to view owner accounts that share the same tags. */

  width: 1024px;
  height: 40px;

  /* Web Font/12 Regular */
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin: 0 auto;

  color: #f9f9f9;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

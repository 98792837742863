html,
body,
#root {
  height: 100%;
  margin: 0;
  height: 100%;
  margin: 0;
}

.user-main-div {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  background-color: var(--Blue_6);
  height: 100vh;
  overflow: auto;
}

.usr-recipient-box5[aria-tag="active"]:hover {
  background-color: var(--Blue_7_hover);
}

.user-main-div::-webkit-scrollbar {
  width: 7px;
  width: 7px;
}

.user-main-div::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

.user-main-div::-webkit-scrollbar-thumb {
  background-color: var(--Primary_white);
  outline: 1px solid var(--Blue_5);
  border-radius: 25px;
  height: 10px;
  background-color: var(--Primary_white);
  outline: 1px solid var(--Blue_5);
  border-radius: 25px;
  height: 10px;
}

.user-main-div::-webkit-scrollbar-thumb:hover {
  background-color: var(--Primary_white);
  background-color: var(--Primary_white);
}

.user-div-fixed {
  height: auto;
  background-color: var(--Blue_6);
  margin-top: 76px;
  height: auto;
  background-color: var(--Blue_6);
  margin-top: 76px;
}

.user-div-auto {
  flex-grow: 1;
  background-color: var(--Blue_3);
  width: 100%;
}

.user-container {
  width: 100%;
  background-color: var(--Blue_6);
  height: 30%;
  min-height: 15%;
  margin: auto;
}

.user-acc-section {
  height: 30%;
  background-color: var(--Blue_6);
  max-width: 1024px;
  margin: auto;
  display: flex;
}

.user-detail-section {
  height: auto;
  background-color: var(--Blue_3);
  max-width: 1024px;
  margin-top: 92px;
  margin: auto;
}

.user-multi-button {
  display: flex;
  padding-top: 30px;
  margin-bottom: 25px;
}

.user-detail-button {
  width: auto;
  padding: 5px 10px;
  height: 30px;
  background-color: var(--Detail_chip);
  border-radius: 18px;
  margin-right: 20px;
  color: var(--Primary_white);
  font-size: 0.75rem;
  text-align: center;
  font-style: Open, Sans;
  font-weight: 400;
  text-align: center;
}

.user-detail-button.active {
  font-weight: 600;
  background-color: var(--Primary_white);
  color: var(--Detail_chip);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.user-bank-detail {
  height: 90px;
  display: flex;
}

.user-account-holder-details {
  border-bottom: 1px solid var(--Blue_3);
  height: 90px;
  display: flex;
}

.user-contact-detail-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 328px;
  height: 177px;
  background-color: var(--Blue_5);
  border-radius: 20px;
  padding: 20px;
}

.user-service-card-row {
  display: flex;
  justify-content: left;
  flex-direction: row;
  margin: 35px 0px;
  gap: 20px;
}

.user-major-div {
  width: 800px;
  margin-top: 29px;
}

.user-minor-div {
  width: 204px;
  margin-top: 29px;
}

.user-individual-person-detail {
  margin-top: 6.5px;
  display: flex;
  border-bottom: 1px solid var(--Blue_3);
}

.user-acc-content {
  justify-content: center;
  display: flex;
  align-items: center;
}

.user-recipient {
  height: 93px;
  border-bottom: 1px solid var(--Blue_3);
  display: flex;
}

.user-recipient-noborder {
  height: 90px;
  border-bottom: 1px solid var(--Blue_7);
  display: flex;
}

.user-individual-name {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-left: 1px;

  color: var(--Primary_white);
  font-size: 16px;
  opacity: 1;
  font-weight: 400;
}

.user-account-name {
  color: var(--Primary_white);
  font-size: 1rem;
  opacity: 1;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-transform: lowercase !important;
}

.user-account-name::first-line {
  text-transform: capitalize;
}

.user-bank-code {
  padding: 20px;
  width: 50%;
}

.user-acc-holder-name {
  padding: 20px;
  width: 50%;
}

.user-acc-name-label {
  color: var(--Primary_grey);
  opacity: 1;
  font-size: 12px;
  padding-top: 4px;
  font-weight: 400;
}

.user-acc-detail {
  padding: 20px;
  width: 50%;
}

.user-acc-content {
  color: var(--Primary_white);
  opacity: 1;
  font-size: 1.2rem;
  border-left: 2px solid var(--Blue_3);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 20px;
}

.user-acc-number-label {
  color: var(--Primary_grey);
  opacity: 1;
  font-size: 12px;
  padding-top: 4px;
  font-weight: 400;
}

.user-recipient-img {
  width: 35px;
  height: 35px;
}

.user-account-status-div {
  font-size: 16px;
  opacity: 1;
  color: var(--Primary_white);
  font-weight: 400;
  text-transform: capitalize;
}

.user-inactive-status {
  color: var(--Failed);
}

.user-active-status {
  color: var(--Settled);
}

@media (max-width: 768px) {
  .user-acc-section {
    flex-direction: column;
  }

  .user-individual-person-detail,
  .user-bank-detail {
    height: auto;
    display: flex;
    flex-direction: column;
  }

  .user-detail-section {
    display: flex;
    flex-direction: column;
  }

  .user-service-card-row {
    margin-top: 20px;
  }

  .user-container {
    margin: auto;
  }
}

.crt-by-text {
  text-transform: capitalize !important;
}

.splitline-div {
  width: 0px;
  height: 28px;
  border: 1px solid var(--Primary_grey);
  opacity: 1;
  margin-right: 20px;
}

.features-div,
.services-div,
.roles-div {
  margin-bottom: 20px;
}

.roles-div {
  margin: 35px 0px;
}

.roles-tab-div,
.features-tab-div,
.services-tab-div {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.usr-recipient-box2 {
  background: var(--Blue_7) 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 34%;
  border-right: 2px solid var(--Blue_3);
}

.usr-recipient-box5 {
  background: var(--Blue_7) 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 44%;
}

.usr-recipient-box6 {
  background: var(--Blue_7) 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 44%;
  cursor: pointer;
}

.usr-recipient-box3 {
  background: var(--Blue_7) 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 34%;
}

.usr-statement {
  height: 90px;
  border-bottom: 1px solid var(--Blue_3);
}

.services-tab-header,
.features-tab-header {
  width: 1024px;
  height: 49px;
  /* UI Properties */
  background: var(--Blue_7) 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 20px;
  margin: 30px 0px 20px 0px;
}

.features-tab-header:nth-child(1),
.services-tab-header:nth-child(1) {
  margin-top: 35px;
}

.features-tab-header h5,
.services-tab-header h5 {
  color: var(--Primary_grey);
  text-align: left;
  font: normal normal 600 14px/19px Open Sans;
  letter-spacing: 0px;
  opacity: 1;
  width: 100%;
  white-space: initial;
}
.services-tab-card,
.features-tab-card,
.roles-tab-card {
  /* top: 541px;
  left: 170px; */
  width: 241px;
  height: 78px;
  /* UI Properties */
  background: var(--Blue_5) 0% 0% no-repeat padding-box;
  border-radius: 15px;
  opacity: 0.8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_white);
  padding: 20px;
}

.email-id-div {
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_white);
  opacity: 1;
  margin-bottom: 5px;
}
.user-title {
  font-size: 14px;
  font-weight: 600;
  color: var(--Primary_grey);
  padding-bottom: 20px;
}

.user-phone {
  padding-top: 4px;
  font-size: 12px;
  color: var(--Primary_grey);
  padding-bottom: 10px;
  font-weight: 400;
  border-bottom: 1px solid var(--Blue_3);
  width: 100%;
}

.user-email-section {
  margin-top: 10px;
}

.user-email-id-div {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--Primary_white);
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 400;
}

.user-email {
  font-size: 0.8rem;
  color: var(--Primary_grey);
  font-weight: 400;
  padding-top: 4px;
}

.not-found-container {
  width: 300px;
  text-align: left;
  letter-spacing: 0px;
  color: var(--Primary_white);
  margin: auto;
}

.not-found-container p {
  padding-left: 33px;
}

.tagsdiv {
  max-width: 1024px;
  border-radius: 20px;
  background-color: var(--Blue_5);
  height: auto;
  padding: 20px;
  position: relative;
}

.tagchipdiv {
  width: 880px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.noaccfoundtext {
  font-size: 14px;
  color: var(--Primary_grey);
  font-weight: 400;
}

.editicon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0 20px 0 20px;
  background-color: var(--Blue_7);
  width: 40px;
  height: 30px;
  cursor: pointer;
}

.editicon:hover {
  opacity: 0.8;
}

.editicon > img {
  height: 15px;
  width: 15px;
}

.tagchip {
  height: 26px;
  border-radius: 17px;
  background-color: var(--Blue_7);
  width: auto;
  padding: 5px 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--Primary_white);
}

.MuiPaginationItem-icon,
.MuiPaginationItem-root {
  font-weight: 600 !important;
  color: var(--Primary_white) !important;
  margin: 0 5px !important;
}

.MuiPaginationItem-root.Mui-selected {
  background-color: var(--Blue_8) !important;
}

.MuiPaginationItem-root:hover {
  background-color: var(--Blue_2) !important;
}

.txnDetailsBanner {
  display: flex;
  justify-content: space-between;
  box-shadow: -5px -5px 11px #71717133, 5px 5px 11px #00000033;
  border-radius: 40px;
}

.txnDetailsBannerLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 5%;
}

.txnDetailsBannerRight {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  width: 30%;
}

.txnDetailsBannerLeftTop {
  font: normal normal normal 32px/43px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  text-transform: capitalize;
}

.txnDetailsBannerLeftBottom {
  text-align: left;
  font: normal normal normal 18px/24px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
}

.txnDetailsBannerRightTop {
  text-align: center;
  font: normal normal bold 20px/27px Open Sans;
  letter-spacing: 0px;
  color: #11b1b1;
  opacity: 1;
}

.txnDetailsBannerRightMiddle {
  text-align: center;
  font: normal normal normal 36px/49px Open Sans;
  letter-spacing: 0px;
  color: #35aa3d;
  opacity: 1;
}

.txnDetailsBannerRightBottom {
  text-align: center;
  font: normal normal normal 18px/24px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  display: flex;
}

.hoverInfo:hover {
  background-color: #00000080;
  border-radius: 50px;
}

.approveBtHover:hover {
  background-color: #00000080;
  border-radius: 50px;
}

.txnDetailsBannerRightBottom>div>button {
  width: 100% !important;
  height: 40px !important;
  margin: 0 10px !important;
  /* padding: 2%; */
  font: normal normal 600 18px/14px Open Sans;
  text-transform: capitalize;
}

.txnDetailsBannerRightBottom>div>button:hover {
  background: none;
}

.approveTxnDetailsBTs,
.approveTxnDetailsBTsMobile {
  display: flex;
  justify-content: space-evenly;
}

.txnDetailsBannerMobileTop {
  background: transparent linear-gradient(180deg, #5e9a9a 0%, #304f4f 100%) 0% 0% no-repeat padding-box;
  border-radius: 0px 0px 50px 50px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  padding: 20px;
}

@media screen and (max-width: 480px) {
  .txnDetailsBannerMobile {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 0px 0px 25px 25px;
    margin-left: -6%;
    margin-right: -6%;
  }

  .txnDetailsBannerMobileTop {
    text-align: center;
  }

  .txnDetailsBanner {
    display: none;
  }

  .txnDetailsBannerRightBottom>div {
    width: 100%;
  }

  .txnDetailsBannerLeftBottom {
    text-align: center;
  }
}

@media screen and (min-width: 480px) {
  .txnDetailsBannerMobile {
    display: none;
  }
}

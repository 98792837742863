.paymenthodTextSettle{
    text-align: center;
    font: italic normal bold 30px/41px Open Sans;
    letter-spacing: 0px;
    color: #ffffff;
}
.paymentProceedBTSettle {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
  border-radius: 30px;
  width: 90%;
  height: 6%;
  cursor: pointer;
  font: normal normal 600 1.6em Open Sans;
}
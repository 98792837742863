@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

@font-face {
  font-family: "fontello";
  src: url("assets/font/fontello.eot");
  src: url("assets/font/fontello.eot") format("embedded-opentype"),
    url("assets/font/fontello.woff") format("woff"),
    url("assets/font/fontello.woff2") format("woff2"),
    url("assets/font/fontello.ttf") format("truetype"),
    url("assets/font/fontello.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

:root {
  --toastify-font-family: "Open Sans", sans-serif;

  /* Blue Colour Shades*/
  --Blue_1: #031f4f;
  --Blue_2: #102c5d;
  --Blue_3: #1d3a6d;
  --Blue_4: #244277;
  --Blue_5: #314d7e;
  --Blue_6: #3a5686;
  --Blue_7: #506994;
  --Blue_8: #667ca3;
  --Blue_9: #93a2be;
  --Blue_10: #afbacf;

  /*  Blue Colour Hover*/
  --Blue_1_hover: #354c72;
  --Blue_2_hover: #40567d;
  --Blue_3_hover: #4a618a;
  --Blue_4_hover: #506892;
  --Blue_5_hover: #5a7198;
  --Blue_6_hover: #61789e;
  --Blue_7_hover: #7387a9;
  --Blue_8_hover: #8596b5;
  --Blue_9_hover: #a9b5cb;
  --Blue_10_hover: #bfc8d9;

  /* Button Colors */
  --Button_green: #69b76f;
  --Button_green_hover: #87c58c;

  --Button_red: #f05a6a;
  --Button_red_hover: #ff7980;
  --White_hover: #e9e9e9;

  --Button_date_range_mid_selection: #ef888d;

  /* Chips Colors */
  --Detail_chip: #373f4b;
  --Filter_chip_fill: #3a5686;
  --Filter_chip_outline_stroke: #667ca3;

  /* Status Text Color */
  --Abandoned: #b4b4b4;
  --Settled: #4ddd37;
  --Awaiting: #b196fa;
  --Queued: #fbc02d;
  --Failed: #ff5860;
  --Pending: #31e5e5;
  --All_chip: #2c57ff;

  /* Success and Fail Pages Color */
  --Error_BG: #754545;
  --Non_selected_tab: #864c4c;
  --Bottom_and_selected_tab: #985555;
  --Success_BG: #4d8352;
  --Bottom_tab: #55985c;
  --Strip_of_success_page: #e9e9e9;

  /* Toast Message Color */
  --Toast_sucess: #07bc0c;
  --Toast_info: #3498db;
  --Toast_error: #e74c3c;
  --Toast_processing: #ae9041;

  /* Other Colors */
  --Primary_white: #f9f9f9;
  --Primary_grey: #dadada;
  --Primary_black: #1d1d1d;
  --Secondary_grey: #707070;
  --Header_orange: #ff7f33;
  --Failed_to_take_action: #bb5353;
  --Verification_failed: #ce362a;
  --Highlight_orange: #ce720f;
  --Verified_green: #4cb537;
  --Category_selection: #4a6d4b;
  --Increase_balance: #105023;
  --Potential_income: #ffee03;
  --Enter_ammount_bg_green: #3b965e;
  --Nav_background: #e9eff7;
  --Selected_option: #3a5686;
  --Failed_hover: #ff7980;

  /* Fonts weight */
  --Font_normal: 400;
  --Font_semibold: 600;
  --Font_bold: 700;
  --Font_extrabold: 800;

  /* Fonts size */
  --Font_10: 10px;
  --Font_12: 12px;
  --Font_14: 14px;
  --Font_16: 16px;
  --Font_18: 18px;
  --Font_20: 20px;
  --Font_24: 24px;
}

/* Font Height and weight class */
.text_10_normal {
  @apply text-Font_10 font-normal;
}

.text_10_semibold {
  @apply text-Font_10 font-semibold;
}

.text_10_bold {
  @apply text-Font_10 font-bold;
}

.text_12_normal {
  @apply text-Font_12 font-normal;
}

.text_12_semibold {
  @apply text-Font_12 font-semibold;
}

.text_12_bold {
  @apply text-Font_12 font-bold;
}

.text_14_normal {
  @apply text-Font_14 font-normal;
}

.text_14_semibold {
  @apply text-Font_14 font-semibold;
}

.text_14_bold {
  @apply text-Font_14 font-bold;
}

.text_16_normal {
  @apply text-Font_16 font-normal;
}

.text_16_semibold {
  @apply text-Font_16 font-semibold;
}

.text_16_bold {
  @apply text-Font_16 font-bold;
}

.text_18_normal {
  @apply text-Font_18 font-normal;
}

.text_18_semibold {
  @apply text-Font_18 font-semibold;
}

.text_18_bold {
  @apply text-Font_18 font-bold;
}

.text_20_normal {
  @apply text-Font_20 font-normal;
}

.text_20_semibold {
  @apply text-Font_20 font-semibold;
}

.text_20_bold {
  @apply text-Font_20 font-bold;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

body::-webkit-scrollbar {
  width: 3px;
}

.vendor-txn::-webkit-scrollbar {
  width: 3px;
  height: 25px;
}

.vendor-txn::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

.vendor-txn::-webkit-scrollbar-thumb {
  background-color: #b4b4b4;
  outline: 1px solid #314d7e;
  border-radius: 25px;
  height: 75px;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  background-color: #d3d3d3;
}

body::-webkit-scrollbar-thumb {
  background-color: #fafafa;
  border-radius: 25px;
}

.bodymaindiv {
  background-color: #1d3a6d;
  overflow-x: hidden;
  margin-bottom: 120px;
}

@media (min-width: 801px) {
  .bodymaindiv {
    background-color: #1d3a6d;
    overflow-x: hidden;
    margin-bottom: 0px;
  }
}

@layer base {
  html {
    font-family: -apple-system, "Open Sans", sans-serif;
  }
}

#webviewd {
  display: block;
}

#mobileviewd {
  display: none;
}

.clearfix {
  clear: both;
}

input[type="password"] {
  font-size: 12px;
  font-weight: 100;
  letter-spacing: 1px;
  font-family: "fontello" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.row {
  margin-right: -5px;
  margin-left: -5px;
  box-sizing: border-box;
}

.container-fluid {
  margin-right: 0px;
  margin-left: 0px;
  box-sizing: border-box;
}

.row:before {
  content: "";
  display: table;
}

.row:after {
  content: "";
  display: table;
}

.row:after {
  clear: both;
}

.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-xs-1,
.col-sx-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-13 {
  float: left;
}

.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-xs-1,
.col-sx-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-13 {
  position: relative;
  min-height: 1px;
  margin-left: 0px;
  margin-right: 0px;
  box-sizing: border-box;
  padding-left: 5px;
  padding-right: 5px;
}

.col-xl-1 {
  width: 8.333333%;
}

.col-xl-2 {
  width: 16.666667%;
}

.col-xl-3 {
  width: 25%;
}

.col-xl-4 {
  width: 33.333333%;
}

.col-xl-5 {
  width: 41.666667%;
}

.col-xl-6 {
  width: 50%;
}

.col-xl-7 {
  width: 58.333333%;
}

.col-xl-8 {
  width: 66.666667%;
}

.col-xl-9 {
  width: 75%;
}

.col-xl-10 {
  width: 83.333333%;
}

.col-xl-11 {
  width: 91.666667%;
}

.col-xl-12 {
  width: 100%;
}

@media only screen and (min-width: 1025px) and (max-width: 1280px) {
}

@media only screen and (min-width: 982px) and (max-width: 1024px) {
}

@media only screen and (min-width: 802px) and (max-width: 981px) {
  .col-md-1 {
    width: 8.333333%;
  }

  .col-md-2 {
    width: 16.666667%;
  }

  .col-md-3 {
    width: 25%;
  }

  .col-md-4 {
    width: 33.333333%;
  }

  .col-md-5 {
    width: 41.666667%;
  }

  .col-md-6 {
    width: 50%;
  }

  .col-md-7 {
    width: 58.333333%;
  }

  .col-md-8 {
    width: 66.666667%;
  }

  .col-md-9 {
    width: 75%;
  }

  .col-md-10 {
    width: 83.333333%;
  }

  .col-md-11 {
    width: 91.666667%;
  }

  .col-md-12 {
    width: 100%;
  }
}

@media only screen and (min-width: 641px) and (max-width: 801px) {
  .col-sm-1 {
    width: 8.333333%;
  }

  .col-sm-2 {
    width: 16.666667%;
  }

  .col-sm-3 {
    width: 25%;
  }

  .col-sm-4 {
    width: 33.333333%;
  }

  .col-sm-5 {
    width: 41.666667%;
  }

  .col-sm-6 {
    width: 50%;
  }

  .col-sm-7 {
    width: 58.333333%;
  }

  .col-sm-8 {
    width: 66.666667%;
  }

  .col-sm-9 {
    width: 75%;
  }

  .col-sm-10 {
    width: 83.333333%;
  }

  .col-sm-11 {
    width: 91.666667%;
  }

  .col-sm-12 {
    width: 100%;
  }

  #webviewd {
    display: none;
  }

  #mobileviewd {
    display: block;
  }
}

@media only screen and (min-width: 321px) and (max-width: 640px) {
  .col-xs-1 {
    width: 8.333333%;
  }

  .col-xs-2 {
    width: 16.666667%;
  }

  .col-xs-3 {
    width: 25%;
  }

  .col-xs-4 {
    width: 33.333333%;
  }

  .col-xs-5 {
    width: 41.666667%;
  }

  .col-xs-6 {
    width: 50%;
  }

  .col-xs-7 {
    width: 58.333333%;
  }

  .col-xs-8 {
    width: 66.666667%;
  }

  .col-xs-9 {
    width: 75%;
  }

  .col-xs-10 {
    width: 83.333333%;
  }

  .col-xs-11 {
    width: 91.666667%;
  }

  .col-xs-12 {
    width: 100%;
  }

  #webviewd {
    display: none;
  }

  #mobileviewd {
    display: block;
    height: 100vh;
    /* overflow: hidden scroll; */
    scrollbar-width: none;
  }
}

@media only screen and (max-width: 320px) {
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-xs-1,
  .col-sx-2,
  .col-xs-3,
  .col-xs-4,
  .col-xs-5,
  .col-xs-6,
  .col-xs-7,
  .col-xs-8,
  .col-xs-9,
  .col-xs-10,
  .col-xs-11,
  .col-xs-12 {
    width: 100%;
    margin-right: 0px;
  }

  #webviewd {
    display: none;
  }

  #mobileviewd {
    display: block;
  }
}

#id-firstLetter::first-letter,
#email-id-firstLetter::first-letter {
  text-transform: lowercase !important;
}

#id-textTag::first-letter {
  text-transform: none !important;
}

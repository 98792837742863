.setBtncolor {
  background-color: #707070 !important;
  border-radius: 28px !important;
  text-transform: none !important;
  font-size: 16px !important;
  color: #d4d4d4 !important;
  line-height: 2.5rem !important;
  margin-top: 35px;
}

.setBtncolor.filled {
  background-color: #69b76f !important;
  color: white !important;
}

.loginlogoforgot {
  width: 50%;
  margin: 0 auto 4rem;
}

@media only screen and (max-width: 480px) {
  .reset-password-user {
    margin-top: 380px;
  }
}
@media screen and (max-width: 1024px) {
  .bene-txn-viewall-popover {
    width: 100% !important;
  }
}

@media screen and (max-width: 900px) and (min-width: 800px) {
  .beneTxnViewAllPopover {
    width: 190px !important;
  }
}

.settled_bene_txn_popover_btn {
  color: #4ddd37 !important;
  text-transform: none !important;
  cursor: pointer;
  /* padding-left: 20px; */
}

.awaiting_verification_bene_txn_popover_btn,
.approved_bene_txn_popover_btn {
  color: #b196fa !important;
  cursor: pointer;
  text-transform: capitalize !important;
  /* padding-left: 25px; */
}

.blocked_bene_txn_popover_btn,
.denied_bene_txn_popover_btn,
.failed_bene_txn_popover_btn,
.cancelled_bene_txn_popover_btn,
.rejected_bene_txn_popover_btn {
  color: #ff5860 !important;
  /* margin-right: 20px; */
  cursor: pointer;
}

.hold_bene_txn_popover_btn {
  color: #fbc02d !important;

  cursor: pointer;
}

.queued_bene_txn_popover_btn {
  color: #fbc02d !important;

  cursor: pointer;
}

.pending_bene_txn_popover_btn {
  color: #31e5e5 !important;

  cursor: pointer;
}

.abandoned_bene_txn_popover_btn {
  color: #b4b4b4 !important;
  cursor: pointer;
}

.all_bene_txn_popover_btn::first-letter {
  text-transform: capitalize !important;
}

.all_bene_txn_popover_btn {
  text-transform: none !important;
  cursor: pointer;
}

#bene_txn_popover_section {
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: flex-start !important;
  gap: 15px;
  padding-top: 20px !important;
  background-color: #506994;
  color: #f9f9f9;
  font: normal normal normal 600 14px / 19px Open Sans;
}

.bene_txn_popover_section a {
  height: 26px !important;
  text-align: center !important;
  display: inline-flex;
  align-items: center !important;
  justify-content: center !important;
  text-decoration: none !important;
}

.popover-link::first-letter {
  text-transform: capitalize !important;
}

.bene_txn_selected_btn_approved
  .bene_txn_selected_btn_denied
  .bene_txn_selected_btn.pending
  .bene_txn_selected_btn.blocked
  .bene_txn_selected_btn.abandoned
  ::first-letter {
  text-transform: capitalize !important;
}

.bene_txn_selected_btn_denied {
  background-color: #ff5860 !important;
  color: #ff5860 !important;
  width: 110px;
  height: 28px;
  text-transform: none !important;
}

/* .all-txn-status-icon {
  width: 15px !important;
  height: 15px !important;
  margin-right: 5px !important;
} */
.status_icon {
  width: 18px !important;
  margin-left: 5px !important;
  height: 16px !important;
}

.view-all_bene_txn_popover-main {
  position: relative;
  display: inline-block;
}

.all-txn-status-icon,
.all-txn-status-icon special-checkmark {
  margin-left: 5px;
  width: 18px;
  height: 18px;
}

.popover-link {
  background-color: #4ddd37;
  border-radius: 20px;
  color: white;
  padding: 5px 10px;
  cursor: pointer;
  text-align: center;
  text-transform: none !important;
}

.popover-link:hover {
  box-shadow: 1px soild red;
}

.pop_overbtn.settled {
  /* background-color: #4ddd37 !important; */
  color: #4ddd37 !important;
  width: 114px;
  height: 29px;
  text-transform: none !important;
  border: none !important;
}

.pop_overbtn.awaiting_verification,
.pop_overbtn.approved {
  /* background-color: #b196fa !important; */
  color: #b196fa !important;
  width: 175px;
  height: 30px;
  font-size: 13px !important;
  text-transform: capitalize !important;
  border: none !important;
}

.pop_overbtn.pending,
.pop_overbtn.abandoned,
.pop_overbtn.all ::first-letter {
  text-transform: capitalize !important;
}

.pop_overbtn.denied,
.pop_overbtn.blocked,
.pop_overbtn.failed,
.pop_overbtn.cancelled,
.pop_overbtn.rejected {
  /* background-color: #ff5860 !important; */
  color: #ff5860 !important;
  width: 110px;
  height: 28px;
  text-transform: capitalize !important;
  border: none !important;
}

.pop_overbtn.pending {
  /* background-color: #31e5e5 !important; */
  color: #31e5e5 !important;
  width: 110px;
  height: 28px;
  text-transform: none !important;
  border: none !important;
}

.pop_overbtn.queued,
.pop_overbtn.hold {
  /* background-color: #fbc02d !important; */
  color: #fbc02d !important;
  width: 110px;
  height: 28px;
  text-transform: none !important;
  border: none !important;
}

.pop_overbtn.abandoned {
  /* background-color: #b4b4b4 !important; */
  color: #b4b4b4 !important;
  width: 118px;
  height: 28px;
  text-transform: none !important;
  border: none !important;
}

.beneTxnViewAllPopover {
  height: 59px;
  background-color: #506994;
  /* cursor: pointer; */
  width: 230px;
}

.bene-txn-viewall-popover {
  height: 0;
  background-color: #506994;
  width: 231px;
  margin-top: 0;
  /* padding: 10px 0; */
}

.bene-txn-viewall-popover.open {
  visibility: visible;
  transition: height 0.2s ease-in;
  padding: 10px;
}

.bene-txn-viewall-popover.closed {
  visibility: hidden;
  height: 0;
  transition: height 0.2s ease-in, visibility 0s linear 02s;
  margin-top: -5px;
}

.benTxnViewall_inner_div {
  /* width: 205px; */
  margin-left: 18px;
  height: 93px;
  /* margin-top: 12px; */
}

.bene_viewall_popover_icon {
  position: relative;
  bottom: 23px;
  left: 171px;
  width: 20px;
  height: 20px;
  background: #1d3a6d;
  border-radius: 3px;
}

.bene-account-status-dropdown-disable {
  cursor: default !important;
}

.bene-account-status-dropdown {
  cursor: pointer !important;
}
.multiSelectVendorCardVendorSettlement.selected {
  background-color: #4A6D4B;
}

.multiSelectVendorCardVendorSettlement.not-selected {
  background-color: #534C6C;
}

.multiSelectVendorTxnApproval.selected {
  background-color: #4A6D4B;
}

.multiSelectVendorTxnApproval.not-selected {
  background-color: #4A6565;
}

.ViewAll-amount-div-settle {
  display: flex;
  justify-self: flex-start;
  text-align: left;
  font: normal normal 600 24px/33px Open Sans;
  letter-spacing: 0px;
  color: #4DDD37;
  opacity: 1;
}

.settlement-date-div {
  display: flex;
  font: normal normal 600 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #b0bbce;
  align-items: center;
}

.viewAllaccount-div-settle {
  /* padding-left: -3%; */
  display: flex;
  justify-self: flex-start;
  color: white;
  font-family: Open Sans, Regular;
  font-size: 20px;
  text-align: center;
}


@media screen and (max-width: 768px) {}

.selectRadioBT {
  padding: 0 5%;
}

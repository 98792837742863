.internal_Container_txn {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.Internal_stepper_Div {
  width: 100%;
  background-color: #314c7f;
  height: 164px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  box-shadow: 2px 2px 10px 0px rgba(26, 26, 26, 0.733);
  position: fixed;
  top: 0;
  z-index: 99;
  left: 0;
}

/*----- new ---------*/

/* Common container styling */

.carousel-container {
  max-width: 1024px;
  margin: 0 auto;
  overflow: hidden;
  padding-top: 186px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.carousel-container-res {
  padding-top: 5rem;
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding-bottom: 5rem;
}

/* Mounted (Enter) and Unmounted (Exit) Animations */
.mountedRight {
  opacity: 0;
  transform: translateX(50%);
  animation: slideInRightBBPS 0.6s forwards;
}

.unmountedRight {
  opacity: 1;
  transform: translateX(0%);
  animation: slideOutRight 0.6s forwards;
}

.mountedLeft {
  opacity: 0;
  transform: translateX(-100%);
  animation: slideInLeft 0.6s forwards;
}

.unmountedLeft {
  opacity: 1;
  transform: translateX(0%);
  animation: slideOutLeft 0.6s forwards;
}

@keyframes slideInRightBBPS {
  0% {
    opacity: 0;
    transform: translateX(50%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes slideOutRight {
  to {
    opacity: 0;
    transform: translateX(100%);
  }
}

@keyframes slideInLeft {
  to {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes slideOutLeft {
  to {
    opacity: 0;
    transform: translateX(-100%);
  }
}

.container-righttoleft-animate-intrtxn {
  animation: loadRigthtoLeft 0.6s ease-out forwards;
}

.container-lefttoright-animate-intrtxn {
  animation: loadLefttoRight 0.6s ease-out forwards;
}

@keyframes loadRigthtoLeft {
  0% {
    transform: translateX(100px);
  }

  100% {
    transform: translateX(0px);
  }
}

@keyframes loadLefttoRight {
  0% {
    transform: translateX(-100px);
  }

  100% {
    transform: translateX(0px);
  }
}

@media screen and (max-width: 800px) {
  .Internal_stepper_Div {
    bottom: 0 !important;
    top: unset !important;
    height: 60px !important;
  }
}

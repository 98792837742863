.benePan_Main {
  width: 100%;
  scroll-snap-align: start;
  display: flex;

  justify-content: space-between;
  align-items: flex-start;
}

.dividepan {
  width: 472px;
  margin: 0px 20px;
}

.pan_Enter {
  height: auto;
  border-radius: 20px;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  transition: all 0.1s ease;
  margin-bottom: 20px;
  box-shadow: 0px 4px 4px 0px #1d1d1d33;
}

.panEdit_Legal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 13px 0px;
  padding: 0px 30px;
}

.panEdit_Legal > span {
  font-size: 12px;
  color: #fefefe;
  margin: 0px 0px 20px 35px;
}

.panEdit_GST {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 13px 0px;
  padding: 0px 24px;
}

.gstMsg {
  width: 472px;
  height: 128px;
  color: #f9f9f9;
  background: #ce720f 0% 0% no-repeat padding-box;
  border-radius: 40px;
  opacity: 1;
  margin-left: 20px;
  margin-bottom: 20px;
  padding: 20px 30px;
}

.panEdit_GST > span {
  font-size: 12px;
  color: #fefefe;
  margin: 1px 5px -30px 35px;
}

.pan_InputEdit {
  background-color: #3a5686;
  height: 125px;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 30px;
  box-shadow: -5px -5px 11px #71717133;
  width: 100%;
}

.pan_Input {
  background-color: #3a5686;
  height: 129px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 30px;
  /* box-shadow: -5px -5px 11px #71717133; */
  /* box-shadow: 0px 4px 4px 0px #1d1d1d33; */
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.gst_Input {
  background-color: #3a5686;
  height: 129px;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: -5px -5px 11px #71717133;
  width: 100%;
}

.gst_det {
  display: flex;
  flex-direction: column;
}

.contain_det {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* padding: 0 20px; */
}

.contain_det_edit {
  display: flex;
  flex-direction: column;
}

.contain_det > p {
  color: #dadada;
  font: normal normal 600 16px/22px Open Sans;
  margin: 20px 0px;
}
.contain_det_edit > p {
  color: #dadada;
  font: normal normal 600 16px/22px Open Sans;
  margin: 20px 0px;
}

.gst_det > p {
  color: #dadada;
  font: normal normal 600 16px/22px Open Sans;
  margin: 20px 0px;
}

.input_Field {
  border: 1px solid #dadada;
  border-radius: 10px;
  position: relative;
  transition: all 0.1s ease;
}

.input_Field > img {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 28%;
  right: 4%;
}

.pan_Legal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 13px 0px;
  padding: 0px 30px;
}

.pan_Legal > span {
  font-size: 12px;
  color: #fefefe;
  margin: 1px 5px -30px 35px;
}

.bene_top_account {
  width: 165px;
  height: 22px;
  text-align: left;
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #fafafa;
  opacity: 1;
  margin-left: 30px;
  margin-bottom: 4px;
  position: relative;
}

.associate-panacc-info-container {
  width: 300px;
  height: 110px;
  padding: 10px;
  background: var(---506994) 0% 0% no-repeat padding-box;
  background: #506994 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  position: absolute;
  left: 160px;
  top: 10px;
  z-index: 0;
}

.associate-panacc-info {
  height: 85px;
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) var(--unnamed-font-size-12) /
    var(--unnamed-line-spacing-17) var(--unnamed-font-family-open-sans);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.mergeEdit_name {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  font-size: 16px;
}

.pan-Name {
  width: 197px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: capitalize;
}

.mergeGst_name {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  font-size: 16px;
}

.mergeEdit_name > img {
  width: 22px;
  height: 22px;
  margin-right: 20px;
}

.mergeGst_name > img {
  width: 21px;
  height: 20px;
  margin-right: 20px;
}

.panEdit_Name {
  text-transform: capitalize;
  margin-left: -10px;
  white-space: normal;
}

.input_Field > label {
  position: absolute;
  color: #dadada;
  margin-left: 15px;
  top: 8px;
  transition: all 0.1s ease;
  background-color: #3a5686;
  padding: 0px 3px;
  cursor: text;
  z-index: 2;
}

.input_Box {
  width: 412px;
  height: 42px;
  background-color: transparent;
  color: #ffffff;
  padding: 10px 20px;
  font-size: 16px;
  line-height: 22px;
}

.pan_Details {
  width: 472px;
  border-radius: 30px;
  min-height: 395px !important;
  background: #3a5686 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 30px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.ass_top {
  width: 472px;
  height: 329px;
}

.achived_main {
  width: 100%;
}

.hidden_accounts {
  margin-left: 30px;
  margin-top: 20px;
}

.hidden_accounts > p {
  width: 375px;
  height: 17px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
}

.hidden_accounts p > span {
  width: 259px;
  height: 19px;
  text-align: left;
  font: normal normal 600 14px/17px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
}

.scroll_All_approval {
  overflow-y: scroll;
  margin-bottom: 15px;
}

.achived_scroll {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 28px;
}

.child_scroll {
  width: 412px !important;
  height: 68px !important;
  border-radius: 20px !important;
  display: flex !important;
  justify-content: flex-start !important;
  padding: 0px !important;
  margin: 0px !important;
  position: relative !important;
  align-items: flex-start !important;
  margin-bottom: 10px !important;
  text-transform: none !important;
}

.child_scroll::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: px;
  background-color: black;
}

.child_scroll:hover {
  background: #506994 0% 0% no-repeat padding-box !important;
}

.bene_view_acc:hover {
  background: #fefefe 0% 0% no-repeat padding-box !important;
  color: #1d3a6d !important;
}

.bank_name {
  width: 73px;
  height: 68px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 18px 0px 0px 18px;
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bank_name > img {
  width: 58px;
  height: 15px;
  opacity: 1;
}

.viewProfile {
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: inherit;
  position: relative;
}

.viewProfile::after {
  content: "";
  position: absolute;
  left: 0;
  top: 11px;
  width: 1px;
  height: 48px;
  background-color: #dadada;
}

.viewProfile::after {
  content: "";
  position: absolute;
  left: 0;
  top: 11px;
  width: 1px;
  height: 48px;
  background-color: #dadada;
}

.viewProfile > p {
  width: 39px;
  height: 9px;
  text-align: left;
  font: normal normal normal 7px/9px Open Sans;
  letter-spacing: 0px;
  color: #fefefe;
  opacity: 1;
  margin-top: 4px;
}

.viewProfile > img {
  width: 25px;
  height: 25px;
  opacity: 1;
}

.client_name {
  height: fit-content;
  margin-top: 13px !important;
  margin-left: 83px !important;
}

.ass_create:hover {
  background: #fefefe 0% 0% no-repeat padding-box !important;
  color: #1d3a6d !important;
}

.name_client2 {
  width: 253px;
  height: 22px;
  text-align: left;
  font: normal normal 600 16px/15px Open Sans;
  letter-spacing: 0px;
  color: #b4b4b4;
  opacity: 1;
  margin-top: 3px;
  margin-bottom: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: capitalize;
}

.bank_clientEdit {
  height: 17px;
  text-align: left;
  font: normal normal normal 12px/12px Open Sans;
  letter-spacing: 0px;
  color: #fafafa;
  opacity: 1;
  width: 232px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.bank_no {
  width: 131px;
  height: 17px;
  text-align: left;
  font: normal normal normal 12px/9px Open Sans;
  letter-spacing: 0px;
  color: #fafafa;
  opacity: 1;
  margin-left: 4px;
}

.achived_main > p {
  margin-left: 30px;
  width: 102px;
  height: 17px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #f9F9F9;
  opacity: 1;
  margin-bottom: 4px;
}

.top_acc {
  width: 158px;
  height: 22px;
  text-align: left;
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #fafafa;
  opacity: 1;
  margin-left: 30px;
  margin-bottom: 4px;
  position: relative;
}

.new_message_text {
  width: 472px;
  height: 116px;
  background: #ce720f 0% 0% no-repeat padding-box;
  border-radius: 30px;
  opacity: 1;
  margin-left: 20px;
  display: flex;
  align-items: center;
  padding: 20px;
  padding-right: 25px;
}

.new_message_text p > span {
  width: 232px;
  height: 22px;
  text-align: left;
  font: normal normal 600 16px/19px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  margin-bottom: 4px;
  display: inline-block;
}

.new_message_text_block_nonarchiveEdit {
  width: 472px;
  height: 85px;
  background: #ce720f 0% 0% no-repeat padding-box;
  border-radius: 40px;
  opacity: 1;
  margin-left: 20px;
  display: flex;
  align-items: center;
  padding: 20px 30px;
  margin-bottom: 20px;
}

.new_message_text_block_nonarchiveEdit > img {
  width: 24px;
  height: 24px;
  margin-right: 20px;
}

.new_message_text_block_nonarchiveEdit > p {
  width: 368px;
  height: 60px;
  text-align: left;
  font: normal normal normal 14px/22px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
}

.new_message_text_block_nonarchiveEdit_associate {
  width: 472px;
  height: 85px;
  background: #ce720f 0% 0% no-repeat padding-box;
  border-radius: 40px;
  opacity: 1;
  margin-left: 20px;
  display: flex;
  align-items: center;
  padding: 20px 30px;
  margin-bottom: 20px;
}

.new_message_text_block_nonarchiveEdit_associate > img {
  width: 24px;
  height: 24px;
  margin-right: 20px;
}

.new_message_text_block_nonarchiveEdit_associate > p {
  width: 368px;
  height: 78px;
  text-align: left;
  font: normal normal normal 14px/22px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
  margin-top: 10px;
}

.new_message_text_block {
  width: 472px;
  height: 85px;
  background: #ce720f 0% 0% no-repeat padding-box;
  border-radius: 40px;
  opacity: 1;
  margin-left: 20px;
  display: flex;
  align-items: center;
  padding: 20px 30px;
  /* margin-bottom: 56px; */
}

.new_message_text_block > img {
  width: 24px;
  height: 24px;
  margin-right: 20px;
}

.new_message_text_block > p {
  width: 368px;
  height: 44px;
  text-align: left;
  font: normal normal normal 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.new_message_text > img {
  width: 24px;
  height: 24px;
  margin-right: 20px;
}

.new_message_text > p {
  width: 388px;
  height: 76px;
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.top_acc > img {
  position: absolute;
  width: 10px;
  height: 10px;
  top: -4px;
  right: 35px;
  cursor: pointer;
}

.swap_butt {
  margin-left: 30px;
  margin-bottom: 10px;
}

.swap_butt_1 {
  width: 58px !important;
  height: 24px !important;
  border-radius: 18px !important;
  text-align: center !important;
  font: normal normal 600 10px/14px Open Sans !important;
  letter-spacing: 0px !important;
  text-transform: capitalize !important;
}

.swap_butt_2 {
  width: 58px !important;
  height: 24px !important;
  margin-left: 10px !important;
  border-radius: 18px !important;
  text-align: center !important;
  font: normal normal 600 10px/14px Open Sans !important;
  letter-spacing: 0px !important;
  text-transform: capitalize !important;
}

.top_con_gst {
  width: 356px;
  height: 18px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #e0e0e0;
  opacity: 1;
  margin-left: 28px;
}

.top_con {
  width: 390px;
  height: fit-content;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #e0e0e0;
  opacity: 1;
  margin-left: 30px;
}

::-webkit-scrollbar {
  width: 9px;
  height: 10px !important;
}

::-webkit-scrollbar-thumb {
  width: 9px;
  height: 20px;
  background: #eeeeee 0% 0% no-repeat padding-box;
  border-radius: 25px;
  opacity: 1;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-corner {
  background-color: #f1f1f1;
}

.ass_bottom {
  width: 472px;
  height: 173px;
  border-radius: 30px;
  padding-top: 22px;
  padding-left: 30px;
  background: #506994 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  z-index: 6;
  margin-top: 20px;
}

.ass_bottom_editFlow {
  width: 472px;
  height: 115px;
  border-radius: 30px;
  padding-top: 20px;
  padding-left: 30px;
  background: #506994 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  z-index: 6;
}

.ass_create {
  width: 412px !important;
  height: 48px !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 24px !important;
  margin-top: 10px !important;
  font-size: 16px !important;
  text-transform: none !important;
}

.ass_create > img {
  width: 25px;
  height: 25px;
  opacity: 1;
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
  border-radius: 50%;
  margin-right: 7px;
}

.ass_arc {
  width: 412px !important;
  height: 48px !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 24px !important;
  margin-top: 10px !important;
  font-size: 16px !important;
  text-transform: none !important;
}

.ass_arc > img {
  width: 25px;
  height: 25px;
  opacity: 1;
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
  border-radius: 50%;
  margin-right: 7px;
}

.ass_arc:hover {
  background: #7bca8b 0% 0% no-repeat padding-box !important;
}

.ass_next:hover {
  background: #7bca8b 0% 0% no-repeat padding-box !important;
}

.plus_Add {
  margin-right: 20px;
  font-size: 28px;
  font-weight: 300;
}

.ass_bottom > p {
  font-size: 12px;
  color: #ffffff;
}

.ass_bottom_editFlow > p {
  font-size: 12px;
  color: #ffffff;
}

.ass_next > p {
  font-size: 12px;
  color: #ffffff;
}

.ass_next > img {
  width: 25px;
  height: 25px;
  opacity: 1;
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
  border-radius: 50%;
  margin-right: 7px;
}

.ass_next {
  width: 412px !important;
  height: 48px !important;
  background: #63ba74 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 24px !important;
  color: #ffffff !important;
  margin-top: 10px !important;
  font-size: 16px !important;
  text-transform: none !important;
}

.bene_view_acc {
  width: 412px !important;
  height: 48px !important;
  background: #8696b5 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 24px !important;
  color: #ffffff !important;
  margin-top: 10px !important;
  font-size: 16px !important;
  text-transform: none !important;
}

.pan_in_bottom {
  margin-top: 30px;
  width: 471px;
  height: 84px;
  background: #bea542 0% 0% no-repeat padding-box;
  border-radius: 30px;
  display: flex;
  align-items: center;
}

.pan_in_bottom > p {
  font-size: 16px;
  color: #ffffff;
  margin-right: 20px;
}

.pan_in_bottom img {
  width: 24px;
  height: 24px;
  background: #bea542 0% 0% no-repeat padding-box;
  margin: 0px 20px;
}

.pan_in_bottom2 {
  margin-top: 30px;
  width: 471px;
  height: 139px;
  background: #bea542 0% 0% no-repeat padding-box;
  border-radius: 30px;
  display: flex;
}

.status_para {
  font-size: 16px;
  color: #ffffff;
  margin: 20px 56px 20px 0px;
}

.status_image {
  width: 24px;
  height: 24px;
  background: #bea542 0% 0% no-repeat padding-box;
  margin: 35px 23px 0px 24px;
}

.wrong_panEdit {
  display: flex;
  align-items: center;
  height: 55px;
}

.wrong_panEdit > img {
  width: 20px;
  height: 20px;
  margin: 0px 10px 0px 30px;
}

.wrong_panEdit > span {
  font: normal normal normal 16px/16px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
}

.look_loading_div {
  width: 472px;
  height: 520px;
  margin: 0px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pan_in_bottom {
  margin-top: 30px;
  width: 471px;
  height: 84px;
  background: #bea542 0% 0% no-repeat padding-box;
  border-radius: 30px;
  display: flex;
  align-items: center;
}

.pan_in_bottom > p {
  font-size: 16px;
  color: #ffffff;
  margin-right: 20px;
}

.pan_in_bottom img {
  width: 24px;
  height: 24px;
  background: #bea542 0% 0% no-repeat padding-box;
  margin: 0px 20px;
}

.pan_in_bottom2 {
  margin-top: 30px;
  width: 471px;
  height: fit-content;
  background: #bea542 0% 0% no-repeat padding-box;
  border-radius: 30px;
  display: flex;
  align-items: flex-start;
}

.pan_in_bottom2 > p {
  font-size: 16px;
  color: #ffffff;
  margin-right: 20px;
  margin: 20px 0px;
  margin-right: 56px;
}

.pan_in_bottom2 > img {
  width: 24px;
  height: 24px;
  background: #bea542 0% 0% no-repeat padding-box;
  margin: 35px 23px 0px 24px;
}

.pan_new_crt_acc_bottom {
  display: flex;
  width: 472px;
  height: auto;
  border-radius: 30px;
  padding: 26px;
  background: #3a5686 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
}

.bank_new_create_btn:hover {
  color: #f9f9f9 !important;
  background: #f9f9f912 0% 0% no-repeat padding-box !important;
}

.css-98ecne-MuiTypography-root {
  text-transform: capitalize !important;
}

.dividepan .MuiFormControl-root .MuiOutlinedInput-root {
  border-radius: 10px;
}

/* .vendor-txn {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5em;
  padding: 1% 5%;
  overflow: hidden;
  overflow-y: scroll;
  height: 90%;
} */

.vendor-txn::-webkit-scrollbar {
  width: 5px;
  height: 25px;
}

.vendor-txn::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

.vendor-txn::-webkit-scrollbar-thumb {
  background-color: #b4b4b4;
  outline: 1px solid #314D7E;
  border-radius: 25px;
  height: 75px;
}

 .account_type::-webkit-scrollbar-thumb {
  background-color: white;
 }
.multiTxnDetailsMain {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.multiTxnContainer {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 300px;
  background: #3a5686 0% 0% no-repeat padding-box;
  padding-top: 105px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.multiSelectDataCard {
  height: 100%;
  overflow-y: scroll;
  position: relative;
  bottom: 0;
}

.multiSelectTopContainer {
  top: 120px;
  left: 171px;
  width: 1023px;
  height: 90px;
  opacity: 1;
  border-bottom: 1px solid #1d3a6d;
  display: flex;
}

.multiSelectPostApprovalContainer {
  width: 1024px;
  height: 300px;
  display: flex;
  justify-content: space-between;
}

.insufficientMsg {
  width: 100%;
  height: 28px;
  text-align: left;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
}

.multiSelectPostApprovalText {
  width: 257px;
  height: 19px;
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
  white-space: nowrap;
  margin-top: 26px;
  margin-bottom: 4px;
}

.multiSelectCancelBtn {
  width: 102px !important;
  height: 90px !important;
  background: #506994 0% 0% no-repeat padding-box !important;
  border-radius: 0 !important;
  text-transform: none !important;
}

.multiSelectCancelBtn:hover {
  background: #63799f !important;
}

.multiBtnCommon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.multiBtnCommon > img {
  width: 29px;
  height: 29px;
  opacity: 1;
  margin-bottom: 4px;
}

.multiBtnCommon > p {
  width: 40px;
  height: 14px;
  text-align: center;
  font: normal normal normal 10px/14px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
}

.multiAmountOuter {
  height: 90px;
  width: 717px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 20px;
}

.multiSelectRejectBtn {
  width: 102px !important;
  height: 90px !important;
  background: #f05a6a 0% 0% no-repeat padding-box !important;
  text-transform: none !important;
  border-radius: 0 !important;
}

.multiSelectRejectBtnDisabled:hover {
  background: #cc4a51 0% 0% no-repeat padding-box !important;
}

.multiSelectApproveBtn {
  width: 102px !important;
  height: 90px !important;
  background: #69b76f 0% 0% no-repeat padding-box !important;
  text-transform: none !important;
  border-radius: 0 !important;
}

.multiSelectApproveBtnDisabled:hover {
  background: #59a25f 0% 0% no-repeat padding-box !important;
}

.multiSelectAmountInner {
  width: 108px;
  height: 22px;
  text-align: left;
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #4ddd37;
  opacity: 1;
  margin-bottom: 4px;
}

.multiSelectTextInner {
  width: 114px;
  height: 17px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
}

.multiSelectDataCard > h4 {
  width: 90%;
  height: 22px;
  text-align: left;
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
  margin: 20px auto;
  white-space: nowrap;
}

.multiSelctedTxnMainContainer {
  width: 90%;
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 auto;
}

.multiSelectApprovalContainer {
  width: 254px;
  height: 150px;
  background: #506994 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  margin-right: 20px;
  margin-bottom: 20px;
  position: relative;
}

.mutliSelectCardTop {
  height: 89px;
  background: #3a5686 0% 0% no-repeat padding-box;
  border-radius: 10px 10px 0px 0px;
  opacity: 1;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 10px;
}

.multiLowerBottom {
  padding: 10px 10px 10px 10px;
}

.mutliSelectCardTop > h4 {
  width: 99px;
  text-align: left;
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #4ddd37;
  opacity: 1;
}

.mutliSelectCardTop > h5 {
  width: 185px;
  text-align: left;
  font: normal normal normal 14px/26px Open Sans;
  letter-spacing: 0px;
  color: #fafafa;
  text-transform: lowercase;
  opacity: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.amount-style {
  width: 99px;
  text-align: left;
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #4ddd37;
  opacity: 1;
}

.mutliSelectCardTop > h5::first-line {
  text-transform: capitalize;
}

.multiSelectBottomDate {
  display: flex;
  justify-content: space-between;
}

.multiSelectBottomDate > p {
  width: 76px;
  height: 14px;
  text-align: right;
  font: normal normal 600 10px/14px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
  white-space: nowrap;
}

.moreDetailsBottom {
  width: 94px !important;
  height: 22px !important;
  background: #7286a7 0% 0% no-repeat padding-box !important;
  border-radius: 5px 0px 0px 0px !important;
  opacity: 1;
  display: flex !important;
  justify-content: space-around !important;
  align-items: center !important;
  cursor: pointer !important;
}

.moreDetailsBottom > h6 {
  width: 58px !important;
  height: 14px !important;
  text-align: left !important;
  font: normal normal normal 10px/14px Open Sans !important;
  letter-spacing: 0px !important;
  color: #f9f9f9 !important;
  text-transform: none !important;
}

.moreDetailsBottom > img {
  width: 10px;
  height: 10px;
  opacity: 1;
}

.refreshBottom {
  display: flex;
  padding-right: 20px;
  align-items: center;
  align-items: flex-start;
}

.multiSelctBottomContainer > h4 {
  width: 79px;
  height: 17px;
  text-align: left;
  font: normal normal 600 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
  white-space: nowrap;
  margin-bottom: 4px;
}

.multiSelctBottomContainer > h5 {
  width: 72px;
  height: 14px;
  text-align: left;
  font: normal normal normal 10px/14px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
  white-space: nowrap;
}

.Owner_Balance {
  height: 25px;
  display: flex;
  overflow: hidden;
}

.balanceTitle {
  width: 20px;
  position: relative;
  z-index: 9;
}

.balanceTitle p {
  height: 18px;
}

.balanceText > .Owner_Balance > .name1 > h4 {
  width: auto;
  height: 17px;
  text-align: left;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
  margin-bottom: 4px;
}

.balanceText > h5 {
  height: 14px;
  text-align: left;
  font: normal normal normal 10px/14px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
}

.multiRefreshButton {
  border-left: 1px solid #dadada;
  margin-top: 3px;
  width: 90px !important;
  height: 10px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.multiRefreshButton > img {
  width: 12px;
  height: 12px;
  opacity: 1;
  margin-right: 4px;
}

.multiRefreshButton > h6 {
  width: 37px !important;
  text-align: left !important;
  font: normal normal 600 10px/26px Open Sans !important;
  letter-spacing: 0px !important;
  color: #dadada !important;
  text-transform: none !important;
}

.associatebutton-acc {
  margin-left: 30px;
  margin-bottom: 10px;
}

.associatebuttonPan-acc {
  width: auto !important;
  height: 24px !important;
  border-radius: 18px !important;
  text-align: center !important;
  font: normal normal 600 10px/14px Open Sans !important;
  letter-spacing: 0px !important;
  text-transform: none !important;
}

.associatebuttonBank-acc {
  width: auto !important;
  height: 24px !important;
  margin-left: 10px !important;
  border-radius: 18px !important;
  text-align: center !important;
  font: normal normal 600 10px/14px Open Sans !important;
  letter-spacing: 0px !important;
  text-transform: none !important;
}

/* .custom-scroll-container-acc::-webkit-scrollbar {
    width: 5px;
}

.custom-scroll-container-acc::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 8px;
}

.custom-scroll-container-acc::-webkit-scrollbar-thumb {
    background-color: #dadada;
    border-radius: 8px;
}

.custom-scroll-container-acc::-webkit-scrollbar-thumb:hover {
    background-color: #dadada;
    border-radius: 8px;
} */

.label-container {
  color: #dadada;
}

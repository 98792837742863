.editPanMain {
  width: 560px;
  height: 211px;
  /* UI Properties */
  background: #153164 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  opacity: 1;
}

.editPanMain_1 {
  width: 500px;
  height: 27px;
  text-align: left;
  font: normal normal 600 20px/27px Open Sans;
  letter-spacing: 0px;
  color: #ff7f33;
  opacity: 1;
  margin-top: 30px;
  margin-bottom: 20px;
  margin-left: 30px;
}

.editPanMain_2 {
  width: 500px;
  height: 22px;
  /* UI Properties */
  text-align: left;
  font: normal normal normal 12px/22px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-bottom: 50px;
  margin-left: 30px;
}

.editPanMain_but2 {
  text-transform: none !important;
  width: 100px !important;
  height: 42px !important;
  /* UI Properties */
  background: #69b76f 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #00000033 !important;
  color: #ffffff !important;
  border-radius: 20px !important;
}

.editPanMain_but1 {
  text-transform: none !important;
  margin-left: 351px !important;
  margin-right: 20px !important;
  width: 50px !important;
  height: 22px !important;
  /* UI Properties */
  text-align: center !important;
  text-decoration: underline !important;
  font: normal normal normal 16px/14px Open Sans !important;
  letter-spacing: 0px !important;
  color: #ffffff !important;
}

@media screen and (max-width: 800px) {
  .alert-popup-cancle {
    background-color: #314D7E;
    display: flex;
    flex-direction: column;
    padding: 30px 15px 30px 15px;
    gap: 20px;
    border-radius: 20px 20px 0 0;
    position: fixed;
    bottom: 0;
  }
  .alert-title {
    color: #FF7F33;
    max-width: 330px;
    font-size: 16px;
    font-weight: 600;
    line-height: 21.79px;
  }
  .alert-boby-content {
    color: #FFFFFF;
    max-width: 309px;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.07px;
  }
}

@media screen and (max-width: 400px){
  .mobile-responsive-edit-alert-popup {
    width: 350px;
  }
}
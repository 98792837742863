.loader-icon {
  width: 30px;
  height: 14px;
  opacity: 1;
}

.checkmark-icon {
  width: 15px;
  height: 15px;
  margin-left: 4px;
  opacity: 1;
}

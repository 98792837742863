.internalTxnViewallCard {
  display: flex;
  flex-wrap: wrap;
  width: 93%;
  margin: 0 auto;
  margin-top: 15px;
  /* gap: 20px; */
}

.multiSelectInternalTxnViewAllCard {
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 10px;
  grid-column: span 1;
  height: 102px;
  /* margin: 10px ; */
  background-color: var(--Blue_6);
  width: 255px;
  transition: height 0.1s;
}

.internalTxn_viewall_inner_card_container {
  width: 255px;
}

.internalTxn_card-container {
  position: relative;
  width: 255px;
  height: 98px;
}

@media screen and (min-width: 3800px) and (min-height: 1920px) {
  .internalTxnViewallCard {
    width: 85% !important;
  }

  .multiSelectInternalTxnViewAllCard:hover,
  .multiSelectInternalTxnViewAllCard,
  .internalTxn_card-container {
    width: 260px !important;
  }
}

@media screen and (min-width: 2560px) and (min-height: 1440px) {
  .internalTxnViewallCard {
    width: 95% !important;
  }

  .multiSelectInternalTxnViewAllCard:hover,
  .multiSelectInternalTxnViewAllCard,
  .internalTxn_card-container {
    width: 260px !important;
  }
}

@media screen and (min-width: 1920px) and (min-height: 1080px) {
  .internalTxnViewallCard {
    width: 85% !important;
  }

  .multiSelectInternalTxnViewAllCard:hover,
  .multiSelectInternalTxnViewAllCard,
  .internalTxn_card-container {
    width: 240px !important;
  }

  .internalTxn_viewall_inner_card_container {
    width: 240px;
  }
}

@media screen and (min-width: 1400px) and (min-height: 960px) {
  .internalTxnViewallCard {
    width: 95%;
  }

  .multiSelectInternalTxnViewAllCard:hover,
  .multiSelectInternalTxnViewAllCard,
  .internalTxn_card-container {
    width: 250px !important;
  }

  .internalTxn_viewall_inner_card_container {
    width: 250px;
  }
}

@media screen and (min-width: 1300px) and (max-width: 1400px) {
  .internalTxnViewallCard {
    width: 96%;
  }

  .multiSelectInternalTxnViewAllCard:hover,
  .multiSelectInternalTxnViewAllCard,
  .internalTxn_card-container {
    width: 240px !important;
  }

  .internalTxn_viewall_inner_card_container {
    width: 239px;
  }
}

@media screen and (max-width: 800px) {
  .multiSelectInternalTxnViewAllCard {
    width: 100%;
  }

  .internalTxn_card-container {
    width: 100%;
  }

  .internalTxn_viewall_inner_card_container {
    width: 100%;
  }

  .internalTxn_viewall_bank_acc_div {
    width: 100% !important;
  }

  /* .multiSelectInternalTxnViewAllCard:hover {
    z-index: 99;
  } */
}

.internalTxn_viewall_acc_title {
  color: var(--Primary_grey);
  font-size: 10px;
  margin-top: 5px;
  font-weight: 600;
}

.internalTxn_viewall_bank_acc_div {
  justify-content: space-between;
  display: flex;
  color: var(--Primary_white);
  font-size: 14px;
  padding: 0px 8px 8px 8px;
}

.internalTxn_viewall_name_div {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px 5px 10px;
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: var(--Settled);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.internalTxn_view_all_user {
  white-space: nowrap;
  /* width: 14rem; */
  overflow: hidden;
  text-overflow: ellipsis;
}

.internalTxn_viewall_box_status {
  width: 5px;
  height: 15px;
  background-color: var(--Settled);
  border-radius: 0px 5px 0px 0px;
  top: 0px;
  position: absolute;
  right: 0px;
}

.internalTxn_viewall_arrow_icon {
  height: 17px;
  margin: 2px;
  width: 18px;
}

/* .internalTxn_account {
  width: 57px;
  height: 19px;
} */

.internalTxn_viewall_assigned {
  display: none;
  height: 15px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  text-transform: none;
  padding-left: 10px;
  padding-right: 10px;
}

.internalTxn_viewall_label_div {
  display: flex;
  height: 20px;
  right: 0px;
  position: absolute;
  bottom: 65px;
}

.css-wdslgk {
  padding-right: 0 !important;
}

@keyframes slideOutRightToLeft {
  0% {
    visibility: visible;
    transform: translateX(0);
  }

  100% {
    visibility: hidden;
    transform: translateX(-10%);
  }
}

.internalTxn_viewall_card_container {
  margin-right: 0px !important;
  width: 90px !important;
  height: 26px !important;
}

.internalTxn_viewall_hover_content {
  display: none;
  border-top: 1px solid var(--Blue_3);
}

.multiSelectInternalTxnViewAllCard:hover {
  position: absolute;
  border: 1px solid var(--Failed);

  height: 199px !important;
  transition: height 0.2s;
  z-index: 998;
}

.multiSelectInternalTxnViewAllCard:hover .internalTxn_viewall_hover_content {
  margin-top: 10px !important;
  display: block;
  transition: all 0.2s ease 0.2s;
}

@keyframes growHeight {
  from {
    height: 0px;
  }

  to {
    height: 100px;
  }
}

.internalTxn_viewall_category_div {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 2px solid #71717133;
}

.internalTxn_viewall_payment_mode_div {
  /* width: "50%"; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px !important;
  height: 26px !important;
}

.internalTxn_viewall_paymentmode_image {
  object-fit: contain;
  width: 35px;
  height: 10px;
}

.multiSelectInternalTxnViewAllCard.disabled {
  z-index: 0;
  cursor: default;
  background-color: var(--Primaery_black);
  overflow-y: hidden;
}

.internalTxn_logo {
  border-radius: 20px 0px 0px 20px;
}

.internalTxn_logofirst {
  border-radius: 0px 20px 20px 0px;
}

.multiSelectInternalTxnViewAllCard:hover
  .internalTxn_logo
  .internalTxn_logofirst {
  border-radius: 20px 0px 0px 20px;
}

.container-options {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  height: 2.5em;
  padding: 2.5%;
  border-radius: 30px;
  background-color: white;
}
.container-text {
  text-align: left;
  font: normal normal 600 13px/18px Open Sans;
  letter-spacing: 0px;
  color: #4e4a5e;
  opacity: 1;
}

.dashboard-card {
  width: 315px;
  position: relative;
}

.bank-logo {
  position: absolute;
  /* width: 30%;
  margin-right: -6px;
  margin-top: -4px; */
  width: 100px;
  bottom: 0;
  right: 0;
}

/* @media (min-width: 1280px) {
  .bank-logo {
    margin-right: -10px;
    margin-bottom: -6px;
  }
}

@media (min-width: 1440px) {
  .bank-logo {
    margin-right: -12px;
    margin-bottom: -8px;
  }
}

@media (min-width: 1600px) {
  .bank-logo {
    margin-right: -14px;
    margin-bottom: -10px;
  }
} */

.bank-logo .card-logo {
  border-radius: 0 0 20px 0; /* Equivalent to "rounded-bl-3xl 5xl:rounded-bl-large" */
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.25); /* Equivalent to "drop-shadow-xl" */
}

.balance-amt {
  text-align: left;
  font: normal normal 600 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #dedede;
  opacity: 1;
}

.balance-text {
  text-align: left;
font: normal normal normal 10px/14px Open Sans;
letter-spacing: 0px;
color: #C9C9C9;
opacity: 1;
margin-top: 5px;
}
.recentTransactionsViewAllInternal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;
  opacity: "1";
  box-shadow: 5px 5px 11px #00000033;
  background: #3a5686;
  width: 450px;
  height: 150px;
  border-radius: 40px;
  flex-shrink: 1;
}
.recentTransactionsViewAllInternal:hover {
  background-color: #5676ad;
}

.ViewAll-amount-div {
  margin-left: 5%;
  text-align: left;
  font: normal normal 600 24px/33px Open Sans;
  letter-spacing: 0px;
  color: #b196fa;
  opacity: 1;
}

.upper-internal-div {
  margin-top: 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  visibility: visible;
  opacity: 1;
  transition: all 1s ease-in;
}

.middle-acc-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  padding: 0 20px 0 20px;
}

.upper-div:hover .ViewAll-amount-div {
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease-out;
}
.upper-div:hover .date-div  {
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease-out;
}
.upper-div:hover .status-text  {
  display: inline;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
  transform: translateX(0px);
  transition: all 1s ease-in;
}

.date-div {
  width: 107px;
  height: 19px;
  text-align: right;
  font: normal normal 600 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #b0bbce;
  opacity: 1;
}

.status-text{
  display: none;
  transform: translateX(100px);
}

.logo-div-viewall-source {
  background: white;
  height: 42px;
  width: 130px;
  border-radius: 0px 30px;
  align-self: flex-end;
}

.logo-div-viewall-dest {
  background: white;
  height: 42px;
  width: 130px;
  border-radius: 30px 0px;
  align-self: flex-end;
}

.logo {
  margin-top: 8px;
}

.amount-div {
  height: 28px;
  font-family: Open Sans, Semibold;
  font-size: 20px;
  text-align: right;
}

.ViewAllaccount-div {
  padding-left: 5%;
  height: 27px;
  width: 150px;
  color: #fafafa;
  font-family: Open Sans, Regular;
  font-size: 20px;
  text-align: center;
}

.LogosInternal {
  display: flex;
  align-self: flex-end;
  gap: 42%;
  width: 100%;
}

.source-account-div-internal {
  height: 27px;
  width: 91px;
  color: #fafafa;
  font-family: Open Sans, Regular;
  font-size: 20px;
  text-align: center;
}
.dest-account-div-internal {
  height: 27px;
  width: 91px;
  color: #fafafa;
  font-family: Open Sans, Regular;
  font-size: 20px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .recentTransactionsViewAllInternal {
    flex-grow: 1;
    flex-shrink: 1;
  }
}


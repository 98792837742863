.calendarWrap {
  width: 446px;
  height: 91px;
  background: #3a5686 0% 0% no-repeat padding-box;
  border-radius: 47px;
  opacity: 1;
  display: flex;
  box-shadow: 5px 5px 11px #00000033;
}

.inputBox {
  width: 80%;
  margin: auto;
  box-shadow: none;
  background: #3a5686 0% 0% no-repeat padding-box;
  color: white;
  font: normal normal normal 28px Open Sans;
  letter-spacing: 0px;
  color: #f05a6a;
  border-width: 0;
}

.calendarElement {
  background: #3a5686 0% 0% no-repeat padding-box;
  font: normal normal normal 16px Open Sans;
  position: sticky;
  z-index: 2000;
  box-shadow: 5px 5px 11px #00000033;
  border: 1px solid #f05a6a;
  border-radius: 20px;
}

.rdrDateDisplayWrapper {
  background: #3a5686 0% 0% no-repeat padding-box;
  border-radius: 47px;
}

.rdrDateInput {
  background: #3a5686 0% 0% no-repeat padding-box;
  border-color: white;
}

.rdrDateInput > input {
  font: normal normal normal 16px Open Sans;
  color: white;
}
.rdrMonthPicker > select {
  color: white;
}

.rdrYearPicker > select {
  color: white;
}

.rdrDayNumber > span {
  color: white;
}

.rdrDayPassive .rdrDayNumber > span {
  opacity: 0.5;
}

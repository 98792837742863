.main-container-profile {
  width: 100%;
  background-color: #3a5686;
  height: 391px;
}

.upperDiv {
  background-color: #3a5686;
  max-width: 1024px;
  margin: auto;
  display: flex;
  padding-top: 110px;
}

.upperDiv_responsive {
  background-color: #3a5686;
  max-width: 1024px;
  margin: auto;

  padding-top: 15px;
}

.Profile-title {
  padding-left: 30px;
  padding-right: 20px;
}

.profile-icon img {
  height: 50px;
  /* box-shadow: -5px -5px 11px #71717140; */
  opacity: 1;
}

.Profile-title h1 {
  color: #ffffff;
}

.horizontal-Line {
  padding-bottom: 14px;
  border-bottom: 2px solid #1d3a6d;
  color: #c9c9c9;
}

.horizontal-Line_responsive {
  padding-bottom: 2px;
  color: #c9c9c9;
}

.right-side {
  width: 565px;
  height: 281px;
}

.left-side {
  width: 498px;
  height: 279px;
}

.left-side img {
  opacity: 1;
  width: 100%;
}

.verifield {
  position: relative;
  /* width: auto; */
  background: #314d7e 0% 0% no-repeat padding-box;
  box-shadow: -5px -5px 11px #71717133;
  border-radius: 20px;
  /* opacity: 0.8; */
}

.verifield_responsive {
  position: relative;
  width: 100%;
  background: #314d7e 0% 0% no-repeat padding-box;
  box-shadow: -5px -5px 11px #71717133;
  margin-left: 0px;
}

.upperContent {
  padding-top: 13px;
  display: flex;
  justify-content: space-between;
}
.upperContent p {
  color: #c9c9c9;
}
.middleContent {
  padding-top: 13px;
  display: flex;
  justify-content: space-between;
}
.middleContent p {
  color: #c9c9c9;
}

.firstHalf {
  color: #ffffff;
}

.secondHalf {
  display: flex;
  color: #c9c9c9;
  justify-content: space-between;
  margin-right: 2px;
}

.lastContent {
  padding-top: 13px;
  width: 100%;
}

.lastContent p {
  color: #c9c9c9;
}

.bottomDiv {
  height: auto;
  background-color: #1d3a6d;
  max-width: 1024px;
  margin: auto;

  display: flex;
  padding-top: 35px;
  justify-content: space-between;
}

.ver-detail {
  padding: 20px 50px 0px 30px;
  gap: 20px;
}

.ver-detail h1 {
  color: #dadada;
  padding-bottom: 8px;
}
.detail-css {
  /* width: 271px; */
  width: auto;
}

.detail-css p {
  border-bottom: 1px solid #1d3a6d;
  padding-bottom: 10px;
  color: #c9c9c9;
}
.detail-css span {
  padding-top: 5px;
  color: #ffffff;
}

.legalName {
  padding-top: 10px;
  text-transform: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 271px;
  color: #ffffff;
}

.EditDetailsResponsive {
  margin-top: 4px;
  background-color: #031f4f;
  border-radius: 5px;
  height: 22px;
  width: 22px;
  margin-left: 180px !important;
  display: flex;
  justify-content: space-between;
}

@media (min-width: 321px) and (max-width: 1024px) {
  .EditDetailsResponsive {
    margin-left: 300px;
  }
}

@media (max-width: 1024px) {
  .legalName {
    /* width: 328px; */
  }
}

@media (max-width: 320px) {
  .legalName {
    width: 200px;
  }
}

.legalName span {
  color: #ffffff;
  padding-top: 5px;
}

.legalName p {
  border-bottom: 1px solid #1d3a6d;
  padding-bottom: 10px;
  color: #c9c9c9;
}

.GstNum {
  padding-top: 10px;
}

.GstNum span {
  color: #ffffff;
  padding-top: 5px;
}

.GstNum p {
  padding-bottom: 10px;
  color: #c9c9c9;
}

.profileAction {
  padding-top: 20px;
}

.profileAction h1 {
  color: #dadada;
  border-bottom: 1px solid #1d3a6d;
  padding-bottom: 12px;
  padding-left: 30px;
}

.profileAction_responsive h1 {
  color: #dadada;
  border-bottom: 1px solid #1d3a6d;
  padding-bottom: 15px;
  padding-top: 15px;
  padding-left: 30px;
  display: flex;
  background-color: #506994;
  align-items: center;
}

.UpperSection {
  width: 100%;
  height: 100px;
  border-bottom: 1px solid #1d3a6d;
}

.UpperSection_responsive {
  width: 100%;

  border-bottom: 1px solid #1d3a6d;
}

.changePassword {
  width: 33%;

  padding-top: 10px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  cursor: pointer;
  align-items: center;
}

.LowerSection .changePassword {
  border-radius: 0px 0px 0px 20px;
}

.changePassword::after {
  content: "";
  border-right: 1px solid #1d3a6d;
  position: absolute;
  height: 80%;
  right: 0;
  top: 10%;
}

.changePassword img {
  width: 30px;
  height: 30px;
  text-align: center;
  margin-bottom: 3px;
  /* margin-left: 28px; */
}
.changePassword p {
  color: #ffffff;
  text-align: center;
  font-size: small;
}
.Logout {
  width: 33%;
  /* padding-left: 13px;
  padding-top: 15px;
  margin-right: 3px; */
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Logout::after {
  content: "";
  border-right: 1px solid #1d3a6d;
  position: absolute;
  height: 80%;
  right: 0;
  top: 10%;
}
.Logout img {
  width: 30px;
  height: 30px;
  text-align: center;
  margin-bottom: 3px;
  /* margin-left: 28px; */
}
.Logout p {
  color: #ffffff;
  text-align: center;
  font-size: small;
  padding-top: 5px;
  padding-right: 5px;
}
.EditProfile {
  width: 33%;
  padding-top: 15px;
  cursor: pointer;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  /* position: relative; */
}

.LowerSection .EditProfile {
  border-radius: 0px 0px 20px 0px;
}

.EditProfile img {
  width: 30px;
  height: 30px;
  text-align: center;
  margin-bottom: 3px;
  /* margin-left: 38px; */
}
.EditProfile p {
  color: #ffffff;
  text-align: center;
  font-size: small;
}
.LowerSection {
  width: 100%;
  height: 90px;
}

.full-page {
  width: 100%;
  height: auto;
  margin-top: 76px;
  overflow: unset;
}

.bene-detail-loader-box {
  img {
    width: 49px;
    height: 50px;
  }
}

.split-one {
  width: 100%;
  /* height: 54vh; */
  background: #1d3a6d 0% 0% no-repeat padding-box;
  opacity: 1;
}

.split-two {
  width: 100%;
  height: 36vh;
  background: #3a5686 0% 0% no-repeat padding-box;
  box-shadow: 5px 5px 11px #00000026;
  opacity: 1;
}

.profile {
  height: 30%;
  background-color: #1d3a6d;
  max-width: 1024px;
  margin: auto;
  /* margin-top: 150px; */
  padding-top: 75px;
}

.location {
  height: auto;
  background-color: #3a5686;
  max-width: 1024px;
  margin: auto;
  margin-top: 15px;
}

.corp-name {
  width: 166px;
  height: 27px;
  text-align: left;
  font: normal normal 600 20px/27px Open Sans;
  letter-spacing: 0px;
  color: #fafafa;
  opacity: 1;
}

.corp-name-main {
  width: 380px;
  height: 22px;
  text-align: left;
  font: normal normal Open Sans;
  letter-spacing: 0px;
  color: #fafafa;
  opacity: 1;
  white-space: nowrap;
  text-transform: capitalize !important;
}

.corp-sub-name {
  width: 380px;
  /* height: 49px; */
  text-align: left;
  font: normal normal Open Sans;
  letter-spacing: 0px;
  color: #fafafa;
  opacity: 1;
}

.corp-name-label {
  width: 200px;
  height: 6px;
  text-align: left;
  font: normal normal Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
}

.parent-div {
  display: flex;
}

.top-bottom-spacing {
  margin-top: 20px;
}

.corp-divide-one {
  flex: 60%;
}

.corp-divide-two {
  flex: 40%;
}

.title-label-block {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 20px;
}

.title-padding {
  margin-right: 20px;
}

.title-padding-add {
  height: 40px;
}

.title-padding-gst {
  height: 60px;
  width: 60px;
}

.row-sub-name {
  display: flex;
  flex-direction: row;
}

.row-sub-name-pad {
  margin-left: 20px;
}

.location-icon {
  margin-top: 14px;
}

.address-corp {
  margin-top: 8px;
}

.geo-map {
  width: 332px;
  height: 239px;
}

.geo-map-pad {
  margin: 20px 0px 20px 0px;
  margin-left: 40px;
}

.nav-pan-btn {
  display: flex;
  flex-direction: row;
}

.nav-pan-style {
  background: #12284f 0% 0% no-repeat padding-box;
  border-radius: 6px;
  margin-left: 50px;
}

.nav-pan-padding-add {
  height: 48px;
  width: 200px;
}

.div-fixed-gst {
  height: auto;
  background: #1d3a6d 0% 0% no-repeat padding-box;
  margin-top: 76px;
}

.div-auto-gst {
  flex-grow: 1;
  background: #3a5686 0% 0% no-repeat padding-box;
  width: 100%;
}

.vendorApproval {
  height: 100vh;
  overflow: hidden;
  background-color: #364949;
  /* padding-top: 50px; */
}

.showMultipleBT {
  width: 45px;
  height: 45px;
  background: #3e5555 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 5px 20px #00000033 !important;
  border-radius: 10px !important;
  margin-right: 10% !important;
  position: absolute !important;
  top: 110px;
  z-index: 100;
}

.selectTxnSearchImg {
  width: 25px;
  height: 25px;
}

@media only screen and (max-width: 480px) {
  .showMultipleBT {
    display: none !important;
  }
}

#internalTxnSingleApproval {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  height: 100vh;
  position: relative;
}
#internalTxnViewall {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  overflow: auto;
  height: 100%;
  width: 100%;
}
.internalToggleBtn {
  border-radius: 5px;
  padding: 8px;
  height: 34px !important;
  width: 34px !important;
}
.internalTxnToggleContainer {
  margin-left: 15px;
  margin-top: 25px;
  height: 32px !important;
  width: 67px !important;
  display: flex;
  justify-content: space-around;
  cursor: pointer;
  background: #244277;
  border-radius: 5px;
  opacity: 1;
}

@media (min-width: 2560px) and (max-width: 2560px) and (min-height: 1440px) and (max-height: 1440px) {
  #internalTxnViewall {
    margin-top: 30px;
  }
}

@media (min-width: 3840px) and (max-width: 3840px) and (min-height: 1920px) and (max-height: 1920px) {
  #internalTxnViewall {
    margin-top: 20px;
  }
}

@media (min-width: 1440px) and (max-width: 1440px) and (min-height: 960px) and (max-height: 960px) {
  #internalTxnViewall {
    margin-top: 40px;
  }
}

@media (min-width: 1920px) and (max-width: 1920px) and (min-height: 1080px) and (max-height: 1080px) {
  #internalTxnViewall {
    margin-top: 40px;
  }
}

.filter-main {
  height: 34px !important;
  width: 58px !important;
  padding-top: 10px;
  display: flex;
  justify-content: space-around;
  cursor: pointer;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  opacity: 1;
}

.filter-main:hover {
  background-color: #506994;
}

.filter-images-element {
  width: 14px !important;
  height: 14px;
}

.button_container_div {
  display: flex;
  margin-top: 55px !important;
}

.filterdropdown-div {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1024px !important;
  margin: 0 auto;
  padding-top: 20px;
  z-index: 1;
}

@keyframes growDown {
  0% {
    transform: scaleY(0);
  }

  100% {
    transform: scaleY(1);
  }
}

.internalTxnApprovalFirstContainer {
  width: 341px;
  height: 220px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.internalTxnApprovalFirstContainer .BeneTxn_ApprovalTxn_SectionPage {
  width: 100%;
  height: 100%;
}

.BeneTxn_ApprovalFirstDivision {
  display: flex;
  justify-content: space-between;
  width: 301px;
  height: 43px;
  opacity: 1;
  margin-left: 20px;
  margin-top: 20px;
}

.internal_AwaitingImg {
  display: flex;
  justify-content: center;
  width: 41px;
  align-items: center;
  height: 67px;
  margin-top: 20px;
}
.internal_AwaitingImg img {
  opacity: 1;
}

.internal_AwaitingVrfTxt {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 234px;
  height: 67px;
  opacity: 1;
  margin-top: 30px;
}
.internal_DistributionTxt {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 234px;
  height: 67px;
  opacity: 1;
  margin-top: 30px;
}

.internal_DistributionTxt p {
  height: 22px;
  text-align: left;
  font-weight: 600;
  font: 16px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
}

.internal_DistributionTxt span {
  width: 240px;
  font: 14px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
}

.internal_AwaitingVrfTxt p {
  width: 163px;
  height: 22px;
  text-align: left;
  font: normal normal 600 15px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
.internal_Settledtext {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 234px;
  opacity: 1;
  margin-top: 30px;
}

.internal_AwaitingVrfTxt span {
  width: 243px;
  font: normal normal normal 13px Open Sans;
  letter-spacing: 0px;
  color: #fafafa;
  opacity: 1;
}

.internal_ViewTxn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 213px;
  height: 31px;
  cursor: pointer;
  background: #1d3a6d 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  margin-top: 29px;
}
.internal_ViewTxn p {
  width: 95px;
  height: 17px;
  margin-left: 10px;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
}
.internal_ViewTxn img {
  width: 13px;
  height: 11px;
  opacity: 1;
  margin-right: 10px;
}
.internalTxnApprovalFirstContainer .approvaltext1 {
  display: flex;
  width: 100%;
  margin-bottom: 4px;
}

.internalTxnApprovalFirstContainer .approvaltext1 p {
  width: 149px;
  height: 33px;
  text-transform: lowercase;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  font: normal normal 600 24px/33px Open Sans;
  letter-spacing: 0px;
  color: #4ddd37;
  margin-top: 20px;
  margin-left: 20px;
  text-align: left;
  opacity: 1;
}

.internalTxnApprovalFirstContainer1 .approvaltext1 p::first-line {
  text-transform: capitalize !important;
}

.internalTxnApprovalFirstContainer .approvaltext1 span {
  width: 108px;
  height: 19px;
  margin-top: 20px;
  padding-left: 55px;
  margin-right: -20px;
  font: normal normal normal 14px/19px Open Sans;
  color: #fcfcfc;
  opacity: 1;
}

.internalTxnApprovalFirstContainer .approvaltext2 {
  width: 100%;
  display: flex;
  margin-bottom: 4px;
}
.BeneTxn_ApprovalSecondDivision {
  display: flex;
  justify-content: space-between;
  width: 301px;
  height: 43px;
  opacity: 1;
  margin-left: 20px;
  margin-top: 15px;
}

.internalTxnApprovalFirstContainer .approvaltext2 .z1 {
  width: 34px;
  height: 17px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
  margin-left: 20px;
}

.internalTxnApprovalFirstContainer .approvaltext2 .z2 {
  width: 284px;
  height: 17px;
  text-align: right;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
  margin-right: 20px;
  margin-bottom: 20px;
}

.internalTxnApprovalFirstContainer .approvaltext3 {
  margin-top: -20px;
}

.internalTxnApprovalFirstContainer .approvaltext3 p {
  width: 229px;
  height: 19px;
  margin-top: 20px;
  margin-left: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #fcfcfc;
  opacity: 1;
}

.internalTxnApprovalFirstContainer .approvaltext3 span {
  width: 44px;
  height: 17px;
  margin-left: 20px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
}

.internalTxnApprovalFirstContainer .approvaltext4 {
  margin-top: -5px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.internalTxnApprovalFirstContainer .approvaltext4 p {
  width: 163px;
  height: 19px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: 20px;
  margin-left: 20px;
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #fcfcfc;
  opacity: 1;
}

.internalTxnApprovalFirstContainer .approvaltext4 span {
  width: 111px;
  height: 17px;
  margin-left: 20px;
  margin-bottom: 50px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
}

.BeneTxn_ApprovalAcdivSecond {
  width: 341px;
  height: 220px;
  color: white;
  background-color: #506994;
}

.ApprovalAcdiv2 .Approvalsection2 {
  width: 100%;
  height: 100%;
}

.ApprovalAcdiv2 .Approvalsection2 .approvalDiv1 {
  display: flex;
  margin-right: 22px;
  margin-left: 20px;
  justify-content: space-between;
}

.ApprovalAcdiv2 .Approvalsection2 .approvalDiv1 p {
  width: 182px;
  height: 19px;
  text-transform: lowercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 20px;
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #fcfcfc;
  opacity: 1;
}

.ApprovalAcdiv2 .Approvalsection2 .approvalDiv1 p::first-line {
  text-transform: capitalize !important;
}

.ApprovalAcdiv2 .Approvalsection2 .approvalDiv1 .approvalDiv1span {
  width: 73px;
  height: 19px;
  margin-top: 20px;
  text-align: -webkit-match-parent;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #fcfcfc;
  opacity: 1;
}

.ApprovalAcdiv2 .Approvalsection2 .approvalDiv2 {
  display: flex;
  justify-content: space-between;
  margin-right: 20px;
}

.ApprovalAcdiv2 .Approvalsection2 .approvalDiv2 .n1 {
  width: auto;
  height: 17px;
  margin-left: 20px;
  margin-top: 4px;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
}

.ApprovalAcdiv2 .Approvalsection2 .approvalDiv2 .n2 {
  width: 72px;
  height: 17px;
  margin-top: 4px;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
  margin-right: 3px;
}

.ApprovalAcdiv2 .Approvalsection2 .approvalDiv3 {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.ApprovalAcdiv2 .Approvalsection2 .approvalDiv3 p {
  width: 88px;
  height: 19px;
  margin-left: 20px;
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #fcfcfc;
  opacity: 1;
}

.ApprovalAcdiv2 .Approvalsection2 .approvalDiv3 span {
  width: 85px;
  height: 19px;
  margin-right: 20px;
  text-align: right;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #fcfcfc;
  opacity: 1;
}

.ApprovalAcdiv2 .Approvalsection2 .approvalDiv4 {
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
}

.ApprovalAcdiv2 .Approvalsection2 .approvalDiv4 p {
  width: 120px;
  height: 17px;
  margin-left: 20px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
}

.ApprovalAcdiv2 .Approvalsection2 .approvalDiv4 span {
  width: 34px;
  height: 17px;
  margin-right: 20px;
  text-align: right;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
}

.ApprovalAcdiv2 .Approvalsection2 .approvalDiv5 {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.ApprovalAcdiv2 .Approvalsection2 .approvalDiv5 p {
  width: 93px;
  height: 19px;
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #fcfcfc;
  opacity: 1;
  margin-left: 20px;
}

.ApprovalAcdiv2 .Approvalsection2 .approvalDiv5 span {
  width: 54px;
  height: 17px;
  margin-top: 4px;
  margin-left: 20px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
}

.ApprovalAcdiv2 .Approvalsection2 .approvalDiv5 .b1 {
  width: 100px;
  height: 35px;
  margin-right: 20px;
  background: #fafafa;
  border-radius: 22px;
  opacity: 1;
}

.ApprovalAcdiv2 .Approvalsection2 .approvalDiv5 .b1 img {
  width: 80px;
  height: 20px;
  margin-top: 7px;
  margin-left: 10px;
  margin-bottom: 12px;
  margin-right: 17px;
}

@keyframes moveLeft {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}
.BeneTxn_ApprovalAcdivThird {
  width: 341px;
  height: 220px;
  background-color: #506994;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.BeneTxn_ApprovalAcdivThird .BeneTxn_ApprovalSectionThird {
  width: 100%;
  height: 100%;
}
.BeneTxn_ApprovalAcdivThird
  .BeneTxn_ApprovalSectionThird
  .AprovalpageContainx1 {
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.BeneTxn_ApprovalAcdivThird
  .BeneTxn_ApprovalSectionThird
  .AprovalpageContainx1
  p {
  width: 135px;
  height: 27px;
  text-transform: lowercase;
  margin-top: 20px;
  margin-left: 20px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #fcfcfc;
  opacity: 1;
}

.BeneTxn_ApprovalAcdivThird
  .BeneTxn_ApprovalSectionThird
  .AprovalpageContainx1
  p::first-line {
  text-transform: capitalize !important;
}

.BeneTxn_ApprovalAcdivThird
  .BeneTxn_ApprovalSectionThird
  .AprovalpageContainx1
  span {
  width: 100px;
  height: 27px;
  margin-top: 20px;
  margin-right: 20px;
  text-align: right;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #fcfcfc;
  opacity: 1;
}

.BeneTxn_ApprovalAcdivThird
  .BeneTxn_ApprovalSectionThird
  .AprovalpageContainx2 {
  margin-top: -2%;
  display: flex;
  justify-content: space-between;
}

.BeneTxn_ApprovalAcdivThird
  .BeneTxn_ApprovalSectionThird
  .AprovalpageContainx2
  .b1 {
  width: 89px;
  height: 15px;
  text-align: left;
  font: normal normal normal 12px/19px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
  margin-top: 04px;
  margin-left: 20px;
}

.BeneTxn_ApprovalAcdivThird
  .BeneTxn_ApprovalSectionThird
  .AprovalpageContainx2
  .b2 {
  width: 80px;
  height: 15px;
  margin-top: 04px;
  margin-right: 20px;
  text-align: right;
  font: normal normal normal 12px/19px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
}

.internalNoAccountFound {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 65px;
  opacity: 1;
}
.internalLeftNoAccount {
  width: 390px;
  height: 44px;
  text-align: center;
  font: normal normal normal 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 0.9;
}

.internalRightNoAccount {
  width: 340px;
  height: 261px;
  opacity: 1;
}
@keyframes rotateOnce {
  100% {
    transform: rotate(360deg);
  }
}
@media (min-width: 1000px) and (max-width: 1520px) {
  .BeneTxn_AllAprovalAcountInfo {
    height: 75.5vh;
  }
}

@media (min-width: 1530px) and (max-width: 1920px) {
  .BeneTxn_AllAprovalAcountInfo {
    height: 73vh;
  }
}
@media (min-width: 1930px) and (max-width: 2570px) {
  .BeneTxn_AllAprovalAcountInfo {
    height: 85.5vh;
  }
}
@media (min-width: 2570px) and (max-width: 3850px) {
  .BeneTxn_AllAprovalAcountInfo {
    height: 89vh;
  }
}
.rotateOnce {
  animation: rotateOnce 1s ease-in-out;
}

.BeneTxn_TxnExecuted {
  width: 257px;
  height: 34px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
}

.BeneTxn_ApprovalDivision .approvalParentdiv {
  display: flex;
  flex-direction: column;
  gap: 04px;
}

.BeneTxn_RefreshBalance {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 42px;
  height: 62px;
}

.BeneTxn_RefreshBalance img {
  width: 30px;
  height: 30px;
  opacity: 1;
}

.BeneTxn_RefreshBalance p {
  width: 42px;
  height: 28px;
  text-align: center;
  font: normal normal normal 10px/14px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.BeneTxn_MultiView {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  margin-right: 12px;
  justify-content: center;
  width: 60px;
  opacity: 1;
}

.BeneTxn_MultiView img {
  width: 30px;
  height: 30px;
  background: transparent;
  opacity: 1;
}

.BeneTxn_MultiView p {
  width: 24px;
  height: 28px;
  text-align: center;
  font: normal normal normal 10px/14px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.BeneTxn_ApprovalDivision .approvalchild1 {
  width: 300spx;
  height: 19px;
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #fafafa;
  opacity: 1;
}

.BeneTxn_ApprovalDivision .approvalchild2 {
  width: 463px;
  height: 42px;
  /* UI Properties */
  text-align: left;
  font: normal normal normal 10px/14px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
}

.Approvalsec2 .navbarleft {
  display: flex;
  flex-direction: column;
  margin-left: 60px;
  gap: 4px;
}

.Approvalsec2 .navbarright {
  display: flex;
  flex-direction: column;
  margin-left: 60px;
  gap: 04px;
}

.Approvalsec2 .approvedtag1 {
  width: 23px;
  height: 27px;
  text-align: left;
  font: normal normal 600 20px/27px Open Sans;
  letter-spacing: 0px;
  color: #3cb371;
  opacity: 1;
}

.Approvalsec2 .approvedtag2 {
  width: 55px;
  height: 17px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_grey);
  opacity: 1;
}

.Approvalsec2 .approvedtag3 {
  width: 23px;
  height: 27px;
  text-align: left;
  font: normal normal 600 20px/27px Open Sans;
  letter-spacing: 0px;
  color: #ff5860;
  opacity: 1;
}

.Approvalsec2 .approvedtag4 {
  width: 48px;
  height: 17px;
  text-align: center;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_grey);
  opacity: 1;
}

.filterdropdown-transition-close {
  transition: height 0.2s ease-in, visibility 0s linear 0.2s;
  height: 0;
  visibility: hidden;
}

.filterdropdown-transition-open {
  visibility: visible;
  animation: growDown 300ms ease-in-out forwards;
  transform-origin: top center;
  transition: height 0.2s ease-in, visibility 0s;
  margin-top: 209px;
  background-color: #3a5686;
  height: 125px;
  border-radius: 0 !important;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 998;
}

@keyframes growDown {
  0% {
    transform: scaleY(0);
  }

  100% {
    transform: scaleY(1);
  }
}

.BeneTxn_PendingAc {
  display: flex;
  flex-direction: column;
  width: 341px;
  background: #314d7e 0% 0% no-repeat padding-box;
  box-shadow: 5px 5px 11px #00000033;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.BeneTxn_PendingAcTxt p {
  width: 246px;
  height: 27px;
  text-align: left;
  font: normal normal normal 17px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
  margin-top: 20px;
  margin-left: 20px;
}

.BeneTxn_PendingDivTxn p {
  width: 299px;
  height: 48px;
  text-align: left;
  font: normal normal normal 13px Open Sans;
  letter-spacing: 0px;
  color: #f05a6a;
  opacity: 1;
}

.BeneTxn_InstantPaySection {
  display: flex;
  width: 1025px;
  height: 55px;
  position: relative;
  top: -19px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.BeneTxn_InstantPayDivOne {
  display: flex;
  justify-content: space-between;
  width: 341px;
  height: 100%;
}

.BeneTxn_PayMethodSection {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.BeneTxn_PayMethodSection p {
  width: 152px;
  height: 27px;
  font: normal normal normal 17px Open Sans;
  letter-spacing: 0px;
  color: #fcfcfc;
  opacity: 1;
  margin-top: 7px;
}

.BeneTxn_PayMethodSection span {
  width: 113px;
  height: 31px;
  text-align: left;
  font: normal normal normal 10px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
}

.BeneTxn_VerifiedPrimarySec {
  display: flex;
  margin-top: 13px;
  gap: 3px;
}

.BeneTxn_VerifiedPrimarySec img {
  width: 16px;
  height: 16px;
  opacity: 1;
}

.BeneTxn_VerifiedPrimarySec p {
  width: 51px;
  height: 12px;
  text-align: left;
  font: normal normal normal 10px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
}

.BeneTxn_InstantPayDivTwo {
  width: 341px;
  height: 100%;
  display: flex;
}

.BeneTxn_BalanceDiv {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-top: 10px;
}

.BeneTxn_BalanceDiv p {
  width: 136px;
  height: 28px;
  font: normal normal normal 15px Open Sans;
  letter-spacing: 0px;
  color: #fcfcfc;
  opacity: 1;
}

.BeneTxn_BalanceDiv span {
  width: 163px;
  height: 37px;
  font: normal normal normal 11px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
}

.BeneTxn_AllRefreshPage {
  display: flex;
  position: relative;
  height: 20px;
  left: -35px;
  top: 14px;
  justify-content: center;
  gap: 4px;
}

.RefreshDivider {
  width: 0px;
  height: 12px;
  border: 1px solid #c9c9c9;
  opacity: 1;
}

.BeneTxn_RefreshPageSec {
  display: flex;
  margin-left: 2px;
  gap: 3px;
}

.BeneTxn_RefreshPageSec p {
  width: 50px;
  height: 19px;
  position: relative;
  bottom: 1px;
  text-align: left;
  font: normal normal normal 11px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
}

.BeneTxn_RefreshPageSec img {
  width: 12px;
  height: 12px;
  opacity: 0.7;
}

.BeneTxn_AddStatus img {
  width: 24px;
  height: 24px;
  opacity: 1;
  position: relative;
  top: 22px;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
}

.BeneTxn_LastApprovalPage {
  display: flex;
  flex-direction: column;
  gap: 3px;
  position: relative;
  left: -7px;
  top: 22px;
}

.BeneTxn_LastApprovalPage p {
  width: 171px;
  height: 19px;
  text-align: left;
  font: normal normal 600 12px Open Sans;
  letter-spacing: 0px;
  padding-top: 4px;
  color: #fcfcfc;
  opacity: 1;
}

.BeneTxn_LastApprovalPage span {
  width: 264px;
  height: 34px;
  text-align: left;
  font: normal normal normal 11px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.beneTxn_TestingButton {
  height: 220px;
  opacity: 100;
  width: 341px;
  background-color: red;
}

.BeneTxn_TxnAmountText {
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.BeneTxn_TxnAmountText p {
  width: 149px;
  height: 33px;
  text-align: left;
  font: normal normal 600 18px Open Sans;
  letter-spacing: 0px;
  color: #4ddd37;
  opacity: 1;
}

.BeneTxn_TxnAmountText span {
  width: 132px;
  height: 19px;
  text-align: left;
  font: normal normal normal 13px/19px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
}

.BeneTxn_TxnAccountNoText {
  display: flex;
  position: relative;
  flex-direction: column;
  left: 20px;
  gap: 4px;
}

.BeneTxn_TxnAccountNoText p {
  width: 144px;
  height: 27px;
  text-align: left;
  font: normal normal normal 16px Open Sans;
  letter-spacing: 0px;
  color: #fcfcfc;
  opacity: 1;
}

.BeneTxn_TxnAccountNoText span {
  width: 92px;
  height: 19px;
  font: normal normal normal 13px / 19px Open Sans;
  letter-spacing: 0px;
  margin-left: 60px;
  color: #c9c9c9;
  opacity: 1;
}

.BeneTxn_Settled {
  width: 342px;
  height: 100%;
  background: #506994 0% 0% no-repeat padding-box;
  display: flex;
  gap: 20px;
  border-left: 2px solid #1d3a6d;
  border-bottom-right-radius: 30px;
  border-top-right-radius: 30px;
  margin-left: -1px;
  padding-left: 20px;
}

.BeneTxn_Declined {
  width: 342px;
  height: 100%;
  background: #506994 0% 0% no-repeat padding-box;
  display: flex;
  gap: 20px;
  border-left: 2px solid #1d3a6d;
  border-bottom-right-radius: 30px;
  border-top-right-radius: 30px;
  margin-left: -1px;
  padding-left: 20px;
}

.internalStatusImage {
  display: flex;
  justify-content: center;
  width: 41px;
  align-items: center;
  height: 67px;
  margin-top: 20px;
}

.internalStatusImage img {
  opacity: 1;
}

.internalStatusImageMob {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 67px;
  margin-top: 5px;
}

.internalStatusImageMob img {
  opacity: 1;
}

.BeneTxn_SettledImgaes {
  display: flex;
  align-items: center;
  width: 41px;
  height: 67px;
  opacity: 1;
  margin-top: 30px;
}

.BeneTxn_DeclinedImgaes {
  width: 41px;
  height: 67px;
  opacity: 1;
  margin-top: 30px;
}

.BeneTxn_DistributionTxt {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 234px;
  height: 67px;
  opacity: 1;
  margin-top: 30px;
}

.BeneTxn_DeclienPageTxt {
  display: flex;
  width: 341px;
  height: 220px;
  flex-direction: column;
  gap: 10px;
}

.BeneTxn_DeclinedPage p {
  width: 115px;
  height: 16px;
  text-align: center;
  font: normal normal normal 16px Segoe UI;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
}

.BeneTxn_ViewTransction {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 213px;
  height: 31px;
  cursor: pointer;
  background: #1d3a6d 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  margin-top: 29px;
}

.BeneTxn_ViewTransction p {
  width: 95px;
  height: 17px;
  margin-left: 10px;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.BeneTxn_ViewTransction img {
  width: 13px;
  height: 11px;
  opacity: 1;
  margin-right: 10px;
}

/* --------------------- */
.internalTxnApprovalFirstContainer .approvaltext1 {
  display: flex;
  width: 100%;
  margin-bottom: 4px;
}

.internalTxnApprovalFirstContainer .approvaltext1 p {
  width: 149px;
  height: 33px;
  text-transform: lowercase;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  font: normal normal 600 24px/33px Open Sans;
  letter-spacing: 0px;
  color: #4ddd37;
  margin-top: 20px;
  margin-left: 20px;
  text-align: left;
  opacity: 1;
}

.internalTxnApprovalFirstContainer1 .approvaltext1 p::first-line {
  text-transform: capitalize !important;
}

.internalTxnApprovalFirstContainer .approvaltext1 span {
  width: 108px;
  height: 19px;
  margin-top: 20px;
  padding-left: 55px;
  margin-right: -20px;
  font: normal normal normal 14px/19px Open Sans;
  color: #fcfcfc;
  opacity: 1;
}

.internalTxnApprovalFirstContainer .approvaltext2 {
  width: 100%;
  display: flex;
  margin-bottom: 4px;
}

/* ------------------ */
.BeneTxn_ApprovalSecondDivision {
  display: flex;
  justify-content: space-between;
  width: 301px;
  height: 43px;
  opacity: 1;
  margin-left: 20px;
  margin-top: 15px;
}

.BeneTxn_TxnShopText {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.BeneTxn_TxnShopText p {
  width: 164px;
  height: 27px;
  text-align: left;
  text-transform: lowercase;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font: 14px Open Sans;
  letter-spacing: 0px;
  color: #fcfcfc;
  opacity: 1;
}

.BeneTxn_TxnShopText p::first-line {
  text-transform: capitalize !important;
}

.BeneTxn_TxnShopText span {
  width: 94px;
  height: 19px;
  font: normal normal normal 13px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
}

.BeneTxn_TxnIfsc {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.BeneTxn_TxnIfsc p {
  width: 95px;
  height: 27px;
  text-align: left;
  font: normal normal normal 14px Open Sans;
  letter-spacing: 0px;
  color: #fcfcfc;
  opacity: 1;
}

.BeneTxn_TxnIfsc span {
  width: 55px;
  height: 19px;
  font: normal normal normal 12px / 19px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
  margin-left: 37px;
}

.BeneTxn_ApprovalThirdDivision {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 301px;
  height: 43px;
  opacity: 1;
  margin-top: 15px;
  margin-left: 20px;
}

.BeneTxn_TxnAccountStatus {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.BeneTxn_TxnAccountStatus p {
  width: 66px;
  height: 27px;
  font: normal normal normal 14px Open Sans;
  letter-spacing: 0px;
  color: #fcfcfc;
  opacity: 1;
}

.BeneTxn_TxnAccountStatus span {
  width: 91px;
  height: 19px;
  font: normal normal normal 13px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
}

.BeneTxn_TxnBankImg_LogoBg {
  width: 104px;
  height: 38px;
  background: #fafafa;
  border-radius: 22px;
  opacity: 1;
}

.BeneTxn_TxnBankImg_LogoBg img {
  width: 80px;
  height: 20px;
  position: relative;
  top: 9px;
  left: 11px;
}

.internalTxnApprovalFirstContainer .approvaltext2 .z1 {
  width: 34px;
  height: 17px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
  margin-left: 20px;
}

.internalTxnApprovalFirstContainer .approvaltext2 .z2 {
  width: 284px;
  height: 17px;
  text-align: right;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
  margin-right: 20px;
  margin-bottom: 20px;
}

.internalTxnApprovalFirstContainer .approvaltext3 {
  margin-top: -20px;
}

.internalTxnApprovalFirstContainer .approvaltext3 p {
  width: 229px;
  height: 19px;
  margin-top: 20px;
  margin-left: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #fcfcfc;
  opacity: 1;
}

.internalTxnApprovalFirstContainer .approvaltext3 span {
  width: 44px;
  height: 17px;
  margin-left: 20px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
}

.internalTxnApprovalFirstContainer .approvaltext4 {
  margin-top: -5px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.internalTxnApprovalFirstContainer .approvaltext4 p {
  width: 163px;
  height: 19px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: 20px;
  margin-left: 20px;
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #fcfcfc;
  opacity: 1;
}

.internalTxnApprovalFirstContainer .approvaltext4 span {
  width: 111px;
  height: 17px;
  margin-left: 20px;
  margin-bottom: 50px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
}

.BeneTxn_ApprovalAcdivSecond {
  width: 341px;
  height: 220px;
  color: white;
  background-color: #506994;
}

.BeneTxn_AmountVerification {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 301px;
  height: 43px;
  margin-top: 15px;
  margin-left: 20px;
}

.BeneTxn_PreApprovalVfn p {
  width: 138px;
  height: 38px;
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
}

.BeneTxn_verificationAmount {
  display: flex;
  align-items: center;
  justify-content: center;
}

.BeneTxn_verificationAmount p {
  width: 67px;
  height: 28px;
  text-align: right;
  font: normal normal normal 18px Open Sans;
  letter-spacing: 0px;
  color: #fcfcfc;
  opacity: 1;
}

.plusSign {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 4px; */
}

.plusSign img {
  width: 20px;
  height: 20px;
  opacity: 1;
}

.BeneTxn_TxnAmountPrice {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 301px;
  height: 43px;
  margin-left: 20px;
}

.BeneTxn_TxnApprovalAmountTxt p {
  width: 132px;
  height: 19px;
  text-align: left;
  font: normal normal normal 14px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
}

.BeneTxn_TxnApprovalAmount p {
  width: 48px;
  height: 28px;
  text-align: left;
  font: normal normal normal 15px Open Sans;
  letter-spacing: 0px;
  color: #fcfcfc;
  opacity: 0.9;
}

.EqualSign {
  display: flex;
  justify-content: center;
  align-items: center;
}

.EqualSign img {
  width: 20px;
  height: 20px;
  opacity: 1;
}

.BeneTxn_Amount_PostVerification {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 301px;
  height: 43px;
  margin-left: 20px;
}

.BeneTxn_PostVerificationAmount {
  display: flex;
  align-items: center;
  justify-content: center;
}

.BeneTxn_PostApprovalVfn p {
  width: 156px;
  height: 38px;
  text-align: left;
  font: normal normal normal 14px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
}

.BeneTxn_PostVerificationAmount p {
  width: 105px;
  height: 28px;
  font: normal normal normal 17px Open Sans;
  letter-spacing: 0px;
  color: #b196fa;
  opacity: 1;
}

.ApprovalAcdiv2 .Approvalsection2 {
  width: 100%;
  height: 100%;
}

.ApprovalAcdiv2 .Approvalsection2 .approvalDiv1 {
  display: flex;
  margin-right: 22px;
  margin-left: 20px;
  justify-content: space-between;
}

.ApprovalAcdiv2 .Approvalsection2 .approvalDiv1 p {
  width: 182px;
  height: 19px;
  text-transform: lowercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 20px;
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #fcfcfc;
  opacity: 1;
}

.ApprovalAcdiv2 .Approvalsection2 .approvalDiv1 p::first-line {
  text-transform: capitalize !important;
}

.ApprovalAcdiv2 .Approvalsection2 .approvalDiv1 .approvalDiv1span {
  width: 73px;
  height: 19px;
  margin-top: 20px;
  text-align: -webkit-match-parent;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #fcfcfc;
  opacity: 1;
}

.ApprovalAcdiv2 .Approvalsection2 .approvalDiv2 {
  display: flex;
  justify-content: space-between;
  margin-right: 20px;
}

.ApprovalAcdiv2 .Approvalsection2 .approvalDiv2 .n1 {
  width: auto;
  height: 17px;
  margin-left: 20px;
  margin-top: 4px;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
}

.ApprovalAcdiv2 .Approvalsection2 .approvalDiv2 .n2 {
  width: 72px;
  height: 17px;
  margin-top: 4px;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
  margin-right: 3px;
}

.ApprovalAcdiv2 .Approvalsection2 .approvalDiv3 {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.ApprovalAcdiv2 .Approvalsection2 .approvalDiv3 p {
  width: 88px;
  height: 19px;
  margin-left: 20px;
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #fcfcfc;
  opacity: 1;
}

.ApprovalAcdiv2 .Approvalsection2 .approvalDiv3 span {
  width: 85px;
  height: 19px;
  margin-right: 20px;
  text-align: right;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #fcfcfc;
  opacity: 1;
}

.ApprovalAcdiv2 .Approvalsection2 .approvalDiv4 {
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
}

.ApprovalAcdiv2 .Approvalsection2 .approvalDiv4 p {
  width: 120px;
  height: 17px;
  margin-left: 20px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
}

.ApprovalAcdiv2 .Approvalsection2 .approvalDiv4 span {
  width: 34px;
  height: 17px;
  margin-right: 20px;
  text-align: right;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
}

.ApprovalAcdiv2 .Approvalsection2 .approvalDiv5 {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.ApprovalAcdiv2 .Approvalsection2 .approvalDiv5 p {
  width: 93px;
  height: 19px;
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #fcfcfc;
  opacity: 1;
  margin-left: 20px;
}

.ApprovalAcdiv2 .Approvalsection2 .approvalDiv5 span {
  width: 54px;
  height: 17px;
  margin-top: 4px;
  margin-left: 20px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
}

.ApprovalAcdiv2 .Approvalsection2 .approvalDiv5 .b1 {
  width: 100px;
  height: 35px;
  margin-right: 20px;
  background: #fafafa;
  border-radius: 22px;
  opacity: 1;
}

.ApprovalAcdiv2 .Approvalsection2 .approvalDiv5 .b1 img {
  width: 80px;
  height: 20px;
  margin-top: 7px;
  margin-left: 10px;
  margin-bottom: 12px;
  margin-right: 17px;
}

.internalDeclinedPopup {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 246px;
  left: 403px;
  width: 561px;
  height: 276px;
  background: #153164 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  opacity: 1;
  margin: 5px;
}

.internalDeclinedPopup .declinedReasion {
  display: flex;
  flex-direction: column;
}

.internalDeclinedPopup .declinedReasion h2 {
  width: auto;
  height: 27px;
  text-align: left;
  font: normal normal 600 20px/27px Open Sans;
  letter-spacing: 0px;
  color: #ff7f33;
  opacity: 1;
  margin-top: 30px;
  margin-left: 30px;
}

.internalDeclinedPopup .declinedReasion p {
  width: 362px;
  height: 22px;
  text-align: left;
  font: normal normal 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  white-space: nowrap;
  margin-left: 30px;
  margin-top: 10px;
}

.internalDeclinedPopup .inputfield {
  display: flex;
  position: relative;
  margin-left: 30px;
  margin-top: 5px;
}

.internalDeclinedPopup .inputfield input {
  width: 501px;
  height: 42px;
  font-size: 16px;
  color: white;
  padding: 0px 10px;
  background-color: transparent;
  border: 1.2px solid #dadada;
  border-radius: 10px;
  outline: none;
  opacity: 1;
}

.inputfield label {
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  font-size: 16px;
  color: #ffff;
  padding: 0 10px;
  pointer-events: none;
  transition: 0.5s;
}

.inputfield input:focus,
.inputfield input:valid {
  border: 1.2px solid #4ddd37;
}

.inputfield input:focus ~ label,
.inputfield input:valid ~ label {
  top: 0;
  font-size: 12px;
  color: #4ddd37;

  background: #153164;
}

.transition {
  background-color: blue;
  position: relative;
  transition: transform 7s ease-in-out;
  transform: translateX(-100%);
}

@keyframes moveLeft {
  0% {
    left: 0;
  }

  100% {
    left: -100%;
  }
}

.internalDeclinedPopup .benefbtnconfandCan {
  display: flex !important;
  align-items: center;
  justify-content: flex-end;
  margin-right: 30px;
  margin-bottom: 29px;
  gap: 30px;
}

.internalDeclinedPopup .buttonContainer {
  display: flex !important;
  align-items: center;
  position: relative;
  justify-content: flex-end;
  margin-right: 30px;
  margin-bottom: 29px;
  gap: 30px;
}

@media screen and (min-width: 300px) and (max-width: 500px) {
  .internalDeclinedPopup .buttonContainer {
    margin-bottom: 15px !important;
  }
}

.internalDeclinedPopup .benefbtnconfandCan .confirmBtn {
  width: 68px;
  height: 32px;
  background-color: var(--Secondary_grey);
  /* opacity: 0.5; */
  border-radius: 20px;
  text-align: center;
  font-family: Open Sans;
  font-size: var(--Font_12);
  font-weight: var(--normal);
  letter-spacing: 0px;
  color: var(--Primary_white) !important;
}

.buttonContainer .confirmBtn {
  width: 68px;
  height: 32px;
  background-color: var(--Secondary_grey);
  /* opacity: 0.5; */
  border-radius: 20px;
  text-align: center;
  font-family: Open Sans;
  font-size: var(--Font_12);
  font-weight: var(--normal);
  letter-spacing: 0px;
  color: var(--Primary_white) !important;
}

.cancleBtn,
.confirmBtn {
  transition: transform 0.5s ease;
}

.buttonContainer .loader-active #cancelBtn,
.buttonContainer .loader-active #confirmBtnnxt {
  transform: translateX(-100%);
}

.buttonContainer .loader-active #loader {
  width: 100px;
  transition: width 0.5s ease;
}

.buttonContainer .cancleBtn {
  width: 50px;
  height: 22px;
  text-align: center;
  text-decoration: underline;
  font: normal normal normal 16px/14px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.BeneTxn_ApprovalAcdivThird {
  width: 341px;
  height: 220px;
  background-color: #506994;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.BeneTxn_ApprovalAcdivThird .BeneTxn_ApprovalSectionThird {
  width: 100%;
  height: 100%;
}

.BeneTxn_DateTimeAndMoreDetails {
  display: flex;
  justify-content: space-between;
}

.BeneTxn_DateTimeDiv {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-top: 15px;
}

.BeneTxn_DateTimeDiv p {
  width: 153px;
  font: normal normal normal 13px Open Sans;
  letter-spacing: 0px;
  color: #fcfcfc;
  opacity: 1;
}

.BeneTxn_DateTimeDiv span {
  width: 82px;
  height: 17px;
  text-align: left;
  font: normal normal normal 11px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
}

.BeneTxn_ApprovalAcdivThird
  .BeneTxn_ApprovalSectionThird
  .AprovalpageContainx1 {
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.BeneTxn_ApprovalAcdivThird
  .BeneTxn_ApprovalSectionThird
  .AprovalpageContainx1
  p {
  width: 135px;
  height: 27px;
  text-transform: lowercase;
  margin-top: 20px;
  margin-left: 20px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #fcfcfc;
  opacity: 1;
}

.BeneTxn_ApprovalAcdivThird
  .BeneTxn_ApprovalSectionThird
  .AprovalpageContainx1
  p::first-line {
  text-transform: capitalize !important;
}

.BeneTxn_ApprovalAcdivThird
  .BeneTxn_ApprovalSectionThird
  .AprovalpageContainx1
  span {
  width: 100px;
  height: 27px;
  margin-top: 20px;
  margin-right: 20px;
  text-align: right;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #fcfcfc;
  opacity: 1;
}

.BeneTxn_ApprovalAcdivThird
  .BeneTxn_ApprovalSectionThird
  .AprovalpageContainx2 {
  margin-top: -2%;
  display: flex;
  justify-content: space-between;
}

.BeneTxn_ApprovalAcdivThird
  .BeneTxn_ApprovalSectionThird
  .AprovalpageContainx2
  .b1 {
  width: 89px;
  height: 15px;
  text-align: left;
  font: normal normal normal 12px/19px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
  margin-top: 04px;
  margin-left: 20px;
}

.BeneTxn_ApprovalAcdivThird
  .BeneTxn_ApprovalSectionThird
  .AprovalpageContainx2
  .b2 {
  width: 80px;
  height: 15px;
  margin-top: 04px;
  margin-right: 20px;
  text-align: right;
  font: normal normal normal 12px/19px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
}

.BeneTxn_DeclineandConfirm {
  display: flex;
  height: 50%;
  width: 80%;
  margin-left: 69px;
  margin-top: 49px;
  flex-direction: end;
}

.BeneTxn_logo1 {
  display: flex;
  flex-direction: end;
  justify-content: space-between;
  right: 0;
  width: 100%;
  height: 100%;
  background: #506994 0% 0% no-repeat padding-box;
  border-radius: 28px 0px;
  opacity: 1;
}

.BeneTxn_Expentraloader {
  display: flex;
  justify-content: space-between;
  width: 50px;
  height: 29px;
  margin-top: 35px;
  margin-left: 110px;
  opacity: 1;
  align-items: center;
}

.BeneTxn_Unknownpage {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  height: 60px;
  width: 221px;
  margin-top: 20px;
  margin-left: 20px;
}

.BeneTxn_UnknownpageFirst img {
  width: 40px;
  height: 40px;
  opacity: 1;
}

.BeneTxn_UnknownpageTwo {
  width: 161px;
  height: 60px;
}

.BeneTxn_UnknownpageTwo span {
  width: 118px;
  height: 22px;
  text-align: left;
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.BeneTxn_UnknownpageTwo p {
  width: 169px;
  height: 34px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #fafafa;
  opacity: 1;
}

.BeneTxn_Declinedpage {
  width: 273px;
  height: 100%;
  gap: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ff5860 0% 0% no-repeat padding-box;
  border-radius: 30px 0px;
  opacity: 1;
}

.BeneTxn_DeclinedpageOne {
  width: 40px;
  height: 40px;
  opacity: 1;
}

.BeneTxn_DeclinedpageTwo {
  width: 153px;
  height: 43px;
  opacity: 1;
}

.BeneTxn_DeclinedpageTwo span {
  width: 72px;
  height: 22px;
  text-align: left;
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.BeneTxn_DeclinedpageTwo p {
  width: 163px;
  height: 17px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #fafafa;
  opacity: 1;
}

.BeneTxn_Approvedpage {
  width: 273px;
  height: 100%;
  gap: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px 0px;
  opacity: 1;
  background: #3cb371 0% 0% no-repeat padding-box;
}

.BeneTxn_ApprovedpageFirst {
  width: 40px;
  height: 40px;
  opacity: 1;
}

.BeneTxn_ApprovedpageTwo {
  width: 153px;
  height: 43px;
  opacity: 1;
}

.BeneTxn_ApprovedpageTwo span {
  width: 72px;
  height: 22px;
  text-align: left;
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.BeneTxn_ApprovedpageTwo p {
  width: 163px;
  height: 17px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #fafafa;
  opacity: 1;
}

.BeneTxn_ApprovalLeftSide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 5px;
  margin-bottom: 1px;
  gap: 04px;
  width: 45%;
  align-items: center;
}

.BeneTxn_ApprovalLeftSide img {
  width: 40px;
  height: 40px;
  box-shadow: -5px -5px 11px #71717140;
  opacity: 1;
}

.BeneTxn_ApprovalLeftSide p {
  width: 34px;
  height: 14px;
  text-align: center;
  font: normal normal normal 10px/14px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.BeneTxn_ApprovalDivider {
  width: 0px;
  height: 100%;
  border: 1px solid #1d3a6d;
  opacity: 1;
}

.BeneTxn_ApprovalRightSide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  width: 45%;
  gap: 04px;
}

.BeneTxn_ApprovalRightSide img {
  width: 40px;
  height: 40px;
  box-shadow: -5px -5px 11px #71717140;
  opacity: 1;
}

.BeneTxn_ApprovalRightSide p {
  width: 40px;
  height: 14px;
  text-align: center;
  font: normal normal normal 10px/14px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.disableBackGround {
  pointer-events: none;
}

.BeneAccount_NoAccountFound {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 65px;
  opacity: 1;
}

.BeneAccount_LeftTextContentNoAccount {
  width: 390px;
  height: 44px;
  text-align: center;
  font: normal normal normal 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 0.9;
  align-items: center;
}

.BeneAccount_RightTextContentNoAccount {
  width: 340px;
  height: 261px;
  opacity: 1;
  text-align: center;
}

.require_scroller {
  overflow-y: auto;
  width: 100%;
  height: auto;
}

.require_scroller::-webkit-scrollbar {
  width: 10px;
}

.require_scroller::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

.require_scroller::-webkit-scrollbar-thumb {
  background-color: #fafafa;
  outline: 1px solid #314d7e;
  border-radius: 25px;
  height: 25%;
}

.BeneTxn_SecondNavbarVerification p {
  width: 24px;
  height: 28px;
  text-align: center;
  font: normal normal normal 10px/14px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.BeneTxn_DrawerPannel {
  width: 100%;
  height: 769px;
  background-color: rgb(160, 153, 153);
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
}

.BeneTxn_DrawerMainDiv {
  width: 100%;
  background-color: #9740a14d;
  height: 208px;
  z-index: 999;
}

.BeneTxn_Drawerfirst {
  width: 100%;
  height: 100%;
  background: rgb(49, 240, 138);
}

.BeneTxn_DrawerSecond {
  width: 100%;
  height: 900px;
  background-color: #b196fa;
}

@keyframes rotateOnce {
  100% {
    transform: rotate(360deg);
  }
}

@media (min-width: 1000px) and (max-width: 1520px) {
  .BeneTxn_AllAprovalAcountInfo {
    height: 75.5vh;
  }
}

@media (min-width: 1530px) and (max-width: 1920px) {
  .BeneTxn_AllAprovalAcountInfo {
    height: 73vh;
  }
}

@media (min-width: 1930px) and (max-width: 2570px) {
  .BeneTxn_AllAprovalAcountInfo {
    height: 85.5vh;
  }
}

@media (min-width: 2570px) and (max-width: 3850px) {
  .BeneTxn_AllAprovalAcountInfo {
    height: 89vh;
  }
}

.rotateOnce {
  animation: rotateOnce 1s ease-in-out;
}

.headertextbottomv_mob {
  color: #c9c9c9;
  font-size: 12px;
}

.filter-main-mob {
  display: flex;
  justify-content: space-around;
}

.txnimgfv-mob {
  display: table-cell;
  width: 34%;
  vertical-align: middle;
  cursor: pointer;
  padding: 5px;
  border-right: 2px solid #1e3a6d;
}

.multiImgMob {
  display: table;
  width: 100%;
  vertical-align: middle;
  cursor: pointer;
}

.multiViewMob {
  display: table-cell;
  width: 55px;
  vertical-align: middle;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.txnimgmv-mob {
  display: table-cell;
  width: 33%;
  vertical-align: middle;
  cursor: pointer;
  padding: 5px;
}

.beneacc_approval_heder_containermv-mob {
  /* background-color: #314c7f; */
  margin-top: 0px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 5;
}

.internalApprovalHeaderMob {
  width: 100%;
  padding: 10px 10px;
  border-bottom: 2px solid #1d3a6d;
  display: table;
}

.txnBeneapprovaltextv-mob {
  width: 100%;
  padding: 10px 10px;
  display: table;
}

.txnBeneapprovalheaderarrowvcv-mob {
  display: table-cell;
  width: 10%;
  vertical-align: middle;
  cursor: pointer;
}

.txnBeneapprovalheadertextmv-mob {
  display: table-cell;
  vertical-align: middle;
}

.approText {
  color: #dadada;
}

.internal_DistributionTxtMob {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 234px;
  height: 67px;
  opacity: 1;
  margin-top: 15px;
}

.itTxnAwaitingVerifyImgMob {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 67px;
  margin-top: 5px;
}

.itTxnAwaitingVerifyImgMob img {
  opacity: 1;
}

.internal_DistributionTxtMob p {
  height: 22px;
  text-align: left;
  font: normal normal 600 17px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
}

.internal_DistributionTxtMob span {
  width: 237px;
  font: normal normal normal 14px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
}

.internalViewTxnMob {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 140px;
  height: 36px;
  cursor: pointer;
  background: #1d3a6d 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  margin-top: 20px;
}

.internalViewTxnMob p {
  /* width: 95px; */
  height: 17px;
  margin-left: 10px;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.internalViewTxnMob img {
  width: 13px;
  height: 11px;
  opacity: 1;
  margin-right: 10px;
}

.internalAwaitingMob {
  width: 280px;
}

.BeneTxn_ViewTransctionAwaiting_mob {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 153px;
  height: 42px;
  cursor: pointer;
  background: #1d3a6d 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  margin-top: 35px;
}

.BeneTxn_ViewTransctionAwaiting_mob p {
  width: 95px;
  height: 17px;
  margin-left: 10px;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.BeneTxn_ViewTransctionAwaiting_mob img {
  width: 13px;
  height: 11px;
  opacity: 1;
  margin-right: 10px;
}

.itTxn_ErrorTxt_mob p {
  height: 22px;
  text-align: left;
  font: normal normal 600 17px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
}

.itTxn_ErrorTxt_mob span {
  width: 237px;
  font: normal normal normal 14px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
}

.itTxn_ErrorTxt_mob {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 234px;
  height: 67px;
  opacity: 1;
  margin-top: 15px;
}

.itTxn_ViewTransctionRejected_mob {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 153px;
  height: 42px;
  cursor: pointer;
  background: #1d3a6d 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  margin-top: 5px;
}

.itTxn_ViewTransctionRejected_mob p {
  width: 95px;
  height: 17px;
  margin-left: 10px;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.itTxn_ViewTransctionRejected_mob img {
  width: 13px;
  height: 11px;
  opacity: 1;
  margin-right: 10px;
}

.headingAccountDetail {
  font: normal normal 16px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
}

.itTxnApprovalfiltertextMob {
  width: 100%;
  padding: 10px 10px;
  display: table;
}

.declinePopupMobile {
  opacity: 80% !important;
}

.itTxnFailedTxtMob {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 234px;
  /* height: 67px; */
  opacity: 1;
  margin-top: 15px;
}

.itTxnFailedTxtMob p {
  height: 22px;
  text-align: left;
  font: normal normal 600 17px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
}

.itTxnFailedTxtMob_div {
  font: normal normal normal 14px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
  width: 270px !important;
  height: 60px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.BeneTxn_ViewTranscRejected_mob_mob {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 153px;
  height: 42px;
  cursor: pointer;
  background: #1d3a6d 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  margin-top: 30px;
}

.internal_DistributionTxtMob {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 234px;
  height: 67px;
  opacity: 1;
  margin-top: 15px;
}

.itTxn_ViewTransctionRejected_mob p {
  width: 95px;
  height: 17px;
  margin-left: 10px;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.itTxn_ViewTransctionRejected_mob img {
  width: 13px;
  height: 11px;
  opacity: 1;
  margin-right: 10px;
}

.itTxnRejectedImgMob {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 67px;
  /* margin-top: 5px; */
}

.itTxnRejectedImgMob img {
  opacity: 1;
}

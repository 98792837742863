.multi-content-container {
   transition: opacity 0.5s ease-in-out;
}

.multi-content-container.hidden {
   opacity: 0;
}

@media screen and (min-width: 1440px) and (min-height: 960px) {
  .multi-content-container {
    overflow-y: auto;
    height: 800px;
  }

  .content-container {
    overflow: hidden;
    height: 800px;
  }
}

@media screen and (min-width: 1920px) and (min-height: 1080px) {
  .multi-content-container {
    overflow-y: auto;
    height: 1000px;
  }

  .content-container {
    overflow: hidden;
    
  }
}

@media screen and (min-width: 2550px) and (min-height: 1230px) {
  .multi-content-container {
    overflow-y: auto;
    height: 1200px;
  }
}


@media screen and (min-width: 2560px) and (min-height: 1440px) {
  .multi-content-container {
    overflow-y: hidden;
    height: 1200px;
  }
}

@media screen and (min-width: 3840px) and (min-height: 1920px) {
  .multi-content-container {
    overflow-y: hidden;
    height: 1300px;
  }
}

.more_button:hover{
  background-color: #A3A3A3;
  opacity: 1;
}
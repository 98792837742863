.edit_pan_con_main {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.main_edit_stepper_div {
  width: 100%;
  background-color: #314c7f;
  height: 164px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  box-shadow: 2px 2px 10px 0px rgba(26, 26, 26, 0.733);
  position: fixed;
  top: 0;
  z-index: 10;
  left: 0;
}

.edit_pan_details_all {
  width: 1024px;
  max-width: 1024px;
  padding-top: 186px;
  height: 100%;
  overflow: hidden;
  scroll-behavior: smooth;
  display: flex;
  scroll-snap-type: x mandatory;
  padding-bottom: 46px;
}

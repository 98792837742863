.beneTxnViewallCard {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  margin: 0 auto;
  /* padding-bottom: 145px; */
  margin-top: 15px;
  /* gap: 20px; */
}

.multiSelectBeneTxnViewAllCard {
  cursor: pointer;
  /* box-shadow: -5px -5px 11px #71717133 !important; */
  box-sizing: border-box;
  border-radius: 10px;
  grid-column: span 1;
  height: 98px;
  /* margin: 10px ; */
  background-color: #3a5686;
  width: 255px;
  transition: height 0.1s;
}

.bene_txn_viewall_inner_card_container {
  width: 255px;
}

.bene_txn_card-container {
  position: relative;
  width: 255px;
  height: 98px;
}

@media screen and (min-width: 3800px) and (min-height: 1920px) {
  .beneTxnViewallCard {
    width: 85% !important;
  }

  .multiSelectBeneTxnViewAllCard:hover,
  .multiSelectBeneTxnViewAllCard,
  .bene_txn_card-container {
    width: 260px !important;
  }
}

@media screen and (min-width: 2560px) and (min-height: 1440px) {
  .beneTxnViewallCard {
    width: 95% !important;
  }

  .multiSelectBeneTxnViewAllCard:hover,
  .multiSelectBeneTxnViewAllCard,
  .bene_txn_card-container {
    width: 260px !important;
  }
}

@media screen and (min-width: 1920px) and (min-height: 1080px) {
  .beneTxnViewallCard {
    width: 85% !important;
  }

  .multiSelectBeneTxnViewAllCard:hover,
  .multiSelectBeneTxnViewAllCard,
  .bene_txn_card-container {
    width: 240px !important;
  }

  .bene_txn_viewall_inner_card_container {
    width: 240px;
  }
}

@media screen and (min-width: 1400px) and (min-height: 960px) {
  .beneTxnViewallCard {
    width: 95%;
  }

  .multiSelectBeneTxnViewAllCard:hover,
  .multiSelectBeneTxnViewAllCard,
  .bene_txn_card-container {
    width: 250px !important;
  }

  .bene_txn_viewall_inner_card_container {
    width: 250px;
  }
}

@media screen and (min-width: 1300px) and (max-width: 1400px) {
  .beneTxnViewallCard {
    width: 96%;
  }

  .multiSelectBeneTxnViewAllCard:hover,
  .multiSelectBeneTxnViewAllCard,
  .bene_txn_card-container {
    width: 240px !important;
  }

  .bene_txn_viewall_inner_card_container {
    width: 239px;
  }
}

@media screen and (max-width: 800px) {
  .multiSelectBeneTxnViewAllCard {
    width: 100%;
  }
  .bene_txn_card-container {
    width: 100%;
  }
  .bene_txn_viewall_inner_card_container {
    width: 100%;
  }
  .beneTxn_viewall_bank_acc_div {
    width: 100% !important;
  }
}

.beneTxn_viewall_acc_title {
  color: #dadada;
  font-size: 10px;
  margin-bottom: 20px;
  font-weight: 600;
}

.beneTxn_viewall_bank_acc_div {
  color: #f9f9f9;
  font-size: 14px;
  margin-bottom: 8px;
  width: 14rem;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: lowercase;
  height: 20.2px;
}

.beneTxn_viewall_name_div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: bold;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.beneTxn_viewall_bank_acc_div:first-line {
  text-transform: capitalize !important;
}

.beneTxn_view_all_user {
  white-space: nowrap;
  /* width: 14rem; */
  overflow: hidden;
  text-overflow: ellipsis;
}

.beneTxn_viewall_box_status {
  width: 5px;
  height: 15px;
  background-color: #4ddd37;
  border-radius: 0px 5px 0px 0px;
  top: 0px;
  position: absolute;
  right: 0px;
}

.beneTxn_viewall_assigned {
  display: none;
  height: 15px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  text-transform: none;
  padding-left: 10px;
  padding-right: 10px;
}

.beneTxn_viewall_label_div {
  display: flex;
  height: 20px;
  right: 0px;
  position: absolute;
  bottom: 65px;
}

.css-wdslgk {
  padding-right: 0 !important;
}

@keyframes slideOutRightToLeft {
  0% {
    visibility: visible;
    transform: translateX(0);
  }

  100% {
    visibility: hidden;
    transform: translateX(-10%);
  }
}

.beneTxn_viewall_card_container {
  margin-right: 0px !important;
  width: 90px !important;
  height: 26px !important;
}

.bene_txn_viewall_hover_content {
  display: none;
  padding-top: 2px;
  border-top: 1px solid #1d3a6d;
}

.multiSelectBeneTxnViewAllCard:hover {
  position: absolute;
  border: 1px solid #ff5860;
  height: 185px !important;
  transition: height 0.2s;
  z-index: 4
}

.multiSelectBeneTxnViewAllCard:hover .bene_txn_viewall_hover_content {
  display: block;
  transition: all 0.2s ease 0.2s;
}

@keyframes growHeight {
  from {
    height: 0px;
  }

  to {
    height: 100px;
  }
}

.beneTxn_viewall_category_div {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 2px solid #71717133;
}

.beneTxn_viewall_payment_mode_div {
  /* width: "50%"; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50% !important;
  height: 26px !important;
}

.beneTxn_viewall_paymentmode_image {
  object-fit: contain;
  width: 35px;
  height: 10px;
}

.multiSelectBeneTxnViewAllCard.disabled {
  z-index: 0;
  cursor: default;
  background-color: #000;
  overflow-y: hidden;
}

.bene_txn_logo {
  border-radius: 20px 0px 0px 20px;
}

.multiSelectBeneTxnViewAllCard:hover .bene_txn_logo {
  border-radius: 20px 0px 0px 20px;
}

.device-count-header {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
}

.devices-count {
  display: flex;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.devices-count p:nth-of-type(1) {
  text-align: left;
  font: normal normal 600 20px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_white);
  opacity: 1;
  margin-bottom: 5px;
}

.devices-count p:nth-of-type(2) {
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_grey);
  opacity: 1;
  width: 80px;
}

.generate-button {
  background-color: white;
  padding: 5px;
  border-radius: 5px;
}

.token-text {
  color: var(--Primary_white);
  word-break: break-all;
  height: auto;
}

.token-text::first-letter {
  text-transform: lowercase;
}

.devices_div_container {
  background-color: #314c7f;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
}

.devices_div {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  height: 200px;
  z-index: 1;
  position: relative;
  align-items: center;
}

.devices-gradient {
  height: 200px;
  position: absolute;
  background-color: var(--Blue_7);
  top: 0;
}

.devices-view-count {
  font-size: 12px;
  margin-bottom: 10px;
  letter-spacing: 0px;
  color: var(--Primary_grey);
  font-style: Open, Sans;
}

.No-of-devices {
  font-size: 1.25rem;
  font-weight: bold;
  margin-right: 12px;
  margin-top: 16px;
}

.all-devices-container {
  background: linear-gradient(to right, #1d3a6d 60%, #244277 40%);
}
.devices_acc_total_count_symbol {
  transform: matrix(0, -1, 1, 0, 0, 0);
  font-style: italic;
  color: var(--Primary_white);
  margin-right: 4px;
  font-size: 1.125rem;
}

.devices-acc-no-acc-image {
  width: 288px;
  height: 250px;
  opacity: 0.9;
}

.devices-acc-no-devicesaccount-text,
.no-devicesaccount-text2 {
  color: var(--Primary_white);
  opacity: 1;
  font-size: 1rem;
  text-align: center;
}

.no-beneaccount-text2 {
  display: inline-flex;
  padding-bottom: 18px;
}

#infinityScrollDevicesView {
  display: flex !important;
  flex-direction: column !important;
  overflow: auto;
  height: 100%;
  margin-top: 14rem;
}
@media screen and (max-width: 800px) {
  #infinityScrollDevicesView {
    margin-top: 7rem !important;
  }
  .devices_div {
    height: 80px !important;
  }
  .devices-gradient {
    height: 80px !important;
  }
  .device-count-header {
    margin-top: 6px !important;
  }
  .devices-gradient {
    width: 47% !important;
  }
}

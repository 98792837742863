#BeneviewAllScrollContainerBarbene .infinite-scroll-component {
  overflow-x: hidden;
}
.expentraLoaderclass,
.buttonContainer .BeneTxn_expentraLoaderclass {
  position: relative;
  width: 68px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.benefbtnconfandCan .expentraLoaderclass {
  border-radius: 20px;
  opacity: 0.55;
}

.bene-require-expentraLoaderclass {
  position: relative;
  width: 68px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  opacity: 1;
}
.bene-require-expentraLoaderclass img {
  width: 40px;
  height: 40px;
}
.buttonContainer .BeneTxn_expentraLoaderclass {
  opacity: 1;
}

.expentraLoaderclass img {
  width: 20px;
  height: 40px;
}

.ApprovalNavbar {
  width: 100%;
  height: 208px;
}

.Approvalsec1 {
  width: 100%;
  background: linear-gradient(to right, #506994 50%, #3a5686 50%);
  z-index: 99;
  opacity: 1;
  height: 208px;
}

.Approvalsec2 {
  width: 1024px;
  display: flex;
  background: linear-gradient(to right, #506994 20%, #3a5686 20%);
  height: 208px;
  justify-content: flex-end;
  align-items: end;
  margin: auto;
}

.Approvalsec2 .approvalAcountTopbar {
  display: flex;
  align-items: center;
  height: 50%;
  width: 100%;
}

.Approvalsec2 .firstheaderApproval {
  width: 210px;
}

.Approvalsec2 .SecondheaderApproval {
  display: flex;
  align-items: center;
}

.Approvalsec2 .approvalParentdiv {
  display: flex;
  flex-direction: column;
  gap: 04px;
}

.Approvalsec2 .approvalchild1 {
  width: 51px;
  height: 27px;
  text-align: left;
  font: normal normal 600 20px/27px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_white);
  opacity: 1;
  white-space: nowrap;
  margin-left: 10px;
}

.Approvalsec2 .approvalchild2 {
  width: 100px;
  height: 17px;
  text-align: center;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_grey);
  opacity: 1;
}

.Approvalsec2 .navbarleft {
  display: flex;
  flex-direction: column;
  margin-left: 60px;
  gap: 4px;
}

.Approvalsec2 .navbarright {
  display: flex;
  flex-direction: column;
  margin-left: 60px;
  gap: 04px;
}

.Approvalsec2 .approvedtag1 {
  width: 23px;
  height: 27px;
  text-align: left;
  font: normal normal 600 20px/27px Open Sans;
  letter-spacing: 0px;
  color: var(--Settled);
  opacity: 1;
}

.Approvalsec2 .approvedtag2 {
  width: 55px;
  height: 17px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_grey);
  opacity: 1;
}

.Approvalsec2 .approvedtag3 {
  width: 23px;
  height: 27px;
  text-align: left;
  font: normal normal 600 20px/27px Open Sans;
  letter-spacing: 0px;
  color: var(--Failed);
  opacity: 1;
}

.Approvalsec2 .approvedtag4 {
  width: 48px;
  height: 17px;
  text-align: center;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_grey);
  opacity: 1;
}

@media (max-width: 1520px) {
  .allAprovalAcountInfo {
    height: 76vh;
  }
}

.approvedAcoutDetails {
  display: flex;
  flex-direction: row;
  width: 1024px;
  height: 200px;
  background: #314d7e;

  border-radius: 30px;
  margin-bottom: 20px;

  margin: 20px auto;
}

.ApprovalAcdiv1 {
  width: 341px;
  height: 100%;
  border-right: 2px solid #506994;
}

.ApprovalAcdiv1 .Approvalsection1 {
  width: 100%;
  height: 100%;
}

.ApprovalAcdiv1 .approvaltext1 {
  display: flex;
  width: 100%;
  margin-bottom: 4px;
}

.ApprovalAcdiv1 .approvaltext1 p {
  width: 163px;
  height: 19px;
  text-transform: lowercase;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #fcfcfc;
  margin-top: 20px;
  margin-left: 20px;
  opacity: 1;
}

.ApprovalAcdiv1 .approvaltext1 p::first-line {
  text-transform: capitalize !important;
}

.ApprovalAcdiv1 .approvaltext1 span {
  width: 108px;
  height: 19px;
  margin-top: 20px;
  padding-left: 55px;
  margin-right: -20px;
  font: normal normal normal 14px/19px Open Sans;
  color: #fcfcfc;
  opacity: 1;
}

.ApprovalAcdiv1 .approvaltext2 {
  width: 100%;
  display: flex;
  margin-bottom: 4px;
}

.ApprovalAcdiv1 .approvaltext2 .z1 {
  width: 34px;
  height: 17px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
  margin-left: 20px;
}

.ApprovalAcdiv1 .approvaltext2 .z2 {
  width: 284px;
  height: 17px;
  text-align: right;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
  margin-right: 20px;
  margin-bottom: 20px;
}

.ApprovalAcdiv1 .approvaltext3 {
  margin-top: -20px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ApprovalAcdiv1 .approvaltext3 p {
  /*width: 229px;*/
  width: 300px;
  height: 19px;
  margin-top: 20px;
  overflow: hidden;
  margin-left: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #fcfcfc;
  opacity: 1;
}

.ApprovalAcdiv1 .approvaltext3 span {
  width: 44px;
  height: 17px;
  margin-left: 20px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
}

.ApprovalAcdiv1 .approvaltext4 {
  margin-top: -5px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ApprovalAcdiv1 .approvaltext4 p {
  width: 300px;
  height: 19px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: 20px;
  margin-left: 20px;
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #fcfcfc;
  opacity: 1;
}

.ApprovalAcdiv1 .approvaltext4 span {
  width: 111px;
  height: 17px;
  margin-left: 20px;
  margin-bottom: 50px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
}

.ApprovalAcdiv2 {
  width: 341px;
  height: 100%;
  color: white;
  border-right: 2px solid #506994;
}

.ApprovalAcdiv2 .Approvalsection2 {
  width: 100%;
  height: 100%;
}

.ApprovalAcdiv2 .Approvalsection2 .approvalDiv1 {
  display: flex;
  margin-left: 20px;
  margin-top: -7px;
  justify-content: space-between;
}

.ApprovalAcdiv2 .Approvalsection2 .approvalDiv1 p {
  width: 182px;
  height: 19px;
  text-transform: lowercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 22px;
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #fcfcfc;
  opacity: 1;
}

.ApprovalAcdiv2 .Approvalsection2 .approvalDiv1 .approvalDiv1span {
  height: 19px;
  margin-top: 20px;
  text-align: -webkit-match-parent;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #fcfcfc;
  opacity: 1;
}

.ApprovalAcdiv2 .Approvalsection2 .approvalDiv2 {
  display: flex;
  justify-content: space-between;
  margin-right: 20px;
}

.ApprovalAcdiv2 .Approvalsection2 .approvalDiv2 .n1 {
  width: auto;
  height: 17px;
  margin-left: 20px;
  margin-top: 4px;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
}

.ApprovalAcdiv2 .Approvalsection2 .approvalDiv2 .n2 {
  width: 72px;
  height: 17px;
  margin-top: 4px;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
  margin-right: 3px;
}

.ApprovalAcdiv2 .Approvalsection2 .approvalDiv3 {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.ApprovalAcdiv2 .Approvalsection2 .approvalDiv3 p {
  width: 150px !important;
  height: 19px;
  margin-left: 20px;

  overflow: hidden;
  text-overflow: ellipsis;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #fcfcfc;
  opacity: 1;
}

.ApprovalAcdiv2 .Approvalsection2 .approvalDiv3 span {
  width: 81px;
  height: 19px;
  margin-right: 20px;
  text-align: right;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #fcfcfc;
  opacity: 1;
}

.ApprovalAcdiv2 .Approvalsection2 .approvalDiv4 {
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
}

.ApprovalAcdiv2 .Approvalsection2 .approvalDiv4 p {
  width: 120px;
  height: 17px;
  margin-left: 20px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
}

.ApprovalAcdiv2 .Approvalsection2 .approvalDiv4 span {
  width: 34px;
  height: 17px;
  margin-right: 20px;
  text-align: right;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
}

.ApprovalAcdiv2 .Approvalsection2 .approvalDiv5 {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.ApprovalAcdiv2 .Approvalsection2 .approvalDiv5 p {
  width: 93px;
  height: 19px;
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #fcfcfc;
  opacity: 1;
  margin-left: 20px;
}

.ApprovalAcdiv2 .Approvalsection2 .approvalDiv5 span {
  width: 54px;
  height: 17px;
  margin-top: 4px;
  margin-left: 20px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
}

.ApprovalAcdiv2 .Approvalsection2 .approvalDiv5 .b1 {
  width: 100px;
  height: 35px;
  margin-right: 20px;
  background: #fafafa;
  border-radius: 22px;
  opacity: 1;
}

.ApprovalAcdiv2 .Approvalsection2 .approvalDiv5 .b1 img {
  width: 80px;
  height: 20px;
  margin-top: 7px;
  margin-left: 10px;
  margin-bottom: 12px;
  margin-right: 17px;
}

.mainPopup {
  position: fixed;
  left: 0%;
  width: 100%;
  height: 100%;
  box-shadow: 0px 3px 6px #00000029;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
}

.internalDeclinedPopup {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 246px;
  left: 403px;
  background: #153164 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  opacity: 1;
  margin-left: 5px;
  margin-right: 5px;
}

/*@media only screen and (min-width: 300px) and (max-width: 480px) {
  .internalDeclinedPopup {
    width: 335px !important;
    height: 270px !important;
    top: 150px;
    left: 12px;
    padding: 10px;
  }
  .internalDeclinedPopup .benefbtnconfandCan {
    margin-bottom: 0px !important;
  }
  .internalDeclinedPopup .msgReason p {
    width: 330px !important;
    height: 25px !important;
    white-space: wrap !important;
    margin-left: 10px !important;
  }
  .internalDeclinedPopup .msgReason h2 {
    margin-left: 10px !important;
  }
  .internalDeclinedPopup .customInputfield {
    margin-left: 10px !important;
  }
}*/

.internalDeclinedPopup .msgReason {
  display: flex;
  flex-direction: column;
}

.internalDeclinedPopup .msgReason h2 {
  width: 156px;
  text-align: left;
  font: normal normal 600 20px/27px Open Sans;
  letter-spacing: 0px;
  color: #ff7f33;
  opacity: 1;
  margin-top: 20px;
  margin-left: 30px;
}

.internalDeclinedPopup .msgReason p {
  width: 90%;
  height: 25px;
  text-align: left;
  font: normal normal 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  white-space: nowrap;
  margin-left: 30px;
  margin-top: 10px;
}
@media only screen and (min-width: 300px) and (max-width: 480px) {
  .internalDeclinedPopup .msgReason p {
    /* width: 0px; */
    /* height: 0px; */
    white-space: wrap;
  }
}
/* @media only screen and (min-width: 300px) and (max-width: 350px) {
  .failed-action-message {
    width: 221px !important;
  }
} */
.internalDeclinedPopup .customInputfield {
  display: flex;
  position: relative;
  margin-left: 30px;
  /* margin-top: 36px; */
  margin-right: 17px;
}

.internalDeclinedPopup .customInputfield input {
  font-size: 16px;
  color: white;
  padding: 0px 10px;
  background-color: transparent;
  outline: none;
  opacity: 1;
}

.customInputfield label {
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  font-size: 16px;
  color: #ffff;
  padding: 0 10px;
  pointer-events: none;
  transition: 0.5s;
}

.internalDeclinedPopup .benefbtnconfandCan {
  display: flex !important;
  align-items: center;
  justify-content: flex-end;
  margin-right: 30px;
  margin-bottom: 29px;
  gap: 30px;
}

.internalDeclinedPopup .benefbtnconfandCan .confirmBtn {
  width: 100px;
  height: 42px;
  box-shadow: 0px 3px 6px #00000033;
  border: 2px solid #cccccc;
  /* opacity: 0.5; */
  border-radius: 20px;
  text-align: center;
  font: normal normal normal 16px/14px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
}

.internalDeclinedPopup .benefbtnconfandCan .cancleBtn {
  width: 50px;
  height: 22px;
  text-align: center;
  text-decoration: underline;
  font: normal normal normal 16px/14px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.ApprovalAcdiv3 {
  width: 341px;
  height: 100%;
}

.ApprovalAcdiv3 .Approvalsection3 {
  width: 100%;
  height: 100%;
}

.ApprovalAcdiv3 .Approvalsection3 .AprovalpageContainx1 {
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ApprovalAcdiv3 .Approvalsection3 .AprovalpageContainx1 p {
  width: 135px;
  height: 27px;
  text-transform: lowercase;
  margin-top: 17px;
  margin-left: 20px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #fcfcfc;
  opacity: 1;
}

.ApprovalAcdiv3 .Approvalsection3 .AprovalpageContainx1 p::first-line {
  text-transform: capitalize !important;
}

.ApprovalAcdiv3 .Approvalsection3 .AprovalpageContainx1 span {
  width: 100px;
  height: 27px;
  margin-top: 20px;
  margin-right: 20px;
  text-align: right;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #fcfcfc;
  opacity: 1;
}

.ApprovalAcdiv3 .Approvalsection3 .AprovalpageContainx2 {
  margin-top: -2%;
  display: flex;
  justify-content: space-between;
}

.ApprovalAcdiv3 .Approvalsection3 .AprovalpageContainx2 .b1 {
  width: 89px;
  height: 15px;
  text-align: left;
  font: normal normal normal 12px/19px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
  margin-left: 20px;
  margin-top: -2px;
}

.ApprovalAcdiv3 .Approvalsection3 .AprovalpageContainx2 .b2 {
  width: 80px;
  height: 15px;
  margin-top: 04px;
  margin-right: 20px;
  text-align: right;
  font: normal normal normal 12px/19px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
  margin-top: -2px;
}

.ApprovalAcdiv3 .Approvalsection3 .logo {
  display: flex;
  height: 50%;
  width: 80%;
  margin-left: 68px;
  margin-top: 46px;
  flex-direction: end;
}

.ApprovalAcdiv3 .Approvalsection3 .logo .logo1 {
  display: flex;
  flex-direction: end;
  justify-content: space-between;
  right: 0;
  width: 100%;
  height: 100%;
  background: #506994 0% 0% no-repeat padding-box;
  border-radius: 28px 0px;
  opacity: 1;
}

.ApprovalAcdiv3 .Approvalsection3 .logo .logo1 .expentraloader {
  display: flex;
  justify-content: space-between;
  width: 50px;
  height: 29px;
  margin-top: 35px;
  margin-left: 110px;
  opacity: 1;
  align-items: center;
}

.ApprovalAcdiv3 .Approvalsection3 .logo .logo1 .errorApproval {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  height: 60px;
  width: auto;
  margin-top: 20px;
  margin-left: 20px;
}

.errorApproval {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  height: 60px;
  width: auto;
  margin: 20px;
}

.ApprovalAcdiv3 .Approvalsection3 .logo .logo1 .Unknownpage .Unknownpage1 img {
  width: 40px;
  height: 40px;
  opacity: 1;
  margin-top: 10px;
  margin-left: 25px;
}
.errorApproval .failed img {
  width: 40px;
  height: 40px;
  opacity: 1;
}

.ApprovalAcdiv3 .Approvalsection3 .logo .logo1 .Unknownpage .Unknownpage2 {
  width: auto;
  height: 60px;
}

.ApprovalAcdiv3 .Approvalsection3 .logo .logo1 .Unknownpage .Unknownpage2 span {
  width: 118px;
  height: 22px;
  text-align: left;
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
.mobilefailed span {
  width: 118px;
  height: 22px;
  text-align: left;
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.ApprovalAcdiv3 .Approvalsection3 .logo .logo1 .Unknownpage .Unknownpage2 p {
  width: 169px;
  height: 34px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #fafafa;
  opacity: 1;
}
.logo .logo1 .errorApproval .mobilefailed p {
  width: 300px;
  height: auto;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #fafafa;
  opacity: 1;
}
.loaderApproval {
  width: 300px;
  height: 88px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 35px;
}

.ApprovalAcdiv3 .Approvalsection3 .logo .logo1 .Declinedpage {
  width: 273px;
  height: 100%;
  gap: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ff5860 0% 0% no-repeat padding-box;
  border-radius: 30px 0px;
  opacity: 1;
}

.ApprovalAcdiv3 .Approvalsection3 .logo .logo1 .Declinedpage .Declinedpage1 {
  width: 40px;
  height: 40px;
  opacity: 1;
}

.ApprovalAcdiv3 .Approvalsection3 .logo .logo1 .Declinedpage .Declinedpage2 {
  width: 153px;
  height: 43px;
  opacity: 1;
}

.ApprovalAcdiv3
  .Approvalsection3
  .logo
  .logo1
  .Declinedpage
  .Declinedpage2
  span {
  width: 72px;
  height: 22px;
  text-align: left;
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.ApprovalAcdiv3 .Approvalsection3 .logo .logo1 .Declinedpage .Declinedpage2 p {
  width: 163px;
  height: 17px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #fafafa;
  opacity: 1;
}

.ApprovalAcdiv3 .Approvalsection3 .logo .logo1 .Approvedpage {
  width: 273px;
  height: 100%;
  gap: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px 0px;
  opacity: 1;
  background: #3cb371 0% 0% no-repeat padding-box;
}

.ApprovalAcdiv3 .Approvalsection3 .logo .logo1 .Approvedpage .Approvedpage1 {
  width: 40px;
  height: 40px;
  opacity: 1;
}

.ApprovalAcdiv3 .Approvalsection3 .logo .logo1 .Approvedpage .Approvedpage2 {
  width: 153px;
  height: 43px;
  opacity: 1;
}

.ApprovalAcdiv3
  .Approvalsection3
  .logo
  .logo1
  .Approvedpage
  .Approvedpage2
  span {
  width: 72px;
  height: 22px;
  text-align: left;
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.ApprovalAcdiv3 .Approvalsection3 .logo .logo1 .Approvedpage .Approvedpage2 p {
  width: 163px;
  height: 17px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #fafafa;
  opacity: 1;
}

.ApprovalAcdiv3 .Approvalsection3 .logo .logo1 .Approvalleftside {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 5px;
  margin-bottom: 1px;
  gap: 04px;
  width: 45%;
  align-items: center;
}

.ApprovalAcdiv3 .Approvalsection3 .logo .logo1 .Approvalleftside img {
  width: 40px;
  height: 40px;
  box-shadow: -5px -5px 11px #71717140;
  opacity: 1;
}

.ApprovalAcdiv3 .Approvalsection3 .logo .logo1 .Approvalleftside p {
  width: 34px;
  height: 14px;
  text-align: center;
  font: normal normal normal 10px/14px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.ApprovalAcdiv3 .Approvalsection3 .logo .logo1 .approvaldivider {
  width: 0px;
  height: 100%;
  border: 1px solid #1d3a6d;
  opacity: 1;
}

.ApprovalAcdiv3 .Approvalsection3 .logo .logo1 .Approvalrightside {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  width: 45%;
  gap: 04px;
}

.ApprovalAcdiv3 .Approvalsection3 .logo .logo1 .Approvalrightside img {
  width: 40px;
  height: 40px;
  box-shadow: -5px -5px 11px #71717140;
  opacity: 1;
}

.ApprovalAcdiv3 .Approvalsection3 .logo .logo1 .Approvalrightside p {
  width: 40px;
  height: 14px;
  text-align: center;
  font: normal normal normal 10px/14px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.BeneAccount_NoAccountFound {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 95px;
  /* gap: 61px; */
  opacity: 1;
}

.BeneAccount_LeftTextContentNoAccount {
  width: 274px;
  height: 44px;
  text-align: center;
  font: normal normal normal 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 0.9;
  padding-bottom: 90px;
}

.BeneAccount_RightTextContentNoAccount {
  width: 340px;
  height: 261px;
  opacity: 1;
}

#requireApprovalScroll {
  overflow: auto;
  width: 100%;
  height: 73vh;
}

body::-webkit-scrollbar {
  display: none;
}

.requireApproval_scroller::-webkit-scrollbar {
  width: 10px;
}

.requireApproval_scroller::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

.requireApproval_scroller::-webkit-scrollbar-thumb {
  background-color: #fff;
  outline: 1px solid #314d7e;
  border-radius: 25px;
  height: 100px;
}

.confirm-btn-enable {
  width: 68px;
  height: 32px;
  background: var(--Button_green);
  border-radius: 20px;
  border: none;
  opacity: 1;
  color: var(--Primary_white);
  font-size: var(--Font_12);
  font-weight: var(--Font_semibold);
  font-family: Open Sans;
  cursor: pointer;
}

.bene_approval_heder_containerm {
  background-color: #314c7f;
  background-color: #314c7f;
  margin-top: 0px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
}

.allBeneapprovalheadertext {
  width: 100%;
  padding: 20px 10px;
  border-bottom: 4px solid #1d3a6d;
  display: table;
}

.allBeneapprovalheaderarrow {
  display: table-cell;
  width: 10%;
  vertical-align: middle;
  cursor: pointer;
}

.allBeneapprovalheadertextm {
  display: table-cell;
  width: 90%;
  vertical-align: middle;
  padding: 0px 10px;
}

.allBeneapprovaltext {
  width: 100%;
  padding: 20px 20px;
  border-bottom: 2px solid #3a5686;
  color: #c9c9c9;
}

.bene_approval_containerm {
  width: 100%;
  margin-top: 100px;
}

.SecondheaderApprovalm {
  display: flex;
  align-items: center;
  padding: 20px;
  color: #c9c9c9;
}

.navbarleftm {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 50%;
  text-align: left;
}

.navbarrightm {
  display: flex;
  flex-direction: column;
  gap: 04px;
  width: 50%;
  text-align: right;
}

.approvedAcoutDetailsm {
  width: 95%;
  background: #314d7e;
  border-radius: 30px;
  margin-bottom: 20px;
  margin: 20px auto;
}

.ApprovalAcdivm1 {
  width: 100%;
  height: 100%;
  border-bottom: 2px solid #506994;
}

.ApprovalAcdivm1 .Approvalsection1 {
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
}

.ApprovalAcdivm1 .approvaltext1 {
  display: flex;
  width: 100%;
  margin-bottom: 4px;
}
.approvaltext1m :first-child {
  width: 163px;
  height: 19px;
  text-transform: lowercase;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #fcfcfc;
  opacity: 1;
}
.approvaltext1m :first-child::first-line {
  text-transform: capitalize !important;
}
.approvalDiv1m :first-child {
  width: 182px;
  height: 19px;
  text-transform: lowercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 22px;
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #fcfcfc;
  opacity: 1;
}
.approvalDiv1m :first-child::first-line {
  text-transform: capitalize !important;
}
.ApprovalAcdiv2 .Approvalsection2 .approvalDiv1 p::first-line {
  text-transform: capitalize !important;
}
.approvalCategorym :first-child {
  /* width: 400px; */
  height: 19px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: 20px;
  margin-left: 20px;
  text-align: left;
  font: normal normal normal 14px / 19px Open Sans;
  letter-spacing: 0px;
  color: #fcfcfc;
  opacity: 1;
}
.approvalCategorym :first-child ::first-line {
  text-transform: capitalize !important;
}
.approvalRequestedmChild {
  width: 150px;
  height: 19px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  font: normal normal normal 14px / 19px Open Sans;
  letter-spacing: 0px;
  color: #fcfcfc;
  opacity: 1;
  text-transform: capitalize !important;
}
.approvalRequestedmChild ::first-line {
  text-transform: capitalize !important;
}
.AprovalpageContainx1 p ::first-line {
  text-transform: capitalize !important;
}

@media screen and (max-width: 600px) {
  /* .mainPopup{
    bottom: 0 !important;
  } */
  .internalDeclinedPopup .msgReason h2 {
    font: normal normal 600 16px/27px Open Sans !important;
  }

  .internalDeclinedPopup .msgReason p {
    font: normal normal 14px/22px Open Sans !important;
    white-space: wrap !important;
    /* width: 298px !important; */
  }
  .internalDeclinedPopup .customInputfield input {
    font-size: 14px !important;
    width: 100% !important;
    margin-right: 10px;
  }

  .customInputfield label {
    font-size: 14px !important;
  }

  .buttonContainer .confirmBtn,
  .buttonContainer .cancleBtn {
    font: normal normal normal 14px/14px Open Sans !important;
  }
}

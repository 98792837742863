.transactionHistory_pop_div {
  width: 96vw;
  height: 92vh;
  top: 4%;
  left: 2%;
}

.itxn_card_hover_overlay {
  display: none;
  position: absolute;
  width: 74px;
  height: 20px;
  border-radius: 5px;
}

.itxn:hover > .itxn_card_hover_overlay {
  display: block;
  background-color: #f9f9f9;
  opacity: 0.2;
  z-index: 9;
}

@media (max-width: 800px) {
  .transactionHistory_pop_div {
    width: 100% !important;
    height: 100% !important;
    top: 0% !important;
    left: 0% !important;
  }

  .popup_responsive {
    display: none !important;
  }
}

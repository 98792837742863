

.internalTxnDetailsBannerLeft,
.internalTxnDetailsBannerMiddle,
.internalTxnDetailsBannerRight {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: inherit;
}

.internalTxnDetailsBannerLeft {
  font: normal normal normal 27px/33px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
}

.internalTxnDetailsBannerRight {
  font: normal normal normal 27px/33px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
}
.internalTxnDetailsBannerLeft > div:nth-child(2),
.internalTxnDetailsBannerRight > div:nth-child(2) {
  background-color: white;
  width: 132px;
  height: 37px;
  padding: 5px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 25px;
}

.internalTxnDetailsBannerMiddleBottom {
  font: normal normal normal 32px/43px Open Sans;
  letter-spacing: 0px;
}

.internalTxnDetailsBannerMiddleTop {
  font: normal normal bold 20px/27px Open Sans;
  letter-spacing: 0px;
}

.txnDetailsBannerClientMbBottom {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.txnDetailsBannerClientMbBottom > div {
  height: 250px;
  width: 250px;
  justify-content: space-evenly;
  border-radius: 50%;
}

.txnDetailsBannerClientMbBottom > div:nth-child(1) {
  margin-left: -50px;
}

.txnDetailsBannerClientMbBottom > div:nth-child(2) {
  margin-right: -50px;
}

#txnDetailsBannerClientMb > div:nth-child(1) {
  align-self: center;
}

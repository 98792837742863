


.edit_bank_con_main{
  width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}


.edit_bank_details_all {
  max-width: 1024px;
  padding-top: 186px;
  height: 100%;
  overflow: hidden;
  scroll-behavior: smooth;
  display: flex;
  scroll-snap-type: x mandatory;
  margin: 0 auto;
}

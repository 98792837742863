.enterAmount {
  background: #338a3e 0% 0% no-repeat padding-box;
  border-radius: 40px;
}

.enterAmountInput {
  width: 100%;
  font: normal normal normal 2.2em Open Sans !important;
  letter-spacing: 0px !important;
  color: #ffffff !important;
}

.enterAmountInput input {
  text-align: center;
  border-bottom: 2px solid #ffffff !important;
}

.enterAddDetails {
  text-align: left !important;
  font: normal normal normal 18px/24px Open Sans !important;
  letter-spacing: 0px !important;
  width: 100%;
}

.enterAddDetails>text {
  color: #c9c9c9;
}

.additionalDetailsText {
  font: normal normal 600 24px/33px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.estimatedDate>span {
  font: normal normal normal 16px/22px Open Sans;
}

@media only screen and (max-width: 480px) {
  .SettingAndNotifications {
    display: block;
  }
}

.SettingAndNotifications {
  margin-right: 5%;
}

.css-4d9bkr {
  width: 100% !important;
}

.css-1i7f94s {
  padding: 2% 0px !important;
}

.css-m41wei {
  width: 100% !important;
}

.css-ofrcno-MuiGrid-root > .MuiGrid-item {
  padding-left: 0px !important;
}
.css-1osj8n2-MuiGrid-root {
  -webkit-flex-basis: 70% !important;
  -ms-flex-preferred-size: 70% !important;
  flex-basis: 70% !important;
  max-width: 70% !important;
}

.mobilmenumain {
  display: table;
  width: 100%;
  height: 100%;
  padding: 8px 0px !important;
  font-size: 12px;
}

.mobilmenu {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  width: 25%;
}

.css-1957t9h-MuiTypography-root {
  font-size: 14px !important;
}

.owner-datacard {
  background-color: red;
  margin-top: 6px;
  width: 129px;
  height: 111px;
  background: var(--Blue_7) 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  margin: 0px 6px;
}

.isMob {
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 119px;
  width: 100px;
}

.isMob .colorpage {
  margin-left: 67px;
}

.isMob .cardpage {
  transform: translateY(-15px);
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-left: 0;
  padding-left: 10px;
  padding-top: 0 !important;
  position: static;
  /* width: 110px; */
}

.isMob .word1 {
  font-size: 36px;
  font-weight: 600;
  height: 40px;
  line-height: 40px;
  width: 42px;
  letter-spacing: 2px !important;
}

@media (max-width: 800px) {
  .internalTxn-navMenu .owner-datacard {
    width: 100px;
  }

  .internalTxn-navMenu .colorpage {    
    margin-left: 67px;
  }
}

@media (max-width: 600px) {
  .internalTxn-navMenu .owner-datacard {    
    width: 100px;
  }

  .internalTxn-navMenu .colorpage {
    margin-left: 67px;
  }
}

@media (max-width: 400px) {
  .internalTxn-navMenu .owner-datacard {
    width: 100px;
  }
}

@media (max-width: 380px) {
  .internalTxn-navMenu .owner-datacard {
    width: 92px;
  }
  .internalTxn-navMenu .colorpage {
    margin-left: 59px;
  }
}
/* @media (max-width: 800px) {
  .owner-datacard {
    width: 100px;
    height: 114px;
  }

  .colorpage {
    margin-left: 66px !important;
  }

  .word1 {
    font-size: 36px !important;
    font-weight: 600 !important;
    height: 40px !important;
    line-height: 40px !important;
    width: 42px !important;
  }
} */

.txnStatusCard1 {
  margin-top: 150px;
  align-self: right;
  background-color: white;
  width: fit-content;
  padding: 2% 0% 0% 0%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 40px;
}



@media only screen and (max-width: 480px) {
  .threeOptions {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: fit-content;
    width: 90%;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 40px 40px 0px 0px;
  }
  .logoWithText {
    position: a;
    display: flex;
    color: white;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 0;
  }
  .txnStatusCard1 {
    flex-grow: 1;
    box-shadow: none;
    width: 100%;
  }
}

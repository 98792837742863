.truncated-container {
  text-align: center;
  max-width: 455px;
  max-height: 70px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  line-height: 1.2em;
  height: calc(1.2em * 3);
  cursor: default;
  margin: 0px 0px 10px 0px;
  font-size: 10px;
}

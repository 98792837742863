.bene_txn_search-image_acc {
  width: 18px;
  border-radius: 10px;
}

#txn_search_acc:focus {
  padding-left: 55px;
  padding-right: 35px;
}
#txn_search_acc {
  width: 1024px;
  padding-left: 55px;
  padding-right: 35px;
  height: 40px;
  outline: none;
}
.bene_txn_viewall_search_inner_image_acc {
  width: 15px;
  height: 15px;
  position: absolute;
  top: 13px;
  left: 20px;
}
.bene_txn_search_close_image_acc {
  width: 16px;
  height: 16px;
  position: absolute;
  right: 20px;
  top: 12px;
}
.bene-txn-searchbar_acc {
  border-radius: 0 !important;
  left: 0;
  width: 100%;
}
.bene-txn-searchbar_acc.open {
  height: 90px;
  animation: growDown 300ms ease-in-out forwards;
  transform-origin: top center;
}

@keyframes growDown {
  0% {
    transform: scaleY(0);
  }

  100% {
    transform: scaleY(1);
  }
}
.bene-txn-searchbar_acc.closed {
  transition: all 0.2s ease-in;
  height: 0;
}
.bene-txn-viewall-search-bar_acc {
  display: flex;
  width: 100%;
  max-width: 1024px !important;
  margin: 0 auto;
  z-index: 1;
  flex-direction: row;
  border-top: 1px solid #031f4f;
  height: 93px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.search_bar_acc {
  width: 1024px;
  height: 40px;
  background: #244277 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  position: relative;
  margin-bottom: 15px;
}

.bene-txn-viewall-search-bar_acc_input {
  width: 1024px;
  height: 49px;

  background: #244277 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
}
.search-amount-div_acc {
  width: 165px;
  height: 49px;
  background: #667ca3 0% 0% no-repeat padding-box;
  border-radius: 10px 0px 0px 10px;
  opacity: 1;
  font-size: 14px;
  color: #fafafa;
  padding: 12px;
}
.bene_txn_viewall_search_button_acc {
  width: 105px;
  height: 45px;
  background: #707070 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  margin-left: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.expand {
  animation: expandHeight 0.3s forwards;
}

.collapse {
  animation: collapseHeight 0.3s forwards;
}

@keyframes expandHeight {
  from {
    height: 0;
  }
  to {
    height: 93px;
  }
}

@keyframes collapseHeight {
  from {
    height: 93px;
  }
  to {
    height: 0;
  }
}

.cardSecurityCenter {
  width: 330px !important;
  height: 2.8175rem !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  border-radius: 10px !important;
  padding: 10px !important;
  margin: 0 5px !important;
  text-transform: capitalize !important;
  transition: all 0.3s ease-in-out;
}

.cardSecurityCenter:hover {
  background-color: rgba(255, 255, 255, 0.2) !important;
  border: none !important;
  border-radius: 10px !important;
  padding: 10px !important;
  transition: background-color 0.3s ease-in-out;
}

@media (max-width: 640px) {
  .cardSecurityCenter {
    width: 100% !important;
  }
}

.cardSecurityCenter > img {
  width: 1.25rem;
  height: 1.25rem;
}

.contentDataSecurity {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.contentDataSecurity > img {
  width: 1.4375rem;
  height: 1.4375rem;
  margin-right: 0.625rem;
}

.contentDataSecurity > h6 {
  width: fit-content;
  height: 1.1875rem;
  text-align: left;
  font: normal normal normal 0.875rem/1.1875rem Open Sans;
  letter-spacing: 0px;
  opacity: 1;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.1875rem;
}

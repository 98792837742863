.selectPayContainer {
  color: white;
  display: flex;
  justify-content: center;
  margin-top: -60px;
  position: fixed;
  width: 100vw;
}

.paymenthodText {
  text-align: center;
  font: italic normal bold 30px/41px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
}

.selectPayLeft {
  width: 40%;
  padding: 2% 15% 2% 5%;
  background-color: #1d3a6d;
}

.selectPayRight {
  width: 50%;
  padding: 2% 5%;
  background-color: #3a5686;
  display: flex;
  height: 100vh;
  flex-direction: column;
}

.selectPayHeading > p {
  font: normal normal normal 50px/68px Open Sans;
  letter-spacing: 0px;
  color: #f1f1f1;
  margin: 0%;
  text-align: left;
}

.selectPayHeading > span {
  font: normal normal normal 24px/33px Open Sans;
  letter-spacing: 0px;
  color: #f1f1f1;
  text-align: left;
  width: 100%;
  display: block !important;
  margin-bottom: 5%;
}

.paymentCardHeading {
  display: flex;
  width: 90%;
  justify-content: space-between;
  margin-bottom: 2%;
}

#selectPayHeadingMobile {
  top: 120px;
}

#TxnCreatedCardMobile {
  padding-bottom: 5%;
  color: white;
}

@media only screen and (max-width: 480px) {
  #selectPayHeadingMobile {
    top: 120px;
    background-color: #1d3a6d;
  }
  .selectPayContainer {
    padding-top: 240px;
    display: flex;
    flex-wrap: wrap;
  }
  .selectPayRight {
    width: 100%;
    border-radius: 40px 40px 0px 0px;
    padding-bottom: 20%;
    height: 100vh;
  }
  #TxnCreatedCardDesktop {
    display: none;
  }
  .selectPayHeading {
    display: none;
  }
}

@media only screen and (min-width: 480px) {
  #TxnCreatedCardMobile {
    display: none;
  }
}

#paymentBTsMobile {
  background-color: #1d3a6d;
}

.paymentProceedBT {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
  border-radius: 30px;
  width: 90%;
  height: 30px;
  cursor: pointer;
  font: normal normal 600 1.6em Open Sans;
}

.paymentCardsContainer::-webkit-scrollbar {
  width: 10px;
}

.paymentCardsContainer::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

.paymentCardsContainer::-webkit-scrollbar-thumb {
  background-color: #eeeeee;
  outline: 1px solid slategrey;
  border-radius: 25px;
  height: 40px;
}

.cin_main_div {
  display: flex;
  flex-direction: column;
}

.cin_search {
  position: fixed;
  position: fixed;
  width: 100%;
  height: 13.125rem;
  background: var(--Blue_6) 0% 0% no-repeat padding-box;
  height: 13.125rem;
  background: var(--Blue_6) 0% 0% no-repeat padding-box;
  opacity: 1;
  padding-bottom: 1.6875rem;
  padding-bottom: 1.6875rem;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 99;
}

@media (max-height: 800px) {
  .cin_search {
    height: 8rem;
    padding-bottom: 0;
  }
  .cin_search_1024 {
    width: 100%;
  }
  .cin_input_div {
    width: 80%;
  }
}

.cin_search_1024 {
  max-width: 64rem;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cin_input_div {
  display: flex;
  position: relative;
  margin-right: 1.25rem;
}

.cin_input_div > input {
  width: 54.75rem;
  height: 2.5rem;
  background: var(--Blue_4) 0% 0% no-repeat padding-box;
  border-radius: 0.625rem;
  opacity: 1;
  padding-left: 1.25rem;
  text-align: left;
  font: normal normal normal 0.875rem/1.1875rem Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_white);
  outline: none;
}

.cin_input_div > input::placeholder {
  text-align: left;
  font: normal normal normal 0.875rem/1.1875rem Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_grey);
  opacity: 1;
}

.cin_input_div > img {
  width: 1.25rem;
  height: 1.25rem;
  position: absolute;
  right: 1.25rem;
  top: 0.688rem;
  cursor: pointer;
}

.cin_fetch_button {
  width: 8rem !important;
  height: 2.5rem;
  border-radius: 0.625rem !important;
  text-align: center !important;
  font: normal normal 600 0.875rem/1.1875rem Open Sans !important;
  letter-spacing: 0px !important;
  text-transform: none !important;
}
.btnfetch {
  width: 8rem !important;
  border-radius: 0.625rem !important;
  text-align: center !important;
  font: normal normal 600 0.875rem/1.1875rem Open Sans !important;
  letter-spacing: 0px !important;
  text-transform: none !important;
}

@media only screen and (min-width: 1024px) {
  .btnfetch {
    width: 39px !important;
  }
}

@media only screen and (min-width: 800px) and (max-width: 1024px) {
  .btnfetch {
    width: 49px !important;
  }
}

@media only screen and (max-width: 800px) {
  .btnfetch {
    width: 100% !important;
    height: 3.063rem !important;
    border-radius: 0px !important;
    height: 2.75rem !important;
  }
}

@media only screen and (max-width: 600px) {
  .btnfetch {
    width: 100% !important;
    height: 2.75rem !important;
  }
}
.searchInputContainer {
  display: flex;
  flex-direction: row;
  padding: 0 15px;
  gap: 22px;
  border-radius: 0 !important;
}

@media only screen and (max-width: 800px) {
  .cin_fetch_button {
    width: 100% !important;
    border-radius: 0px !important;
  }

  .searchInputContainer {
    display: flex;
    flex-direction: column;
    padding: 0px;
    gap: 0px;
    border-radius: 0 !important;
  }
}

@media only screen and (min-width: 1024px) {
  .btnfetch {
    width: 39px !important;
  }
}

@media only screen and (min-width: 800px) and (max-width: 1024px) {
  .btnfetch {
    width: 49px !important;
  }
}

@media only screen and (max-width: 800px) {
  .btnfetch {
    width: 100% !important;
    height: 3.063rem !important;
    border-radius: 0px !important;
    height: 2.75rem !important;
  }
}

@media only screen and (max-width: 600px) {
  .btnfetch {
    width: 100% !important;
    height: 3.063rem !important;
    height: 2.75rem !important;
  }
}
.searchInputContainer {
  display: flex;
  flex-direction: row;
  padding: 0 15px;
  gap: 22px;
  border-radius: 0 !important;
}

@media only screen and (max-width: 800px) {
  .cin_fetch_button {
    width: 100% !important;
    border-radius: 0px !important;
  }

  .searchInputContainer {
    display: flex;
    flex-direction: column;
    padding: 0px;
    gap: 0px;
    border-radius: 0 !important;
  }
}

.cin_details {
  width: 100%;
  height: 16.9375rem;
  margin-top: 13.125rem;
  background: var(--Blue_7) 0% 0% no-repeat padding-box;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cin_details_1024 {
  max-width: 64rem;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.cin_details_1 {
  display: flex;
  width: 64rem;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.0625rem solid #1d3a6d;
  height: 5.625rem;
}

.cin_img_div {
  width: 6.375rem;
  height: 5.625rem;

  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--Primary_white) 0% 0% no-repeat padding-box;
  opacity: 1;
}

.cin_img_div > img {
  width: 2.5rem;
  height: 2.5rem;
}

.cin_details_1_left {
  width: 22.4375rem;
  position: relative;
}

.cin_details_1_right {
  width: 22.4375rem;
  position: relative;
}

.cin_details_1_left::after {
  content: "";
  position: absolute;
  right: 0;
  top: -0.5rem;
  width: 0.0625rem;
  height: 3.75rem;
  background-color: #1d3a6d;
}

.cin_details_1_left > h4 {
  text-align: left;
  font: normal normal 600 1rem/1.375rem Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_white);
  opacity: 1;
}

.cin_details_1_left > h6 {
  text-align: left;
  font: normal normal normal 0.75rem/1.5625rem Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_grey);
  opacity: 1;
}

.cin_details_1_right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 41.5625rem;
  padding-left: 1.25rem;
}

.cin_details_1_right_cin_no > h6 {
  text-align: left;
  font: normal normal normal 0.75rem/1.5625rem Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_grey);
  opacity: 1;
}

.cin_details_1_right_cin_no > h4 {
  text-align: left;
  font: normal normal 600 1rem/1.375rem Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_white);
  opacity: 1;
}

.cin_tabs {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.25rem 0px;
}

.cin_tabs_1024 {
  max-width: 64rem;
  width: 64rem;
}

.cin_tabs_button {
  margin-bottom: 1.25rem;
  display: flex;
}

.cin_tabs_button_1 {
  width: fit-content !important;
  height: 1.6875rem !important;
  border-radius: 1.125rem !important;
  text-align: center !important;
  letter-spacing: 0px !important;
  margin-right: 1.25rem !important;
  text-transform: none !important;
  padding: 0.3125rem 1.25rem !important;
}

@media (min-width: 768px) {
  .cin_tabs_button_1:not(.active):hover {
    background-color: var(--Details_chip_hover) !important;
  }
}

@media (max-width: 768px) {
  .cin_tabs_button_1 {
    width: 100% !important;
    height: 39px !important;
    border-right: 1px solid #a3a3a3 !important;
    border-radius: 0 !important;
    margin: 0 !important;
  }

  .cin_tabs_button_1:last-child {
    border-right: none !important;
  }
}

@media (max-width: 768px) {
  .cin_tabs_button_1 {
    width: 100% !important;
    height: 39px !important;
    border-right: 1px solid #a3a3a3 !important;
    border-radius: 0 !important;
    margin: 0 !important;
  }

  .cin_tabs_button_1:last-child {
    border-right: none !important;
  }
}

.cin_details_slide {
  display: flex;
  align-items: center;
}

.cin_details_slide_con_cap {
  width: 20.5rem;
  height: 11.1875rem;
  background: var(--Blue_5) 0% 0% no-repeat padding-box;
  border-radius: 1.25rem;
  opacity: 0.8;
  margin-right: 1.25rem;
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cin_details_slide_con_cap > h4 {
  text-align: left;
  font: normal normal 600 0.875rem/1.1875rem Open Sans;
  letter-spacing: 0px;
  opacity: 1;
}

.cin_details_slide_con_cap_customer_no > h5 {
  text-align: left;
  font: normal normal normal 0.875rem/1.1875rem Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_white);
  opacity: 1;
}

.cin_details_slide_con_cap_customer_no > h6 {
  text-align: left;
  font: normal normal normal 0.75rem/1.0625rem Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_grey);
  opacity: 1;
}

.cin_details_slide_con_cap_email {
  padding: 0.625rem 0px;
}

.cin_details_slide_con_cap_email > h5 {
  text-align: left;
  font: normal normal normal 0.875rem/1.1875rem Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_white);
  opacity: 1;
}

.cin_details_slide_con_cap_email > h6 {
  text-align: left;
  font: normal normal normal 0.75rem/1.0625rem Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_grey);
  opacity: 1;
}

.cin_details_slide_con_cap_customer_no {
  padding: 0.625rem 0px;
  border-bottom: 0.0625rem solid #1d3a6d;
}

.cin_directors_slide {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.cin_directors_box {
  width: 15rem;
  height: 190px;
  background: var(--Blue_5) 0% 0% no-repeat padding-box;
  opacity: 1;
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0.625rem;
  margin-bottom: 14px;
  cursor: pointer;
  margin-right: 11px;
}

@media (max-width: 1024px) and (min-width: 768px) {
  .cin_directors_box {
    width: 26rem;
    max-width: 28rem;
    height: 160px;
    margin-bottom: 15px;
    margin-right: 15px;
    margin-left: 0px;
  }
}

@media (max-width: 768px) {
  .cin_directors_box {
    width: 90%;
    height: 105px;
    margin-bottom: 20px;
    margin-right: 20px;
    margin-left: 20px;
  }
}

.cin_directors_box:hover {
  background: #4c6a9e 0% 0% no-repeat padding-box;
}

.cin_directors_box_img > img {
  width: 3.125rem;
  height: 3.125rem;
  opacity: 1;
  margin-bottom: 0.625rem;
}

.cin_directors_box_img {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 5px;
}

@media (max-width: 1024px) and (min-width: 768px) {
  .cin_directors_box_img > img {
    width: 2.5rem;
    height: 2.5rem;
  }
}

@media (max-width: 768px) {
  .cin_directors_box_img > img {
    display: none;
  }
}

.cin_directors_box_img > h4 {
  width: 96%;
  max-width: 100%;
  height: 1.375rem;
  text-align: center;
  font: normal normal 600 16px/19px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_white);
  opacity: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  position: relative;
}

.cin_directors_box_details_left > h5 {
  text-align: left;
  font: normal normal 400 14px/19px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_white);
  opacity: 1;
}

.cin_directors_box_details_left > h6 {
  text-align: left;
  font: normal normal normal 0.75rem/1.5625rem Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_grey);
  opacity: 1;
}

@media (max-width: 1024px) and (min-width: 768px) {
  .cin_directors_box_img > h4 {
    /* text-align: left !important; */
    width: 100%;
    display: block;
    padding-bottom: 10px;
  }
}

@media (max-width: 768px) {
  .cin_directors_box_img > h4 {
    text-align: left !important;
    width: 100%;
    display: block;
    padding-bottom: 10px;
  }
}

.cin_directors_box_details {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.cin_directors_box_details > img {
  width: 22px;
  height: 22px;
  transform: rotate(180deg);
  cursor: pointer;
}

.cin_address_slide {
  width: 50.3125rem;
  height: 18.125rem;
  background: var(--Blue_5) 0% 0% no-repeat padding-box;
  border-radius: 1.25rem;
  opacity: 0.8;
  padding: 1.25rem;
  display: flex;
}

.cin_address_slide_left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 1.25rem;
}

.cin_address_slide_left > h5 {
  text-align: right;
  font: normal normal 600 1rem/1.375rem Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-bottom: 1.25rem;
}

.cin_address_slide_left > p {
  text-align: left;
  font: normal normal normal 0.875rem/1.1875rem Open Sans;
  letter-spacing: 0px;
  /* color: #ffffff; */
  opacity: 1;
  width: 24.8125rem;
  height: 3.5625rem;
}

.cin_address_slide_right {
  width: 21.75rem;
  height: 15.625rem;
}

.cin_default_img {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 18.75rem;
}

.cin_default_img > img {
  width: 25rem;
  height: 17.75rem;
  opacity: 1;
  margin-bottom: 2.5rem;
}

.cin_default_img > p {
  text-align: center;
  font: normal normal normal 1.25rem/1.6875rem Open Sans;
  letter-spacing: 0px;
  /* color: #fafafa; */
  opacity: 1;
  width: 424px;
  height: 54px;
}

.cin_loading_loader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 22.625rem;
}

.calendarHeader {
  width: 100%;
  background: var(--Blue_7);
  height: 49px;
  font-size: 14px;
  display: flex;
  justify-items: center;
  align-items: center;
  padding-left: 20px;
}
@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.popup-container {
  width: 40.085vw;
  max-width: 500px;
  position: fixed;
  top: 35%;
  left: 50%;
  padding: 1rem;
  transform: translate(-50%, -50%);
  background-color: var(--Blue_3);
  border-radius: 1rem;
  z-index: 9999;
  animation: slideInFromRight 0.4s ease-out forwards;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (min-width: 1200px) {
  .popup-container {
    width: 35vw;
    max-width: 480px;
    top: 30%;
    left: 35%;
  }
}

@media (max-width: 1199px) {
  .popup-container {
    width: 50vw;
    max-width: 450px;
    top: 30%;
    left: 25%;
  }
}

@media (max-width: 767px) {
  .popup-container {
    width: 80vw;
    max-width: 380px;
    top: 30%;
    left: 19%;
  }
}

@media (max-width: 480px) {
  .popup-container {
    width: 90vw;
    max-width: 320px;
    top: 32%;
    padding: 0.8rem;
    left: 11%;
  }
}

.dateheader {
  width: 100%;
  background: var(--Blue_7);
  padding: 15px;
  font-size: 14px;
  font-weight: 600;
}

.datecontent {
  width: 100%;
  padding: 15px;
  height: 95px;
}

.datediv {
  width: 20%;
  background: var(--Blue_7);
  padding: 10px 10px;
  text-align: center;
  border-radius: 10px;
  font-size: 14px;
  float: left;
  font-weight: 600;
}

.datedisc {
  width: 80%;
  padding: 15px 10px;
  float: left;
  font-size: 12px;
  font-weight: 600;
}

hr.new {
  margin: 0rem auto 0rem auto;
  overflow: hidden;
  border: 0px;
  border-bottom: 2px solid #1c396c;
  width: 90%;
}

hr.new1 {
  margin: 0rem auto 0rem auto;
  overflow: hidden;
  border: 0px;
  border-bottom: 2px solid var(--Blue_7);
  width: 90%;
}

::-ms-scrollbar {
  width: 12px;
}

::-ms-scrollbar-track {
  background: #d3d3d3;
}

::-ms-scrollbar-thumb {
  background: #fafafa;
  border-radius: 6px;
}

::-ms-scrollbar-thumb:hover {
  background: #d3d3d3;
}

@media only screen and (min-width: 1024px) {
  .calenderWeb {
    display: none;
  }
  .calenderMob {
    display: block;
  }
}

@media only screen and (max-width: 1024px) {
  .calenderMob {
    display: none;
  }
  .calenderWeb {
    display: block;
  }
}

.ScrollingBehavior {
  overflow-y: hidden;
  height: 100vh;
}

.inset-0.opened {
  animation: opened 0.5s ease-in forwards;
}

.inset-0.closed {
  animation: closed 0.3s ease-in forwards;
}

.defaultclose {
  height: 0;
  opacity: 0;
  overflow: hidden;
}

@keyframes opened {
  0% {
      height: 0;
      opacity: 0.5;
      overflow: hidden;
    }
    
    25%{
      height: 25vh;        
    }
  
    50% {
      height: 40vh;
    }

    75%{
      height: 65vh;
    }
  
    100% {
      height: 85vh;
      opacity: 1;
      overflow: hidden;
    }
}

@keyframes closed {
  0% {
    height: 85vh;
    /* opacity: 1; */
    overflow: hidden;
  }

  50% {
    height: 40vh;
  }

  100% {
    height: 0;
    opacity: 0;
    overflow: hidden;
  }
}
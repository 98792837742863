.main-div::-webkit-scrollbar {
    width: 7px;
}

.main-div::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

.main-div::-webkit-scrollbar-thumb {
    background-color: var(--Primary_white);
    outline: 1px solid var(--Blue_5);
    border-radius: 1.563rem;
    height: 0.625rem;
}

.main-div::-webkit-scrollbar-thumb:hover {
    background-color: var(--Primary_white);
}

.loaderImg{
    width: 3.125rem;
    height: 3.125rem;
}

.primaryDetailsandFunctionblock {
    margin-top: 2.5rem;
    padding-top: 4rem;
}

.infoBlock {
    width: 50rem;
}

.functionBlock {
    width: 14rem;
}

.functionBlockImg {
    width: 1.875rem;
    height: 1.875rem;
}

.bankImageBlock{
    width: 25rem;
}

.bankImage {
    width: 6.438rem;
    height: 5.75rem;
}

.ifscCodeBlock{
    width: 25rem;
}

.ifscCodeBlock_Error{
    width: 18rem;
}

.functionBlock_Error{
    width: 21rem;
}

.detailsButton{
    width: 5rem;
    height: 1.625rem;
    padding: 0.375rem 1.25rem;
    border-radius: 1.875rem;
}

.detailsCardBlock{
    border-radius: 1.25rem;
    width: 15.063rem;
}

.redirectBlockArrow{
    width: 1.375rem;
    height: 1.375rem;
    border-radius: 0.313rem;
    background-color: var(--Blue_3);
}

.arrowDimensions{
    height: 0.60rem;
}

.createdByName{
    width: 8.25rem;
}

.initiatorName{
    width: 8.25rem;
}

.internalImageDiv{
    position: absolute;
    width: 3.125rem;
    height: 3.125rem;
    bottom: 2.25rem;
    top: 0.65rem;
    right: -1.25rem;
    border-top-left-radius: 1.25rem;
    border-bottom-right-radius: 1.25rem;
    background-color: var(--Primary_white);
}

.internalAccImageDimensions{
    width: 1.875rem;
    height: 1.875rem;
}

.errorMsg{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
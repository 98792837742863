.activeone {
  width: 236px;
  height: 93px;
  display: flex;
  gap: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #55985c 0% 0% no-repeat padding-box;
  box-shadow: -5px -5px 11px #71717133;
  border-radius: 20px 0px 0px 0px;
  opacity: 1;
}

.activeoneMobile {
  width: 50%;
  height: 100%;
  display: flex;
  gap: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: "#9B76F 0% 0% no-repeat padding-box";

  box-shadow: -5px -5px 11px #71717133;
  /* border-radius: 20px 0px 0px 0px; */
  opacity: 1;
}

.activetwo {
  width: 236px;
  height: 93px;
  display: flex;
  gap: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #55985c 0% 0% no-repeat padding-box;
  border-radius: 0px 20px 0px 0px;
  opacity: 1;
}

.activetwoMobile {
  width: 50%;
  height: 100%;
  display: flex;
  gap: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #69b76f 0% 0% no-repeat padding-box;

  /* border-radius: 0px 20px 0px 0px; */
  opacity: 1;
}

.tabbutton1 {
  width: 236px;
  height: 93px;
  display: flex;
  gap: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 30px 0px 0px 0px;
  background-color: #4c8452;
  cursor: pointer;
  transition: background-color 0.7s ease, color 0.8s ease;
}

.tabbutton1Mobile {
  width: 50%;
  height: 100%;
  display: flex;
  gap: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #55985c;
  cursor: pointer;
  transition: background-color 0.7s ease, color 0.8s ease;
}

.tabbutton2 {
  width: 236px;
  height: 93px;
  display: flex;
  gap: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 0px 30px 0px 0px;
  background-color: #4c8452;
  cursor: pointer;
  transition: background-color 0.8s ease, color 0.8s ease;
}

.tabbutton2Mobile {
  height: 100%;
  width: 50%;
  display: flex;
  gap: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  /* border-radius: 0px 20px 0px 0px; */
  background-color: #55985c;

  cursor: pointer;
  transition: background-color 0.8s ease, color 0.8s ease;
}

.txnStatusCardVendorCreate{
  align-self: right;
  background-color: white;
  width: 85%;
  height: 75vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 40px;
}

.txnStatusCardVendorCreate>div:nth-child(1){
  display: flex;
  flex-direction: column;
  gap:10%;
}

.txnStatusCard1>p{
  font: normal normal 600 24px/33px Open Sans;
  letter-spacing: 0px;
  color: #000000;
}

.amountText {
  padding-top: 5%;
  font: normal normal 600 32px/43px Open Sans;
  letter-spacing: 0px;
  color: #27B882;
}

.threeOptions {
  margin-top: 100px;
  display: flex;
  justify-content: space-evenly;
  padding:5% 0;
  background: #7fb085 0% 0% no-repeat padding-box;
  box-shadow: -5px -5px 11px #71717133;
  border-radius: 40px;
  height: fit-content;
}

.threeOptions>div {
  cursor: pointer;
}

.threeOptions>div {
  cursor: pointer;
}
.logoWithText {
  display: flex;
  color: white;
  font: normal normal 600 1.2em Open Sans;
  flex-direction: column;
}
.logoWithText > div >img {
  width: 60px;
  height: 60px;
}

.detailsTextCreateVendor {
  text-align: left;
  font-style: Open Sans;
  font-size: 1.3em;
  letter-spacing: 0px;
  color: #000000;
  padding: 5% 10%;
}

.detailsTextCreateVendor>p{
  color: #828282;
  padding: 0;
  margin:0
}

.txnStatusCardVendorCreateName{
text-align: center;
font: normal normal 600 1.7em Open Sans;
letter-spacing: 0px;
padding-bottom: 5%;
color: #000000;
}

.paraNImgCreateVendorStatus {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.paraNImg div{
    margin-top: -40px;
}

@media only screen and (max-width: 480px) {
  .threeOptions {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: fit-content;
    width: 90%;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 40px 40px 0px 0px;
  }
  .logoWithText {
    position: a;
    display: flex;
    color: white;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 0;
  }
  .txnStatusCard1 {
    flex-grow: 1;
    box-shadow: none;
    width: 100%;
  }
}

.Inter_amount {
  width: 100%;
  height: 170px;
  background-color: #3b965e;
  display: flex;
  justify-content: center;
  text-align: center;
  border-radius: 20px;
  font-size: 32px;
  color: #ffffff;
}

.Internal_divideconfirm {
  margin: 0 10px;
  width: 472px;
}

.Internal_divideTxnconfirm {
  width: 472px;
  margin: 0px 10px;
}
.Internal_divideTxnconfirm_res {
  width: 100%;
}
.Internal_divideconfirm_res {
  width: 100%;
}

.second_side_div_Internal {
  margin-bottom: 20px;
  width: 472px;
  height: 159px;
  position: relative;
}

.Inter_settle_main_div_container_img_2_New {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 110px;
  height: 32px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 20px 0px;
  opacity: 1;
  object-fit: contain;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Inter_settle_main_div_container_img_2_New > img {
  width: 65px;
  height: auto;
}

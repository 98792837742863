.accountModalImage {
  margin: -3px 50px;
}
.accountModalBankNumber {
  margin: -3px 110px;
  padding: 10px;
}
.beneAccountCard {
  width: 100%;
  height: 100px !important;
  border-radius: 20px !important;
  box-shadow: 5px 5px 11px #00000033 !important;
  grid-column: span 1;
  background-color: #3a5686 !important;
  box-sizing: border-box;
  padding: 10px !important;
  padding-right: 1px !important;
}

@media screen and (min-width: 1900px) {
  .beneAccountCard {
    width: 266px;
  }
}

.beneAccountCard:hover .assignedTxn {
  display: block;
}

.allAccountCard {
  white-space: nowrap;
  width: 20rem;
  overflow: hidden;
}

.accountNameDiv {
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #b196fa;
  white-space: nowrap;
  text-transform: lowercase !important;
}

.accountNameDiv::first-line {
  text-transform: capitalize !important;
}

.accountNameSpan {
  color: #b0bbce;
  font-size: 10px;
  margin-bottom: 20px;
}
.statusSlide {
  width: 5px;
  height: 15px;
  background-color: #4ddd37;
  border-radius: 0px 5px 0px 0px;
}

.dataNotFound {
  display: flex;
  flex-direction: column;
  --tw-bg-opacity: 1;
  background-color: rgba(29, 58, 109, var(--tw-bg-opacity));
  width: 60%;
  gap: 12%;
  margin-top: 10px;
}

.infinite-scroll-component-beneAcc {
  width: 100%;
}

.assignedTxn-pending-box {
  background-color: rgba(49, 229, 229, 0.2);
  color: rgba(49, 229, 229, 1);
  border-radius: 0px 3px 0px 0px;
  text-transform: capitalize;
}

.assignedTxn-approved-box {
  background-color: rgba(77, 221, 55, 0.2);
  color: rgba(77, 221, 55, 1);
  border-radius: 0px 3px 0px 0px;
  text-transform: capitalize;
}

.assignedTxn-rejected-box,
.assignedTxn-blocked-box {
  background-color: rgba(255, 88, 96, 0.2);
  color: rgba(255, 88, 96, 1);
  border-radius: 0px 3px 0px 0px;
  text-transform: capitalize;
}

.assignedTxn-archived-box {
  background-color: rgba(207, 207, 207, 0.2);
  border-radius: 0px 3px 0px 0px;
  color: rgba(207, 207, 207, 1);
  text-transform: capitalize;
}
.input_searchDiv {
  width: 485px;
  height: 34px;
  background: #314d7e 0% 0% no-repeat padding-box;
  border: 1px solid #f05a6a;
  border-radius: 5px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0px 5px;
  transition: width 0.3s ease;
}

.input_searchDiv > input {
  width: 86%;
  outline: none;
  background-color: transparent;
  text-align: left;
  font: normal normal normal 14px/18px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
}

.input_searchDiv > input::placeholder {
  width: auto;
  height: 19px;
  text-align: left;
  font: normal normal normal 14px/18px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
}
.account_popup_cancel {
  width: 34px !important;
  height: 34px !important;
  background: #314d7e 0% 0% no-repeat padding-box !important;
  border-radius: 8px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  color: #fff;
}

.custom-scroll-container::-webkit-scrollbar {
  width: 8px;
}

.custom-scroll-container::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 8px;
}

.custom-scroll-container::-webkit-scrollbar-thumb {
  background-color: #dadada;
  border-radius: 8px;
}

.custom-scroll-container::-webkit-scrollbar-thumb:hover {
  background-color: #dadada;
  border-radius: 8px;
}

.label-container {
  color: #dadada;
}

.main_pop_div {
  width: 96vw;
  height: 92vh;
  position: fixed;
  top: 4%;
  left: 2%;
  z-index: 99999;
  overflow: hidden;
  border-radius: 20px;
}

@media screen and (max-width: 800px) {
  .main_pop_div {
    top: 0 !important;
  }
  .AccviewallMobile{
    background-color: #1d3a6d!important;
    opacity: 1!important;
  }
}

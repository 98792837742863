.multiSelectVendorCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background: inherit;
  width: 450px;
  height: 120px;
  border-radius: 40px;
  flex-shrink: 1;
  box-shadow: 3px 3px 3px 3px rgb(50, 47, 47);
  border-radius: 30px;
  flex-shrink: 1;
}

.multiSelectVendorCard:hover {
  background-color: #5676ad;
}

.ViewAll-amount-div {
  text-align: left;
  font: normal normal 600 20px/25px Open Sans;
  letter-spacing: 0px;
  color: #4ddd37;
  opacity: 1;
}

.upper-div-multiSelect {
  display: flex;
  justify-content: space-between;
  align-items: top;
  gap: 60px;
  flex-direction: row;
  width: fit-content;
  opacity: 1;
  top: 0px;
}
.second {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

/* .upper-div:hover .ViewAll-amount-div {
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease-out;
}
.upper-div:hover .date-div {
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease-out;
}
.upper-div:hover .status-text {
  display: inline;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
  transform: translateX(0px);
  transition: all 1s ease-in;
} */

.status-text {
  display: none;
  transform: translateX(100px);
}

.logo-div-viewall {
  background: white;
  height: 42px;
  width: 130px;
  border-radius: 30px 5px 30px 0px;
  align-self: flex-end;
}

.logo {
  margin-top: 8px;
}

.amount-div {
  height: 28px;
  font-family: Open Sans, Semibold;
  font-size: 20px;
  text-align: right;
}

.ViewAllaccount-div {
  /* padding-left: -3%; */
  height: 27px;
  width: 150px;
  color: #fafafa;
  font-family: Open Sans, Regular;
  font-size: 20px;
  text-align: center;
}

.amountWithLogo {
  display: flex;
  align-self: flex-end;
  gap: 5%;
}

@media screen and (max-width: 768px) {
  .recentTransactionsCard {
    flex-grow: 1;
    flex-shrink: 1;
  }
}

.selectRadioBT {
  padding: 0 5%;
  display: flex;
  flex-direction: column;
}
.instant-pay-logo {
  position: relative;
  background-color: white;
  width: 120px;
  border-radius: 0px 30px;
}

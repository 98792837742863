.createTxnBt {
  width: 286px;
  height: 54px;
  margin-bottom: -40px !important;
  font: normal normal normal 20px/14px Open Sans !important;
  letter-spacing: 0px !important;
  color: #ffffff !important;
  background: #e37718 0% 0% no-repeat padding-box !important;
  border-radius: 10px !important;
  text-transform: none !important;
}
.totalTxnSettle {
  text-align: center;
  font: normal normal 600 34px/46px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-bottom: 7%;
}
.estimatedDateSettleTxn {
  font: normal normal normal 15px/20px Open Sans;
  letter-spacing: 0px;
  color: #69b76f;
  opacity: 1;
  margin-bottom: 20%;
  height: 30px;
}
.SettleTxnContainer {
  background: #29487e 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  text-align: center;
  width: fit-content;
  flex-shrink: 1;
  flex-grow: 1;
  height: fit-content;
  margin: 0% 15% 0% 15%;
}
.singleSettleTxnContainer{
  border-radius: 20px;
  text-align: center;
  width: 60%;
  height: 100%;
  flex-shrink: 1;
  flex-grow: 1;
  margin: 0% 20% 0% 20%;
}
.estimatedDateSettleTxnSingle{
  font: normal normal normal 0.4em Open Sans;
  letter-spacing: 0px;
  color: #69b76f;
  opacity: 1;
  height: 30px;
  margin-top: 6%;
}
.settleTxnAmountText{
  text-align: center;
  font: normal normal 600 1.2em Open Sans;
  letter-spacing: 0px;
  color: #69B76F;
  opacity: 1;
  margin: 4% 0% 2% 0%;
}
.dashedLineSettleTxnStatus{
  margin: 10px 0px;
  height: 3px;
  background: repeating-linear-gradient(
    to right,
    transparent,
    transparent 20px,
    #000000 20px,
    #000000 40px
  );
  opacity: 0.2;
}
.detailsTextSettle {
  text-align: left;
  font: normal normal 600 18px/24px Open Sans;
  letter-spacing: 0px;
  color: #000000;
  margin: 20px;
}

.detailsTextSettle b{
  font: normal normal 600 18px/24px Open Sans;
  letter-spacing: 0px;
  color: #000000;
}

.detailsTextSettle p{
  margin: 0 0 15px 0;
  font: normal normal normal 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #828282;
}
.beneContact_Main {
  width: 100%;
  scroll-snap-align: start;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.dividecontact-crt-flow {
  width: 472px;
  max-height: 400vh;
  margin: 0px 20px;
}

.contactright {
  width: 100%;
}

.cateParent {
  height: 50px;
  margin-left: -16px;
  border-radius: 19px;
}

.cateImage {
  margin: 6px 9px;
}
.cateEditParent {
  height: 50px;
  margin-left: -16px;
  border-radius: 10px;
}

.cateEditImage {
  margin: 6px 9px;
}

.custom-scroll-container::-webkit-scrollbar {
  width: 8px;
}

.custom-scroll-container::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 8px;
}

.custom-scroll-container::-webkit-scrollbar-thumb {
  background-color: #dadada;
  border-radius: 8px;
}

.custom-scroll-container::-webkit-scrollbar-thumb:hover {
  background-color: #dadada;
  border-radius: 8px;
}

.label-container {
  color: #dadada;
}
.categoryName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
}
.divtitlebarcontact {
  display: none;
}
.contact-detail-legal-name {
  flex: 90%;
  padding-left: 10px;
}

@media screen and (max-width: 800px) {
  .form_Div {
    padding-top: 70px;
  }
}

@media screen and (max-width: 600px) {
  .beneContact_Main {
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 18px;
  }
  .categoryName {
    max-width: 250px !important;
  }
  .contact-detail-legal-name {
    flex: 80%;
    padding-left: 0px;
  }
  .contact-crt-mobile-view {
    margin-bottom: 80px;
  }
  .dividecontact-crt-flow {
    padding: 0 20px;
    margin: 0 !important;
    width: 100%;
  }
  .cateEditImage {
    width: 30px !important;
    height: 30px !important;
  }
  .dividecontact-contact-name-crt {
    width: 350px !important;
  }
  .select-all-font-size {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 437px) {
  .dividecontact-crt-select-all {
    margin-bottom: 10px;
  }
  .categoryName {
    max-width: 150px !important;
  }
  .cateEditImage {
    width: 25px !important;
    height: 30px !important;
  }
  .dividecontact-contact-name-crt {
    width: 260px !important;
  }
  .mobile-account-name{
    flex-direction: column;
    height: auto;
  }
  .mobile-account-name-div {
    width: auto;
    justify-content: flex-start;
  }
  .mobile-account-name-span {
    width: 14rem;
    font-size: 12px;
  }
}

@media screen and (max-width: 362px) {
  .cateEditImage {
    width: 15px !important;
  }
}

@media only screen and (max-width: 480px) {
  .approvalDetailsCard {
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
  }
}

.approvalDetailsCardRight {
  width: 50%;
  background: #4a6565 0% 0% no-repeat padding-box;
  box-shadow: -5px -5px 11px #71717133;
  border-radius: 40px;
}

.detailsText2 {
  text-align: left;
  font-style: Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  padding: 0 5%;
  display: flex;
  flex-direction: column;
  font-size: 1.1em;
  gap: 0.5vh;
}

.detailsText2>p {
  line-height: 0px;
}

.paraNImg {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5%;
}

.dashedBorderBox {
  color: #c9c9c9;
  border-spacing: 15px;
}

.dashedBorderBox>b {
  color: white;
}

.simpleBorderBox {
  margin-bottom: 3%;
  padding-bottom: 3%;
  border-bottom: 1px solid #A0A0A0;
  color: #c9c9c9;
  border-spacing: 15px;
}

.simpleBorderBox>b {
  color: white;
}

.noBorderBox>b {
  color: white;
}

.noBorderBox {
  color: #c9c9c9;
}

.paraNImg>img {
  padding: 1.5%;
  align-self: center;
  background: #fafafa 0% 0% no-repeat padding-box;
  border-radius: 18px;
}

.approvalDetailsCardRightHeading {
  font: normal normal 600 23px/32px Open Sans;
  text-transform: capitalize;
  letter-spacing: 0px;
  color: #fafafa;
  padding: 2%;
}

.approvalAmtDate>div {
  padding-top: 2%;
  font: normal normal 600 40px/55px Open Sans;
  letter-spacing: 0px;
  color: #69b76f;
}

.approvalAmtDate>p {
  padding-bottom: 2%;
  font: normal normal normal 18px/24px Open Sans;
  letter-spacing: 0px;
  color: #707070;
}

.approvalAmtDate {
  background: #def5ed 0% 0% no-repeat padding-box;
}

.paraNImg>b {
  color: #ffffff;
  width: 95%;
}

.approveDetailsBTs,
.approveDetailsBTsMobile {
  display: flex;
  justify-content: space-evenly;
  margin: 5%;
  width: 70%
}

.declineBt {
  background: #ea545b 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 31px !important;
}

.approveBt {
  background: #69b76f 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 28px !important;
}

.main_Container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.stepper_Div {
  width: 100%;
  background-color: #314c7f;
  height: 164px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  box-shadow: 2px 2px 10px 0px rgba(26, 26, 26, 0.733);
  position: fixed;
  top: 0;
  z-index: 99;
  left: 0;
}

.form_Div {
  width: 1024px;
  max-width: 1024px;
  padding-top: 186px;
  height: 100%;
  overflow: hidden;
  scroll-behavior: smooth;
  display: flex;
  scroll-snap-type: x mandatory;
  padding-bottom: 20px;
}

.form_Div_responsive {
  width: auto;
  padding-top: 80px;
  height: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth;
  display: flex;
  /* scroll-snap-type: x mandatory; */
  padding-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;
  /* padding: 15px !important; */
}

.divideconfirm {
  margin: 0 20px;
  width: 472px;
}

.stepper-bottom-shadow {
  box-shadow: 2px 2px 10px 0px rgba(26, 26, 26, 0.733);
}

@media screen and (max-width: 800px) {
  .stepper_Div {
    bottom: 0 !important;
    top: unset !important;
    height: 80px !important;
  }
  /* .form_Div {
    padding-top: 100px !important;
  } */
}

.txnStatusContainer {
  display: flex;
  gap: 5%;
  padding:0 10%;
  align-items: center;
  justify-content: center;
  background: #6ba673 0% 0% no-repeat padding-box;
  background-size: contain;
  height: 100vh;
  overflow: hidden;
}

.leftbody1 {
  text-align: center;
  font: normal normal 600 18px Open Sans;
  letter-spacing: 0px;
  color: hsl(0, 0%, 100%);
  width: 40%;
}

.leftbody1>h3{
  text-align: center;
  font: normal normal 600 1.5em Open Sans;
  letter-spacing: 0px;
  color: #FFFFFF;
}

.rightbody1 {
  width: 40%;
  display: flex;
  justify-content: center;

}

@media only screen and (max-width: 480px) {
  .txnStatusContainer {
    background-color: white;
  }
  .leftbody1 {
    color: black;
  }
  .rightbody1 {
    padding-bottom: 120px;
  }
}

.create-txn-container-top {
  margin: 0 100px 0 100px;
  display: flex;
  padding-top: 120px;
  justify-content: space-between;
}
.create-txn-headings {
  flex-direction: row;
}
.create-txn-container-title {
  text-align: left;
  font: normal normal normal 60px/82px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.create-txn-container-subtitle {
  text-align: left;
  font: normal normal normal 24px/33px Open Sans;
  letter-spacing: 0px;
  color: #f1f1f1;
  opacity: 1;
}

.create-txn-container-bottom {
  margin: 0 100px 100px 100px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.create-txn-menu-title {
  margin-top: 50px;
  text-align: left;
  font: normal normal 600 22px/30px Open Sans;
  letter-spacing: 0px;
  color: #ff7f33;
  text-transform: uppercase;
  opacity: 1;
}
.create-txn-menu-items {
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  gap: 60px;
}

.create-txn-menu-items span {
  margin-top: 80px;
  text-align: right;
  font: normal normal normal 12px/16px Open Sans;
  letter-spacing: 0px;
  color: #fafafa;
  text-transform: capitalize;
}

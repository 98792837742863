.balance-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #506994;
  height: 50px;
  border-radius: 10px;
  opacity: 1;
  padding: 5px 0px 5px 10px;
  padding-left: 10px;
  position: relative;
  margin-bottom: 10px;
  cursor: pointer;
}
.balance-card:hover {
  background-color: #60779d;
}

.balance-card:last-child {
  margin-bottom: 0px;
}

.balance-card .left-side-div {
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  align-items: flex-start;
}

.balance-card .left-side-div p {
  text-align: left;
  font: normal normal 600 14px/17px Open Sans;
  letter-spacing: 0px;
  color: #F9F9F9;
  opacity: 1;
}

.balance-card .left-side-div p:nth-child(2) {
  text-align: left;
  font: normal normal normal 10px/11px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
}

.balance-card .right-side-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.balance-card .right-side-div .right-first-child {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 5px;
}

.balance-card .right-side-div .right-first-child p {
  text-align: right;
  font: normal normal 600 14px/17px Open Sans;
  letter-spacing: 0px;
  color: #4DDD37;
  opacity: 1;
}

.balance-card .right-side-div .right-first-child p:nth-child(2) {
  text-align: right;
  font: normal normal normal 10px/11px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
}

@media only screen and (min-width:800px){
  .balance-card .right-side-div .right-first-child p:nth-child(2) {      
      width: 140px;      
    }
}

.balance-card .right-side-div .right-second-child {
  width: 29px;
  height: 50px;
  border-radius: 0px 10px 10px 0px;
  background: #667ca3 0% 0% no-repeat padding-box;
  border-radius: 0px 10px 10px 0px;
  opacity: 1;
  margin-left: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.balance-card .right-side-div .right-second-child img {
  height: 16px;
  width: 16px;
}

.balance-card .right-side-div .right-second-child:hover {
  background-color: #667ca3;
}

.arrowUpDown-div {
  position: absolute;
  height: 10px;
  background-color: green;
}

.arrowUpDown-div-up {
  position: absolute;
  top: 26px;
  right: 45px;
}

.arrowUpDown-div-down {
  position: absolute;
  top: 9px;
  right: 48px;
}

.css-zs07ro-MuiInputBase-root-MuiFilledInput-root {
  background: #3a5686 0% 0% no-repeat padding-box !important;
  color: white !important;
  border: none !important;
  border-radius: 10px !important;
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
  margin:0 !important;
}

#filled-basic-label {
  color: #c9c9c9;
  font-size: larger !important;
}

.textFieldContainer {
  display: flex;
  justify-content: space-between;
  flex-shrink: 1;
  height: 65px;
  width:446px !important;
}

.css-zs07ro-MuiInputBase-root-MuiFilledInput-root:before {
  border-bottom: none !important;
}

.css-zs07ro-MuiInputBase-root-MuiFilledInput-root:after {
  border-bottom: none !important;
}
.user-edit-bank-name {
  z-index: 1;
  position: relative;
  background: var(---4cb537-verified-green) 0% 0% no-repeat padding-box;
  background: #4cb537 0% 0% no-repeat padding-box;
  padding-top: 35px;
  bottom: 26px;
}
.editBank-logo-style {
  width: 115px !important;
  height: 47px !important;
  padding: 3px;
  background: #fafafa 0% 0% no-repeat padding-box;
  opacity: 1;
  margin: 23px -19px -43px 0px;
}
.edit-bank-name {
  z-index: 1;
  position: relative;
  background: var(---4cb537-verified-green) 0% 0% no-repeat;
  background: #4cb537 0% 0% no-repeat;
  padding-top: 13px;
  bottom: 26px;
  height: auto;
  width: 480px;
}
.edit-box-rounded {
  border-bottom-right-radius: 2rem;
  border-bottom-left-radius: 2rem;
}

.bank-crt-main-header {
  width: 440px;
  height: 22px;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600)
    var(--unnamed-font-size-16) / var(--unnamed-line-spacing-22)
    var(--unnamed-font-family-open-sans);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
  margin-bottom: 15px;
}

.bank-crt-main-header-address {
  width: 440px;
  height: 22px;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600)
    var(--unnamed-font-size-16) / var(--unnamed-line-spacing-22)
    var(--unnamed-font-family-open-sans);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
  margin-bottom: -6px;
}

.bank-crt-sub-header {
  width: 40px;
  height: 17px;
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) var(--unnamed-font-size-12) /
    var(--unnamed-line-spacing-17) var(--unnamed-font-family-open-sans);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(---dadada);
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
}

.beneEditAcc_holder_name_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 13px 0 0 -13px;
  padding: 0 30px;
}

.beneEditAcc_holder_span {
  margin-top: 0;
  margin-left: -13px;
  font-size: 12px;
  color: #fefefe;
}

.beneEditAcc_holder_span_div {
  margin: -5px 63px;
  padding-bottom: 13px;
  padding-top: 2px;
}

.beneEditAcc_holder_name {
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  margin: 0 0 0 -10px;
  text-wrap: wrap;
  word-wrap: break-word;
  flex: 1;
}
.custom-scroll-success-container::-webkit-scrollbar {
  width: 8px;
}

.custom-scroll-success-container::-webkit-scrollbar-track {
  background: inherit;
  border-radius: 8px;
}

.custom-scroll-success-container::-webkit-scrollbar-thumb {
  background-color: #f5f5dc;
  border-radius: 5px;
}

.custom-scroll-success-container::-webkit-scrollbar-thumb:hover {
  background-color: inherit;
  border-radius: 5px;
}

/*----------------------------------------*/
/* CSS
/*----------------------------------------*/
@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSans-Regular.eot");
  src: url("../fonts/OpenSans-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/OpenSans-Regular.woff2") format("woff2"),
    url("../fonts/OpenSans-Regular.woff") format("woff"),
    url("../fonts/OpenSans-Regular.ttf") format("truetype"),
    url("../fonts/OpenSans-Regular.svg#OpenSans-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.landingbody {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #ffffff !important;
  overflow-x: hidden;
}

.landingimg {
  vertical-align: middle;
  border-style: none;
  width: 100%;
}

.landingimg-fluid {
  max-width: 100%;
  height: auto;
}

.landingimg-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.landingcontainer {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.landingvideo {
  background-color: #ffffff;
  -webkit-box-shadow: 0 2px 14px rgba(33, 54, 61, 0.15);
  box-shadow: 0 2px 14px rgba(33, 54, 61, 0.15);
}

.landingvideo img {
  background-color: #ffffff;
  -webkit-box-shadow: 0 2px 14px rgba(33, 54, 61, 0.15);
  box-shadow: 0 2px 14px rgba(33, 54, 61, 0.15);
}

.aos-animate h6 {
  font-size: 1rem;
  margin-bottom: 0.1rem;
  font-family: inherit;
  font-weight: 700;
}

.carousel * {
  box-sizing: border-box;
  border-radius: 20px;
}

.carousel.carousel-slider {
  position: relative;
  margin: 0;
  overflow: hidden;
}

.carousel .control-dots {
  position: absolute;
  bottom: 0;
  margin: 10px 0;
  padding: 0;
  text-align: center;
  width: 100%;
  z-index: 1;
  display: none !important;
}

.carousel .thumbs-wrapper {
  margin: 42px auto !important;
  overflow: hidden;
  width: max-content;
}

element {
  transform: translate3d(-160px, 0px, 0px);
  transition-duration: 350ms;
}

/*---------------------
	animation CSS
-----------------------*/

.rw-words {
  display: inline;
  text-indent: 10px;
}

.rw-words-1 span {
  position: absolute;
  opacity: 0;
  overflow: hidden;
  color: #ffffff;
  -webkit-animation: rotateWord 15s linear infinite 0s;
  -moz-animation: rotateWord 15s linear infinite 0s;
  -o-animation: rotateWord 15s linear infinite 0s;
  -ms-animation: rotateWord 15s linear infinite 0s;
  animation: rotateWord 15s linear infinite 0s;
}

.rw-words-1 span:nth-child(2) {
  -webkit-animation-delay: 3s;
  -moz-animation-delay: 3s;
  -o-animation-delay: 3s;
  -ms-animation-delay: 3s;
  animation-delay: 3s;
  color: #ffffff;
}

.rw-words-1 span:nth-child(3) {
  -webkit-animation-delay: 6s;
  -moz-animation-delay: 6s;
  -o-animation-delay: 6s;
  -ms-animation-delay: 6s;
  animation-delay: 6s;
  color: #ffffff;
}

.rw-words-1 span:nth-child(4) {
  -webkit-animation-delay: 9s;
  -moz-animation-delay: 9s;
  -o-animation-delay: 9s;
  -ms-animation-delay: 9s;
  animation-delay: 9s;
  color: #ffffff;
}

.rw-words-1 span:nth-child(5) {
  -webkit-animation-delay: 12s;
  -moz-animation-delay: 12s;
  -o-animation-delay: 12s;
  -ms-animation-delay: 12s;
  animation-delay: 12s;
  color: #ffffff;
}

.rw-words-1 span:nth-child(1) {
  -webkit-animation-delay: 15s;
  -moz-animation-delay: 15s;
  -o-animation-delay: 15s;
  -ms-animation-delay: 15s;
  animation-delay: 15s;
  color: #ffffff;
}

@-webkit-keyframes rotateWord {
  0% {
    opacity: 0;
  }

  2% {
    opacity: 0;
    -webkit-transform: translateY(-30px);
  }

  5% {
    opacity: 1;
    -webkit-transform: translateY(0px);
  }

  17% {
    opacity: 1;
    -webkit-transform: translateY(0px);
  }

  20% {
    opacity: 0;
    -webkit-transform: translateY(30px);
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@-ms-keyframes rotateWord {
  0% {
    opacity: 0;
  }

  2% {
    opacity: 0;
    -ms-transform: translateY(-30px);
  }

  5% {
    opacity: 1;
    -ms-transform: translateY(0px);
  }

  17% {
    opacity: 1;
    -ms-transform: translateY(0px);
  }

  20% {
    opacity: 0;
    -ms-transform: translateY(30px);
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes rotateWord {
  0% {
    opacity: 0;
  }

  2% {
    opacity: 0;
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }

  5% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }

  17% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }

  20% {
    opacity: 0;
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

/*---------------------
	Helper CSS
-----------------------*/

.cardview-body h6 {
  font-size: 1rem;
  margin-bottom: 0.1rem;
  font-family: inherit;
  font-weight: 700;
}

.spad {
  padding: 40px 0px;
}

/*.section-title {line-height: 60px;}*/
.section-title h2 {
  margin-bottom: 20px;
  font-family: "Open Sans", sans-serif;
  line-height: 80px;
  font-size: 1.8rem;
}

.section-title p {
  margin-bottom: 0;
}

/*.section-btn {margin-top: 30px;}*/
.set-bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 0;
}

.vertical-center {
  padding: 100px 0px;
}

.vertical-center-inner {
  padding: 30px 0px;
}

.vertical-center-inner1 {
  padding: 100px 0px 0px 0px;
}

.vertical-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

#scrollUp {
  bottom: 10px;
  font-size: 24px;
  right: 30px;
  width: 50px;
  background-color: #ff5a60;
  color: #fff;
  text-align: center;
  height: 50px;
  line-height: 47px;
  border-radius: 50px;
  -webkit-transition: all linear 0.5s;
  transition: all linear 0.5s;
  -webkit-transform: translate(0, 200%);
  transform: translate(0, 200%);
  opacity: 0;
  -webkit-animation: ldt-slide-bottom-in 1s cubic-bezier(0, 0.5, 0.5, 1)
    forwards;
  animation: ldt-slide-bottom-in 1s cubic-bezier(0, 0.5, 0.5, 1) forwards;
}

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  background-color: #ff5a60;
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1000;
  transition: opacity 0.3s ease-in-out;
  opacity: 1;
  -webkit-animation: ldt-slide-bottom-in 1s cubic-bezier(0, 0.5, 0.5, 1)
    forwards;
  animation: ldt-slide-bottom-in 1s cubic-bezier(0, 0.5, 0.5, 1) forwards;
}

.scroll-to-top:hover {
  opacity: 1;
}

.scroll-up {
  bottom: 10px;
  font-size: 24px;
  right: 30px;
  width: 50px;
  background-color: #ff5a60;
  color: #fff;
  text-align: center;
  height: 50px;
  line-height: 47px;
  border-radius: 50px;
  -webkit-transition: all linear 0.5s;
  transition: all linear 0.5s;
  -webkit-transform: translate(0, 200%);
  transform: translate(0, 200%);
  opacity: 1;
  -webkit-animation: ldt-slide-bottom-in 1s cubic-bezier(0, 0.5, 0.5, 1)
    forwards;
  animation: ldt-slide-bottom-in 1s cubic-bezier(0, 0.5, 0.5, 1) forwards;
  position: fixed;
}

@keyframes ldt-slide-bottom-in {
  0% {
    -webkit-transform: translate(0, 200%);
    transform: translate(0, 200%);
    opacity: 0;
  }

  50% {
    -webkit-transform: translate(0, 100%);
    transform: translate(0, 100%);
    opacity: 1;
  }

  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}

@-webkit-keyframes ldt-slide-bottom-in {
  0% {
    -webkit-transform: translate(0, 200%);
    transform: translate(0, 200%);
    opacity: 0;
  }

  50% {
    -webkit-transform: translate(0, 100%);
    transform: translate(0, 100%);
    opacity: 1;
  }

  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}

.text-right {
  text-align: right !important;
}

#webview {
  display: block;
}

#mobileview {
  display: none;
}

.shapes-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  overflow: hidden;
}

.boxshadow {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 20px 30px -20px;
}

.shadow-box {
  -webkit-transition: -webkit-box-shadow 0.3s linear;
  transition: -webkit-box-shadow 0.3s linear;
  transition: box-shadow 0.3s linear;
  transition: box-shadow 0.3s linear, -webkit-box-shadow 0.3s linear;
  -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 6px rgba(0, 0, 0, 0.03);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 6px rgba(0, 0, 0, 0.03);
}

.landingscroll {
  position: absolute;
  display: inline-block;
  color: #dadada;
  bottom: 5%;
  z-index: 2;
  left: 45%;
  cursor: pointer;
}

.landingscroll:hover {
  text-decoration: none;
  color: #ff5a60;
}

.landingscroll > div {
  margin-top: 0px;
}

.landingscroll > div:after {
  content: "";
  width: 15px;
  height: 15px;
  position: absolute;
  top: 10px;
  margin: auto;
  right: 0;
  bottom: 0;
  left: 0;
}

.landingscroll > div:after {
  border-right: 2px solid #dadada;
  border-bottom: 2px solid #dadada;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.bounce {
  -moz-animation: bounce 3s infinite;
  -webkit-animation: bounce 3s infinite;
  animation: bounce 3s infinite;
}

@-moz-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }

  40% {
    -moz-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }

  60% {
    -moz-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }
}

@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }

  40% {
    -moz-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }

  60% {
    -moz-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }
}

@-ms-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }

  40% {
    -moz-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }

  60% {
    -moz-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }
}

@-o-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }

  40% {
    -moz-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }

  60% {
    -moz-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }

  40% {
    -moz-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }

  60% {
    -moz-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }
}

.appstoreimg {
  position: absolute;
  right: 36px;
  z-index: 4;
  bottom: 17%;
  width: 160px;
}

/*---------------------
	Commom elements
-----------------------*/

/* Buttons */
.site-btn {
  position: relative;
  display: inline-block;
  padding: 15px 30px;
  font-size: 18px;
  font-weight: 500;
  line-height: 16px;
  border-radius: 50px;
  font-family: "Open Sans", sans-serif;
  min-width: 100px;
  text-align: center;
  border: 2px solid #ff5a60;
  cursor: pointer;
  color: #ffffff;
}

.site-btn:hover {
  color: #212529;
}

.site-btn.no-radius {
  border-radius: 2px;
}

.site-btn.sb-full-- {
  display: block;
  width: 100%;
  border-radius: 0;
}

.site-btn.sb-gradients {
  padding: 10px 20px;
  border: none;
  color: #ffffff;
  margin: 5px 10px;
  cursor: pointer;
}

.site-btn.sb-gradients.sbg-line {
  color: #212529;
  z-index: 1;
  min-width: 100px;
}

.site-btn.sb-gradients.sbg-line:hover {
  color: #ffffff;
  z-index: 1;
}

.site-btn.sb-gradients.sbg-line:after {
  position: absolute;
  content: "";
  width: 96%;
  height: 90%;
  background: #ffffff;
  top: 2px;
  left: 2px;
  z-index: -1;
  border-radius: 50px;
  transition: background 0.5s ease;
}

.site-btn.sb-gradients.sbg-line:hover:after {
  background: transparent;
}

.site-btn.sb-gradients {
  height: 100%;
  background: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(#ff5a60),
    color-stop(100%, #0279ff),
    color-stop(100%, #0279ff),
    to(#0279ff)
  );
  background: -moz-linear-gradient(
    left,
    #ff5a60 0%,
    #0279ff 100%,
    #0279ff 100%,
    #0279ff 100%
  );
  background: -o-linear-gradient(
    left,
    #ff5a60 0%,
    #0279ff 100%,
    #0279ff 100%,
    #0279ff 100%
  );
  background: linear-gradient(
    to right,
    #ff5a60 0%,
    #0279ff 100%,
    #0279ff 100%,
    #0279ff 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5a60', endColorstr='#0279FF', GradientType=1);
}

.text-center {
  text-align: center !important;
}

.gradient-bg,
.sb-gradients {
  background: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(#ff5a60),
    color-stop(100%, #0279ff),
    color-stop(100%, #0279ff),
    to(#0279ff)
  );
  background: -moz-linear-gradient(
    left,
    #ff5a60 0%,
    #0279ff 100%,
    #0279ff 100%,
    #0279ff 100%
  );
  background: -o-linear-gradient(
    left,
    #ff5a60 0%,
    #0279ff 100%,
    #0279ff 100%,
    #0279ff 100%
  );
  background: linear-gradient(
    to right,
    #ff5a60 0%,
    #0279ff 100%,
    #0279ff 100%,
    #0279ff 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5a60', endColorstr='#0279FF', GradientType=1);
}

.shadow-lg {
  -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.browser {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(1%, #fff),
    color-stop(10%, #eef1f3)
  );
  background: linear-gradient(#fff 1%, #eef1f3 10%);
  position: relative;
  padding-top: 2em;
  border-radius: 0.4em;
}

.browser:before {
  display: block;
  position: absolute;
  content: "";
  top: 0.75em;
  left: 1em;
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  background: #f44;
  -webkit-box-shadow: 0 0 0 1px #f44, 1.5em 0 0 1px #9b3, 3em 0 0 1px #fb5;
  box-shadow: 0 0 0 1px #f44, 1.5em 0 0 1px #9b3, 3em 0 0 1px #fb5;
}

.iphonemain {
  background: #fff;
  max-width: 255px;
  border-radius: 26px;
  padding: 10px 0;
  -webkit-box-shadow: inset 0 4px 10px 1px #fff,
    inset 0 0 6px 0 rgba(66, 77, 86, 0.5), 0 2px 0 #aeb5bb,
    0 20px 50px -25px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 4px 10px 1px #fff, inset 0 0 6px 0 rgba(66, 77, 86, 0.5),
    0 2px 0 #aeb5bb, 0 20px 50px -25px rgba(0, 0, 0, 0.5);
  border: 1px solid #bfbfbf;
  position: absolute;
  left: 0%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  top: 0px;
}

.iphonemain .screen {
  width: 95%;
  margin: 0 auto;
  border: 1px solid #bfbfbf;
  border-radius: 15px;
}

.iphonemain .screen img {
  border-radius: 15px;
}

/*---------------------
	Header section
-----------------------*/

.header-section {
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 99;
  min-height: 65px;
  padding: 10px 0px;
}

.header-section .site-btn {
  float: right;
}

.header-section .responsive-bar,
.header-section .user {
  display: none;
}

.site-logo {
  float: left;
  width: 120px;
  margin-top: 10px;
}

.main-menu {
  /*float: left;*/
  text-align: center;
}

.menu-list {
  list-style: none;
}

.menu-list li {
  display: inline;
}

.menu-list li a {
  display: inline-block;
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  padding: 10px 5px;
  margin-right: 10px;
  color: #212529;
  cursor: pointer;
}

.menu-list li a:hover {
  color: #ff5a60;
}

.menu-list li.actived a {
  color: #ff5a60;
}

.header-section.sticky {
  position: fixed !important;
  background-color: #fff;
  left: 0;
  top: 0;
  right: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.11);
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.11);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.11);
  min-height: 65px;
  z-index: 1030;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

/*---------------------
	main Section
-----------------------*/

.main-section {
  position: relative;
  display: block;
  background-color: #ffffff;
  width: 100%;
  overflow: hidden;
}

.main-section .laptop-image {
  max-width: 685px;
  position: absolute;
  z-index: 2;
  left: 0px;
  margin-top: 80px;
}

.main-section .smartphone-image {
  width: 171px;
  max-width: none;
  position: relative;
  float: right;
  margin-top: 107px;
}

.main-text {
  margin-top: 80px;
}

.main-text h2 span {
  color: #ffffff;
  font-size: 1.8rem;
  margin-bottom: 20px;
  font-family: "Open Sans", sans-serif;
  font-weight: 900;
}

.main-text h4 {
  color: #ffffff;
  font-size: 1rem;
  font-family: "Open Sans", sans-serif;
  line-height: 21px;
}

.gradient.gradient-primary-auxiliary {
  background-image: -webkit-linear-gradient(
    45deg,
    #ff5a60 0,
    #6b6cbd 50%,
    #0279ff 100%
  );
  /* Chrome10-25, Saf5.1-Saf6, iOS -6.1, Android -4.3 */
  background-image: -moz-linear-gradient(
    45deg,
    #ff5a60 0,
    #6b6cbd 50%,
    #0279ff 100%
  );
  /* Fx3.6-15 */
  background-image: -o-linear-gradient(
    45deg,
    #ff5a60 0,
    #6b6cbd 50%,
    #0279ff 100%
  );
  /* Op11.10-12.02 */
  background-image: linear-gradient(
    45deg,
    #ff5a60 0,
    #6b6cbd 50%,
    #0279ff 100%
  );
  /* W3C, Fx16+, Chrome26+, IE10+, Op12.10+, Saf6.1+ */
}

.main-section .static-shape.ghost-shape {
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 50px;
}

.main-section .static-shape.ghost-shape-1 {
  top: 0;
  -webkit-transform: translate3D(0%, -84%, 0) skewX(0deg) rotate(0deg);
  -moz-transform: translate3D(0%, -84%, 0) skewX(0deg) rotate(0deg);
  -o-transform: translate3D(0%, -84%, 0) skewX(0deg) rotate(0deg);
  transform: translate3D(0%, -84%, 0) skewX(0deg) rotate(0deg);
  background-color: #ffffff;
  max-height: 430px;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.main-section .static-shape.pattern-dots-1 {
  bottom: 0;
  left: 0;
  width: 150px;
  height: 20%;
  background-color: transparent;
  background-image: -webkit-radial-gradient(
    rgba(255, 255, 255, 0.1) 3px,
    transparent 3px
  );
  background-image: -moz-radial-gradient(
    rgba(255, 255, 255, 0.1) 3px,
    transparent 3px
  );
  background-image: -o-radial-gradient(
    rgba(255, 255, 255, 0.1) 3px,
    transparent 3px
  );
  background-image: radial-gradient(
    rgba(255, 255, 255, 0.1) 3px,
    transparent 3px
  );
  background-size: 30px 30px;
}

.main-section .static-shape.pattern-dots-2 {
  top: 0;
  right: 0;
  width: 250px;
  height: 50%;
  background-color: transparent;
  background-image: -webkit-radial-gradient(
    rgba(255, 255, 255, 0.1) 3px,
    transparent 3px
  );
  background-image: -moz-radial-gradient(
    rgba(255, 255, 255, 0.1) 3px,
    transparent 3px
  );
  background-image: -o-radial-gradient(
    rgba(255, 255, 255, 0.1) 3px,
    transparent 3px
  );
  background-image: radial-gradient(
    rgba(255, 255, 255, 0.1) 3px,
    transparent 3px
  );
  background-size: 30px 30px;
}

.main-section .static-shape {
  position: absolute;
}

.main-section-image {
  pointer-events: none;
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  left: 0%;
  margin: 0px 26px;
  transform-origin: 0 20%;
  z-index: 3;
  top: 345px;
}

.main-section .phone-big {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 267px;
}

.main-section-image .phone-small {
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 0;
  order: 0;
  -ms-flex-item-align: start;
  align-self: flex-start;
  margin: 50px 60px 0 80px;
}

.iphonemain {
  max-width: 200px;
}

.main-section-google {
  pointer-events: none;
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 3;
  right: 0px;
  margin-top: 34px;
}

.main-section .landingcontainer {
  padding-bottom: 20rem;
}

.main-section [class^="landingcontainer"] {
  padding-top: 200px;
}

.main-section-image .landingcontainer {
  padding-bottom: 20rem;
}

/*---------------------
	inner Section
-----------------------*/

.inner-section {
  position: relative;
  display: block;
  background-color: #ffffff;
  padding: 50px 0px;
  width: 100%;
  overflow: hidden;
}

.landinginner-text {
  position: absolute;
  top: 20px;
}

.landinginner-text .privacy {
  color: #ffffff;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  font-size: 1.8rem;
  text-align: center;
}

.landinginner-text h4 {
  color: #ffffff;
  font-size: 1em;
  font-family: "Open Sans", sans-serif;
  line-height: 21px;
}

.gradient.gradient-primary-auxiliary {
  background-image: -webkit-linear-gradient(
    45deg,
    #ff5a60 0,
    #6b6cbd 50%,
    #0279ff 100%
  );
  /* Chrome10-25, Saf5.1-Saf6, iOS -6.1, Android -4.3 */
  background-image: -moz-linear-gradient(
    45deg,
    #ff5a60 0,
    #6b6cbd 50%,
    #0279ff 100%
  );
  /* Fx3.6-15 */
  background-image: -o-linear-gradient(
    45deg,
    #ff5a60 0,
    #6b6cbd 50%,
    #0279ff 100%
  );
  /* Op11.10-12.02 */
  background-image: linear-gradient(
    45deg,
    #ff5a60 0,
    #6b6cbd 50%,
    #0279ff 100%
  );
  /* W3C, Fx16+, Chrome26+, IE10+, Op12.10+, Saf6.1+ */
}

.inner-section .static-shape.ghost-shape {
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 50px;
}

.inner-section .static-shape.ghost-shape-1 {
  top: 0;
  -webkit-transform: translate3D(0%, -61%, 0) skewX(0deg) rotate(0deg);
  -moz-transform: translate3D(0%, -61%, 0) skewX(0deg) rotate(0deg);
  -o-transform: translate3D(0%, -61%, 0) skewX(0deg) rotate(0deg);
  transform: translate3D(0%, -61%, 0) skewX(0deg) rotate(0deg);
  background-color: #ffffff;
  max-height: 190px;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.inner-section .static-shape.pattern-dots-1 {
  bottom: 0;
  left: 0;
  width: 150px;
  height: 100%;
  background-color: transparent;
  background-image: -webkit-radial-gradient(
    rgba(255, 255, 255, 0.1) 3px,
    transparent 3px
  );
  background-image: -moz-radial-gradient(
    rgba(255, 255, 255, 0.1) 3px,
    transparent 3px
  );
  background-image: -o-radial-gradient(
    rgba(255, 255, 255, 0.1) 3px,
    transparent 3px
  );
  background-image: radial-gradient(
    rgba(255, 255, 255, 0.1) 3px,
    transparent 3px
  );
  background-size: 30px 30px;
}

.inner-section .static-shape.pattern-dots-2 {
  top: 0;
  right: 0;
  width: 250px;
  height: 100%;
  background-color: transparent;
  background-image: -webkit-radial-gradient(
    rgba(255, 255, 255, 0.1) 3px,
    transparent 3px
  );
  background-image: -moz-radial-gradient(
    rgba(255, 255, 255, 0.1) 3px,
    transparent 3px
  );
  background-image: -o-radial-gradient(
    rgba(255, 255, 255, 0.1) 3px,
    transparent 3px
  );
  background-image: radial-gradient(
    rgba(255, 255, 255, 0.1) 3px,
    transparent 3px
  );
  background-size: 30px 30px;
}

.inner-section .static-shape {
  position: absolute;
}

.content-inner-section {
  position: relative;
  display: block;
  background-color: #ffffff;
  padding-bottom: 10rem;
}

.content-inner-section .shape {
  position: absolute;
  z-index: 0;
  -webkit-box-shadow: -45px 85px 85px 5px rgba(24, 23, 21, 0.03);
  box-shadow: -45px 85px 85px 5px rgba(24, 23, 21, 0.03);
  border-radius: 65px;
  -webkit-transform: rotate(-35deg);
  transform: rotate(-35deg);
  width: 130px;
  height: 100%;
}

.content-inner-section .shape-1 {
  top: 0;
  left: 0;
}

.content-inner-section .shape-2 {
  top: 0;
  left: 16%;
}

.content-inner-section .shape-3 {
  top: 0%;
  left: 75%;
}

.content-inner-section .shape-4 {
  top: 0%;
  left: 70%;
}

.content-inner-section h4 {
  margin-bottom: 15px;
  font-family: "Open Sans", sans-serif;
  font-size: 1.5rem;
}

.content-inner-section p {
  margin-bottom: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  text-align: left;
}

/*---------------------
	features Section
-----------------------*/

.features-section {
  position: relative;
  display: block;
  background-color: #ffffff;
  padding: 150px 0px 80px 0px;
  width: 100%;
  overflow: hidden;
}

.features-section:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 100 310' fill='rgb(255,255,255)' fill-opacity='1'%3E%3Cpolygon points='0,310 100,310 100,0'%3E%3C/polygon%3E%3C/svg%3E");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  content: "";
  height: 310px;
  left: 0;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 1;
  top: -310px;
}

.features-section-inner {
  position: relative;
  display: block;
  background-color: #ffffff;
}

.features-section .shapes-container {
  overflow: unset;
}

.features-section .shape {
  position: absolute;
  z-index: 0;
  -webkit-box-shadow: -45px 85px 85px 5px rgba(24, 23, 21, 0.03);
  box-shadow: -45px 85px 85px 5px rgba(24, 23, 21, 0.03);
  border-radius: 65px;
  -webkit-transform: rotate(-35deg);
  transform: rotate(-35deg);
  width: 130px;
  height: 800px;
}

.features-section .shape-1 {
  top: 0;
  left: 0;
}

.features-section .shape-2 {
  top: 0;
  left: 16%;
}

.features-section .shape-3 {
  top: 0%;
  left: 75%;
}

.features-section .shape-4 {
  top: 0%;
  left: 70%;
}

.process-step {
  position: relative;
  text-align: center;
  padding: 25px;
  margin-top: 30px;
  margin-bottom: 30px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 2px 14px rgba(33, 54, 61, 0.15);
  box-shadow: 0 2px 14px rgba(33, 54, 61, 0.15);
  min-height: 375px;
}

.process-step:hover {
  -webkit-box-shadow: 0 14px 43px rgba(33, 54, 61, 0.15);
  box-shadow: 0 14px 43px rgba(33, 54, 61, 0.15);
}

.process-step .process-icon {
  height: 55px;
  margin-bottom: 30px;
  padding-top: 5px;
}

.process-step h4 {
  margin-bottom: 15px;
  font-family: "Open Sans", sans-serif;
  font-size: 1.5rem;
}

.process-step p {
  margin-bottom: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  text-align: center;
}

.process-step a {
  color: #689be8;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

.process-step a:hover {
  color: #ff5a60;
}

.online-payment {
  width: 55px;
  margin: 0px auto;
}

.security {
  width: 55px;
  margin: 0px auto;
}

.developer {
  width: 55px;
  margin: 0px auto;
}

/*---------------------
  How it work Section
-----------------------*/

.howitwork-section {
  position: relative;
  display: block;
  background-color: #ffffff;
  padding: 80px 0px 80px 0px;
  width: 100%;
  overflow: hidden;
}

.howitwork-section .shapes-container {
  overflow: unset;
}

.howitwork-section .shape {
  position: absolute;
}

.background-shape-main {
  background-color: #f3f4f6;
  idth: 100%;
  height: 100%;
  display: block;
  border-radius: 120px;
  z-index: -1;
}

.howitwork-section .background-shape-main {
  z-index: 0;
  left: 0;
  top: 10%;
  width: 500px;
  height: 720px;
  -webkit-transform: translate3d(-25%, 20%, 0) rotate(-30deg);
  transform: translate3d(-25%, 20%, 0) rotate(-30deg);
  background-image: linear-gradient(35deg, #fff 0, #f3f4f6 100%);
}

.iphone {
  background: #fff;
  max-width: 300px;
  border-radius: 36px;
  padding: 10px 0;
  -webkit-box-shadow: inset 0 4px 10px 1px #fff,
    inset 0 0 6px 0 rgba(66, 77, 86, 0.5), 0 2px 0 #aeb5bb,
    0 20px 50px -25px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 4px 10px 1px #fff, inset 0 0 6px 0 rgba(66, 77, 86, 0.5),
    0 2px 0 #aeb5bb, 0 20px 50px -25px rgba(0, 0, 0, 0.5);
  border: 1px solid #bfbfbf;
  margin: 0px auto;
}

.iphone .screen {
  width: 95%;
  margin: 0 auto;
  border: 1px solid #bfbfbf;
  border-radius: 25px;
  height: 580px;
}

.iphone .screen img {
  border-radius: 25px;
}

.howitwork-section .background-shape-main {
  z-index: 0;
  left: 0;
  top: 10%;
  width: 500px;
  height: 720px;
  -webkit-transform: translate3d(-25%, 20%, 0) rotate(-30deg);
  transform: translate3d(-25%, 20%, 0) rotate(-30deg);
  background-image: linear-gradient(35deg, #fff 0, #f3f4f6 100%);
}

.howitwork-section .owl-dot {
  height: 11px;
  width: 11px;
  border: 2px solid #a8aaab;
  border-radius: 50%;
  display: inline-block;
  margin: 0 6px;
  margin-top: 50px;
}

.howitwork-section .owl-dot.actived {
  background-color: #fb397d;
  border: transparent;
}

.howitwork-section .owl-dots {
  width: 100%;
  text-align: center;
  position: absolute;
}

.owl-item > div {
  cursor: pointer;
  margin: 12% 6%;
  text-align: center;
}

.owl-item.center > div {
  cursor: auto;
  margin: 0px auto;
}

.owl-item.center > div > img {
  cursor: auto;
  margin: 12px 2px 29px 2px;
  border-radius: 25px;
}

.owl-item:not(.center) > div {
  opacity: 0.5;
}

.owl-item:not(.center) > div:hover {
  opacity: 1;
  margin: 10% 4%;
}

/*---------------------
  Faqs Section
-----------------------*/

.faqs-section {
  position: relative;
  display: block;
  background-color: #ffffff;
  width: 100%;
  overflow: hidden;
}

.faqs-section .accordion {
  max-width: 750px;
  margin: 0px auto;
}

.faqs-section .accordion-item {
  position: relative;
  padding: 15px;
  margin: 20px auto;
  background-color: #ffffff;
  -webkit-box-shadow: 0 2px 14px rgba(33, 54, 61, 0.15);
  box-shadow: 0 2px 14px rgba(33, 54, 61, 0.15);
}

.faqs-section .accordion-item:hover {
  -webkit-box-shadow: 0 14px 43px rgba(33, 54, 61, 0.15);
  box-shadow: 0 14px 43px rgba(33, 54, 61, 0.15);
}

.faqs-section .accordion-title {
  border-radius: 0.25rem;
  background-color: #fff;
  border-bottom: 0;
  margin-bottom: 0;
  cursor: pointer;
  color: #75849a;
}

.faqs-section .accordion-title div {
  color: #75849a;
}

.faqs-section .accordion-title div:hover {
  color: #ff5a60;
}

.faqs-section .accordion-content {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 0.75rem 0px 0px 0px;
}

.faqs-section .accordion-content .open {
  display: block;
}

.faqs-section .accordion-content .close {
  display: none;
}

.faqs-section .card-header .plusminus {
  float: right;
  color: #4579c8;
}

.faqs-section .hide {
  display: none;
}

.faqs-section .icon {
  margin-left: 5px;
  margin-right: 15px;
  padding: 0;
  display: inline-block;
  text-align: center;
}

.faqs-section .icon {
  position: absolute;
  left: 0;
  top: 15%;
  font-size: 32rem;
  color: rgba(238, 245, 249, 0.5);
  line-height: 1;
}

.faqs-section .accordion-title .plusminusn {
  width: 48px;
  line-height: 57px;
  color: #4579c8;
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 30px;
  font-weight: bolder;
}

.faqs-section .accordion-title {
  cursor: pointer;
  width: 100%;
}

.faqs-section .accordion_search_container input {
  /*border-radius: 10px;*/
  height: 50px;
  padding: 0 50px;
  width: 100%;
  border: none;
  border-bottom: 1px solid #cccccc;
  background: url("../searchnew.png") #fff;
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: 9px;
}

/*---------------------
 underconstraction CSS
-----------------------*/

#page-wrapperlg {
  max-width: 1024px;
  margin: 12% auto;
}

#page-wrapperlginner {
  width: 100%;
  height: 100%;
}

.page-wrapperlginner-middle {
  padding: 15px;
}

.cardview {
  padding: 12px 12px 12px 12px;
  margin: 0px 0px 0px 0px;
  border: 2px solid #ffffff;
  border-radius: 10px;
  background-color: #fff;
  -webkit-box-shadow: 0 14px 43px rgba(33, 54, 61, 0.15);
  box-shadow: 0 14px 43px rgba(33, 54, 61, 0.15);
  outline: none;
}

/*.cardview:hover {-webkit-box-shadow: 0 14px 43px rgba(33, 54, 61, 0.15);box-shadow: 0 14px 43px rgba(33, 54, 61, 0.15);}*/
.page-wrapperlginner-middle h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.page-wrapperlginner-middle h2 span {
  color: #689be8;
}

.page-wrapperlginner-middle h4 {
  color: #75849a;
  font-size: 1em;
  font-family: "Open Sans", sans-serif;
  line-height: 21px;
}

.cardview-text {
  display: table;
  height: 100%;
  margin-bottom: 20px;
  width: 100%;
}

.cardview-body {
  display: table-cell;
  vertical-align: middle;
}

.cardview-body p {
  margin-bottom: 1rem;
}

.underconstraction-section {
  display: block;
}

.underconstraction-section h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.underconstraction-section h2 span {
  color: #689be8;
}

.underconstraction-section h4 {
  color: #75849a;
  font-size: 1em;
  font-family: "Open Sans", sans-serif;
  line-height: 21px;
}

.underconstraction-section .shapes-container {
  overflow: unset;
}

.underconstraction-section .shape {
  position: absolute;
  z-index: 1;
  -webkit-box-shadow: -45px 85px 85px 5px rgba(24, 23, 21, 0.03);
  box-shadow: -45px 85px 85px 5px rgba(24, 23, 21, 0.03);
  border-radius: 65px;
  -webkit-transform: rotate(-35deg);
  transform: rotate(-35deg);
  width: 130px;
  height: 800px;
}

.underconstraction-section .shape-1 {
  top: 0;
  left: 0;
}

.underconstraction-section .shape-2 {
  top: 0;
  left: 16%;
}

.underconstraction-section .shape-3 {
  top: 0%;
  left: 75%;
}

.underconstraction-section .shape-4 {
  top: 0%;
  left: 70%;
}

.underconstraction-section .footer {
  width: 100%;
  text-align: center;
  line-height: 20px;
  margin-top: 50px;
  bottom: 0px;
  color: #212529;
  font-size: 1rem;
  font-family: "Open Sans", sans-serif;
}

/*---------------------
	Footer Section
-----------------------*/

.footer-widget span {
  color: #acb9cc;
}

.footer-widget p {
  margin-bottom: 10px;
  margin-top: 25px;
}

.footer-widget .widget-title {
  margin-bottom: 10px;
  font-size: 20px;
  margin-top: 25px;
}

.footer-widget ul {
  list-style: none;
  margin-bottom: 15px;
}

.footer-widget ul a {
  font-size: 14px;
  color: #75849a;
  display: block;
  margin-bottom: 12px;
  cursor: pointer;
}

.footer-widget li a:hover {
  color: #ff5a60;
}

.footer-widget ul li:last-child a {
  margin-bottom: 0;
}

.footer-bottom {
  border-top: 1px solid #ebebeb;
  padding: 30px 0;
}

.footer-nav {
  list-style: none;
}

.footer-nav li {
  display: inline-block;
}

.footer-nav li a {
  color: #75849a;
  font-size: 14px;
  margin-left: 20px;
}

.footer .text-lg-right {
  text-align: right;
}

.visacard {
  width: 60px;
  height: 19px;
  background: url("../visa.png");
  background-size: cover;
  float: left;
  margin: 9px 10px 9px 0px;
}

.mastercard {
  width: 56px;
  height: 35px;
  background: url("../mastercard.png");
  background-size: cover;
  float: left;
  margin: 0px 10px;
}

.dinerscard {
  width: 50px;
  height: 36px;
  background: url("../diners_club.png");
  background-size: cover;
  float: left;
  margin: 0px 10px;
}

/*------------------
	Responsive
---------------------*/

@media (min-width: 576px) {
  .landingcontainer {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .landingcontainer {
    max-width: 720px;
  }

  .howitwork-section .background-shape-main {
    width: 1200px;
    top: 0;
  }

  .browser {
    bottom: auto;
    width: 100%;
  }

  .main-section-image {
    top: 150px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-left: 10px;
    left: 50%;
  }
}

@media (min-width: 992px) {
  .landingcontainer {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .landingcontainer {
    max-width: 1240px;
  }
}

.landinfcontainer-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.landingrow {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .coll,
.no-gutters > [class*="coll-"] {
  padding-right: 0;
  padding-left: 0;
}

.coll,
.coll-1,
.coll-10,
.coll-11,
.coll-12,
.coll-2,
.coll-3,
.coll-4,
.coll-5,
.coll-6,
.coll-7,
.coll-8,
.coll-9,
.coll-auto,
.coll-lg,
.coll-lg-1,
.coll-lg-10,
.coll-lg-11,
.coll-lg-12,
.coll-lg-2,
.coll-lg-3,
.coll-lg-4,
.coll-lg-5,
.coll-lg-6,
.coll-lg-7,
.coll-lg-8,
.coll-lg-9,
.coll-lg-auto,
.coll-md,
.coll-md-1,
.coll-md-10,
.coll-md-11,
.coll-md-12,
.coll-md-2,
.coll-md-3,
.coll-md-4,
.coll-md-5,
.coll-md-6,
.coll-md-7,
.coll-md-8,
.coll-md-9,
.coll-md-auto,
.coll-sm,
.coll-sm-1,
.coll-sm-10,
.coll-sm-11,
.coll-sm-12,
.coll-sm-2,
.coll-sm-3,
.coll-sm-4,
.coll-sm-5,
.coll-sm-6,
.coll-sm-7,
.coll-sm-8,
.coll-sm-9,
.coll-sm-auto,
.coll-xl,
.coll-xl-1,
.coll-xl-10,
.coll-xl-11,
.coll-xl-12,
.coll-xl-2,
.coll-xl-3,
.coll-xl-4,
.coll-xl-5,
.coll-xl-6,
.coll-xl-7,
.coll-xl-8,
.coll-xl-9,
.coll-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.coll {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.coll-auto {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.coll-1 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.coll-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.coll-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.coll-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.coll-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.coll-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.coll-7 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.coll-8 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.coll-9 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.coll-10 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.coll-11 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.coll-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

@media (min-width: 576px) {
  .coll-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .coll-sm-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .coll-sm-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .coll-sm-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .coll-sm-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .coll-sm-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .coll-sm-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .coll-sm-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .coll-sm-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .coll-sm-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .coll-sm-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .coll-sm-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .coll-sm-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .coll-sm-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .coll-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .coll-md-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .coll-md-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .coll-md-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .coll-md-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .coll-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .coll-md-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .coll-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .coll-md-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .ol-md-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .coll-md-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .coll-md-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .coll-md-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .coll-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 992px) {
  .coll-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .coll-lg-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .coll-lg-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .coll-lg-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .coll-lg-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .coll-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .coll-lg-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .coll-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .coll-lg-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .coll-lg-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .coll-lg-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .coll-lg-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .coll-lg-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .coll-lg-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 1200px) {
  .coll-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .coll-xl-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .coll-xl-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .coll-xl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .coll-xl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .coll-xl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .coll-xl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .coll-xl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .coll-xl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .coll-xl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .coll-xl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .coll-xl-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .coll-xl-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .coll-xl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .iphone {
    max-width: 246px;
  }

  .iphone .screen {
    height: 480px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /*.menu-list li a {padding: 10px 5px;margin-right: 4px;color: #212529;}*/
  .header-section .responsive-bar {
    float: right;
    font-size: 25px;
    display: block;
    cursor: pointer;
    margin-top: 5px;
  }

  .main-section .laptop-div {
    margin-top: 0px;
    display: block;
  }

  .main-menu {
    float: none;
    position: absolute;
    width: 100%;
    left: 0;
    top: 100%;
    background: #ffffff;
    padding: 0 15px;
    -webkit-box-shadow: 0 14px 43px rgba(33, 54, 61, 0.15);
    box-shadow: 0 14px 43px rgba(33, 54, 61, 0.15);
  }

  .menu-list {
    list-style: none;
  }

  .menu-list li {
    display: block;
    border-top: 1px solid #e1e1e1;
    text-align: left;
  }

  .iphone {
    max-width: 246px;
  }

  .iphone .screen {
    height: 474px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .header-section .responsive-bar {
    float: right;
    font-size: 25px;
    display: block;
    cursor: pointer;
    margin-top: 5px;
  }

  .main-section .laptop-div {
    margin-top: 0px;
    display: block;
  }

  .main-menu {
    float: none;
    position: absolute;
    width: 100%;
    left: 0;
    top: 100%;
    background: #ffffff;
    padding: 0 15px;
    -webkit-box-shadow: 0 14px 43px rgba(33, 54, 61, 0.15);
    box-shadow: 0 14px 43px rgba(33, 54, 61, 0.15);
  }

  .menu-list {
    list-style: none;
  }

  .menu-list li {
    display: block;
    border-top: 1px solid #e1e1e1;
    text-align: left;
  }

  .menu-list li a {
    display: block;
    padding: 10px 5px;
    margin-right: 0;
    color: #20509e;
  }

  .iphone {
    max-width: 334px;
    height: 681px;
  }

  .owl-carousel {
    margin: 0px 15px;
  }

  .appstoreimg {
    bottom: -15%;
  }

  .carousel .thumbs-wrapper {
    display: none;
  }

  #webview {
    display: none;
  }

  #mobileview {
    display: block;
  }
}

@media only screen and (min-width: 481px) and (max-width: 767px) {
  .header-section .responsive-bar {
    float: right;
    font-size: 25px;
    display: block;
    cursor: pointer;
    margin-top: 5px;
  }

  .main-section .laptop-div {
    margin-top: 0px;
    display: block;
  }

  .main-menu {
    float: none;
    position: absolute;
    width: 100%;
    left: 0;
    top: 100%;
    background: #ffffff;
    padding: 0 15px;
    -webkit-box-shadow: 0 14px 43px rgba(33, 54, 61, 0.15);
    box-shadow: 0 14px 43px rgba(33, 54, 61, 0.15);
  }

  .menu-list {
    list-style: none;
  }

  .menu-list li {
    display: block;
    border-top: 1px solid #e1e1e1;
    text-align: left;
  }

  .menu-list li a {
    display: block;
    padding: 10px 5px;
    margin-right: 0;
    color: #20509e;
  }

  .iphone {
    max-width: 334px;
    height: 681px;
  }

  .appstoreimg {
    bottom: -15%;
  }

  .carousel .thumbs-wrapper {
    display: none;
  }

  #webview {
    display: none;
  }

  #mobileview {
    display: block;
  }
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .header-section .responsive-bar {
    float: right;
    font-size: 25px;
    display: block;
    cursor: pointer;
    margin-top: 5px;
  }

  .main-section .laptop-div {
    margin-top: 0px;
    display: block;
  }

  .main-menu {
    float: none;
    position: absolute;
    width: 100%;
    left: 0;
    top: 100%;
    background: #ffffff;
    padding: 0 15px;
    -webkit-box-shadow: 0 14px 43px rgba(33, 54, 61, 0.15);
    box-shadow: 0 14px 43px rgba(33, 54, 61, 0.15);
  }

  .menu-list {
    list-style: none;
  }

  .menu-list li {
    display: block;
    border-top: 1px solid #e1e1e1;
    text-align: left;
  }

  .menu-list li a {
    display: block;
    padding: 10px 5px;
    margin-right: 0;
    color: #20509e;
  }

  .landingscroll {
    display: none;
  }

  .main-text {
    margin-top: 0px;
  }

  .main-section .laptop-image {
    margin-top: 20px;
  }

  .section-title h2 {
    line-height: 40px;
  }

  .process-step {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .features-section .shape-2 {
    top: 30%;
    left: 16%;
  }

  .features-section .shape-4 {
    top: 70%;
    left: 70%;
  }

  .iphone {
    max-width: 92%;
    width: 92%;
  }

  .iphone .screen {
    height: 98%;
  }

  .owl-carousel .owl-item img {
    width: 94.5%;
  }

  .owl-carousel {
    margin: 0px 8px;
  }

  .main-section [class^="landingcontainer"] {
    padding-top: 120px;
  }

  .main-section-image .phone-small {
    margin: 50px 60px 0 60px;
    width: 40%;
  }

  .appstoreimg {
    bottom: 4%;
  }

  .carousel .thumbs-wrapper {
    display: none;
  }

  #webview {
    display: none;
  }

  #mobileview {
    display: block;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .header-section .responsive-bar {
    float: right;
    font-size: 25px;
    display: block;
    cursor: pointer;
    margin-top: 5px;
  }

  .main-section .laptop-div {
    margin-top: 0px;
    display: block;
  }

  .main-menu {
    float: none;
    position: absolute;
    width: 100%;
    left: 0;
    top: 100%;
    background: #ffffff;
    padding: 0 15px;
    -webkit-box-shadow: 0 14px 43px rgba(33, 54, 61, 0.15);
    box-shadow: 0 14px 43px rgba(33, 54, 61, 0.15);
  }

  .menu-list {
    list-style: none;
  }

  .menu-list li {
    display: block;
    border-top: 1px solid #e1e1e1;
    text-align: left;
  }

  .menu-list li a {
    display: block;
    padding: 10px 5px;
    margin-right: 0;
    color: #20509e;
  }

  .landingscroll {
    display: none;
  }

  .iphone {
    max-width: 92%;
    width: 92%;
  }

  .iphone .screen {
    height: 98%;
  }

  .process-step {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .section-title h2 {
    line-height: 30px;
  }

  .owl-carousel .owl-item img {
    width: 94%;
  }

  .owl-carousel {
    margin: 0px 13px;
  }

  .main-section [class^="landingcontainer"] {
    padding-top: 50px;
  }

  .main-section-image .phone-small {
    margin: 50px 60px 0 60px;
    width: 40%;
  }

  .appstoreimg {
    bottom: 4%;
  }

  .carousel .thumbs-wrapper {
    display: none;
  }

  #webview {
    display: none;
  }

  #mobileview {
    display: block;
  }
}

@media only screen and (max-width: 320px) {
  .header-section .responsive-bar {
    float: right;
    font-size: 25px;
    display: block;
    cursor: pointer;
    margin-top: 5px;
  }

  .main-menu {
    float: none;
    position: absolute;
    width: 100%;
    left: 0;
    top: 100%;
    background: #ffffff;
    padding: 0 15px;
    -webkit-box-shadow: 0 14px 43px rgba(33, 54, 61, 0.15);
    box-shadow: 0 14px 43px rgba(33, 54, 61, 0.15);
  }

  .menu-list {
    list-style: none;
  }

  .menu-list li {
    display: block;
    border-top: 1px solid #e1e1e1;
    text-align: left;
  }

  .menu-list li a {
    display: block;
    padding: 10px 5px;
    margin-right: 0;
    color: #20509e;
  }

  .landingscroll {
    display: none;
  }

  .iphone {
    max-width: 90%;
    width: 90%;
  }

  .iphone .screen {
    height: 98%;
  }

  .process-step {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .section-title h2 {
    line-height: 30px;
  }

  .owl-carousel .owl-item img {
    width: 93%;
  }

  .main-section [class^="landingcontainer"] {
    padding-top: 50px;
  }

  .main-section-image .phone-small {
    margin: 50px 60px 0 60px;
    width: 40%;
  }

  .appstoreimg {
    bottom: 4%;
  }

  .carousel .thumbs-wrapper {
    display: none;
  }

  #webview {
    display: none;
  }

  #mobileview {
    display: block;
  }
}

.selectexistinvendor-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: #4e4a5e;
  padding: 5% 2% 1% 5%;
  z-index: 1;
  border-radius: 40px;
}

.selectexistinvendor-top>div {
  text-align: right;
  font: normal normal 600 1.73em/2.3em Open Sans;
  letter-spacing: 0px;
  color: #fafafa;
  opacity: 1;
}


.selectexistinvendor-acc {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5em;
  padding: 1% 5%;
  overflow: hidden;
  overflow-y: scroll;
  height: 90%;
}

.selectexistinvendor-acc::-webkit-scrollbar {
  width: 10px;
}

.selectexistinvendor-acc::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

.selectexistinvendor-acc::-webkit-scrollbar-thumb {
  background-color: #eeeeee;
  outline: 1px solid slategrey;
  border-radius: 25px;
  height: 100px;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #252525;
  opacity: 0.9;
  z-index: 9998;
}

/* .selectexistacc-create {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5em;
  padding: 1% 5%;
  overflow: hidden;
  overflow-y: scroll;
  height: 90%;
} */

.selectexistacc-create::-webkit-scrollbar {
  width: 10px;
}

.selectexistacc-create::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

.selectexistacc-create::-webkit-scrollbar-thumb {
  background-color: #eeeeee;
  outline: 1px solid slategrey;
  border-radius: 25px;
  height: 75px;
}

.items {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.container-drawer {
  background: inherit 0% 0% no-repeat padding-box;
}

.expandable {
  overflow: hidden;
  transition: all .5s ease-out;
  -webkit-transition: all .5s ease-out;
}

.expandable:target {
  height: auto;
}

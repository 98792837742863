.arrow_red {
  position: absolute;
  cursor: pointer;
  width: 25px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrow_red > img {
  width: 15px;
  height: 15px;
  animation: animateRed 2s infinite;
}

@keyframes animateRed {
  0% {
    opacity: 0;
    transform: translate(0px, 3px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate(0px, 10px);
  }
}

.arrow_green {
  position: absolute;
  cursor: pointer;
  width: 25px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrow_green > img {
  width: 15px;
  height: 15px;
  animation: animateGreen 2s infinite;
}

@keyframes animateGreen {
  0% {
    opacity: 0;
    transform: translate(0px, 10px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate(0px, 3px);
  }
}

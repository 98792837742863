.payMethodContainer {
  width: 90%;
  background: #667ca3 0% 0% no-repeat padding-box;
  border-radius: 20px;
  display: flex;
  align-items: center;
  gap: 5%;
  height: 88px;
}

.active {
  width: 90%;
  background: #DEE3EE;
  border-radius: 20px;
  display: flex;
  align-items: center;
  gap: 5%;
  height: 144px;
}

.methodLogo {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 20px 0px 0px 20px;
  height: 100%;
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.paymethodAmount > span {
  font: normal normal normal 14px/27px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
}

.paymethodAmount > b {
  font: normal normal 600 26px/27px Open Sans;
  letter-spacing: 0px;
  color: #4de659;
}

.active .paymethodAmount > b {
  color: #69B76F;
}

.paymethodAmount {
  text-align: left;
}
.payMethodActive {
  display: none;
}
.active .payMethodActive {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  font: normal normal normal 16px/38px Open Sans;
  letter-spacing: 0px;
  color: #446aff;
}

.payMethodInput {
  width: 95px;
  height: 43px;
  /* UI Properties */
  background: #efefef 0% 0% no-repeat padding-box !important;
  border: 1px solid #446aff !important;
  border-radius: 10px;
  text-align: center !important;
  font: normal normal 600 18px/27px Open Sans !important;
  letter-spacing: 0px !important;
  color: #446aff !important;
}

.paymethodRight {
  width: 80%;
  padding-right: 50px;
}

.custom-scroll-success-container::-webkit-scrollbar {
  width: 8px;
}

.custom-scroll-success-container::-webkit-scrollbar-track {
  background: inherit;
  border-radius: 8px;
}

.custom-scroll-success-container::-webkit-scrollbar-thumb {
  background-color: #f5f5dc;
  border-radius: 5px;
}

.custom-scroll-success-container::-webkit-scrollbar-thumb:hover {
  background-color: inherit;
  border-radius: 5px;
}

.detailsText-editcontact {
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  padding: 15px;
  width: 100%;
}
